/**
 * 避難状況登録・詳細画面ベースモジュール。
 * @module app/view/page/_EvacOrderPageBase
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/data/ItemFileWriteStore',
    'dojo/date/locale',
    'dojo/dom',
    'dojo/dom-style',
    'dojo/json',
    'dojo/on',
    'dojo/topic',
    'dojo/promise/all',
    'dijit/TooltipDialog',
    'dijit/popup',
    'dstore/Memory',
    'dstore/Trackable',
    'idis/view/page/_PageBase',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/map/IdisMap',
    'idis/model/UserInfo',
    'app/evacorder/DistrictLayerFactory',
    'app/model/DisasterInfo',
    './EvacOrderCheckTree',
    '../config',
    './DistributionType',
    './EvacOrderType',
    './EvacOrderTypeModel',
    './EvacOrderConfig',
    './Reason',
    './_EvacOrderProvidePageBase',
    './EvacOrderUtil',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/ValidationTextBox',
    'idis/view/form/WordCountTextarea',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    // 'dojox/form/Uploader',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'app/view/form/OrganizationSelector',
    'app/shelter/ShelterOpeningGrid'
    // 'app/provide/view/ProvideRegisterCityDialog',
], function (module, declare, lang, array, ItemFileWriteStore, locale, dom, domStyle, json, on,
    topic, all, TooltipDialog, popup, Memory, Trackable, _PageBase, Router, Requester,
    DialogChain, InfoDialog, IdisMap, UserInfo, DistrictLayerFactory, DisasterInfo, EvacOrderCheckTree,
    config, DistributionType, EvacOrderType, EvacOrderTypeModel, EvacOrderConfig, Reason,
    _EvacOrderProvidePageBase, EvacOrderUtil) {
    /**
     * 地区レイヤー選択時の避難区分別選択色
     * @type {Object}
     * @private
     */
    var _orderTypeSelectColorMap = {
        '01': '#FF2800',
        '02': '#FC002E',
        '03': '#AA00AA',
        '04': '#0C000C',
        '09': '#FFFF00',
        '90': '#808080',
        '11': '#FF2800',
        '13': '#AA00AA',
        '14': '#0C000C',
        '19': '#FFFF00',
        '80': '#808080'
    };

    /**
     * 避難状況登録画面。
     * @class EvacOrderRegisterPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _EvacOrderProvidePageBase,
    /** @lends module:app/evacorder/_EvacOrderRegisterPage~_EvacOrderRegisterPage# */ {
            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--evacorder',

            // お知らせダイアログ
            infoDialog: null,

            // formの内容をpostするためのストア
            formStore: null,

            // ツリー
            tree: null,

            // 地域選択ツリー用のストア
            treeStore: null,

            // 地域選択ツリーのクラス
            treeClass: EvacOrderCheckTree,

            // ページで保持する災害ID
            _disasterId: null,

            // ページで保持するログインユーザの組織情報
            _orgCd: null,

            // ページで保持する市町村コード
            _municipalityCd: null,

            // ユーザーの管理対象市町コード
            _userMunicipalityCds: [],

            // 避難状況ページのレイヤーコントロール
            _layerControl: null,

            // 地図情報filter用の地区ツリーのキャッシュデータ
            _districtTreeCache: null,

            // 選択された避難区分
            _evacOrderType: null,

            _issueReasonLists: [],

            _autoNumSet: {},

            // 県の市町区コード
            PREF_MUNICIPALITY_CODE: config.municInfo.prefMunicCd,

            // 県名
            PREF_NAME: config.municInfo.prefName,

            // 市の市町村コード
            KUMAMOTOCITY_MUNICIPALITY_CODE: config.municInfo.cityMunicCd,

            // 県ユーザでログインした場合のデフォルトコード
            DEFAULT_MUNICIPALITY_CODE: config.municInfo.defaultMunicCd,

            // s県の緯度経度
            INIT_LATLNG: { lat: config.map.latitude, lng: config.map.longitude },

            CORRECT_CANCEL_FLG: {
                TRUE: '1',
                FALSE: '0'
            },
            LATEST_LAYER_STATE: {
                ON: '1',
                OFF: '0'
            },

            // 避難情報の現況(LATEST_STATE)と発令情報を選択（SELECT）するレイヤーに設定するidを指定する。
            // このidをみて、それぞれのレイヤーを判定する。
            // FIXME 暫定的に1000を設定。適切なidを設定するようにする。
            SELECT_LAYER_NO: 100000,
            LATEST_STATE_LAYER_NO: null,

            /**
             * 最後に選択した避難理由のコード
             * @type {string}
             * @private
             */
            _lastReason: null,

            /**
             * 修正用ｎページかどうか
             * @type {boolean}
             * @protected
             */
            isFixPage: false,

            constructor: function () {
                // ダイアログ連鎖を登録
                this.chain = DialogChain.get(this);
                // 避難世帯数・避難者数編集グリッドの初期化
                this.populationStore = Trackable.create(new Memory({
                    idProperty: 'districtCd',
                    data: {
                        items: []
                    }
                }));
                this.populationCollection = this.populationStore.sort('order');
            },

            // DOMノード構築後に呼ばれる
            postCreate: function () {
                this.inherited(arguments);
                if (!this.populationGrid) {
                    // 避難情報一覧画面の場合は、処理を中断
                    return;
                }

                // グリッド上は地区コード順に並ぶようにする
                this.populationGrid.set('collection', this.populationCollection);
                // 理由変更時に人数を更新
                this.own(this.issueReasonType.on('change', lang.hitch(this, '_updatePopulationStore')));
                // 合計数の表示を反映
                this.own(this.populationCollection.on('add, delete, update', lang.hitch(this, '_updateEvacSummary')));
                this._updateEvacSummary();
            },

            // HTML上にウィジェットが設置されてから呼ばれる
            startup: function () {
                this.inherited(arguments);

                // Cookieから取得した災害IDを取得する。
                this._disasterId = DisasterInfo.getDisasterId();

                // 一覧画面から渡された市町村名を取得する。
                this._userMunicipalityCds = UserInfo.getMunicipalityCds();

                // 地図をレイヤーに追加する。
                this.initMap();

                // ログインユーザの組織コードを取得する。
                this.getOrganizationCd();

                // チェックボックス>地図選択時の処理
                this.own(topic.subscribe('app/evacorder/EvacOrderCheckTree::selectDistrict',
                    lang.hitch(this, function (payload) {
                        this.selectDistrictLayerFromCheckTree(payload);
                    })
                ));

                // 地図選択＞チェックボックス選択時の処理
                this.own(topic.subscribe('app/evacorder/DistrictLayerFactory::selectDistrict',
                    lang.hitch(this, function (payload) {
                        this.selectCheckTreeFromDistrictLayer(payload.id);
                    })
                ));

                // 避難情報ツリーを選択した時に呼ばれる
                this.own(topic.subscribe('app/evacorder/EvacOrderCheckTree::calcEvacOrderTarget',
                    lang.hitch(this, function () {
                        // 避難世帯数・避難者数の情報を更新する（グリッドと入力欄に反映される）
                        this._updatePopulationStore();
                    })
                ));

                //続報セレクタに変更イベントを設定
                this.evacOrderType.on('change', lang.hitch(this, function (evt) {
                    this.onEvacOrderTypeChanged(evt);
                }));
            },

            /**
             * 指定された要素を指定された理由を考慮した初期入力値と共にstoreへ登録する。
             * @param {string} reason 避難理由コード
             * @param {Object} item 地区要素
             */
            _putWithEvacNumForReason: function (reason, item) {
                // 選択した避難理由に対し、手入力がされたものがあれば変更せず（規定値との差異で判定する）、
                // 前回の入力値があればその値、無ければ地区の総数を初期入力値として設定
                var targetReason = reason && array.filter(item.evacOrderDistrictReasonList, function (distReason) {
                    return distReason.issueReasonType === reason;
                });

                // 各欄が、ユーザにより手入力による変更がなされているかどうかをチェックする。
                // 前回、プログラムが自動でセットした値と、現在各欄に入っている値を比較し、
                // 不一致だったら手入力あり、一致していたら手入力なしと認定。
                var isEvacHoldInput = !item.evacHouseholdNum ||
                    (this._autoNumSet[item.districtCd] &&
                        this._autoNumSet[item.districtCd].evacHouseholdNum === item.evacHouseholdNum);
                var isEvaqueeNumInput = !item.evaqueeNum ||
                    (this._autoNumSet[item.districtCd] &&
                        this._autoNumSet[item.districtCd].evaqueeNum === item.evaqueeNum);
                var isDistrictFreeNameInput = !item.districtFreeName ||
                    (this._autoNumSet[item.districtCd] &&
                        this._autoNumSet[item.districtCd].districtFreeName === item.districtFreeName);
                var isDistrictFreeNameKanaInput = !item.districtFreeNameKana ||
                    (this._autoNumSet[item.districtCd] &&
                        this._autoNumSet[item.districtCd].districtFreeNameKana === item.districtFreeNameKana);

                if (targetReason && targetReason.length) {
                    // 各カラムに関して、手入力されたものがあるかどうかを判定。手入力されていたら、手入力されている方を優先する。
                    // 手入力されているものがなければ、
                    item.evacHouseholdNum =
                        isEvacHoldInput ?
                            targetReason[0].evacHouseholdDistNum : item.evacHouseholdNum;
                    item.evaqueeNum =
                        isEvaqueeNumInput ?
                            targetReason[0].evaqueeDistNum : item.evaqueeNum;
                    item.districtFreeName =
                        isDistrictFreeNameInput ?
                            targetReason[0].districtFreeName : item.districtFreeName;
                    item.districtFreeNameKana =
                        isDistrictFreeNameKanaInput ?
                            targetReason[0].districtFreeNameKana : item.districtFreeNameKana;

                    // 「現況」欄用の整形
                    var currentStatus = [];
                    currentStatus.push(EvacOrderType.get(targetReason[0].evacOrderType).simpleLabel);
                    currentStatus.push(locale.format(new Date(targetReason[0].evacOrderTimestamp)));

                    item.currentStatus = currentStatus.join('');
                } else {
                    this._latestTargetReason = null;
                    item.evacHouseholdNum =
                        isEvacHoldInput ? item.householdsNum : item.evacHouseholdNum;
                    item.evaqueeNum =
                        isEvaqueeNumInput ? item.population : item.evaqueeNum;
                    item.districtFreeName =
                        isDistrictFreeNameInput ? item.districtName : item.districtFreeName;
                    item.districtFreeNameKana =
                        isDistrictFreeNameKanaInput ? item.districtNameKana : item.districtFreeNameKana;

                    item.currentStatus = '-';
                }
                this._autoNumSet[item.districtCd] = {};
                this._autoNumSet[item.districtCd].evacHouseholdNum = item.evacHouseholdNum;
                this._autoNumSet[item.districtCd].evaqueeNum = item.evaqueeNum;
                this._autoNumSet[item.districtCd].districtFreeName = item.districtFreeName;
                this._autoNumSet[item.districtCd].districtFreeNameKana = item.districtFreeNameKana;

                item.executingEvacOrderType = this.evacOrderType.get('value');

                this.populationStore.putSync(item);
            },

            /**
             * 発令内容が変わった際に呼ばれる
             */
            onEvacOrderTypeChanged: function (evt) {
                this._updateReason(evt);
            },

            /**
             * 避難世帯数・避難者数グリッドの内容を地区選択状態によって更新する。
             */
            _updatePopulationStore: function () {
                // ツリーが初期化前の場合は何もしない
                if (!this.tree) {
                    return;
                }

                var reason = this.issueReasonType.get('value');
                // 発令理由が選ばれていない場合は、グリッドに地区を表示しない
                if (!reason) {
                    this.populationStore.fetchSync().forEach(function (item) {
                        var itemId = this.populationStore.getIdentity(item);
                        this.populationStore.removeSync(itemId);
                    }, this);
                    return;
                }

                var checkedList = this.tree.getCheckedLeafs();
                // チェック済み要素をキーとする辞書（削除対象確認に利用）
                var checkedMap = {};
                // チェック済み要素一覧のうち、store未登録の要素を記録
                var newItemList = [];
                array.forEach(checkedList, function (item) {
                    var itemId = this.populationStore.getIdentity(item);
                    checkedMap[itemId] = true;
                    if (!this.populationStore.getSync(itemId)) {
                        newItemList.push(item);
                    }
                }, this);
                // store登録済み要素のうち、チェックの外れた要素を記録
                var oldItemIdList = [];
                this.populationStore.fetchSync().forEach(function (item) {
                    var itemId = this.populationStore.getIdentity(item);
                    if (!checkedMap[itemId]) {
                        oldItemIdList.push(itemId);
                    }
                }, this);
                // チェック解除済み要素をstoreから除去
                array.forEach(oldItemIdList, function (id) {
                    this.populationStore.removeSync(id);
                }, this);
                // 一覧更新前と避難理由が変わっている場合は既存要素の避難世帯数・避難者数を更新
                if (this._lastReason !== reason) {
                    this.populationStore.fetchSync().forEach(lang.hitch(this, '_putWithEvacNumForReason', reason));
                    this._lastReason = reason;
                }
                // 新規チェック要素をstoreへ登録
                array.forEach(newItemList, function (item) {
                    // ツリーに影響を与えないため、コピーを登録
                    // 避難理由に合わせて避難世帯数・避難者数の初期値を設定
                    this._putWithEvacNumForReason(reason, lang.mixin(null, item));
                }, this);
            },

            /**
             * 避難世帯数グリッドの発令カラムを更新する。
             */
            _updateReason: function (evt) {
                console.log(evt);
                this.populationStore.fetchSync().forEach(function (item) {
                    item.executingEvacOrderType = this.evacOrderType.get('value');
                    this.populationStore.put(item);
                }, this);
            },

            /**
             * 避難世帯数・避難者数の合計表示を更新する。
             */
            _updateEvacSummary: function () {
                var evaqueeNum = 0;
                var evacHouseholdNum = 0;
                this.populationCollection.fetchSync().forEach(function (item) {
                    evaqueeNum += (item.evaqueeNum) ? parseInt(item.evaqueeNum, 10) : 0;
                    evacHouseholdNum += (item.evacHouseholdNum) ? parseInt(item.evacHouseholdNum, 10) : 0;
                });
                this.evaqueeNumNode.innerHTML = evaqueeNum;
                this.evacHouseholdNumNode.innerHTML = evacHouseholdNum;
            },

            /**
             * チェックツリーを選択する。
             * 地図レイヤー選択時に呼ばれる。
             */
            selectCheckTreeFromDistrictLayer: function (id) {
                // 対応するチェックボックスの選択状態を切り替える
                this.tree.model.store.get(id).then(lang.hitch(this, function (item) {
                    this.tree.setChecked(item, !this.tree.isChecked(item));
                }));
            },

            /**
             * 避難地区を選択状態にする。
             * チェックボックス選択時に呼ばれる。
             */
            selectDistrictLayerFromCheckTree: function (id) {
                // 対応する避難地区情報をもつレイヤーを選択する
                var layerList = this._layerControl.layers[this.SELECT_LAYER_NO].getLayers();
                array.some(layerList, function (layer) {
                    // 背景地図レイヤーはfeatureを持っていないため事前に確認する
                    // D_CDは地区レイヤーに設定する地区ID。geojson、レイヤーのプロパティjson、treejsonに設定されている。
                    var layerId = layer.feature && layer.feature.properties.D_CD;
                    if (layerId === id) {
                        var options = layer.options || layer._layers[layer._leaflet_id - 1].options; //jshint ignore:line
                        options.selectDistrictLayer(layer);
                        return true; // break
                    }
                });
            },

            /**
             * ログインユーザの組織コードをセッションから取得する
             */
            getOrganizationCd: function () {
                this._orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
                    UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
                        UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');

            },

            // 現在その市町村において登録されている（取り消しされていない）避難情報の発令理由の一覧を取得・保存する
            getIssuesList: function () {
                var issueReasonLists = [];
                this._districtTreeCache.forEach(lang.hitch(this, function (dist) {
                    array.forEach(dist.evacOrderDistrictReasonList, function (reason) {
                        if (reason.issueReasonType &&
                            issueReasonLists.indexOf(reason.issueReasonType) === -1) {
                            issueReasonLists.push(reason.issueReasonType);
                        }
                    }, this);
                }));
                return issueReasonLists;
            },

            // 地区情報を取得する
            getDistrictInfo: function (districtCd) {
                var districtInfo;
                array.forEach(this._districtTreeCache, function (dist) {
                    if (dist.districtCd === districtCd) {
                        districtInfo = dist;
                    }
                });
                return districtInfo;
            },

            /**
             * 地図を初期生成する。
             */
            initMap: function () {
                // 地図を生成する。
                this.map = new IdisMap(this.mapNode, {
                    config: config.map,
                    keyboard: false, // コメント時に+/-が使用できないため
                    touchExtend: false,
                    minZoom: 9,
                    drawControlTooltips: false
                    // 初期値は県を設定する。
                }).setView(this.INIT_LATLNG, 11);

                // destroy時にmapを破棄するよう設定
                this.own(this.map);

                // 生成したmapのlayerControlを画面にセットする。
                this._layerControl = this.map.layerControl;

            },

            /**
             * 市町村コードから、対象の市町の緯度経度を取得して、地図を移動させる。
             */
            initLatlng: function () {
                // 「県」以外の場合は地図を追加した後で対象の市町の緯度経度を取得して、
                // 地図の中心位置に移動させる。
                // 「県」の場合は移動しない。
                if (this._municipalityCd === this.PREF_MUNICIPALITY_CODE) {
                    return;
                }
                // 市町村の緯度経度情報の一覧を取得する。
                Requester.get('/data/municipality/municipalityList.json', { preventCache: false })
                    .then(lang.hitch(this, function (obj) {

                        var municipalities = obj.municipalities;
                        var latlng = this.INIT_LATLNG;

                        // 市町村の緯度経度情報の一覧と画面で指定されている市町村を比較して当該市町村の緯度経度を取得する。
                        municipalities.some(lang.hitch(this, function (item) {
                            if (item.municipalityCd === this._municipalityCd) {
                                latlng = item.latlng;
                                return true; //break
                            }
                        }));
                        this.map.setView(latlng, 12);
                    }));
            },

            /**
             * 避難情報発令地区「選択」レイヤーを地図上に表示する。
             * 選択レイヤーの初期値は、詳細情報のstyle.urlから取得する。
             *「選択」レイヤーはgeojsonからDistrictSelectLayerFactoryによって生成する。
             * @returns {Promise} 追加完了後に解決するPromise
             */
            showSelectLayer: function () {
                return Requester.get('/data/layer/data/evacorder/area/' + this._municipalityCd + '.geojson')
                    .then(lang.hitch(this, function (selectLayer) {
                        // 地区レイヤーファクトリクラスにツリーの地区情報も渡して、geojsonに登録されている地区をfilterする。
                        var layer = DistrictLayerFactory.getGeoJsonLayers(selectLayer, this._districtTreeCache);
                        this._layerControl.addLayer(layer, this.SELECT_LAYER_NO);
                        this._layerControl.toFront(this.SELECT_LAYER_NO);
                    })).then(lang.hitch(this, function () {
                        // レイヤーが追加されてから、避難区分変更時のメソッドを呼んで避難区分に応じた色を設定する。
                        // 市町村を切り替えた際に、レイヤーを追加した後に色を反映したいため、レイヤー追加後に呼んでおく。
                        this.onEvacTypeChange(this.evacOrderType.value);
                    }));
            },

            /**
             * 現況レイヤーを取得して地図上に表示する。
             * @returns {Promise} 完了後に解決するPromise
             */
            showLatestStateLayer: function () {
                // 市町村の避難情報「現況」レイヤーのidを取得する。
                return Requester.get('/api/evacorders/layerid', {
                    query: {
                        disasterId: this._disasterId,
                        municipalityCd: this._municipalityCd
                    }
                }).then(lang.hitch(this, function (id) {
                    // 市町村の「現況」レイヤーを取得する。
                    this.LATEST_STATE_LAYER_NO = id;
                    return Requester.get('/data/layer/tree/' + id + '.json');
                })).then(lang.hitch(this, function (layerInfo) {
                    // 現在の避難情報発令状況を地図上に表示する。
                    var opacity = 1;
                    return this._layerControl.addGeneralLayer(layerInfo, opacity);
                })).then(lang.hitch(this, function () {
                    // 「現況」レイヤーは背面に移動する。
                    this._layerControl.layers[this.LATEST_STATE_LAYER_NO].bringToBack();
                }));
            },

            /**
             * 指定された地区コード一覧をチェック状態にする。
             * @param {string[]} districtCdList 地区コード一覧
             */
            _selectDistricts: function (districtCdList) {
                array.forEach(districtCdList, function (distCd) {
                    this.tree.model.store.get(distCd).then(lang.hitch(this, function (item) {
                        if (item) {
                            this.tree.setChecked(item, true);
                        }
                    }));
                }, this);
            },

            /**
             * 避難情報種別が変更された際に呼ばれる。
             * onChange時に呼ばれるようにhtmlにattachされている。
             * @param {string} value 発令理由コード
             */
            onEvacTypeChange: function (value) {

                this._evacOrderType = value;
                // 初期化時に避難区分がフォームにセットされた段階で当該メソッドが呼ばれてしまう。
                // 地図生成時にモジュールにセットするlayerControlが未生成の可能性があるため
                // 事前にlayerControlの有無をチェックする。
                if (!this._layerControl || Object.keys(this._layerControl.layers).length === 0) {
                    return;
                }
                var layerGroup = this._layerControl.layers[this.SELECT_LAYER_NO];
                var color = _orderTypeSelectColorMap[value];
                // レイヤーのfeatureをそれぞれ確認して、選択されているものは色を変更する。
                layerGroup.eachLayer(function (layer) {
                    // Polygonはlayer.options、MultiPoligonはlayer._layer[id].optionsとしてoptionsを保持している。
                    var options = layer.options || layer._layers[layer._leaflet_id - 1].options; //jshint ignore:line
                    options._selectedColor = color;
                    if (options._isSelected) {
                        layer.setStyle({ fillColor: color });
                    }
                });
                this._updatePopulationStore();
            },

            /**
             * 地区リストの配列をカンマ区切りの文字列に変換する。
             */
            parseArray2String: function (array) {
                var str = '';
                if (array.length !== 0) {
                    array.forEach(function (obj) {
                        str += obj.districtCd + ',';
                    });
                    str = str.slice(0, -1);
                }
                // 最後の文字列を削除する。
                return str;
            },

            /**
             * 地区ツリーの配列から、緊急速報メールに必要な地区情報だけを抜き出して、地区配列として返す
             */
            convertDistrictList: function (districtList) {
                return array.map(districtList, function (item) {
                    var gridItem = this.populationStore.getSync(this.populationStore.getIdentity(item));
                    return {
                        districtCd: item.districtCd,
                        issueReasonType: null,
                        evacOrderType: null,
                        districtName: gridItem.districtName,
                        districtFreeName: gridItem.districtFreeName,
                        districtFreeNameKana: gridItem.districtFreeNameKana,
                        evacuee: gridItem.evaqueeNum ? parseInt(gridItem.evaqueeNum, 10) : 0,
                        household: gridItem.evacHouseholdNum ? parseInt(gridItem.evacHouseholdNum, 10) : 0,
                        timestamp: null
                    };
                }, this);
            },

            /**
             * 地区ツリーの配列から、DB登録に必要な地区情報だけを抜き出して、地区配列として返す。
             * 
             * @param {array} districtList      地区ツリーのitemリスト
             * @param {string} evacOrderType    発令内容コード
             * @param {string} issueReasonType  発令理由コード
             * @param {boolean} isRegist        true: 登録、false: 訂正
             * @returns 
             */
            convertDistrictArray: function (districtList, evacOrderType, issueReasonType, isRegist) {
                return array.map(districtList, function (item) {
                    var gridItem = this.populationStore.getSync(this.populationStore.getIdentity(item));
                    // 解除の場合は、地区別に異なる発令内容のセットが必要
                    var individualEvacOrderType = null;
                    if (evacOrderType === '90' || evacOrderType === '80') {
                        // 選択した発令理由に対し、最新の情報を取得する。
                        var latestStatus = array.filter(item.evacOrderDistrictReasonList, function (distReason) {
                            return distReason.issueReasonType === issueReasonType;
                        });
                        var latestEvacOrderType = latestStatus.length > 0 ? latestStatus[0].evacOrderType : '';
                        // 訂正報で、元々現況が解除報だった場合は、現在の発令理由をセットする。
                        if (!isRegist && EvacOrderUtil.isReleased(latestEvacOrderType)) {
                            individualEvacOrderType = latestEvacOrderType;
                        } else {
                            // それ以外の場合（新規発令の場合＆訂正報でも、現況が解除報でない場合）
                            // c.f. 「訂正報でも、現況が解除報でない場合」は、例えば解除しそびれた地区を追加で解除する場合など
                            // この処理にたどり着く時点で、evacOrderDistrictReasonListが空であることはないので、判定処理が不要。
                            individualEvacOrderType = EvacOrderConfig.RELEASE_MAP[latestEvacOrderType];
                        }
                    }
                    return {
                        districtCd: item.districtCd,
                        // 解除の場合は、地区別に異なる発令内容をセットするが、そうでなければ一律で良い
                        evacOrderType:
                        (evacOrderType === '90' || evacOrderType === '80') ? individualEvacOrderType : evacOrderType,
                        districtFreeName: gridItem.districtFreeName,
                        districtFreeNameKana: gridItem.districtFreeNameKana,
                        evaqueeDistNum: gridItem.evaqueeNum ? parseInt(gridItem.evaqueeNum, 10) : 0,
                        evacHouseholdDistNum: gridItem.evacHouseholdNum ? parseInt(gridItem.evacHouseholdNum, 10) : 0
                    };
                }, this);
            },

            // 指定された地区（item）の最新状況が、指定の発令理由で「発令済み」かどうかをチェックする
            isAlerted: function (item, issueReasonType, evacOrderTimestamp, isCheckRelease) {
                // 選択した発令理由に対し、最新の情報を取得する。
                var latestStatus = array.filter(item.evacOrderDistrictReasonList, function (distReason) {
                    return distReason.issueReasonType === issueReasonType;
                });

                // 指定された発令理由に合致する現況情報がなければ、falseを返す
                if (!latestStatus || !latestStatus.length) {
                    return false;
                }

                // 発令画面の場合、最新状況が「解除」でもfalseを返す（）
                if (isCheckRelease && EvacOrderUtil.isReleased(latestStatus[0].evacOrderType)) {
                    return false;
                }

                // 発令時刻より前の時間で「解除」しようとしていても、falseを返す
                if (evacOrderTimestamp < latestStatus[0].evacOrderIssuedTimestamp) {
                    return false;
                }

                // それ以外の場合は、発令中と判断する
                return true;
            },

            /**
             * 地区ツリーの配列から、全域判定用に必要な地区情報だけを抜き出して、地区配列として返す。
             * 訂正の場合は、訂正対象の避難情報の発令時間がevacOrderTimestampで連携される。新規発令の場合はnull。
             */
            createLalertAreaList: function (districtTree, districtArray,
                deselectedList, form, isCancelling, isCorrecting) {
                var evacOrderLalertAreaMap = {};
                var evacOrderTimestamp = form.evacOrderTimestamp.getTime();

                array.forEach(districtTree, function (item) {

                    // まず、今発令しようとしている発令理由以外にについて整理する。
                    array.forEach(item.evacOrderDistrictReasonList, function (reason) {
                        // evacOrderId === 0だと、発令されていない情報
                        if (reason.evacOrderId !== 0 && reason.issueReasonType !== form.issueReasonType) {
                            if (!reason.issueReasonType) {
                                return;
                            }
                            var list = evacOrderLalertAreaMap[reason.issueReasonType];
                            if (!list) {
                                list = [];
                            }
                            list.push(this.createLalertArea(item.districtCd, reason, null));
                            evacOrderLalertAreaMap[reason.issueReasonType] = list;
                        }
                    }, this);

                    // 避難情報取り消し時は、現在選択中の発令理由以外はLアラートに連携しないので、ここでreturnする
                    if (isCancelling) {
                        return;
                    }

                    var list4currentIssue = evacOrderLalertAreaMap[form.issueReasonType];
                    if (!list4currentIssue) {
                        list4currentIssue = [];
                    }

                    // 次に、今発令しようとしている発令理由について整理する。
                    // 地区が選択中かどうかを判定
                    var isSelected = false;
                    array.forEach(districtArray, function (dist) {
                        if (dist.districtCd === item.districtCd) {
                            isSelected = true;
                        }
                    });

                    var latestStatus = this.getLatestStatus(item, form.issueReasonType);

                    if ((isSelected && !form.evacOrderId) ||
                        (isSelected && form.evacOrderId && latestStatus &&
                            (form.evacOrderId === String(latestStatus.evacOrderId) ||
                                evacOrderTimestamp >= latestStatus.evacOrderTimestamp)) ||
                        (isSelected && form.evacOrderId && !latestStatus)) {
                        // 新規発令かつ地区が選択中の場合
                        // 訂正でもその地区に対する最新報を訂正・上書きする場合
                        //   -> (evacOrderIdまたはevacOrderTimestampで判断)
                        // 訂正でも地区の追加（未発令地区への避難情報付与）である場合
                        // 画面入力内容がLアラートへの連携内容になる
                        list4currentIssue.push(this.convertDistrictForIssue(item, form, isCorrecting));
                    } else {

                        // 選択されていない地区である or 訂正するのが、その地区に対する最新報でない場合、
                        // その地区についてのlatestStatusをLアラート連携対象とする。
                        // latestStatusがなければ、そもそもLアラート内容に含めない。
                        if (!latestStatus) {
                            //その地区に、発令予定の発令理由での過去の発令情報がなければ、そもそもリストには含めない。
                            return;
                        }

                        // latestStatusの内容をLアラートに連携するのであれば、Lアラート用に情報を修正する
                        var district = this.convertDistrictFromLatestSituation(
                            item.districtCd, latestStatus, deselectedList, evacOrderTimestamp);
                        if (district) {
                            list4currentIssue.push(district);
                        }
                    }
                    evacOrderLalertAreaMap[form.issueReasonType] = list4currentIssue;
                }, this);

                return evacOrderLalertAreaMap;
            },

            getLatestStatus: function (item, issueReasonType) {
                // 選択した発令理由に対し、最新の情報を取得する。
                var latestStatus = array.filter(item.evacOrderDistrictReasonList, function (distReason) {
                    return distReason.issueReasonType === issueReasonType;
                });

                // 指定された発令理由に合致する過去報がなければ、nullをreturnする
                if (!latestStatus || !latestStatus.length) {
                    return null;
                }

                // array.filterの戻り値は配列である 最新報があるならlengthは必ず1であるため、0番目の要素を返す
                return latestStatus[0];
            },


            /**
             * 今から避難情報を登録/訂正する訳ではないが、現状で避難情報の登録がある地区について、
             * 最新状況（latestStatus）からLアラート連携に必要な情報だけを抜き出し・変換して、地区情報として返す。
             */
            convertDistrictFromLatestSituation: function (districtCd, latestStatus,
                deselectedList, evacOrderTimestamp) {
                // セットする避難情報地区の発令内容には、基本はlatestStatusのevacOrderTypeをセットするが、
                // 訂正対象が最新報（または最新報の更新）で、かつ訂正によりチェックを外された地区だったら、地区のパージを意味する。
                // その場合は、最新報の「前報」の発令内容、つまり「latestStatusのlastEvacOrderType」をセット表示するべき
                var evacOrderType = latestStatus.evacOrderType;
                if (evacOrderTimestamp >= latestStatus.evacOrderTimestamp &&
                    deselectedList && deselectedList.indexOf(districtCd) !== -1) {
                    if (!latestStatus.lastEvacOrderType) {
                        // 前報がなければ、その地区への発令そのものを取り消しているということなので、Lアラートに書かない
                        return null;
                    }
                    // 前報があれば、前報の発令内容をセットする。
                    evacOrderType = latestStatus.lastEvacOrderType;
                }

                // 過去報があれば、情報をセットして返す。
                return this.createLalertArea(districtCd, latestStatus, evacOrderType);
            },

            createLalertArea: function (districtCd, latestStatus, evacOrderType) {
                return {
                    districtCd: districtCd,
                    areaName: latestStatus.districtFreeName,
                    areaNameKana: latestStatus.districtFreeNameKana,
                    evacuateSort: evacOrderType ? evacOrderType : latestStatus.evacOrderType,
                    evacuateIssue: evacOrderType ? evacOrderType : latestStatus.evacOrderType,
                    reason: latestStatus.issueReason,
                    evaqueeNum: parseInt(latestStatus.evaqueeDistNum, 10),
                    evacHouseholdNum: parseInt(latestStatus.evacHouseholdDistNum, 10),
                    note: latestStatus.note,
                    evacGuidance: latestStatus.evacGuidance,
                    evacOrderTimestamp: latestStatus.evacOrderTimestamp
                };
            },

            /**
             * 今から発令予定の地区について、
             * 地区ツリーの各地区要素から、Lアラート連携に必要な情報だけを抜き出し・変換して、地区情報として返す。
             */
            convertDistrictForIssue: function (item, form, isCorrecting) {
                var gridItem = this.populationStore.getSync(this.populationStore.getIdentity(item));

                // 選択した発令理由に対し、最新の情報を取得する。
                var latestStatus = array.filter(item.evacOrderDistrictReasonList, function (distReason) {
                    return distReason.issueReasonType === form.issueReasonType;
                });
                var latestEvacOrderType = latestStatus.length > 0 ? latestStatus[0].evacOrderType : '';
                var evacuateSort = '';
                if (form.evacOrderType === '90' || form.evacOrderType === '80') {
                    // 解除報を連携する場合、新規登録なら現況情報をストレートに解除報用コードに変換すればいい。
                    // ただ、訂正であった場合、元から解除の情報をもう一度解除として訂正するケースがあり、その場合は変換不要。
                    evacuateSort = (isCorrecting && EvacOrderUtil.isReleased(latestEvacOrderType)) ?
                        latestEvacOrderType : EvacOrderConfig.RELEASE_MAP[latestEvacOrderType];
                } else {
                    evacuateSort = form.evacOrderType;
                }

                return {
                    districtCd: item.districtCd,
                    areaName: gridItem.districtFreeName,
                    areaNameKana: gridItem.districtFreeNameKana,
                    // 画面で「解除」が選ばれていたら、最新状況で発令されているコードに対応する解除コードをセットする。
                    // 画面で「発令」が選ばれていたら、一括で、フォームで選ばれている発令コードをセットする
                    evacuateSort: evacuateSort,
                    evacuateIssue: evacuateSort,
                    reason: form.issueReason,
                    evaqueeNum: parseInt(gridItem.evaqueeNum, 10),
                    evacHouseholdNum: parseInt(gridItem.evacHouseholdNum, 10),
                    note: form.note,
                    evacGuidance: form.evacGuidance,
                    evacOrderTimestamp: form.evacOrderTimestamp
                };
            },

            checkDistricts: function (form, districtArray, isRegist) {
                // 種別が解除でなければチェックしない
                if (!EvacOrderUtil.isReleased(form.evacOrderType)) {
                    return;
                }

                var nonIssuedDistNameList = [];
                array.forEach(districtArray, function (checkedDist) {
                    //各地区に対して、現在画面で選んでいる発令内容で避難情報が発令中かどうかをチェック
                    var item = this.populationStore.getSync(checkedDist.districtCd);
                    // 新規発令の時「現況が解除」はNG、訂正報なら許容 （isAlertメソッド内で制御）
                    if (!this.isAlerted(item, form.issueReasonType, form.evacOrderTimestamp, isRegist)) {
                        nonIssuedDistNameList.push(item.districtName);
                    }
                }, this);

                return nonIssuedDistNameList;
            },

            // これから発令する避難情報が「格上げまたは範囲拡大」であるかどうかの判定を行う。
            _checkUpgradeStatus: function (form) {
                // 新規登録時
                var jsonStr = json.stringify(form);
                return Requester.post('/api/evacorders/checkUpgradeStatus', {
                    data: jsonStr,
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(lang.hitch(this, function (data) {
                    console.debug('緊急速報メール配信対象フラグ : ' + data.isUrgentMailTarget);

                    return data.isUrgentMailTarget;
                }));
            },

            setEvacOrderTypeSelector: function (issueReasonType, evacOrderType) {
                // 動的な避難区分セレクターを作る
                var selectData = [];

                array.forEach(EvacOrderTypeModel, function (item) {
                    if (item.available === true || item.available[0] === true) {
                        selectData.push(item);
                    }
                });

                var selectContent = null;
                if (issueReasonType &&
                    (issueReasonType === '01' || issueReasonType === '02' || issueReasonType === '05')) {
                    selectContent = {
                        identifier: 'evacOrderTypeCd',
                        label: 'labelWithLevel',
                        items: selectData
                    };
                } else {
                    selectContent = {
                        identifier: 'evacOrderTypeCd',
                        label: 'labelWithoutLevel',
                        items: selectData
                    };
                }


                var evacOrderItemFileWriteStore = new ItemFileWriteStore({
                    data: selectContent
                });
                this.evacOrderType.set('sortByLabel', false);
                this.evacOrderType.set('store', evacOrderItemFileWriteStore);
                if (evacOrderType) {
                    // 解除系は81~89のいずれかが連携されるが、クライアント側では80として扱っているので変換の必要がある
                    var evacValue = (evacOrderType.substring(0, 1) === '8') ? '80' : evacOrderType;
                    this.evacOrderType.set('value', evacValue);
                } else {
                    this.evacOrderType.set('value', '11');
                }
                this.evacOrderType.on('change', lang.hitch(this, function (evt) {
                    this.onEvacTypeChange(evt);
                }));
            },

            setOldEvacOrderTypeSelector: function(issueReasonType, evacOrderType){
                // 動的な避難区分セレクターを作る
                var selectData = [];

                array.forEach(EvacOrderTypeModel, function(item){
                    if (item.available === false || item.available[0] === false) {
                        selectData.push(item);
                    }
                });

                var selectContent = null;
                if(issueReasonType &&
                    (issueReasonType === '01' || issueReasonType === '02' || issueReasonType === '05')){
                    selectContent = {
                        identifier  :'evacOrderTypeCd',
                        label       :'labelWithLevel',
                        items       :selectData
                    };
                } else {
                    selectContent = {
                        identifier  :'evacOrderTypeCd',
                        label       :'labelWithoutLevel',
                        items       :selectData
                    };
                }

                var evacOrderItemFileWriteStore = new ItemFileWriteStore({
                        data:  selectContent
                    });
                this.evacOrderType.set('sortByLabel', false);
                this.evacOrderType.set('store', evacOrderItemFileWriteStore);
                if(evacOrderType){
                    // 解除系は91~99のいずれかが連携されるが、クライアント側では90として扱っているので変換の必要がある
                    var evacValue = (evacOrderType.substring(0,1) === '9') ? '90' : evacOrderType;
                    this.evacOrderType.set('value', evacValue);
                }
            },

            /**
             * エラーダイアログを表示する
             * @param {string} content エラーメッセージ
             * @returns false
             */
            showErrDialog: function(content) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title: 'エラー',
                        content: content
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            },

            /**
             * 地区の入力内容のバリデーションチェック
             * @param {boolean} isRegist true: 発令
             * @returns チェック結果（true: OK, false: NG）
             */
            validateDistrictInfo: function (isRegist) {
                // 地区リストをセットする。
                var districtArray = this.tree.getCheckedLeafs();
                // 入力チェック
                if (districtArray.length === 0) {
                    console.debug('地区が選択されていません');
                    return this.showErrDialog('地区が選択されていません');
                }

                // 地区のチェック状態を確認する。（ブラウザの処理の都合により、中の子地区が全部チェックされない可能性がある）
                var checkNotCompletedDistList = [];
                array.forEach(districtArray, function (district) {
                    if (!this.tree.isCheckCompleted(district.districtCd)) {
                        checkNotCompletedDistList.push(district.districtName);
                    }
                }, this);

                // 子地区の処理が終わっていない親地区がある場合、後続処理を実施しないでエラーダイアログを表示する
                if (checkNotCompletedDistList && checkNotCompletedDistList.length > 0) {
                    return this.showErrDialog('以下の地区について、子地区の設定がされていません。<br>' +
                        '「地区」欄から再度チェックし、全ての子地区がチェックされることを<br>' +
                        '確認してから、登録ボタンを押してください。<br><br>' +
                        '地区：' + checkNotCompletedDistList.join('、'));
                }

                // 地区のエラーチェックを行う。
                var nonIssuedDistNameList = this.checkDistricts(
                    this._evacOrderForm, districtArray, isRegist, this._initialEvacOrderType);
                if (nonIssuedDistNameList && nonIssuedDistNameList.length) {
                    // 避難情報が発令されていない/解除済みの地区に対して解除しようとしている場合、エラーダイアログを表示する
                    return this.showErrDialog('以下の地区には、' + Reason.get(this._evacOrderForm.issueReasonType).name +
                        'の避難情報が発令されていないか、発令時刻より前の時間が指定されているため、<br>解除できません。<br><br>' +
                        '地区：' + nonIssuedDistNameList.join('、'));
                }

                // 画面右側の「避難世帯数・避難者数」欄で、対象欄入力欄チェック
                return array.every(this._evacOrderForm.districtList, function(district) {
                    return array.every(['districtFreeName','districtFreeNameKana'], function(key) {
                        if (!district[key]) {
                            return this.showErrDialog('「避難世帯数・避難者数」欄の項目を全て入力してください。');
                        }
                        return true;
                    }, this);
                }, this);
            },

            /**
             * 添付ファイル選択時に呼ばれる。
             */
            loadAttachFile: function () {
                //TODO 避難情報を参考に実装する
                console.debug('EvacOrderRegisterPage#loadAttachFile()');
            },

            // TODO 監視ページと同じように情報表示ボタンを設置する？要検討
            toggleLayer: function () {
                var targetLayer = this._layerControl.layers[this.LATEST_STATE_LAYER_NO];
                if (this._latestLayerState === this.LATEST_LAYER_STATE.ON) {
                    this._latestLayerState = this.LATEST_LAYER_STATE.OFF;
                    domStyle.set(this.mapCntlBtn, 'backgroundColor', '');
                    // document.getElementById('currentStatus').style.backgroundColor = '#F90';
                    this._layerControl.removeLayer(targetLayer);
                } else {
                    this._latestLayerState = this.LATEST_LAYER_STATE.ON;
                    domStyle.set(this.mapCntlBtn, 'backgroundColor', '#F90');
                    this._layerControl.addLayer(targetLayer, this.LATEST_STATE_LAYER_NO);
                    this._layerControl.layers[this.LATEST_STATE_LAYER_NO].bringToBack();
                }
            },

            /**
             * パンくずリストの避難情報概況ページリンクをクリックした時に呼ばれる
             * @param {Event} evt クリックイベント
             */
            onAdminLinkClick: function (evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('evacorder/admin');
            },

            /**
             * パンくずリストの避難情報一覧ページリンクをクリックした時に呼ばれる
             * @param {Event} evt クリックイベント
             */
            onListLinkClick: function (evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('evacorder', {
                    'municipalityCd': this._municipalityCd
                });
            },

            /**
             * 避難理由のtips
             */
            setIssueReasonTypeTips: function () {
                // 避難理由
                var issueReasonTypeTips = new TooltipDialog({
                    id: 'issueReasonTypeTips',
                    style: 'width: 300px;',
                    content: '<p>避難理由は訂正することができません。<br><br>' +
                        '避難理由を訂正する場合には、一度避難情報を取消してから、' +
                        '再度、避難情報を登録してください。</p>'
                });
                this.own(issueReasonTypeTips);
                on(dom.byId('issueReasonTypeLabel'), 'mouseover', function () {
                    popup.open({
                        popup: issueReasonTypeTips,
                        around: dom.byId('issueReasonTypeLabel')
                    });
                });
                on(dom.byId('issueReasonTypeLabel'), 'mouseleave', function () {
                    popup.close(issueReasonTypeTips);
                });
            },

            /**
             * 発令理由詳細のtips
             */
            setIssueReasonTips: function () {
                // 避難理由
                var issueReasonTips = new TooltipDialog({
                    id: 'issueReasonTips',
                    style: 'width: 300px;',
                    content: '<p>発令理由詳細の内容は、Lアラートに連携されて、NHK、Yahoo等から発信される情報として活用されます。</p>'
                });
                this.own(issueReasonTips);
                on(dom.byId('issueReasonLabel'), 'mouseover', function () {
                    popup.open({
                        popup: issueReasonTips,
                        around: dom.byId('issueReasonLabel')
                    });
                });
                on(dom.byId('issueReasonLabel'), 'mouseleave', function () {
                    popup.close(issueReasonTips);
                });
            },

            /**
             * 地区のtips
             */
            setDistrictTips: function () {
                // 避難理由
                var districtTips = new TooltipDialog({
                    id: 'districtTips',
                    style: 'width: 300px;',
                    content: '<p>所属する子地区がすべて選択されると親の地区名で各メディアに連携されます。<br><br>' +
                        '地区情報に補足がある場合には、補足情報に入力してください。</p>'
                });
                this.own(districtTips);
                on(dom.byId('districtLabel'), 'mouseover', function () {
                    popup.open({
                        popup: districtTips,
                        around: dom.byId('districtLabel')
                    });
                });
                on(dom.byId('districtLabel'), 'mouseleave', function () {
                    popup.close(districtTips);
                });
            },

            /**
             * 補足情報のtips
             */
            setNoteTips: function () {
                // 避難理由
                var noteTips = new TooltipDialog({
                    id: 'noteTips',
                    style: 'width: 300px; height:100px',
                    content: '<p>補足情報は、Lアラートに連携されて、NHK、Yahoo等から発信される情報として活用されます。</p>'
                });
                this.own(noteTips);
                on(dom.byId('noteLabel'), 'mouseover', function () {
                    popup.open({
                        popup: noteTips,
                        around: dom.byId('noteLabel')
                    });
                });
                on(dom.byId('noteLabel'), 'mouseleave', function () {
                    popup.close(noteTips);
                });
            }

        });
});
