/**
 * <区市町村連携被害数値情報自動集計ダイアログ>
 *
 * @module app/damage/ScheduledReportMuniAutoAggregateListGrid.js
 */
define(['module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'idis/util/DateUtils',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/IdisSelector',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer'
], function(module, declare, lang, DateUtils, IdisGrid, IdisSelector) {
    /**
     * <クラスの説明>
     *
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    var ScheduledReportMuniAutoAggregateListGrid = 
        declare(module.id.replace(/\//g, '.'), [IdisGrid, IdisSelector],
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */
    {
        columns: [
            {field: 'checkbox', label: 'checkbox', selector: 'checkbox'},
            {field: 'graspSituationDate', label: '状況把握日時', sortable: true,
                formatter: lang.hitch(this, function(item){
                        return DateUtils.format(item);
                })
            },
            {field: 'fixFlg', label: '確定', sortable: true,
                formatter: lang.hitch(this, function(item){
                    if (!item) {
                        return '未確定';
                    } else {
                        return '確定';
                    }
                })
            },
            {field: 'sectName', label: '所属', sortable: true},
            {field: 'reportAuthorName', label: '報告者名', sortable: true},
            {field: 'reporterContact', label: '連絡先番号', sortable: true}
        ]
    });
    return ScheduledReportMuniAutoAggregateListGrid;
});
