/**
 * 招集グループ詳細画面用モジュール。
 * @module app/convocation/view/group/ConvoGroupDetailPage
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/text!./templates/ConvoGroupDetailPage.html', // テンプレート文字列
    'dojo/query',
    'dojo/dom-style',
    'dojo/promise/all',
    'dojo/Deferred',
    'app/convocation/view/group/_ConvoGroupPageBase', // 共通基底クラス
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/control/Router',
    'idis/control/Locator',
    'idis/service/Requester',
    'dstore/Memory',
    'idis/model/UserInfo',
    // 以下、変数で受けないモジュール
    'app/view/form/EmployeeDivisionSelector',
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    'idis/view/form/Button'
], function(module, declare, lang, template, query, domStyle, all, Deferred, _PageBase,
    DialogChain, Loader, Router, Locator, Requester, Memory, UserInfo) {
    /**
     * 招集グループ詳細画面。
     * @class ConvoGroupDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/ConvoGroupDetailPage~ConvoGroupDetailPage# */
        {
            // テンプレート文字列
            templateString: template,

            store: null,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',

            /**
             * 変数の初期化。
             */
            constructor: function() {
                this.chain = DialogChain.get(this);
                // URLからグループコードを取得
                this.groupCd = Locator.getQuery().id;
                this.store = new Memory({
                    'idProperty': 'dpEmployeeCd'
                });
            },

            /**
             * DOMノードを生成するためのメソッド。
             */
            buildRendering: function() {
                this.inherited(arguments);
                this.initDetailPage();
            },

            startup: function() {
                this.inherited(arguments);
                this.setButtonStatus();
            },

            /**
             * 更新ボタンが押下された際の挙動。
             */
            onSubmit:function(){
                var formData = this.form.get('value');
                // formの入力チェック
                if (!this.form.validate()) {
                    return false;
                }
                // 招集対象職員の職員番号の配列
                var employees = [];
                // gridの行数分処理する
                this.store.fetch().forEach(function(item) {
                    // 職員番号のみサーバー側に渡す
                    employees.push(item.employeeCd);
                });
                // requestに追加
                formData.employeeCds = employees;
                this.updateConvoGroup(formData);
                return false;
            },


            /**
             * @param formData form情報
             * グループ情報の更新処理。
             */
            updateConvoGroup: function(formData) {
                //更新確認ダイアログを表示
                this.chain.confirmPut(function(chain) {
                    var promise = Requester.put('/api/convoMails/groups/' + this.groupCd, {
                        data: formData
                    }).then(function() {
                        chain.infoComplete(function() {
                            // 一覧画面に移動
                            Router.moveTo('convocation/group');
                        });
                    }, function(error) {
                        console.log(error);
                        chain.infoError(error);
                    });
                    //ローダーの表示
                    Loader.wait(promise);
                });
            },

            /**
             * 削除ボタンが押下された際の挙動。
             */
            deleteButtonClick: function() {
                //削除確認ダイアログの表示
                this.chain.confirmDel(function(chain) {
                    // DELETEメソッドを発行
                    var promise = Requester.del('/api/convoMails/groups/' + this.groupCd)
                    .then(function() {
                        chain.infoComplete(function() {
                            // 一覧画面に移動
                            Router.moveTo('convocation/group');
                        });
                    }, function(error) {
                        console.log(error);
                        chain.infoError(error);
                    });
                    //ローダーの表示
                    Loader.wait(promise);
                });
            },

            /**
             * 詳細画面の初期化。
             */
            initDetailPage: function() {
                var promise = Requester.get('/api/convoMails/groups/' + this.groupCd).then(lang.hitch(this,
                        function(data) {
                    this.form.set('value', data);

                    // dataにより項目の表示・非表示を制御
                    this.displayItems(data);

                    // 職員をグリッドに追加する
                    data.employeeList.forEach(lang.hitch(this, function(employee) {
                        this.store.addSync({
                            parentName      : employee.parentName,
                            divisionName    : employee.divisionName,
                            employeeCd      : employee.employeeCd,
                            name            : employee.name
                        });
                    }));
                    // グリッドを更新する
                    this.convoGroupRegisterGrid.refresh();
                }), lang.hitch(this, function(error) {
                    console.log(error);
                    this.chain.info('情報の取得に失敗しました。', 'エラー');
                }));
                // ローダの表示
                Loader.wait(promise);
            },

            setButtonStatus: function(){
                this.submitButton.setDisabled(true);
                this.deleteButton.setDisabled(true);
                if (UserInfo.hasAuthz('F08001')) {
                    this.submitButton.setDisabled(false);
                }
                if (UserInfo.hasAuthz('F08001')) {
                    this.deleteButton.setDisabled(false);
                }
                
                // グループ種別は非活性
                this.groupType.set('disabled', true);
            },

            /**
             * dataにより項目の表示・非表示を制御
             */
            displayItems: function(data) {
                // ボタン非表示メッセージを初期状態は非表示
                domStyle.set(this.buttonMsg, 'display', 'none');
                
                // 組織コード
                var divisionCdVal = "";

                // dataの組織コードがundefinedでない場合、値をセット
                if (typeof data.divisionCd !== 'undefined') {
                    divisionCdVal = data.divisionCd;
                }

                // グループが組織と紐づいている場合
                if ('' !== divisionCdVal) {
                    // 更新ボタンを非表示
                    domStyle.set(this.submitButton.domNode, 'display', 'none');
                    // 削除ボタンを非表示
                    domStyle.set(this.deleteButton.domNode, 'display', 'none');
                    // ボタン非表示メッセージを表示
                    domStyle.set(this.buttonMsg, 'display', '');
                }

                // 通知グループの場合のみ登録画面表示を表示
                this.setSelectableFlgDisplay(data.groupType);
                /* 
                    全職員が自動で追加されるグループ
                        366 :【要選択】気象特別警報
                        367 :【要選択】県内震度４以上
                        368 :【要選択】津波注意報以上
                    については、登録画面非表示固定のため非活性にする
                */
                if ([366,367,368].indexOf(Number(this.groupCd)) > -1) {
                    this.selectableFlg.set('disabled', true);
                    domStyle.set(this.selectableFlgMessage, 'display', '');
                }
            }
        });
});
