/**
 * 防災体制・報告の新規登録・詳細ダイアログ用の基底モジュール。
 * @module app/disasterprevention/view/_DisasterPreventionPageBase
 */
define([
    'module',
    'app/disaster/model/DisasterType',
    'app/model/DisasterInfo',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-style',
    'dojo/on',
    'dojo/json',
    'dojo/request/iframe',
    'exif-js/exif',
    'idis/control/Router',
    'idis/consts/USER_TYPE',
    'idis/service/Requester',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    './model/DistributionType',
    './model/HqSetFlg',
    'app/common/consts/FunctionCd',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/SimpleTextarea',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dojox/form/Uploader',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/RadioGroup',
    'app/view/form/OrganizationSelector',
    './DisasterPreventionConfirmDialog',
    'app/view/form/CustomizableMunicipalitySelector'
], function(module, DisasterTypeModel, DisasterInfo, declare, lang, domClass,
    domConstruct, domStyle, on, json, iframe, exif, Router, USER_TYPE, Requester, UserInfo, IdisRest, DialogChain,
    _PageBase, InfoDialog, Loader, DistributionType, HqSetFlg, FunctionCd) {
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    {

        /**
         * データ登録用オブジェクト
         * @type {module:dstore/Store}
         */
        registerStore: null,

        /**
         * データ削除用オブジェクト
         * @type {module:dstore/Store}
         */
        deleteStore: null,

        /**
         * 防災体制ID
         */
        _disasterPreventionId: null,

        /**
         * 災害ID
         */
        _disasterId: null,

        /**
         * 災害種別
         */
        _disasterType: null,

        /**
         * 配信種別 '01':新規, '03':取消, '04':更新, '05':訂正
         */
        distributionType: null,

        /**
         * 体制情報未登録フラグ
         * （フラグが立つ場合、新規登録ボタン表示＆職員参集ボタン非表示）
         */
        _noRecordFlg: true,

        /**
         * 詳細ダイアログで最後に開いた要素
         * （取消ダイアログで表示するために利用）
         * @type {Object}
         * @private
         */
        _lastDetailItem: null,

        // 登録ボタン押下ならtrue
        _clickRegistBtn: false,

        // 取消ボタン押下ならtrue
        _clickCancelBtn: false,

        //_prevStatusStore: null,

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.dialog);
            this.own(this.confirmDialog);
            this.own(this.formDialog);
        },

        /**
         * constructor
         */
        constructor: function() {
            // データ登録用オブジェクト
            this.registerStore = new IdisRest({
                idProperty: 'disasterPrevId',
                target: '/api/disasterPrevention'
            });
            // データ登録用オブジェクト
            this.deleteStore = new IdisRest({
                idProperty: 'disasterPrevId',
                target: '/api/disasterPrevention/delete'
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }
            //災害情報を取得する
            Requester.get('/api/disasters/' + this._disasterId)
            .then(lang.hitch(this, function(res) {
                this._disasterType = res.disasterType;
                // 一覧画面の検索欄セット
                if(this.disasterType){
                    // this.disasterType.set('value', this._disasterType);
                    this.disasterType.set('value', '');
                }
                if(this.status){
                    // 体制セレクタ
                    this.setStatus();
                }
            }));
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            this.initRegisterPage();
            this.initConfirmDialog();
        },

        /**
         * 体制をセット
         */
        setStatus: function(){
            // 自治体コード
            // var areaSelVal = this.municipalitySelector.get('value')!==config.municInfo.prefMunicCd?
            //     this.municipalitySelector.get('value') : config.municInfo.prefCd;
            // var areaSelVal = this.municipalitySelector.get('value');
            // var disasterType = this.disasterType ? this.disasterType.get('value') : this._disasterType;
            // var self = this;
            // if (disasterType) {
            //     Requester.get('/api/disasterPrevention/statusForSelect?' +
            //         'areaCd=' + areaSelVal + '&disasterType=' + disasterType +
            //         '&includeRelease=true', {
            //         headers: { 'Content-Type': 'application/json; charset=utf-8' },
            //         handleAs: 'json',
            //         preventCache: true
            //     }).then(function (data) {
            //         var data2 = [{
            //             'code':'',
            //             'areaCd':'',
            //             'disasterType':'',
            //             'label':'&nbsp;',
            //             'hqType':'99'
            //         }];
            //         for (var i=0; i<data.total; i++) {
            //             data2.push(data.items[i]);
            //         }
            //         self._prevStatusStore = new Memory({
            //             idProperty: 'code',
            //             data: data2
            //         });
            //         self.status.set('sortByLabel', false);
            //         self.status.set('store', self._prevStatusStore);
            //     }, function (err) {
            //         console.log(err);
            //     });
            // }
        },

        /**
         * 新規登録ダイアログを表示する。
         * テンプレートHTMLでdata-dojo-attach-eventプロパティーを用い、
         * 新規登録ボタンのクリックイベントにこのメソッドを紐付けている。
         */
        showRegisterDialog: function() {
            this._clickRegistBtn = false;
            this._clickCancelBtn = false;
            var page = this.dialog.getChildren()[0];
            page.initDialog(this);
            this.dialog.show();
        },

        /**
         * 登録画面のフォームが投稿された際の動作を設定する。
         */
        initRegisterPage: function() {
            // 登録ダイアログの最初の子要素が登録画面
            var page = this.dialog.getChildren()[0];
            page.initDialog(this);
            // 登録画面のsendイベントを受け取る
            page.on('post-send', lang.hitch(this, function(evt) {
                this._clickRegistBtn = true;
                var sendData = lang.mixin({
                    disasterId: this._disasterId
                }, evt.value);
                // 災害種別名
                sendData.disasterTypeName = DisasterTypeModel.get(sendData.disasterType).label;
                //                  //添付ファイルIDをセット
                // var attachFileList = sendData.attachFileList;
                //                  if(attachFileList.length > 0) {
                //                      var attachFileIds = [];
                //                      for(var i = 0; i < attachFileList.length; i++) {
                //                          attachFileIds.push(attachFileList[i].disasterPrevAtcFileId);
                //                      }
                //                        sendData.attachFileIds = attachFileIds.join(',');
                //                  } else {
                //                        sendData.attachFileIds = '';
                //                  }
                // NOTE:長崎県案件では添付ファイルは扱わない
                sendData.attachFileIds = '';
                delete sendData.attachFile;
                delete sendData.attachFileList;

                // 配信対象の場合、配信情報確認画面を表示
                if (sendData.provideFlg) {
                        // 配信種別 新規
                        this.distributionType = DistributionType.REPORT;
                        // 登録情報確認ダイアログ
                        this.showConfirmDialog(sendData);
                        return;
                }

                // 外部配信対象外の場合、共通の登録確認画面
                var self = this;
                this.chain.confirmAdd(function(chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(this.registerStore.add(sendData)).then(function() {
                        chain.infoComplete(function() {
                            self.updateGridQuery();
                            self._buttonFlg = false;
                            self.dialog.hide();
                            chain.hide();
                        });
                    }, function(err) {
                        var jsonData;
                        if(err.response.data) {
                            jsonData = json.parse(err.response.data);
                            // すでに体制状況が登録されている場合はDBエラー（E2001）
                            if(jsonData.code && jsonData.code === 'E2001') {
                                chain.info('選択された自治体では既に体制が登録されています', 'エラー');
                                return;
                            }
                        }
                        // 失敗時
                        err.response.data = jsonData;
                        chain.infoError(err);
                    });
                });

            }));
        },

        /**
         * 詳細ダイアログを表示する。
         * @param {Object} item 参照する行のデータ
         */
        showDetailDialog: function(item) {
            this._clickRegistBtn = false;
            this._clickCancelBtn = false;
            // 最後に開いたデータの項目を保持
            // 配信取消ダイアログでオリジナルの情報を表示するために利用
            this._lastDetailItem = item;
            this.innerDetailDialog.initDialog(this, item);
            this.detailDialog.show();
        },

        /**
         * 配信情報(体制情報)ダイアログを表示する。
         * @param {Object} obj 体制情報
         * @param {string} obj.hqSetFlg 本部設置状況
         * @param {string} obj.status 体制状況
         */
        showConfirmDialog: function(obj) {
            var item = obj;
            // 本部解散の場合、廃止日時をセット
            var headquarterDatetime = item.hqSetFlg===HqSetFlg.ESTABLISH ?
                    (item.hqSetTimestampMS || item.hqSetTimestamp) :
                    (item.hqSetTimestampMS || item.hqAbolishedTimestamp);
            // 本部解散の場合、解散する本部名（更新/訂正前本部名）をセット
            var headquarterName = item.hqSetFlg===HqSetFlg.ESTABLISH ? item.hqName : this._lastDetailItem.hqName;
            var args = {
                municipalityCd : obj.municipalityCd,
                headquarterName : headquarterName,
                hqSetFlg: obj.hqSetFlg,
                // status: obj.status,
                headquarterDatetime: headquarterDatetime,
                distributionType: this.distributionType,
                isUnsentLalert: obj.isUnsentLalert,
                sendData: obj
            };
            // ダイアログの最初の子要素が登録画面
            var dialog = this.confirmDialog.getChildren()[0];
            // ダイアログの画面を初期化する。
            dialog.initDialog(args);
            // ダイアログを表示する。
            this.confirmDialog.show();
        },

        /**
         * 配信情報(体制情報)確認ダイアログを初期化する。
         */
        initConfirmDialog: function(){
            // ダイアログの最初の子要素が登録画面
            var dialog = this.confirmDialog.getChildren()[0];
            // 配信情報確認画面のsendイベントを受け取る
            dialog.on('distribution-send', lang.hitch(this, function(evt) {
                var sendData = evt.value;
                // 配信情報をセット
                var chain = this.chain;
                if (this._clickRegistBtn){
                    // 新規登録
                    delete sendData.disPrevDistributionType;
                    delete sendData.errataDescription;
                    Loader.wait(this.registerStore.add(sendData)).then(lang.hitch(this, function() {
                        chain.infoComplete(function() {
                            this._noRecordFlg = false;
                            this.updateGridQuery();
                            this.dialog.hide();
                            dialog._closeDialog();
                            chain.hide();
                        });
                    }), function(err) {
                        dialog._closeDialog();
                        var jsonData;
                        if(err.response.data) {
                            jsonData = json.parse(err.response.data);
                            // すでに体制状況が登録されている場合はDBエラー（E2001）
                            if(jsonData.code && jsonData.code === 'E2001') {
                                chain.info('選択された自治体では既に体制が登録されています', 'エラー');
                                return;
                            }
                        }
                        // 失敗時
                        err.response.data = jsonData;
                        chain.infoError(err);
                    });
                } else if (this._clickCancelBtn){
                    // 取消
                    var removePromise = this.deleteStore.put({
                            disPrevDistributionType: sendData.disPrevDistributionType,
                            disasterPrevId: sendData.disasterPrevId,
                            provideFlg: sendData.provideFlg,
                            complementaryInfo: sendData.complementaryInfo,
                            errataDescription: sendData.errataDescription,
                            allDelete: sendData.allDelete,
                            version: sendData.version
                        });
                    Loader.wait(removePromise).then(lang.hitch(this, function() {
                        chain.infoComplete(lang.hitch(this, function() {
                            // 取消成功時
                            this.updateGridQuery();
                            // 各ダイアログを閉じる
                            this.detailDialog.hide();   // 詳細ダイアログ
                            dialog._closeDialog();      // 確認ダイアログ
                            chain.hide();               // 完了ダイアログ
                        }));
                    }), function(err) {
                        // 失敗時
                        if(err.response.data && typeof(err.response.data) === 'string'){
                            err.response.data = json.parse(err.response.data);
                        }
                        chain.infoError(err);
                    });
                } else {
                    // 更新・訂正
                    Loader.wait(this.registerStore.put(sendData)).then(lang.hitch(this, function() {
                        chain.infoComplete(lang.hitch(this, function() {
                            // 取消成功時
                            this.updateGridQuery();
                            // 各ダイアログを閉じる
                            this.detailDialog.hide();   // 詳細ダイアログ
                            dialog._closeDialog();      // 確認ダイアログ
                            chain.hide();               // 完了ダイアログ
                        }));
                    }), function(err) {
                        // 失敗時
                        if(err.response.data && typeof(err.response.data) === 'string'){
                            err.response.data = json.parse(err.response.data);
                        }
                        chain.infoError(err);
                    });
                }
            }));
        },

        /**
         * 新規登録ボタン/職員参集管理ボタンの表示/非表示を切り替える
         */
        changeButton: function() {
            // デフォルト 不可
            domStyle.set(this.registerButton.domNode, 'display', 'none');
            domStyle.set(this.empGatheringButton.domNode, 'display', 'none');
            // 登録情報がない場合
            if(this._noRecordFlg){
                // 自分の自治体 or 代理登録権限ありの場合のみ表示
                if (UserInfo.hasWriteAuthz(FunctionCd.DISASTERPREVENTION) &&
                    (UserInfo.getUserType() === USER_TYPE.REGION && UserInfo.getRegionCd() === this.municipalityCd ||
                    UserInfo.getMunicipalityCd() === this.municipalityCd) ||
                    UserInfo.hasProxyWriteAuthz(FunctionCd.DISASTERPREVENTION)) {
                        domStyle.set(this.registerButton.domNode, 'display', '');
                }

            // 登録情報がある場合
            } else {
                domStyle.set(this.empGatheringButton.domNode, 'display', 'none');
                // 職員参集画面への遷移 -> 操作権限のあるユーザー＆管内の市町村であれあば可
                if(UserInfo.hasWriteAuthz(FunctionCd.EMPLOYGATHERING)){
                    switch (UserInfo.getUserType()) {
                        case USER_TYPE.MUNICIPALITY:
                            if(UserInfo.getMunicipalityCd()===this.municipalityCd){
                                domStyle.set(this.empGatheringButton.domNode, 'display', '');
                            }
                            break;
                        case USER_TYPE.REGION:
                        if(UserInfo.getMunicipalityCds().indexOf(this.municipalityCd)>-1 || 
                            UserInfo.getRegionCd() === this.municipalityCd){
                                domStyle.set(this.empGatheringButton.domNode, 'display', '');
                            }
                            break;
                        case USER_TYPE.PREFECTURE:
                            domStyle.set(this.empGatheringButton.domNode, 'display', '');
                            break;
                    }
                }
            }
        },

        /**
         * 概況情報をエクセル形式で出力します。
         */
        onOutputButtonClick: function() {
            var page = this.formDialog.getChildren()[0];
            page.initDialog(this);
            this.formDialog.show();
        }
    });
});
