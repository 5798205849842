/**
* 体制状況・報告概況画面用モジュール。
* @module app/employgathering/EmployGatheringStatusListPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/text!./templates/EmployGatheringStatusListPage.html',
    'dstore/Memory',
    'dstore/Trackable',
    'idis/control/Router',
    'idis/store/IdisRest',
    './_EmployGatheringStatusPageBase',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'app/model/DisasterInfo',
    'idis/control/Locator',
    'dojo/dom-style',
    'app/config',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'app/disaster/model/DisasterType',
    // 以下、変数として受け取らないモジュール
    'dijit/layout/BorderContainer',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'idis/view/form/DateInput',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'app/employgathering/EmployGatheringFormDialog',
    './EmployGatheringStatusListGrid',
    './EmployGatheringStatusDetailDialog'
], function(module, declare, lang, json, template, Memory, Trackable,
    Router, IdisRest, _PageBase, DialogChain, Loader, DisasterInfo, Locator,
    domStyle, config, UserInfo, USER_TYPE, DisasterType) {
    /**
     * 検索条件格納用オブジェクト
     * @type {Object}
     */
    // var filterStore = {};

    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/view/page/DisasterAdmintPage~DisasterAdminPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--employgathering',

        /**
        * 職員参集管理パラメータ格納用オブジェクト
        * @type {Object}
        */
        DisasterPrevInfItems : null,

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        summaryStore: null,

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        /**
         * データ登録用オブジェクト
         * @type {module:dstore/Store}
         */
        registerStore: null,

        /**
         * 災害ID
         */
        disasterId: null,

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.detailDialog);
            // this.own(this.formDialog);
        },

        constructor: function() {
            // 体制情報用データ格納用オブジェクト
            this.summaryStore = new IdisRest({
                idProperty: 'disasterPrevId',
                target: '/api/disasterPrevention',
                rangeStartParam: 'offset'
            });
            // グリッド用データ格納用オブジェクト
            this.tempStore = new IdisRest({
                idProperty: 'empGatherId',
                target: '/api/employGathering'
            });
            // データ登録用オブジェクト
            this.registerStore = new IdisRest({
                idProperty: 'empGatherId',
                target: '/api/employGathering'
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
            // 災害IDをDisasterInfoから取得
            this.disasterId = DisasterInfo.getDisasterId();
            if(!this.disasterId) {
                this.disasterId = 1;
                console.warn('災害IDが設定させていません。');
            }
            this._municipalityCd = Locator.getQuery().municipalityCd;
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            this.initDisasterPreventionStatusInf();
            // this.initGrid(); // 同期のため初期処理から除外
        },

        /**
         * 体制情報と本部設置情報をセット
         */
        initDisasterPreventionStatusInf: function(){
            var filter = new this.summaryStore.Filter();
            // 災害ID
            filter = filter.eq('disasterId', this.disasterId);
            // 市町村CDのみで最新報を取得
            filter = filter.eq('municipalityCd', this._municipalityCd);
            // 最新報のみ取得
            filter = filter.eq('pastReportMode', false);
            // filterに対応するcollectionを取得
            var collection = this.summaryStore.filter(filter);
            collection.fetch().then(lang.hitch(this, function(data) {
                if(data.totalLength !== 0){
                    // 取得したデータを共通情報部分に設定する
                    this._disasterPreventionId = data[0].disasterPrevId;
                    this.reportNum.innerHTML = '第' + data[0].seqNum + '報';
                    this.municipalityName.innerHTML = data[0].municipalityName;
                    this.reportTimestamp.innerHTML = data[0].reportTimestamp.replace(/-/g, '/');
                    this.disasterType.innerHTML = DisasterType.get(data[0].disasterType).label;
                    this.statusName.innerHTML = data[0].statusName;
                    this.comment.innerHTML = data[0].comment === null ? '' : data[0].comment;
                    this.hqName.innerHTML = data[0].hqName === null ? '' : data[0].hqName;
                    if(data[0].hqSetTimestamp !== null){
                        this.hqSetTimestamp.innerHTML = data[0].hqSetTimestamp.replace(/-/g, '/');
                    }else{
                        this.hqSetTimestamp.innerHTML = '-';
                    }
                    if(data[0].hqAbolishedTimestamp !== null){
                        this.hqAbolishedTimestamp.innerHTML = data[0].hqAbolishedTimestamp.replace(/-/g, '/');
                    }else{
                        this.hqAbolishedTimestamp.innerHTML = '-';
                    }
                    this.hqSetPlace.innerHTML = data[0].hqSetPlace === null ? '' : data[0].hqSetPlace;
                }else{
                    // データがない場合一覧画面に遷移する
                    Router.moveTo('disasterprevention/status', {municipalityCd : this._municipalityCd});
                }
                this.initGrid(); // 同期のため当関数で実行
            }));
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // 編集グリッドの初期化
            this.store = Trackable.create(new Memory({
                idProperty: 'empGatherId',
                data: {
                    items: []
                }
            }));
            // 全件表示用のcollectionをグリッドにセットする
            // （テンプレートHTML内のdata-dojo-attach-point="grid"でグリッドをthis.gridに紐付け済み）
            var filter = new this.tempStore.Filter();
            filter = filter.eq('disasterId', this.disasterId);
            filter = filter.eq('disasterPrevId', this._disasterPreventionId);
            filter = filter.eq('municipalityCd', this._municipalityCd);
            this.tempStore.filter(filter)
                // APIで取得したデータをTrackableStoreに代入する
                .forEach(lang.hitch(this, function(item){
                    this.store.put(item);
                // collectionをグリッドにセットする
                })).then(lang.hitch(this, function(){
                    this.grid.set('collection', this.store.track());
                }));

            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.showDetailDialog(evt.item);
            }));

            // 市町ユーザの場合、概況リンクを不可視
            if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
                UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
                domStyle.set(this.overviewLabel, 'display', 'none');
            }
        },

        /**
         * 詳細ダイアログを表示する。
         * @param {Object} item 参照する行のデータ
         */
        showDetailDialog: function(item) {
            this.innerDetailDialog.initDialog(this, item);
            this.detailDialog.show();
        },

        // /**
        //  * 詳細ダイアログのフォームが投稿された際の動作を設定する。
        //  */
        // initDetailDialog: function() {
        //     // 詳細ダイアログの最初の子要素が詳細画面
        //     var dialog = this.detailDialog;
        //     var page = dialog.getChildren()[0];

        //     var self = this;
        //     // 詳細画面の組織選択のchangeイベントを受け取る
        //     page.EmployGatheringOrganizationSelector.on('change', lang.hitch(this, function(evt) {
        //         this.grid.collection.fetch().forEach(function(item) {
        //             if(item.empCategory===evt.value){
        //                 self.innerDetailDialog.onChangeOrgSelector(item);
        //             }
        //         });
        //     }));

        //     // 詳細画面のsendイベントを受け取る
        //     page.on('send', lang.hitch(this, function(evt) {
        //         this.chain.confirmPut(function(chain) {
        //             var sendData = evt.value;
        //             // 災害IDをセット
        //             sendData.disasterId = this.disasterId;
        //             if(!sendData.empGatherId){
        //                 // 新規登録→IDを削除
        //                 delete sendData.empGatherId;
        //                 // 防災体制IDをセット
        //                 sendData.disasterPrevId = this.DisasterPrevInfItems.disasterPrevId;
        //                 // 市町村コードをセット
        //                 sendData.municipalityCd = this.DisasterPrevInfItems.municipalityCd;
        //             }
        //             Loader.wait(this.store.add(sendData)).then(lang.hitch(this, function() {
        //                 this.initGrid();
        //                 chain.infoComplete(lang.hitch(this, function() {
        //                     // this.distributionType = '02';
        //                     dialog.hide();
        //                     chain.hide();
        //                 }));
        //             }), function(err) {
        //                 // 失敗時
        //                 if(err.response.data && typeof(err.response.data) === 'string'){
        //                     err.response.data = json.parse(err.response.data);
        //                 }
        //                 chain.infoError(err);
        //             });
        //         });
        //     }));
        // },

        /**
         * グリッドから登録
         */
        onSubmit: function() {
            var recItems = [];
            this.grid.collection.forEach(lang.hitch(this, function(item) {
                // 新規登録の場合は、IDをnullとする
                var empGatherId = parseInt(item.empGatherId,10)!==0 ? item.empGatherId : null;
                // IDを持つ、又は入力ありの場合、更新対象
                if(empGatherId!==null || (item.gatherDisaster!==0 ||
                    item.reportTimestampMS!==null || item.comment!==null)){
                        recItems.push({
                            empGatherId: empGatherId,
                            empCategory: item.empCategory,
                            gatherDisaster : item.gatherDisaster,
                            gatherFlood: item.gatherFlood,
                            reportTimestamp: item.reportTimestampMS,
                            deptCd: item.deptCd,
                            sectCd: item.sectCd,
                            unitCd: item.unitCd,
                            comment: item.comment,
                            version : item.version,
                            orgName : item.orgName
                        });
                }
            }));

            // 固有のバリデーションを実施
            if(!this.validate(recItems)) {
                return false;
            }

            // 以下、バリデーションチェック時、メッセージ表示用要素削除
            recItems.forEach(function(item){
                delete item.orgName;
            });

            // 登録
            this.chain.confirmPut(function(chain) {
                var sendData = {
                    disasterId : this.disasterId,
                    disasterPrevId : this._disasterPreventionId,
                    municipalityCd : this._municipalityCd,
                    empGatheringList : recItems
                };

                Loader.wait(this.registerStore.add(sendData)).then(lang.hitch(this, function() {
                    this.initGrid();
                    chain.infoComplete(lang.hitch(this, function() {
                        // this.distributionType = '02';
                        chain.hide();
                    }));
                }), function(err) {
                    // 失敗時
                    if(err.response.data && typeof(err.response.data) === 'string'){
                        err.response.data = json.parse(err.response.data);
                    }
                    chain.infoError(err);
                });
            });
        },

        //パンくずリストのボタンを押下したときの挙動
        onDisasterPreventionListPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('disasterprevention');
        },

        //パンくずリストのボタンを押下したときの挙動
        onDisasterPreventionStatusPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('disasterprevention/status', {municipalityCd : this._municipalityCd});
        }
    });
});
