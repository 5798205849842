/**
 * ツリー選択入力要素を定義する。
 * @module app/view/page/DisasterTreeSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-style',
    'dojo/text!./templates/DisasterTreeSelector.html',
    'dojo/when',
    'dojo/query',
    'idis/view/_IdisWidgetBase',
    'idis/view/tree/IdisTree',
    'idis/view/form/_NamedMixin',
    // 以下、変数で受けないモジュール
    'idis/view/dialog/InfoDialog',
    'idis/view/form/Button'
], function(module, declare, lang, domClass, domStyle, template, when, query,
             _IdisWidgetBase, IdisTree, _NamedMixin) {
    /**
     * ツリー選択入力要素。
     * @class TreeSelector
     * @extends module:idis/view/_IdisWidgetBase~_IdisWidgetBase
     * @param {Object} kwArgs
     * @param {module:dijit/tree/model} [kwArgs.title] 選択ダイアログのタイトル
     * @param {module:dijit/tree/model} kwArgs.model ツリー・モデル
     * @param {module:dijit/Tree} kwArgs.treeClass ツリー生成用クラス
     */
    return declare(module.id.replace(/\//g, '.'), [_IdisWidgetBase, _NamedMixin],
        /** @lends module:idis/view/form/TreeSelector~TreeSelector# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * ウィジェットのダイアログのタイトル
         * @type {string}
         */
        title: '災害名統合',

        /**
         * このプロパティーが定義されているとき、
         * {@link module:dijit/form/Form~Form}のget('value')による取得対象となる。
         * @type {string}
         */
        value: '',

        /**
         * ツリー・モデル
         * @type {dijit/tree/model}
         */
        model: null,

        /**
         * ツリー・ウィジェット
         * @type {module:dijit/Tree~Tree}
         */
        tree: null,

        /**
         * ツリー生成用クラス
         * @type {function}
         */
        treeClass: IdisTree,

        // DOMノードを生成する
        buildRendering: function() {
            console.log('DisasterTreeSelector buildRendering');
            this.inherited(arguments);
            // 共通CSSクラスを追加
            domClass.add(this.domNode, 'idis-TreeSelector dijitInline');

            // OKボタンを非表示
            var node = query('.dijitDialogPaneActionBar', this.dialog.domNode);
            domStyle.set(node[0], 'display', 'none');

            // 破棄する際はダイアログも連れて行く
            this.own(this.dialog);

        },

        /**
         * ウィジェットの値をリセットする。
         */
        reset: function() {
            console.log('DisasterTreeSelector reset');
            this.set('value', '');
            this.dialog.hide();
        },

        /**
         * ツリーを初期化する。
         */
        _initTree: function() {
            console.log('DisasterTreeSelector _initTree');
            if (!this.tree) {
                this.tree = new this.treeClass({
                    model: this.model
                }, this.treeNode);
                this.tree.startup();
            }
            return this.tree.onLoadDeferred;
        },

        /**
         * 選択用ダイアログを表示する。
         */
        showDialog: function() {
            console.log('DisasterTreeSelector showDialog');
            this._initTree();
            this.dialog.show();
        },

        /**
         * ツリー選択されている要素をこのウィジェットのvalue値として設定する。
         * 要素が選択されていない場合は何もしない。
         */
        applySelectedItem: function() {
            console.log('DisasterTreeSelector applySelectedItem');
            var item = this.tree.selectedItem;
            if (item) {
                this.set('value', this.tree.model.store.getIdentity(item));
            }
        }
    });
});
