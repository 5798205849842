/**
 * ツリー選択入力要素を定義する。
 * @module idis/view/form/TreeSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/dom-class',
    'dojo/dom-style',
    'idis/model/UserInfo',
    'dojo/text!./templates/ChronologyAction.html',
    'dojo/when',
    'idis/view/_IdisWidgetBase',
    //'idis/view/form/_NamedMixin',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button'
], function(module, declare, lang, array, domClass, domStyle,
    UserInfo, template, when, _IdisWidgetBase) {
    /**
     * ツリー選択入力要素。
     * @class TreeSelector
     * @extends module:idis/view/_IdisWidgetBase~_IdisWidgetBase
     * @param {Object} kwArgs
     * @param {module:dijit/tree/model} [kwArgs.title] 選択ダイアログのタイトル
     * @param {module:dijit/tree/model} kwArgs.model ツリー・モデル
     * @param {module:dijit/Tree} kwArgs.treeClass ツリー生成用クラス
     */
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase,
        /** @lends module:idis/view/form/TreeSelector~TreeSelector# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * 未選択時の文字列
         * @type {string}
         */
        chronologyActionId: null,
        content: '',
        hldStatus: '',
        name: '',
        crtTimestamp: '',
        updTimestamp: '',
        // organizationName: '',
        attachFiles: '',

        constructor: function(kwArgs) {
            lang.mixin(this, kwArgs);
        },

        // DOMノードを生成する
        buildRendering: function() {
            this.inherited(arguments);
            // if(this.attachFiles.length>0){
            //     var html = '';
            //     array.forEach(this.attachFiles, function(file){
            //         html += '<img src="' + file + '" height="100">';
            //     },this);
            //     this.attachFile.innerHTML = html;
            // }
            // 登録者であれば編集可
            if(!this.crtUserId || UserInfo.isSysAdmin() || this.crtUserId === UserInfo.getId()){
                domStyle.set(this.updateActionButton.domNode, 'display', '');
            }
        }
    });
});
