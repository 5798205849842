/**
 * 掲示板の新規登録・詳細・続報登録画面用の基底モジュール。
 * @module app/bbs/_BbsPageBase
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom',
    'dojo/text!./templates/BeginnerAdminPage.html',
    'dijit/popup',
    'dijit/TooltipDialog',
    'idis/view/page/_PageBase',
    'idis/view/dialog/DialogChain',
    'dojo/on',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/form/Textarea',
    'dijit/form/SimpleTextarea',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
], function(module, declare, dom, template, popup,
    TooltipDialog, _PageBase, DialogChain, on) {

    /**
     * 初心者モード画面・初心者モードダイアログ画面。
     *  _BeginnerPageBase
     *  module:app/beginner/_BeginnerPageBase~_BeginnerPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/bbs/_BeginnerPageBase~_BeginnerPageBase# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * constructor
         */
        constructor: function() {
            this.chain = DialogChain.get(this);

        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
        },

        /**
         * postCreate
         */
        postCreate: function() {
            this.inherited(arguments);
        },

        /**
         * tips
         */
        setDisasterNameTips: function () {

            var disasterTips = new TooltipDialog({
                id: 'disasterTips',
                style: 'width: 300px; height:100px',
                content: '<p>ここに災害名の説明が出ます</p>'
            });
            this.own(disasterTips);
            on(dom.byId('tipsLabel'), 'mouseover', function () {
                popup.open({
                    popup: disasterTips,
                    around: dom.byId('tipsLabel')
                });
            });
            on(dom.byId('tipsLabel'), 'mouseleave', function () {
                popup.close(disasterTips);
            });
        },

    });
});
