/**
 * 指示共有グループ画面用モジュール。
 * @module app/chat/ChatSharePage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/dom-style',
    'dojo/dom-class',
    'dojo/text!./templates/ChatSharePage.html',
    'idis/view/page/_PageBase',
    'dijit/Dialog',
    'idis/control/Router',
    'idis/control/Locator',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    './_ChatGroupDialogBase',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    './ChatShareGrid',
    './ChatShareDetailDialog',
    './ChatShareRegisterDialog'
], function(module, declare, lang, Deferred, domStyle, domClass, template, _PageBase, Dialog, Router, Locator,
    UserInfo, Requester, IdisRest, Loader, DialogChain, _ChatGroupDialogBase) {
    /**
     * 指示共有グループ画面
     * @class ChatSharePage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _ChatGroupDialogBase,
    /** @lends module:app/chat/ChatSharePage~ChatSharePage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--chatShare',

        /**
         * ストア
         */
        store: null,

        /**
         * チャットグループID
         */
        _chatGroupId: null,

        /**
         * チャットID配列
         */
        _chatIds: [],

        /**
         * 気象情報ID配列
         */
        _dpDistKisyoHistIds: [],

        /**
         * 動員指示・気象情報かどうか
         */
        _isNewsInfo: null,

        /** 「動員指示・気象情報」の指示共有グループID(便宜上の仮置き) */
        CHAT_GRP_ID_NEWS : 'news',

        /**
         * 既読処理中の一覧画面への遷移を防止するためのフラグ
         * （UPDATE中にSELECTすることになるので、デットロックになる）
         */
        // 既読処理が完了しているかどうか
        _readCompleted: false,
        // グループ一覧画面への遷移リクエスト中かどうか
        _isMovingChatShareGroupPage: false,
        // 既読一覧画面への遷移リクエスト中かどうか
        _isMovingChatShareStatusPage: null,

        /**
         * constructor
         */
        constructor: function() {
            // nullなら、動員指示・気象情報
            this._chatGroupId = Locator.getQuery().chatGroupId ?? this.CHAT_GRP_ID_NEWS;
            this._isNewsInfo = this._chatGroupId === this.CHAT_GRP_ID_NEWS;
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'chatId',
                target: '/api/chatShare',
                sortParam: 'sort',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.innerChatShareRegisterDialog);
            this.own(this.innerChatShareDetailDialog);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);

            this.initGrid();
            this.initPage();
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.chatShareGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.showDetailDialog(evt.item);
            }));

            // グリッドの一覧ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'list'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.chatShareGrid.on('listButtonClick', lang.hitch(this, function(evt) {
                this.onListButtonClick(evt.item);
            }));
            // 動員指示・気象情報で不要な列を非表示にする
            if (this._isNewsInfo) {
                domClass.add(this.chatShareGrid.domNode, 'is-news-grid');
            }
            this.updateGridQuery();
        },

        /**
         * 指示共有画面を初期化する。
         */
        initPage: function() {
            // 動員指示・気象情報
            if (this._isNewsInfo) {
                this.initFormValue({
                    chatGroupId : this.CHAT_GRP_ID_NEWS,
                    chatGroupName : '動員指示・気象情報',
                    chatGrpUseType : this.CHAT_GRP_ID_NEWS,
                    canPost : false
                });

            // チャットグループなら、表示詳細情報を取得する
            } else {
                Requester.get('/api/chatShare/groupInfo/' + this._chatGroupId).then(lang.hitch(this, function(data) {
                    this.initFormValue(data);
                }));
            }
            // 新規登録ダイアログを初期化
            this.initRegisterDialog();
            // 詳細ダイアログを初期化
            this.initDetailDialog();
        },

        /**
         * 表示情報をセットする
         */
        initFormValue: function(data) {
            // グループ名
            this.groupName.innerHTML = data.chatGroupName ? data.chatGroupName : '-';
            // 投稿不可の場合、新規登録ボタンを非表示
            if (!data.canPost) {
                domStyle.set(this.RegisterButton.domNode, 'display', 'none');
            }
        },

        /**
         * 新規登録ダイアログを初期化する。
         */
        initRegisterDialog: function() {
            var dialog = this.chatShareRegisterDialog;
            var page = dialog.getChildren()[0];

            // 登録画面のregisterイベントを受け取る
            page.on('register', lang.hitch(this, function(evt) {
                console.debug('registerイベント');
                // 追加確認ダイアログを表示
                this.chain.confirmAdd(function(chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(this.store.add(evt.value)).then(lang.hitch(this, function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                        // 一覧を取得しなおす
                        this.updateGridQuery();
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 詳細ダイアログを初期化する。
         */
        initDetailDialog: function() {
            var dialog = this.chatShareDetailDialog;
            var page = dialog.getChildren()[0];
        },

        /**
         * 指示共有グループを検索する。
         */
        onSubmit: function() {
            console.debug('[検索]ボタンがクリックされました。');
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 指示共有グループ新規登録ダイアログを表示する。
         */
        showRegisterDialog: function() {
            console.debug('[新規登録]ボタンがクリックされました。');
            var data = {
                userId: UserInfo.getId(),
                userName: UserInfo.getName(),
                chatGroupId: this._chatGroupId
            };
            this.innerChatShareRegisterDialog.initDialog(data);
            this.chatShareRegisterDialog.show();
        },

        /**
         * 指示共有グループ詳細ダイアログを表示する。
         */
        showDetailDialog: function(object) {
            console.debug('[詳細]ボタンがクリックされました。');
            this.innerChatShareDetailDialog.initDialog(object);
            this.chatShareDetailDialog.show();
        },

        onListButtonClick: function(object) {
            // 既読処理完了していたら、画面遷移する
            if (this._readCompleted) {
                // 指示共有既読状況一覧画面へ遷移
                Router.moveTo('chatShare/status', {
                    chatId : object.chatId,
                    chatGroupId: this._chatGroupId
                });
            } else {
                // 既読処理中の場合はLoaderを表示して、画面遷移中フラグを立てる
                Loader.show();
                this._isMovingChatShareStatusPage = object;
                console.log('既読処理実行中。処理が終わるのを待ってから画面繊維する。');
            }
        },

        /**
         * グリッドを更新する。
         */
        updateGridQuery: function() {
            var filter = new this.store.Filter();
            // チャットグループIDをセット
            filter = filter.eq('chatGroupId', this._chatGroupId);

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.chatShareGrid.set('collection', collection);

            // チャットIDを取得し、既読情報を更新
            collection.forEach(lang.hitch(this, function(item) {
                if (item.chatId) { this._chatIds.push(item.chatId); }
                if (item.dpDistKisyoHistId) { this._dpDistKisyoHistIds.push(item.dpDistKisyoHistId); }
            })).then(lang.hitch(this, function() {
                this.updateReadStatus();
            }));
        },

        /**
         * 既読情報をサーバーに送信する
         */
        updateReadStatus: function() {
            console.log('既読情報を送信します chatIds: ' + this._chatIds);
            console.log('気象情報の既読情報を送信します _dpDistKisyoHistIds: ' + this._dpDistKisyoHistIds);
            // 既読情報を更新する
            Requester.post('/api/chatShare/read', {
                data: {
                    userId: UserInfo.getId(),
                    readChatIds: this._chatIds,
                    dpDistKisyoHistIds: this._dpDistKisyoHistIds
                }
            }).then(lang.hitch(this, function() {
                // 成功時
                console.debug('success update read status');
                // 既読処理完了前に一覧画面への遷移をされたら、一旦保留しているので、
                // このタイミングで遷移する
                if (this._isMovingChatShareGroupPage) {
                    Loader.hide();
                    Router.moveTo('chatShare/group');
                }
                if (this._isMovingChatShareStatusPage) {
                    Loader.hide();
                    // 指示共有既読状況一覧画面へ遷移
                    Router.moveTo('chatShare/status', {
                        chatId : this._isMovingChatShareStatusPage.chatId,
                        chatGroupId: this._chatGroupId
                    });
                }
                this._readCompleted = true;
            }), lang.hitch(this, function(error) {
                // 失敗時
                console.debug('error update read status');
                console.log(error);
                this.chain.infoError(error);
            }));
        },

        // パンくずリストのボタンを押下したときの挙動
        onChatShareGroupPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 既読処理完了していたら、画面遷移する
            // （既読処理完了前にグループ一覧に遷移するとデットロックでエラーになるため）
            if (this._readCompleted) {
                Router.moveTo('chatShare/group');
            } else {
                // 既読処理中の場合はLoaderを表示して、画面遷移中フラグを立てる
                Loader.show();
                this._isMovingChatShareGroupPage = true;
                console.log('既読処理実行中。処理が終わるのを待ってから画面繊維する。');
            }
        }
    });
});
