/**
 * 避難理由一覧
 * @module app/evacorder/Reason
 */
define([
    'dojo/store/Memory'
], function(Memory) {
    return new Memory({
        data: [
            // lalertReason, typeOfDisasterはサーバ側にも定義が必要
            {id: '01', name: '土砂', lalertReason: '土砂災害警戒のため', typeOfDisaster: '崖崩れ、土石流及び地滑り'},
            {id: '02', name: '洪水', lalertReason: '洪水警戒のため', typeOfDisaster: '洪水'},
            {id: '03', name: '地震', lalertReason: '地震警戒のため', typeOfDisaster: '地震'},
            {id: '04', name: '津波', lalertReason: '津波警戒のため', typeOfDisaster: '津波'},
            {id: '05', name: '高潮', lalertReason: '高潮警戒のため', typeOfDisaster: '高潮'},
            {id: '06', name: '火災', lalertReason: '火災のため', typeOfDisaster: '大規模な火事'},
            {id: '07', name: '暴風', lalertReason: '暴風警戒のため', typeOfDisaster: 'その他'},
            {id: '08', name: '火山', lalertReason: '火山警戒のため', typeOfDisaster: '火山現象'},
            {id: '09', name: '国民保護', lalertReason: '国民保護のため', typeOfDisaster: 'その他'},
            {id: '00', name: 'その他', lalertReason: '', typeOfDisaster: 'その他'}
        ]
    });
});
