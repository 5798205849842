/**
 * 区市町村連携被害数値情報自動集計ダイアログ。
 * @module app/damage/ScheduledReportMuniAutoAggregateDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/text!./templates/ScheduledReportMuniAutoAggregateDialog.html',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'dijit/form/SimpleTextarea',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/DateTimeInput',
    'idis/view/form/Button',
    'app/view/form/MunicipalitySelector',
    'app/view/form/DisasterSelector',
    './ScheduledReportMuniAutoAggregateListGrid'
], function(module, declare, lang, array, template, Router, Requester, IdisRest, DialogChain, 
    InfoDialog, Loader, _PageBase) {
    /**
     * 区市町村連携被害数値情報自動集計ダイアログ画面。
     * @class ScheduledReportMuniAutoAggregateDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/damage/ScheduledReportMuniAutoAggregateDialog~ScheduledReportMuniAutoAggregateDialog# */ {
        // テンプレート文字列
        templateString: template,
        store: null,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--scheduledreport',

        // お知らせ用Dialog
        infoDialog: null,

        _disasterId: null,

        _parent: null,

        /**
         * constructor
         */
        constructor: function() {
            this.chain = DialogChain.get(this);
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'damageNumReportId',
                target: '/api/damageNumReports'
            });
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.muniAutoAggregateGrid);
        },

        resize: function(changeSize, resultSize) {
            this.borderContainer.resize(changeSize, resultSize);
        },

        /**
         * 画面から渡された値で初期表示設定を行う。
         */
        initDialog: function(municipalityCd, disasterId, parent){
            // grid初期化
            this.borderContainer.resize();
            this._disasterId = disasterId;
            this._parent = parent;
            this.initGrid(municipalityCd, disasterId);
        },

        /**
         * Grid初期化。
         */
        initGrid: function(municipalityCd){
            var filter = new this.store.Filter();
            filter = filter.eq('municipalityCd', municipalityCd);
            filter = filter.eq('disasterId', this._disasterId);
            filter = filter.eq('count', -1);
            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.muniAutoAggregateGrid.set('collection', collection);
        },

        /**
         * 集計ボタン押下時の処理。
         */
        autoAggregateSelectedInfo: function(){
            // 現在チェックがついている施設IDを取得
            var checkedRowList = this.muniAutoAggregateGrid.getSelectedIdList();

            // 入力チェック
            if (checkedRowList.length === 0 || checkedRowList === null) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : '未入力',
                        content : '集計する区市町村連携被害数値情報を選択して下さい。'
                    });
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }
            }

            var selectedDamageNumReportIds = checkedRowList.join(',');
            var apiPath = '/api/scheduledReports/muni/autoAggregate?disasterId=' + this._disasterId + 
                '&selectedDamageNumReportIds=' + selectedDamageNumReportIds;
            var message = '自動集計を開始します。よろしいですか？';
            var self = this;
            this.chain.confirm(message, function(chain) {
                var promise = Requester.get(apiPath, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(function(item) {
                    delete item.reportAuthorName;
                    delete item.reportTimestamp;
                    delete item.version;
                    delete item.municipalityCd;

                    // 集計結果を定時報告詳細画面に設定
                    self._parent.form.set('value', item);
                    chain.infoComplete(function() {
                        // ダイアログを閉じる
                        self.leave();
                        chain.hide();
                    });
                }, function(err) {
                    self.leave();
                    chain.infoError(err);
                });
                //ローダーの表示
                Loader.wait(promise);
            });
        }
    });
});
