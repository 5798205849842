/**
 * 被害情報更新画面における被害情報更新ダイアログ
 * @module app/view/page/<ウィジェット名>
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/text!./templates/DamageReportUpdateInfoDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'app/model/DisasterInfo',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/util/FilesUtils',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'dijit/form/RadioButton',
    'app/view/form/CustomizableMunicipalitySelector',
    'app/view/form/MunicipalitySelector',
    'dijit/form/Form',
    'app/damage/damagetype/DamageTypeSelector'
], function(module, declare, lang, template, _PageBase, DisasterInfo, InfoDialog, DialogChain) {
    /**
     * 被害情報更新画面における被害情報更新ダイアログ
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {
        // テンプレート文字列
        templateString: template,

        firstFlg: true,

        constructor: function(){
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        initDialog: function(item) {
            if(!item.damageTimestamp){
              delete item.damageTimestamp;
            }
            //被害種別を表示(TODO:編集可能にアップデート予定)
            var damageTypeArr = item.damageTypeList.split(',');
            if(damageTypeArr) {
                this.innerDamageTypeSelector.setDamageType(damageTypeArr);
            }

            this.innerDamageTypeSelector.changeFlg = false;
            this.firstFlg = false;
            this.form.set('value', item);
        },

         onSubmit: function() {
             var checkDamageType = this.innerDamageTypeSelector.innerDialog.grid.mainTypeIds.join(',');
             var damageTimestamp = '';
              if(this.damageTimestamp._getValueAttr()){
                damageTimestamp = this.damageTimestamp._getValueAttr().getTime();
              }
              // 未選択の場合はエラー
              if(!checkDamageType) {
                console.log('被害種別が選択されていません');
                if(!this.infoDialog) {
                     this.infoDialog = new InfoDialog({
                         title: 'エラー',
                         content: '被害種別が選択されていません'
                     });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
              }

              this.emit('send', {form: {
                admNum: this.admNum.value,
                damageTimestamp: damageTimestamp,
                notifierCategory: this.notifierCategory.value,
                notifierName: this.notifierName.value,
                notifierContact: this.notifierContact.value,
                urgencyType: this.urgencyType.value,
                damageTypeList: checkDamageType,
                humanDamageFlg: this.humanDamageFlg.value,
                content: this.content.value,
                changeFlg: this.innerDamageTypeSelector.changeFlg
              }});
         }
    });
});
