/**
 * テンプレート選択ダイアログ画面用モジュール。
 * @module app/evacorder/EvacOrderTemplateSelectorDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/EvacOrderTemplateSelectorDialog.html',
    'idis/view/dialog/InfoDialog',
    'idis/store/IdisRest',
    'idis/view/page/_PageBase',
    '../config',
    'app/model/Region',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/view/dialog/DialogChain',
    'idis/control/Locator',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    'app/evacorder/EvacOrderTemplateSelectorGrid'
], function(module, declare, lang, template, InfoDialog, IdisRest, WidgetBase, config, Region, UserInfo,
    USER_TYPE, DialogChain, Locator) {

    /**
     * テンプレート選択ダイアログ
     * @class EvacOrderTemplateSelectorDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), WidgetBase,
        /** @lends app/view/Header~Header# */ {

        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        // baseClass: 'parentevacorder-selector',
        baseClass: 'idis-Page idis-Page--evacorder',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        _userRoleCd: '',

        _isPrefUser: false,

        _municipalityCd: null,

        /**
         * コンストラクタ
         */
        constructor: function() {
            this.inherited(arguments);
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'evacOrderTemplateId',
                target: '/api/evacorders/template'
            });
            this.chain = DialogChain.get(this);

            // ベースクラスのコンストラクタを呼ぶ
            this._userRoleCd = UserInfo.getRoleCd();
            this._isPrefUser = (UserInfo.getUserType() === USER_TYPE.PREFECTURE) ? true : false;
            this._municipalityCd = this._isPrefUser ? null :
                UserInfo._userInfo.municipalityCd || UserInfo._userInfo.municipalityCds[0];

            // 避難発令テンプレートIDを取得
            this.evacOrderTemplateId = Locator.getQuery().evacOrderTemplateId;
        },

        /**
         * DOM生成
         */
        buildRendering: function() {
            this.inherited(arguments);
            // グリッドを初期化
            this.initGrid();
        },

        /**
         * 画面生成完了
         */
        startup: function() {
            this.inherited(arguments);
        },

        /**
         * 画面を再表示
         */
        refresh: function() {
            this.container.resize();
            this.item = null;
            this.grid.refresh();
        },

        initContent: function(isEmergency, municipalityCd){
            this.updateGridQuery(isEmergency, municipalityCd);
        },

        /**
         * グリッド初期化
         */
        initGrid: function() {
            // グリッドの行選択イベント
            this.grid.on('applyButtonClick', lang.hitch(this, function(evt) {
                // 選択された行データを退避
                this.item = evt.item;
                this.onSubmit();
            }));

            // 保管した検索条件をセットする
            if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY){
                this.updateGridQuery();
            } else {
                this.updateGridQuery();
            }
        },

        // 選択したコードがregionCdなのかmunicipalityCdなのか確かめる。
        // regionCdの場合はtrueが帰る
        isRegion: function(cd){
            return Region.store.get(cd).then(lang.hitch(this, function(region){
                return region ? true : false;
			}));
        },

        updateGridQuery: function(isEmergency, municipalityCd){
            var filter = new this.store.Filter();


            // 県ユーザでログインしていたなら県のコード（420000）をセットする
            if(UserInfo.getMunicipalityCd() === config.municInfo.prefCd) {
                filter = filter.eq('municipalityCd', UserInfo.getMunicipalityCd());
            } else {
                filter = filter.eq('municipalityCd', municipalityCd);
            }


            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);//api呼んでいる
        },

        /**
         * テンプレート変更実行
         */
        onSubmit: function() {
            if (!this.item) {
                InfoDialog.show('入力チェック', 'テンプレートが選択されていません');
                return false;
            }
            this.emit('update', {
                data: this.item
            });
        }
    });
});
