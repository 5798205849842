/**
 * <モジュールの説明>
 * @module app/view/page/<ウィジェット名>
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/_base/array',
    'dojo/string',
    'dojo/text!./templates/ChronologyFormDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'app/model/DisasterInfo',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/util/FilesUtils',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    '../config',
    'dojo/dom-style',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'dijit/form/RadioButton',
    'app/view/form/CustomizableMunicipalitySelector',
    'dijit/form/Form',
    'app/view/form/ChronologyTypeMultiSelector'
], function(module, declare, lang, Deferred, JSON, array, dStr, template, _PageBase, DisasterInfo, InfoDialog,
		DialogChain, Loader, FilesUtils, UserInfo, USER_TYPE, config, domStyle) {
    /**
     * <クラスの説明>
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {
        // テンプレート文字列
        templateString: template,
        /**
         * 災害ID
         */
        _disasterId: null,

        constructor: function(){
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
            // 災害IDを取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定させていません。');
            }
        },

        initDialog: function() {
            switch (UserInfo.getUserType()) {
                case USER_TYPE.MUNICIPALITY:
                    // 政令指定都市を除く市町村ユーザの場合は市町村コードを変更できない
                    if(UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd) {
                        this.municipalitySelectorNode.set('value', UserInfo.getMunicipalityCd());
                        this.municipalitySelectorNode.set('noAllButton', true);
                    } else {
                        // 政令指定都市ユーザの場合はデフォルト制御せず、行政区の選択を可能とする
                        this.municipalitySelectorNode.set('noAllButton', false);
                    }
                    break;
                case USER_TYPE.REGION:
                    // 振興局ユーザの場合、デフォルトだけセットする
                    this.municipalitySelectorNode.set('value', UserInfo.getRegionCd());
                    this.municipalitySelectorNode.set('noAllButton', true);
                    break;
                case USER_TYPE.PREFECTURE:
                    // 県ユーザの場合は、所属組織によって表示するボタンを変更する
                    
                    if (this.isManagementOffice()) {
                        // 危機対策課ユーザの場合
                        domStyle.set(this.regionLabelArea, 'display', '');
                        domStyle.set(this.municLabelArea, 'display', 'none');
                    } else {
                        this.municipalitySelectorNode.set('value', UserInfo.getMunicipalityCd());
                        this.municipalitySelectorNode.set('noAllButton', true);
                    }
                    break;
                default:
                    break;
            }
        },

        /**
         * 帳票を出力する
         */
        outputListExcel: function() {
            var municipalityCd = this.form.get('value').municipalityCd;
            var regionCd = '';
            var keyword = this.form.get('value').keyword;
            var chronologyType = this.form.get('value').chronologyType;
            var hldStatuses = this.form.get('value').hldStatus;
            var urgencyType = this.form.get('value').urgencyType;
            if (!municipalityCd) {
                municipalityCd = this.form.get('value').regionCd;
            }
            //var reptimestampFrom = this.form.get('value').reptimestampFrom;
            //var reptimestampTo = this.form.get('value').reptimestampTo;
            if(!this._disasterId){
                this.chain.info('災害名が正しく選択されていません。', 'エラー');
                return false;
            }
            // if(!municipalityCd){
            //     this.chain.info('市町村が選択されていません。', 'エラー');
            //     return false;
            // }
            // 振興局を選択していた場合
            if(municipalityCd.substring(0,3) === config.municInfo.prefRegCdGudge &&
                municipalityCd !== config.municInfo.prefMunicCd){
                    regionCd = municipalityCd;
                    municipalityCd = '';
            }
            //ユーザの情報を詰める
            var roleCd = UserInfo.getRoleCd();

            var reqid = 'CHRONOLOGY_LIST_EX';
            var fileName ='ChronologyList.xlsx';
            var paramList = [
                {key: 'disid', value: this._disasterId},
                {key: 'roleCd', value: roleCd},
                {key: 'userId', value: UserInfo.getId()},
                {key: 'userOrganizationCd', value: UserInfo.getLowestOrganizationCd()}
            ];
            if(municipalityCd){
                paramList.push({key: 'municipalityCd', value: municipalityCd});
            }
            if(regionCd){
                paramList.push({key: 'regionCd', value: regionCd});
            }
            // キーワード
            if(keyword){
                paramList.push({key: 'keyword', value: keyword});
            }

            // 出力情報選択
            if(chronologyType){
                var chronologyCategory = null;
                var damageInfoCategory = null;
                var reqInfoCategory = null;

                // chronologyTypeから"27--"(被害情報)と"30--"(要請)でないものを抽出してchronologyCategoryListにセット
                var chronologyCategoryList = array.filter(chronologyType, function(value){
                    return !value.startsWith('27') && !value.startsWith('30');
                });
                // chronologyTypeから"27--"(被害情報)を抽出してdamageInfoCategoryListにセット
                var damageInfoCategoryList = array.filter(chronologyType, function(value){
                    return value.startsWith('27');
                });
                // chronologyTypeから"30--"(要請)を抽出してreqInfoCategoryListにセット
                var reqInfoCategoryList = array.filter(chronologyType, function(value){
                    return value.startsWith("30");
                });

                // "27--"(被害情報),"30--"(要請)ともに全選択の場合
                if(damageInfoCategoryList.length === 14 && reqInfoCategoryList.length === 11){
                    var chronologyCategory = chronologyCategoryList.join("','");
                // "27--"(被害情報)を全選択した場合はchronologyCategoryListに"27"をセット
                } else if(damageInfoCategoryList.length === 14){
                    chronologyCategoryList.push('27');
                    // シングルクオーテーションで括られた文字列リストにするため、「','」で結合
                    var chronologyCategory = chronologyCategoryList.join("','");
                    var reqInfoCategory = reqInfoCategoryList.join("','");
                // "30--"(要請)を全選択した場合はchronologyCategoryListに"30"をセット
                } else if (reqInfoCategoryList.length === 11){
                    chronologyCategoryList.push('30');
                    // シングルクオーテーションで括られた文字列リストにするため、「','」で結合
                    var chronologyCategory = chronologyCategoryList.join("','");
                    var damageInfoCategory = damageInfoCategoryList.join("','");
                // 被害情報と要請を全選択していない場合
                } else {
                    // シングルクオーテーションで括られた文字列リストにするため、「','」で結合
                    var chronologyCategory = chronologyCategoryList.join("','");
                    var damageInfoCategory = damageInfoCategoryList.join("','");
                    var reqInfoCategory = reqInfoCategoryList.join("','");
                }

                // それぞれのListに値がある時、paramListにvalue/keyをセット
                if (chronologyCategory){
                    paramList.push({key: 'chronologyType', value: "'"+chronologyCategory+"'"});
                }
                if(damageInfoCategory){
                    paramList.push({key: 'damageInfoCategory', value: "'"+damageInfoCategory+"'"});
                }
                if(reqInfoCategory){
                paramList.push({key: 'reqInfoCategory', value: "'"+reqInfoCategory+"'"});
                }
            }

            // 対応状況
            if(hldStatuses){
                var hldStatus = hldStatuses.join("','");
                paramList.push({key: 'hldStatus', value: "'"+hldStatus+"'"});
            }
            // 緊急度
            if(urgencyType){
                paramList.push({key: 'urgencyType', value: urgencyType});
            }

            switch (UserInfo.getUserType()) {
                case USER_TYPE.MUNICIPALITY:
                    // 自市町村をセット
                    paramList.push({key: 'userMunicipalityCd', value: UserInfo.getMunicipalityCd()});
                    break;
                case USER_TYPE.REGION:
                    // 振興局ユーザの場合は県コードをセット
                    paramList.push({key: 'userMunicipalityCd', value: config.municInfo.prefMunicCd});
                    break;
                case USER_TYPE.PREFECTURE:
                    // 県ユーザの場合は県コードをセット
                    paramList.push({key: 'userMunicipalityCd', value: config.municInfo.prefMunicCd});
                    break;
                default:
                    // その他機関の場合はダミーをセットする
                    paramList.push({key: 'userMunicipalityCd', value: '999999'});
                    break;
            }

            var data4xoblosDownload = {
                fileName: fileName,
                reqid: reqid,
                paramList: paramList
            };


            var promise = this.download(data4xoblosDownload).then(lang.hitch(this, function() {
                this.infoDialog = new InfoDialog({
                    title : 'ダウンロード完了',
                    content : 'ダウンロードが完了しました。'
                });
                this.infoDialog.show();

            }), lang.hitch(this, function(error) {
                console.error(error);
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : 'エラーが発生しました。管理者にお問い合わせください。'
                });
                this.infoDialog.show();
            }));

            Loader.wait(promise);
        },

        /**
         * 月や日付を2桁にゼロpaddingする
         */
        _zeroPadding: function(month) {
            return ('00' + month).slice(-2);
        },

        // 帳票のダウンロード
        download: function(data) {
            var deferred = new Deferred();

            var xhr = new XMLHttpRequest();
            xhr.open('POST', '/api/xoblos/download', true);
            xhr.responseType = 'arraybuffer';
            xhr.setRequestHeader( 'Content-Type', 'application/json' );
            xhr.onload = function() {

                // エラー時は処理を止める
                if(xhr.status !== 200){
                    deferred.reject();
                    return;
                }

                // バイナリデータを取得
                var arrayBuffer = this.response;
                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
                var baseFileName = 'ChronologyList.xlsx';
                var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    // 擬似的にAタグを作成
                    var link = document.createElement('a');
                    link.style = 'display: none';
                    document.body.appendChild(link);

                    // AタグのURLにバイナリデータをセット
                    var url = window.URL.createObjectURL(blob);
                    link.href = url;

                    // ファイル名をセット
                    link.download = fileName;

                    // 擬似的にリンクをクリック
                    link.click();
                    // 参照を解放
                    window.URL.revokeObjectURL(url);
                    link.remove();
                }

                deferred.resolve();

            };
            xhr.send(JSON.stringify(data));

            return deferred.promise;
        },

        /**
         * Date形式のtimestampをxoBlosインターフェイス仕様のtimestamp文字列に置き換える。
         */
        _getTimestampStr: function(ts){
            if(!ts){
                return '';
            }
            var year = dStr.pad(ts.getFullYear(), 4);
            var month = dStr.pad(ts.getMonth(), 2);
            var date = dStr.pad(ts.getDate(), 2);
            var hour = (ts.getHours()) ? dStr.pad(ts.getHours(), 2) : '00';
            var min = (ts.getMinutes()) ? dStr.pad(ts.getMinutes(), 2) : '00';
            var sec = (ts.getSeconds()) ? dStr.pad(ts.getSeconds(), 2) : '00';

            return year + month + date + hour + min + sec;
        },

        /**
         * キャンセルボタン押下
         */
        onCancel:function(){
            this.getParent().hide();
        },

        /**
         * ユーザが長崎県危機管理部危機管理課ユーザかどうかを判定する
         * NOTE: 独自要件のため組織コードはハードコードする
         * @param {*} id 
         */
        isManagementOffice: function() {
            var roleCd = UserInfo.getRoleCd();
            if (roleCd === 'R01011' || roleCd === 'R01012') {
                return true;
            }
            return false;
        }
    });
});
