define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/util/DateUtils',
    'idis/view/form/Button',
    'app/common/consts/DeliveredStatusFormatMap',
    'app/config',
    'dojo/on',
    './EvacOrderColumn'
], function(module, declare, lang, IdisGrid, helper,DateUtils, Button, deliveredStatus, config, on, EvacOrderColumn) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--evacorder',

        /**
         * 各列の定義
         * @type {Object[]}
         */
		columns: [
	      helper.column('regionName', '地域', {sortable: false}),
          helper.column('municipalityName', '市町', {sortable: false}),
          {
              field: 'latestUpdTimestamp',
              label: '最終更新日時',
              formatter: lang.hitch(this, function(timestamp) {
                  if(timestamp) {
                    return DateUtils.format(timestamp);
                  } else {
                    return '-';
                  }
              }),
              sortable: false
          },
          {field: 'deliveredStatus', label: '配信状況',
            formatter: function(value){
                return deliveredStatus[value] || '';
            },
            className: function(item) {
                // ヘッダーor未配信でない場合はclassNameを返さない
                if (!item || item.deliveredStatus!=='1') {
                    return;
                }
                return 'is-undelivered';
            }
          },
          {
            label: '緊急安全確保',
            field: 'disasterOccur',
            children: [
              {field: 'disasterOccurDistNum', label: '対象地区数', formatter: EvacOrderColumn.distNumFormatter, sortable: false},
              {field: 'disasterOccurEvaqueeDistNum', label: '対象者数', sortable: false},
              {field: 'disasterOccurHouseholdDistNum', label: '対象世帯数', sortable: false}
            ],
            sortable: false
          },
          {
            label: '避難指示',
            field: 'evacOrder',
            children: [
              {field: 'evacOrderDistNum', label: '対象地区数', formatter: EvacOrderColumn.distNumFormatter, sortable: false},
              {field: 'evacOrderEvaqueeDistNum', label: '対象者数', sortable: false},
              {field: 'evacOrderHouseholdDistNum', label: '対象世帯数', sortable: false}
            ],
            sortable: false
          },
          {
            label: '高齢者等避難',
            field: 'evacPrepare',
            children: [
              {field: 'evacPrepareDistNum', label: '対象地区数', formatter: EvacOrderColumn.distNumFormatter, sortable: false},
              {field: 'evacPrepareEvaqueeDistNum', label: '対象者数', sortable: false},
              {field: 'evacPrepareHouseholdDistNum', label: '対象世帯数', sortable: false}
            ],
            sortable: false
          },
          // ボタン
          {
              label: '一覧',
              field: 'list',
              sortable: false,
              renderCell: function(item) {
                  if(item.municipalityCd !== config.municInfo.prefCd){
                      var gridNode = this.grid.domNode;
                      var button = new Button({
                          label: '一覧',
                          onClick: function() {
                              on.emit(gridNode, 'listButtonClick', {item: item});
                          }
                      });
                      return button.domNode;
                  }else{
                      //合計行には詳細ボタンをつけない
                      return null;
                  }

              }
          }
        ]
    });
});
