/**
 * 市町村システム連携 帳票データ画面取り込みダイアログ用モジュール。
 * @module app/evacorder/EvacOrderImportDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/EvacOrderImportDialog.html',
    'dojo/request/iframe',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'app/model/DisasterInfo',
    'idis/view/dialog/DialogChain',
    'idis/service/Requester',
    // 以下、変数で受けないモジュール
    'dojox/form/Uploader',
    'dijit/form/Form',
    'dijit/form/Select',
    'idis/view/form/Button',
    'app/view/form/CustomizableMunicipalitySelector',
    'app/view/page/ErrorMessageDialogForXoblos'
], function (module, declare, lang, template, iframe, InfoDialog, _PageBase,
    Loader, DisasterInfo, DialogChain, Requester) {
    /**
     * 市町村システム連携 帳票データ画面取り込みダイアログ
     * @class EvacOrderImportDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
            /** @lends module:app/evacorder/EvacOrderImportDialog~EvacOrderImportDialog# */ {
            // テンプレート文字列
            templateString: template,

            baseClass: 'idis-Page--safety-dialog',

            _fileId: null,

            infoDialog: null,

            _disasterId: null,

            REQID: 'EVAC_ORDER_IN',

            constructor: function () {
                // 連鎖ダイアログを登録
                this.chain = DialogChain.get(this);
                // 災害IDを取得
                this._disasterId = DisasterInfo.getDisasterId();
                if (!this._disasterId) {
                    this._disasterId = 1;
                    console.warn('災害IDが設定されていません。');
                }
            },

            buildRendering: function () {
                this.inherited(arguments);
                this.own(this.errorMessageDialogForXoblos);
            },

            initDialog: function(municipalityCd) {
                // 市町村コードを設定する。
                this.municipalitySelector.set('value', municipalityCd);
            },

            /**
             * ファイル読み込み実行
             */
            onSubmit: function () {
                if (!this.validate()) {
                    return false;
                }
                var reqid = this.REQID;
                var reqParams = {};
                Requester.get('/data/report/xoblos-config.json').then(lang.hitch(this, function (data) {
                    data.items.some(function (data) {
                        if (reqid === data.reqid) {
                            reqParams = data;
                            return true; //break;
                        }
                    });
                })).then(lang.hitch(this, function () {
                    // ファイルをサーバーにアップロードするAPIのURL
                    var url = '/api/xoblos/uploadReport' +
                        '?reqid=' + reqid +
                        '&disid=' + this._disasterId +
                        '&path=' + reqParams.pathweb;

                    iframe.post(url, {
                        form: this.form.id,
                        handleAs: 'json'
                    }).then(lang.hitch(this, function(ret) {
                        // アップロードしたファイルパスとエラーメッセージjson、
                        // エラー箇所を強調表示したファイルの出力先を指定してxoBlosをコールする。
                        var promise = Requester.post('/api/xoblos/upload',{
                            data: {
                                reqid: reqid,
                                disid: this._disasterId,
                                filename: ret.fileName
                            }})
                            // ローカル検証用
                            // var promise = Requester.get('/data/xoblos/evacorder.json')
                            .then(lang.hitch(this, function (data) {
                                if (data.ResultCode === 0) {
                                    return data.data;
                                }
                                // ResultCode=0以外（xoBlosエラー）の場合にはエラーダイアログを表示する。
                                var err = new Error('xoblos failed');
                                err.data = data;
                                throw err;
                            })).then(lang.hitch(this, function (data) {
                                // 成功の場合、返ってきたJSONデータを登録画面に返す
                                this.attachFile.reset();
                                this.emit('import', {
                                    data: data,
                                    municipalityCd: this.municipalitySelector.get('value')
                                });
                            }), lang.hitch(this, function (error) {
                                // 失敗の場合、引数にデータがあれば、xoBlos用のエラーダイアログを表示する。
                                if (error.data) {
                                    // エラーダイアログに結果コードを渡す
                                    var errPage = this.errorMessageDialogForXoblos.getChildren()[0];
                                    errPage.initErrorMessageDialogForXoblos(
                                        error.data.ResultCode,
                                        reqParams.errpathweb + this._disasterId + '/' + ret.fileName);
                                    this.errorMessageDialogForXoblos.show();
                                    this.attachFile.reset();
                                } else {
                                    // 引数にエラーがなければ、通常のエラーダイアログを表示する。
                                    this.chain.infoError(error, function () {
                                        this.chain.hide();
                                        // 親のダイアログを閉じる。
                                        this.getParent().hide();
                                        this.attachFile.reset();
                                    });
                                }
                            }));
                        // ローダーの表示
                        Loader.wait(promise);
                    }));
                }));
            },

            uploadError: function (ret, error) {
                // 失敗の場合、引数にデータがあれば、xoBlos用のエラーダイアログを表示する。
                if (error.data) {
                    // エラーダイアログに結果コードを渡す
                    var errPage = this.errorMessageDialogForXoblos.getChildren()[0];
                    errPage.initErrorMessageDialogForXoblos(
                        error.data.ResultCode,
                        this.FACILITY_REPORT_ERRPATHWEB + this.REQID + '/' +
                        this._disasterId + '/' + ret.fileName,
                        ret.fileName);
                    this.errorMessageDialogForXoblos.show();
                    this.attachFile.reset();
                } else {
                    // 引数にエラーがなければ、通常のエラーダイアログを表示する。
                    this.chain.infoError(function () {
                        this.chain.hide();
                        // 親のダイアログを閉じる。
                        this.getParent().hide();
                    });
                }
            },

            /**
             * 入力チェック
             */
            validate: function () {
                //ファイルが空の場合は処理を中断
                if (this.attachFile.getFileList().length === 0) {
                    InfoDialog.show('入力チェック', 'ファイルが選択されていません');
                    return false;
                }
                return true;
            },

            /**
             *報告書読み込みで呼ばれる
             **/
            loadFile: function () {
                //ファイルが空の場合は処理を中断
                if (this.attachFile._files.length === 0) {
                    return;
                }

                // エクセルファイル以外の場合はエラー
                var file = this.attachFile._files[0];
                if (file.name.indexOf('.xls') === -1 && file.name.indexOf('.xlsx') === -1 &&
                    file.name.indexOf('.xlsm') === -1) {
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: 'エクセルファイルを選択してください。'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
                    this.attachFile.reset();
                    return;
                }

                var form = new FormData();
                form.append('report', this.attachFile._files[0]);

                // ファイル名をyyyymmddhhssに設定。このファイル名はimport用APIをｊコールするときにも使う。
                var now = new Date();
                this._fileId = now.getFullYear() + this.padZero(now.getMonth() + 1) +
                    this.padZero(now.getDate()) + this.padZero(now.getHours()) +
                    this.padZero(now.getMinutes()) + this.padZero(now.getSeconds());

                // ファイルがonloadされたときにサーバーに一時保存する
                var promise = iframe.post('/api/upload/uploadFile?fileId=' +
                    this._fileId, {
                    form: this.form.id,
                    handleAs: 'json'
                });
                //ローダーの表示
                Loader.wait(promise);
            },

            // 日付をゼロパディング
            padZero: function (num) {
                var result;
                if (num < 10) {
                    result = '0' + num;
                } else {
                    result = '' + num;
                }
                return result;
            }
        });
});
