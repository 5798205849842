/**
 * お知らせ情報グリッド
 * @module app/convocation/view/group/ConvoGroupRegisterGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/control/Router',
    'idis/consts/ACL', 
    'idis/view/form/AclButton',
    'idis/view/grid/helper'
], function(module, declare, lang, IdisGrid, Router,ACL, AclButton,helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
        rowClassName: function(item) {
            return item && !item.pubFlg ? 'is-private' : '';
        },
        /**
         * 各列の定義
         * @type {Object[]}     
         */
        collection: this.store,
        columns: [
                helper.buttonColumn('edit', '編集'),
                {
                    field: 'convoTargetStaff',
                    label: '参集対象職員',
                    children: [{
                        field: 'parentName', label: '部局', sortable: false
                        }, {
                            field: 'divisionName', label: '課', sortable: false
                        }, {
                            field: 'name', label: '氏名', sortable: false
                        }]}
                ]
    });
});
