/**
 * 施設情報グリッド
 * @module app/facility/FacilityTypeGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'app/facility/FacilityHelper'
], function(module, declare, IdisGrid, helper, FacilityHelper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--facility',


        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
/*
                  helper.buttonColumn('typeDetail', '詳細'),
*/
                  FacilityHelper.buttonColumn('typeDetail', '詳細'),
                  helper.column('facilityTypeName', '施設種別')
                  ]
    });
});

