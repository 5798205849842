/**
 * 定時報告画面における定時報告依頼ダイアログ
 * @module app/view/page/<ウィジェット名>
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/text!./templates/ScheduledReportRequestDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'app/model/DisasterInfo',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/tree/CheckTree',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'dijit/form/RadioButton',
    'app/view/form/MunicipalitySelector',
    'app/view/form/CustomizableMunicipalitySelector',
    'dijit/form/Form'
], function(module, declare, lang, template, _PageBase, DisasterInfo, CacheStoreModel, CacheTreeRest,
    InfoDialog, DialogChain, CheckTree) {
    /**
     * 定時報告画面における定時報告依頼ダイアログ
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {
        // テンプレート文字列
        templateString: template,

        treeClass: CheckTree,

        constructor: function(){
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        initDialog: function() {
            this.initTree();
        },


        /**
         * ツリーを初期化する。
         * TODO ツリー初期化後にexpandAll()メソッドを呼ぶ。
         */
        initTree: function() {
            // ツリーを生成する。
            if (!this.tree) {
                this.tree = new this.treeClass({
                    model: new CacheStoreModel({
                        store: new CacheTreeRest({
                            target: '/api/scheduledReports/rqstDest4Tree'
                        })
                    })
                }, this.treeNode);

                this.tree.startup();
            }
            this.own(this.tree);
            //this.tree.onLoadDeferred.then(lang.hitch(this, '_updatePopulationStore'));
            //return this.tree.onLoadDeferred;
        },


        onSubmit: function() {
            // フォームのバリデーションを行う（共通部品）
            if(!this.form.validate()) {
                return false;
            }
            // フォームのバリデーションを行う（独自チェック）
            var errMsg = '';
            if(!this.aggrTimestamp || !this.aggrTimestamp._getValueAttr()){
                errMsg = '報告日時が選択されていません';
            }

            var checkedLeafs = this.tree.getCheckedLeafs();
            if(checkedLeafs.length === 0){
                errMsg = '依頼先が選択されていません';
            }

            if(errMsg !== '') {
                console.debug(errMsg);
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : errMsg
                });
                this.infoDialog.show();
                return false;
            }
            this.emit('send',{
                aggrTimestamp: this.aggrTimestamp._getValueAttr().getTime(),
                checkedLeafs: checkedLeafs
            });
        }
    });
});
