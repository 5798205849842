/**
 * 招集メールテンプレート選択ダイアログ用モジュール。
 * @module app/convocation/view/mail/ConvoTempInsertDialog
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/text!./templates/ConvoTempInsertDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'idis/view/form/Button',
    'app/convocation/view/mail/ConvoTempInsertGrid'
], function(module, declare, template, _PageBase) {
    /**
     * 招集メールテンプレート選択ダイアログ。
     * @class 招集メールテンプレート選択ダイアログ
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/ConvoTempInsertDialog~ConvoTempInsertDialog# */
        {
            // テンプレート文字列
            templateString: template
            
        });
});
