define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/on',
    'idis/view/grid/IdisGrid',
    // 'idis/view/grid/helper',
    'idis/view/form/Button'
], function(module, declare, lang, on, IdisGrid/*, helper*/, Button) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--scheduledreport',

        // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
        rowClassName: function(item) {
            return item && (item.reportedFlg === '0') ? 'red-row' : '';
        },

        showHeader: true,

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            {
                field: 'detail',
                label: '詳細',
                sortable: false,
                renderCell: function(item) {
                    var gridNode = this.grid.domNode;
                    var button = new Button({
                        label: (item.reportedFlg === '1') ? '詳細' : '報告',
                        onClick: function() {
                            on.emit(gridNode, 'detailButtonClick', {item: item});
                        }
                    });
                    // HTMLとしてウィジェットに紐付くDOMノードを返す
                    return button.domNode;
                }
            },
            {field: 'admNum', label: '報告番号'},
            { field: 'aggrTimestamp',
              label: '集計時点'
            },
            {field: 'reportTimestamp', label: '報告日時', className: 'updTimestamp',
                formatter: lang.hitch(this, function(reportTimestamp, item){
                    if(item.reportedFlg === '1') {
                        return reportTimestamp;
                    } else {
                        return '(未報告)';
                    }
                })
            },
            {label: '報告者所属',field: 'organizationName', sortable: false},
            {label: '報告者名',field: 'reportAuthorName'}
        ]
    });
});
