/**
 * <モジュールの説明>
 * @module app/disasterprevention/view/DisasterPreventionFormDialog
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/dom-style',
    'dojo/text!./templates/DisasterPreventionFormDialog.html', // テンプレート文字列
    'idis/consts/USER_TYPE',
    'idis/model/UserInfo',
    'idis/view/page/_PageBase', // 共通基底クラス
    'app/model/DisasterInfo',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    '../../config',
    'idis/control/Locator',
    // 以下、変数で受けないモジュール
    'app/view/form/CustomizableMunicipalitySelector',
    'app/view/form/RegionSelector',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'dijit/form/RadioButton',
    'dijit/form/Form'
], function(module, declare, lang, Deferred, JSON, domStyle, template, USER_TYPE, UserInfo, _PageBase,
        DisasterInfo, InfoDialog, DialogChain, Loader, config, Locator) {
    /**
     * <クラスの説明>
     * @class DisasterPreventionFormDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends app/disasterprevention/view/DisasterPreventionFormDialog~DisasterPreventionFormDialog */ {
        // テンプレート文字列
        templateString: template,

        /**
         * 災害ID
         */
        _disasterId: null,

        /**
         * PDFファイルPATH
         */
        PDF_PATH: '/data/manual/',

        /**
         * ダウンロードファイル名
         * code
         * 01:体制情報概況(市町)
         * 02:体制情報概況（県）
         * 03:体制情報一覧
         */
        _fileMappin: [{
                'code' : '02',
                'reqid' : 'DISASTER_PREVENTION_PREF_OVERVIEW_EX',
                'fileName' : 'DisasterPreventionPrefOverview.xlsx',
                'fileNameJp' : '体制状況概況（県・振興局）.xlsx'
            },{
                'code' : '01',
                'reqid' : 'DISASTER_PREVENTION_OVERVIEW_EX',
                'fileName' : 'DisasterPreventionOverview.xlsx',
                'fileNameJp' : '体制状況概況（市町）.xlsx'
            },{
                'code' : '03',
                'reqid' : 'DISASTER_PREVENTION_PREF_LIST_EX',
                'fileName' : 'DisasterPreventionPrefList.xlsx',
                'fileNameJp' : '体制状況一覧（県・振興局）.xlsx'
            },{
                'code' : '03',
                'reqid' : 'DISASTER_PREVENTION_LIST_EX',
                'fileName' : 'DisasterPreventionList.xlsx',
                'fileNameJp' : '体制状況一覧（市町）.xlsx'
            },{
                // NOTE:長崎県案件では対象外
                'code' : '04',
                'fileName' : '配備体制表.pdf'
            }
        ],

        constructor: function(){
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
            // 災害IDを取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }
        },

        // 出力帳票の種類を変更したときに入力項目を変更する
        /**value
            01:体制情報概況(市町)
            02:体制情報概況（県）
            03:体制情報一覧
            04:配備体制表*/
        onChangeFormType: function() {
            switch (this.formType.value) {
                case '01':
                    domStyle.set(this.areaCdArea, 'display', '');
                    domStyle.set(this.municipalityCdArea, 'display', 'none');
                    domStyle.set(this.repdatetimeArea, 'display', '');
                    break;
                case '02':
                    domStyle.set(this.areaCdArea, 'display', 'none');
                    domStyle.set(this.municipalityCdArea, 'display', 'none');
                    domStyle.set(this.repdatetimeArea, 'display', '');
                    break;
                case '03':
                    domStyle.set(this.areaCdArea, 'display', 'none');
                    domStyle.set(this.municipalityCdArea, 'display', '');
                    domStyle.set(this.repdatetimeArea, 'display', 'none');
                    break;
                case '04':
                    domStyle.set(this.areaCdArea, 'display', 'none');
                    domStyle.set(this.municipalityCdArea, 'display', 'none');
                    domStyle.set(this.repdatetimeArea, 'display', 'none');
                    break;
            }
        },

        // 帳票出力を行う前に入力チェックを行う
        onOutputButtonClick : function() {
            var formType = this.formType.value;
            if(['04'].indexOf(formType)>=0){
                // PDFダウンロード
                var failname = this._fileMappin.filter(function(item){
                    if(item.code===formType) { return true; }
                })[0].fileName;
                // pdfを別タブで開く
                window.open([this.PDF_PATH, failname].join(''), '体制情報管理帳票出力');
                return;
            }
            // 出力条件
            var repdate = this.repdatetime._date.displayedValue;
            var reptime = this.repdatetime._time.displayedValue;
            var municipalityCd = this.municipalitySelector.get('value');
            var areaCd = this.areaListSelector.get('value');
            // 入力チェック
            if(domStyle.get(this.repdatetimeArea, 'display')!=='none' &&
                (!this.repdatetime.validate() || !repdate || !reptime)) {
                console.debug('日時が選択されていません');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '日時が選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return;
            }
            this.getParent().hide();
            // ダウンロード
            this.downloadExcel(formType, repdate, reptime, municipalityCd, areaCd);
        },

        //帳票出力
        downloadExcel : function(formType, repdate, reptime, municipalityCd, areaCd) {
            //repdateをYYYYMMDD形式に変換
            repdate = repdate.replace(/\//g,'');
            //reptimeをhhss形式に変換
            reptime = reptime.replace(':', '');
            var reqid = null;
            var fileName = null;
            var paramList = [];
            // 連携ファイル名とリクエストIDを取得
            var fileInfs = this._fileMappin.filter(function(item){
                if(item.code===formType) { return true; }
            });

            switch (formType) {
                case '01':  // 体制情報概況（市町）
                    reqid = fileInfs[0].reqid;
                    fileName = fileInfs[0].fileName;
                    paramList = [
                        {key: 'regionCd', value: areaCd},
                        {key: 'repdate', value: repdate},
                        {key: 'reptime', value: reptime},
                        {key: 'disid', value: this._disasterId}
                    ];
                    break;
                case '02':   // 体制情報概況（県・振興局）
                    reqid = fileInfs[0].reqid;
                    fileName = fileInfs[0].fileName;
                    paramList = [
                        {key: 'repdate', value: repdate},
                        {key: 'reptime', value: reptime},
                        {key: 'disid', value: this._disasterId}
                    ];
                    break;
                case '03':   // 体制情報一覧
                    if(municipalityCd===config.municInfo.prefCd){
                        // prefCd -> municipalityCd
                        municipalityCd = config.municInfo.prefMunicCd;
                    }
                    if(municipalityCd.slice(0, 3)===config.municInfo.prefRegCdGudge){
                        // 県・振興局
                        reqid = fileInfs[0].reqid;
                        fileName = fileInfs[0].fileName;
                        if (municipalityCd === config.municInfo.prefMunicCd) {
                            // 県
                            paramList = [
                                {key: 'municipalityCd', value: municipalityCd},
                                {key: 'disid', value: this._disasterId}
                            ];
                        } else {
                            // 振興局
                            paramList = [
                                {key: 'regionCd', value: municipalityCd},
                                {key: 'disid', value: this._disasterId}
                            ];
                        }
                    } else {
                        // 市町
                        reqid = fileInfs[1].reqid;
                        fileName = fileInfs[1].fileName;
                        paramList = [
                            {key: 'municipalityCd', value: municipalityCd},
                            {key: 'disid', value: this._disasterId}
                        ];
                    }
                    break;
            }

            var data4xoblosDownload = {
                fileName: fileName,
                reqid: reqid,
                paramList: paramList
            };

            var promise = this.download(data4xoblosDownload).then(lang.hitch(this, function() {
                    this.infoDialog = new InfoDialog({
                        title : 'ダウンロード完了',
                        content : 'ダウンロードが完了しました。'
                    });
                    this.infoDialog.show();

                }), lang.hitch(this, function(error) {
                    console.error(error);
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : 'エラーが発生しました。管理者にお問い合わせください。'
                    });
                    this.infoDialog.show();
            }));

            Loader.wait(promise);
        },

        // 帳票のダウンロード
        download: function(data) {
            var deferred = new Deferred();

            var xhr = new XMLHttpRequest();
            xhr.open('POST', '/api/xoblos/download', true);
            xhr.responseType = 'arraybuffer';
            xhr.setRequestHeader( 'Content-Type', 'application/json' );
            var self = this;
            xhr.onload = function() {

                // エラー時は処理を止める
                if(xhr.status !== 200){
                    deferred.reject('status error:'+ xhr.status);
                    return;
                }
                // ファイル名をレスポンスヘッダーから取り出す
                var contentDisposition = this.getResponseHeader('content-disposition');
                var inputFileName = contentDisposition.replace(/^.*"(.*)"$/, '$1');

                var arrayBuffer = this.response;
                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
                // ファイル名を取得
                var baseFileName = self._fileMappin.filter(function(item){
                    if(item.fileName===inputFileName) { return true; }
                })[0].fileNameJp;
                var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    // 擬似的にAタグを作成
                    var link = document.createElement('a');
                    link.style = 'display: none';
                    document.body.appendChild(link);

                    // AタグのURLにバイナリデータをセット
                    var url = window.URL.createObjectURL(blob);
                    link.href = url;

                    // ファイル名をセット
                    link.download = fileName;

                    // 擬似的にリンクをクリック
                    link.click();
                    // 参照を解放
                    window.URL.revokeObjectURL(url);
                    link.remove();
                }

                deferred.resolve();

            };
            xhr.send(JSON.stringify(data));

            return deferred.promise;
        },

        /**
         * 押された帳票出力ボタンのIDをセットする
         */
        setSelectId : function(id) {
            this.form.set('value', {selectId:id});
        },

        /**
         * 月や日付を2桁にゼロpaddingする
         */
        _zeroPadding: function(month) {
            return ('00' + month).slice(-2);
        },

        initDialog: function(parent) {
            // 現在の画面を取得
            var page = null;
            if (Locator.getQuery().p === 'disasterprevention') {
                // 概況（市町）
                page = '01';
                this.formType.value = '01';
            } else if (Locator.getQuery().p === 'disasterprevention/pref') {
                // 概況（県）
                page = '02';
                this.formType.value = '02';
            } else if (Locator.getQuery().p === 'disasterprevention/status' ||
            Locator.getQuery().p === 'disasterprevention/status/pref') {
                // 一覧（市町・県）
                page = '03';
                this.formType.value = '03';
            }

            // 一覧から遷移した場合は自治体設定の上、選択不可
            if(page === '03'){
                this.municipalitySelector.set('noAllButton', true);
                var muniCd = parent.municipalitySelector.get('value');
                if (muniCd === config.municInfo.prefMunicCd) {
                    // 長崎県は全県コードに変更する
                    muniCd = config.municInfo.prefCd;
                }
                this.municipalitySelector.set('value', muniCd);

            }
            this.municipalitySelector.expandAll();
            // 入力項目の初期化
            this.onChangeFormType();

        }
    });
});
