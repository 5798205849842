/**
 * 施設管理画面用モジュール。
 * @module app/facility/FacilityAdminPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/dom-style',
    'dojo/text!./templates/FacilityAdminPage.html',
    'dojo/dom',
    'dojo/request/iframe',
    'dijit/registry',
    'dstore/Memory',
    'idis/control/Router',
    'idis/control/Locator',
    'idis/view/page/_PageBase',
    'idis/consts/ACL',
    'idis/view/dialog/InfoDialog',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'idis/service/Requester',
    'app/model/DisasterInfo',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    '../config',
    'app/model/Region',
    // 'idis/util/FilesUtils',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    './FacilityGrid',
    './FacilityTypeGrid',
    './FacilityTypeRegisterDialog',
    './FacilityReportDialog',
    './FacilityFileUploadDialog',
    './FacilityTypeDetailDialog',
/*
    'app/view/form/ManageRegionMunicipalitySelector',
*/
    'app/view/form/CustomizableFacilitiesSelector'
], function(module, declare, lang, Deferred, JSON, domStyle, template, dom, iframe,
        registry, Memory, Router, Locator, _PageBase, ACL, InfoDialog, IdisGrid, helper,
        IdisRest, Loader, DialogChain, Requester, DisasterInfo, UserInfo, USER_TYPE, config, Region) {
    /**
     * 検索条件格納用オブジェクト
     * @type {Object}
     */
    var filterStore = {};
    /**
    /**
     * 施設管理画面
     * @class FacilityAdminPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    	/** @lends module:app/facility/FacilityAdminPage~FacilityAdminPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--facility',

        // 施設情報用ストア
        facilityStore: null,

        // 施設種別用ストア
        facilityTypeStore: null,

        // グリッド(施設情報)
        facilityGrid: null,

        // グリッド(施設種別)
        facilityTypeGrid: null,

        /**
         * 災害ID
         */
        _disasterId: null,

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.facilityTypeRegisterDialog);
            this.own(this.facilityTypeDetailDialog);
            this.own(this.facilityReportDialog);
            this.own(this.fileUploadDialog);
            this.initRegisterPage();
            this.initDetailPage();
            this.initSelectBox();//施設種別のデータを取得した後、フィルタに条件をセットする
            this.initFileUploadDialog();
            //管理ユーザ以外は施設種別管理タブを非表示にする。
            if (!UserInfo.isSysAdmin()){
                this.tabContainer.removeChild(this.facilityTypeTab);
            }
        },

        startup: function() {
            this.inherited(arguments);
/*
            if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY){

                if(UserInfo.getMunicipalityCd() === config.municInfo.cityMunicCd){
                    this.municipalityCd.set('noAllButton', false);

                }

                this.municipalityCd.set('value', UserInfo.getMunicipalityCd());

                this.updateFacilityGridQuery(this.form.get('value'));

            } else if (UserInfo.getUserType() === USER_TYPE.REGION){

                this.regionCd.set('value', UserInfo.getRegionCd());
                this.updateFacilityGridQuery(this.form.get('value'));
            }
            this.updateFacilityGridQuery(this.form.get('value'));
*/
            this.initFacilityGrid();
            this.initFacilityTypeGrid();
        },

        constructor: function() {
            this.facilityStore = new IdisRest({
                idProperty: 'facilityId',
                target: '/api/facility'
            });
            this.facilityTypeStore = new IdisRest({
                idProperty: 'facilityTypeId',
                target: '/api/facility/category'
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }
        },

        // 施設種別を避難所に設定した時の処理
        onChangeFacilityTypeIdFlg: function(){
            var facilityTypeId = this.form.get('value').facilityTypeId;

            if(facilityTypeId === '01'){
                // 避難所区分を表示
                domStyle.set(this.shelterCategory, 'display', '');
                // 検索欄の表示を調整
                this.searchContentPane.resize({h: (facilityTypeId === '01' ? 158 : 0)});
                this.borderContainer.layout();
            } else {
                /// 検索欄の表示を調整
                this.searchContentPane.resize({h: (facilityTypeId !== '01' ? 128 : 0)});
                this.borderContainer.layout();
                // 避難所区分を非表示
                domStyle.set(this.shelterCategory, 'display', 'none');
            }
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateFacilityGridQuery: function(value) {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.facilityStore.Filter();
            if (value.searchAreaCd) {
                filter = filter.eq('searchAreaCd', value.searchAreaCd);
            }
            if (value.facilityTypeId) {
                filter = filter.eq('facilityTypeId', value.facilityTypeId);
            }

            if (value.facilityName) {
                filter = filter.eq('facilityName', value.facilityName);
            }

            if (value.address) {
                filter = filter.eq('address', value.address);
            }

            if (value.availableFlg) {
                filter = filter.eq('availableFlg', value.availableFlg);
            }

            if (value.publicFlg) {
                filter = filter.eq('publicFlg', value.publicFlg);
            }

            // 避難所区分
            var flg1 = value.welfareEvacShFlg;
            if (flg1 && flg1.length === 0) {
                filter = filter.eq('welfareEvacShFlg', '');
            }else{
                filter = filter.eq('welfareEvacShFlg', '1');
            }
            var flg2 = value.eDesignatedEvacShFlg;
            if (flg2 && flg2.length === 0) {
                filter = filter.eq('eDesignatedEvacShFlg', '');
            }else{
                filter = filter.eq('eDesignatedEvacShFlg', '1');
            }
            var flg3 = value.designatedEvacShFlg;
            if (flg3 && flg3.length === 0) {
                filter = filter.eq('designatedEvacShFlg', '');
            }else{
                filter = filter.eq('designatedEvacShFlg', '1');
            }
            var flg4 = value.temporaryEvacShFlg;
            if (flg4 && flg4.length === 0) {
                filter = filter.eq('temporaryEvacShFlg', '');
            }else{
                filter = filter.eq('temporaryEvacShFlg', '1');
            }
            var flg5 = value.civilProtectionEvacShFlg;
            if (flg5 && flg5.length === 0) {
                filter = filter.eq('civilProtectionEvacShFlg', '');
            }else{
                filter = filter.eq('civilProtectionEvacShFlg', '1');
            }
            var flg6 = value.otherEvacShFlg;
            if (flg6 && flg6.length === 0) {
                filter = filter.eq('otherEvacShFlg', '');
            }else{
                filter = filter.eq('otherEvacShFlg', '1');
            }

            // filterに対応するcollectionを取得
            var collection = this.facilityStore.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.facilityGrid.set('collection', collection);
            this.setFilterStore();
        },

        // 選択したコードがregionCdなのかmunicipalityCdなのか確かめる。
        // regionCdの場合はtrueが帰る
        isRegion: function(cd){
            return Region.store.get(cd).then(lang.hitch(this, function(region){
                return region ? true : false;
			}));
        },

        /**
         * 検索条件を保管する
         */
        setFilterStore: function() {
            //初期化する
            filterStore = {};
            var value = this.form.get('value');
            filterStore = value;
        },
/*
        setFilterStore: function() {
            //初期化する
            filterStore = {};
            var value = this.form.get('value');
            // 自治体
            if (value.searchAreaCd) {
                filterStore.searchAreaCd = value.searchAreaCd;
            }

            // 地域
            if (value.regionCd) {
                filterStore.regionCd = value.regionCd;
            }
            // 市町村
            else if (value.municipalityCd && value.municipalityCd !== config.municInfo.prefCd) {
                filterStore.municipalityCd = value.municipalityCd;
            }

            // 施設種別
            if (value.facilityTypeId) {
                filterStore.facilityTypeId = value.facilityTypeId;
            }
            // 施設名
            if (value.facilityName) {
                filterStore.facilityName = value.facilityName;
            }
            // 住所
            if (value.address) {
                filterStore.address = value.address;
            }
        },

*/
        /**
         * 保管した検索条件をフォームにセットする
         */
        setFilterData: function() {
            //保管されていれば値をセット
            this.form.set('value', filterStore);
        },
/*
        setFilterData: function() {
            //保管されていれば値をセット
            // 自治体
            if (filterStore.searchAreaCd) {
                this.searchAreaCd.set('value', filterStore.searchAreaCd);
            }

            // 地域
            if (filterStore.regionCd) {
                this.regionCd.set('value', filterStore.regionCd);
            }
            // 市町村
            // 自治体
            else if (filterStore.municipalityCd) {
                this.municipalityCd.set('value', filterStore.municipalityCd);
            }
            // 施設種別
            if(filterStore.facilityTypeId) {
                this.facilityTypeSelectBox.set('value', filterStore.facilityTypeId);
            }
            // 施設名
            if(filterStore.facilityName) {
                this.facilityName.set('value', filterStore.facilityName);
            }
            // 住所
            if(filterStore.address) {
                this.address.set('value', filterStore.address);
            }
        },
*/

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
/*
        updateFacilityTypeGridQuery: function() {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.facilityTypeStore.Filter();

            // filterに対応するcollectionを取得
            var collection = this.facilityTypeStore.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.facilityTypeGrid.set('collection', collection);
        },
*/

        /**
         * 検索ボタンが押されたときに呼び出される。
         * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
         * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
         */
        onSubmit: function() {
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateFacilityGridQuery(this.form.get('value'));
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * ファイルアプロードダイアログ初期化処理
         */
        initFileUploadDialog: function() {
            // 詳細ダイアログの最初の子要素が詳細画面
            var dialog = this.fileUploadDialog;
            var page = dialog.getChildren()[0];
            // 更新ボタンが押された際の動作
            page.on('upload', lang.hitch(this, function(evt) {
                console.log(evt.attachFile._files);
                //ファイルが空の場合は処理を中断
                if(evt.attachFile._files.length === 0) {
                    return;
                }
                // 選択されたファイルタイプに応じて呼び出すAPIを変更
                var type = evt.fileType;
                var reqid = null;
                // 拡張子をファイルによって変更
                var ex = null;
                // 政令指定市の避難勧告等発令状況一覧
                if (type === '01') {
                    reqid = 'FACILITY_LIST_IN';
                    ex = '.xlsx';
                }
                // リクエスト先のxoblosAPIのパス
                var url = '/bousai/Upload/Upload?reqid=' + reqid + '&disid=' + this._disasterId;
                // xoblosから見たときのファイルパス
                var path = '\\192.168.11.31\\in\\upload\\' + evt.FileId + '\\' + evt.FileId + ex;
                // ファイルをサーバーにPOSTする
                // TODO:pathを正しく設定
                var promise = iframe.post(url, {
                    path: path,
                    errpath: path,
                    errmsgpath: path,
                    handleAs: 'json',
                    mimeType: 'application/json'
                }).then(lang.hitch(this, function() {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                    // ファイルアップローダー部品をリセットする
                    evt.attachFile.reset();
                    // ダイアログを非表示
                    dialog.hide();
                }), lang.hitch(this, function(error) {
                    console.log(error);
                    evt.attachFile.reset();
                    this.chain.infoError(error);
                }));
                // ローダーの表示
                Loader.wait(promise);
            }));
        },

        showFileUploadDialog: function() {
            this.fileUploadDialog.show();
        },

        initFacilityGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.facilityGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.onDetailButtonClick(evt.item);
            }));
/*
            // 新しくグリッド用フィルターを作成
            var filter = new this.facilityStore.Filter();
            //初期表示のみ、filterStoreからfilterへデータを直接セットする必要がある。(同期処理を防げないため)
            if(filterStore.searchAreaCd){
                filter = filter.eq('searchAreaCd', filterStore.searchAreaCd);
            }
            if(filterStore.regionCd){
                filter = filter.eq('regionCd', filterStore.regionCd);
            }
            else if(filterStore.municipalityCd){
                filter = filter.eq('municipalityCd', filterStore.municipalityCd);
            }
            if(filterStore.facilityTypeId){
                filter = filter.eq('facilityTypeId', filterStore.facilityTypeId);
            }
            if(filterStore.facilityName){
                filter = filter.eq('facilityName', filterStore.facilityName);
            }
            if(filterStore.address){
                filter = filter.eq('address', filterStore.address);
            }
            // filterに対応するcollectionを取得
            var collection = this.facilityStore.filter(filter);
            // collectionをグリッドにセットする(サーバーにリクエストされる)
            this.facilityGrid.set('collection', collection);
*/
        },


        initFacilityTypeGrid: function() {
            // 全件表示用のcollectionをグリッドにセットする
            // （テンプレートHTML内のdata-dojo-attach-point="grid"でグリッドをthis.gridに紐付け済み）
            this.facilityTypeGrid.set('collection', this.facilityTypeStore.filter());
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.facilityTypeGrid.on('typeDetailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.showFacilityTypeDetailDialog(evt.item);
            }));
        },

        /**
         * 登録画面のフォームが投稿された際の動作を設定する。
         */
        initRegisterPage: function() {
            // 登録ダイアログの最初の子要素が登録画面
            var dialog = this.facilityTypeRegisterDialog;
            var page = dialog.getChildren()[0];
            // 登録画面のsendイベントを受け取る
            page.on('sendregister', lang.hitch(this, function(evt) {
                this.chain.confirmAdd(lang.hitch(this, function(chain) {

                    var promise = this.facilityTypeStore.add(evt.value);
                    Loader.wait(promise).then(lang.hitch(this,function() {
                        chain.infoComplete();
                        dialog.hide();
                        this.initSelectBox();
                        this.initFacilityTypeGrid();
                    }),  function(err) {
                    chain.infoError(err);
                });
                }));

            }));
        },

        /**
         * 詳細画面のフォームが投稿された際の動作を設定する。
         */
        initDetailPage: function() {
            // 詳細ダイアログの最初の子要素が詳細画面
            var dialog = this.facilityTypeDetailDialog;
            var page = dialog.getChildren()[0];
            // 詳細画面のsendイベントを受け取る
            page.on('sendupdate', lang.hitch(this, function(evt) {
                this.chain.confirmPut(function(chain) {
                    var promise = this.facilityTypeStore.put(evt.value);
                    Loader.wait(promise).then(lang.hitch(this,function() {
                        chain.infoComplete();
                        dialog.hide();
                        this.initSelectBox();
                        this.initFacilityTypeGrid();
                    }), function(err) {
                        chain.infoError(err);
                    });
                });
            }));
            page.on('senddelete', lang.hitch(this, function(evt) {
                this.chain.confirmDel(function(chain) {
                    var promise = this.facilityTypeStore.remove(evt.value.facilityTypeId);
                    Loader.wait(promise).then(lang.hitch(this,function() {
                        dialog.hide();
                        chain.infoComplete();
                        this.initSelectBox();
                        this.initFacilityTypeGrid();
                    }), function(err) {
                        chain.infoError(err);
                    });
                });
            }));
        },

        // 施設種別セレクトボックスの初期化を行う
        initSelectBox: function(){
            var promise =
                Requester.get('/api/facility/category');
            Loader.wait(promise).then(lang.hitch(this, function(data) {
                // セレクトボックスに設定するデータの配列を作成
                var newOptions = [{
                    label: '&nbsp;',
                    value: ''
                }];
                // サーバーレスポンスの各要素をselectへ追加
                data.items.forEach(function(object){
                    newOptions.push({
                    label: object.facilityTypeName,
                    value: object.facilityTypeId
                    });
                });
                this.facilityTypeSelectBox.set('options',newOptions);
                //フィルタのデータをセットする。(データの取得と非同期処理が必要)
                this.setFilterData();
                this.updateFacilityGridQuery(this.form.get('value'));
            }));
        },

        onRegisterButtonClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            this.setFilterStore();
            // 施設登録画面へ遷移
            Router.moveTo('facility/register', {
                type : ''
            });
        },

        onDetailButtonClick: function(object) {
            this.setFilterStore();
            // 施設情報詳細画面へ遷移
            Router.moveTo('facility/detail', {
                facilityId: object.facilityId
            });
        },

        showFacilityTypeRegisterDialog: function() {
            this.innerResisterDialog.setPage();
            this.facilityTypeRegisterDialog.show();
        },

        showFacilityTypeDetailDialog: function(object) {
            //ダイアログの最初の子要素が詳細画面
            var dialog = this.facilityTypeDetailDialog;
            var page = dialog.getChildren()[0];
            //画面上のフォームをリセット
            page.form.reset();
            //行データの内容をフォームに反映
            page.form.set('value',object);
            //詳細ダイアログを表示
            this.innerDetailDialog.setPage(object);
            this.facilityTypeDetailDialog.show();
        },

        filterByType: function(value) {
            alert(value);
        },

		//帳票出力
        outputListExcel : function() {
            this.innerfacilityReportDialog.initDialog();
            this.facilityReportDialog.show();

/*
            var reqid = 'FACILITY_LIST_EX';
            var fileName = 'FacilityList.xlsx';
            var paramList = [];

            var data4xoblosDownload = {
                fileName: fileName,
                reqid: reqid,
                paramList: paramList
            };

            var promise = this.download(data4xoblosDownload).then(lang.hitch(this, function() {
                this.infoDialog = new InfoDialog({
                    title : 'ダウンロード完了',
                    content : 'ダウンロードが完了しました。'
                });
                this.infoDialog.show();

            }), lang.hitch(this, function(error) {
                console.error(error);
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : 'エラーが発生しました。管理者にお問い合わせください。'
                });
                this.infoDialog.show();
            }));

            Loader.wait(promise);
*/
		},

        // 帳票のダウンロード
        download: function(data) {
            var deferred = new Deferred();
            var xhr = new XMLHttpRequest();
            xhr.open('POST', '/api/xoblos/download', true);
            xhr.responseType = 'arraybuffer';
            xhr.setRequestHeader( 'Content-Type', 'application/json' );
            xhr.onload = function() {

                // エラー時は処理を止める
                if(xhr.status !== 200){
                    deferred.reject('status error:'+ xhr.status);
                    return;
                }
                // ファイル名をレスポンスヘッダーから取り出す
                var contentDisposition = this.getResponseHeader('content-disposition');
                var inputFileName = contentDisposition.replace(/^.*"(.*)"$/, '$1');

                var arrayBuffer = this.response;
                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
                var baseFileName = '';
                switch (inputFileName) {
                    case 'FacilityList.xlsx':
                        baseFileName = '施設情報一覧.xlsx';
                        break;
                }
                var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    // 擬似的にAタグを作成
                    var link = document.createElement('a');
                    link.style = 'display: none';
                    document.body.appendChild(link);

                    // AタグのURLにバイナリデータをセット
                    var url = window.URL.createObjectURL(blob);
                    link.href = url;

                    // ファイル名をセット
                    link.download = fileName;

                    // 擬似的にリンクをクリック
                    link.click();
                    // 参照を解放
                    window.URL.revokeObjectURL(url);
                    link.remove();
                }

                deferred.resolve();

            };
            xhr.send(JSON.stringify(data));

            return deferred.promise;
        },

        /**
         * 月や日付を2桁にゼロpaddingする
         */
        _zeroPadding: function(month) {
            return ('00' + month).slice(-2);
        }
    });
});
