/**
 * 職員招集メール一覧グリッド
 * @module app/safety/mail/SafetyMailListGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom',
    'dijit/registry',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'dgrid/Selector',
    'dijit/form/CheckBox'
], function(module, declare, lang, locale, dom, registry, IdisGrid, helper, Selector) {
    return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selector], {

        /**
         * 各列の定義
         * @type {Object[]}
         */

        collection: this.store,

        columns: [
            {
                field: 'checkbox',
                label: '再送',
                selector: 'checkbox'
            },
            helper.column('groupName', 'グループ', {sortable: false}),
            helper.column('distributionHistDetailId', '防災配信履歴詳細ID', {sortable: false}),
            helper.column('employeeCd', '職員番号', {sortable: false}),
            helper.column('name', '氏名', {sortable: false}),
            helper.column('phoneNum', '電話番号', {sortable: false}),
            helper.column('distEmailAddress', 'メールアドレス', {sortable: false}),
            {
                field: 'replyTimestamp',
                label: '回答日時',
                className: 'replyTimestamp'
            },
            helper.column('distA01', '回答1'),
            helper.column('distA02', '回答2'),
            helper.column('distA03', '回答3'),
            helper.column('distA04', '回答4'),
            helper.column('distA05', '回答5'),
            helper.column('note', '備考', {sortable: false}),
            helper.buttonColumn('deputyAnswer', '代理回答')
        ]
    });
});
