/**
 * 避難状況画面の地区選択ツリー用モデル
 * @module app/evacorder/EvacOrderStoreModel
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojox/lang/functional/object',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
    // './Reason'
], function(module, array, declare, df, CacheStoreModel, CacheTreeRest /*, Reason*/) {
    /**
     * 各避難区分のツリー表示
     * @type {Object}
     * @private
     */

    // 熊本では、地区ツリーのラベルに現況情報を表記しないため、コメントアウトする。
    // var _orderTypeNameMap = {
    //     '01': '避難準備',
    //     '02': '避難勧告',
    //     '03': '避難指示',
    //     '04': '災害発生情報'
    // };

    /**
     * 避難状況の地区選択用ツリー
     * @param {Object} kwArgs
     * @param {string} kwArgs.disasterId 災害ID
     * @param {string} kwArgs.municipalityCd 市町村コード
     * @class EvacOrderStoreModel
     * @extends module:idis/store/CacheStoreModel~CacheStoreModel
     */
    return declare(CacheStoreModel, /** @lends module:app/evacorder/EvacOrderStoreModel~EvacOrderStoreModel# */ {
        constructor: function(kwArgs) {
            // 地区一覧用storeを生成
            this.store = new CacheTreeRest({
                target: '/api/evacorders/latestSituation',
                targetQuery: {
                    disasterId: kwArgs.disasterId,
                    municipalityCd: kwArgs.municipalityCd
                }
            });
        }

        // 熊本では、地区ツリーのラベルに現況情報を表記しない。
        // ツリーのラベル表示を返す
        // getLabel: function(item) {
        //
        //     // 避難区分ごとに理由一覧をまとめる
        //     var orderTypeReasonListMap = {};
        //     array.forEach(item.evacOrderDistrictReasonList, function(reason) {
        //         var type = reason.evacOrderType;
        //         // 偽値と解除系は除外
        //         if (!type || /0[5-8]/.test(type) || type === '99') {
        //             return;
        //         }
        //         if (!orderTypeReasonListMap[type]) {
        //             orderTypeReasonListMap[type] = [];
        //         }
        //         orderTypeReasonListMap[type].push(reason.issueReasonType);
        //     });
        //     // 出現した避難区分一覧
        //     var typeCodeList = df.keys(orderTypeReasonListMap);
        //     // 何も無ければ地区名のみ返す
        //     if (!typeCodeList.length) {
        //         return item.districtName;
        //     }
        //     // 避難区分がある場合は避難区分コード順に文字列化して繋げる
        //     typeCodeList.sort();
        //     return item.districtName + ' : ' + array.map(typeCodeList, function(typeCode) {
        //         // 避難理由一覧を理由コード順に文字列化
        //         var reasonList = orderTypeReasonListMap[typeCode];
        //         reasonList.sort();
        //         // その他(00)は辞書順で先頭に来てしまうため、含まれる場合は末尾に移動
        //         if (reasonList.length && reasonList[0] === '00') {
        //             reasonList.push(reasonList.shift());
        //         }
        //         var reasonLabel = array.map(reasonList, function(reasonType) {
        //             return Reason.get(reasonType).name;
        //         }).join('、');
        //         return _orderTypeNameMap[typeCode] + '(' + reasonLabel + ')';
        //     }).join(' / ');
        // }
    });
});
