/**
 * 招集グループ画面向け職員情報編集ダイアログ用モジュール。
 * @module app/convocation/view/group/ConvoEditEmployeeDialog
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/promise/all',
    'dojo/text!./templates/ConvoEditEmployeeDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/view/dialog/DialogChain',
    'idis/service/Requester',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'app/view/form/EmployeeSelector'
], function(module, declare, lang, Deferred, all, template, _PageBase, DialogChain, Requester) {
    /**
     * 招集グループ画面向け職員情報編集ダイアログ。
     * @class ConvoEditEmployeeDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/ConvoEditEmployeeDialog~ConvoEditEmployeeDialog# */
        {
            // テンプレート文字列
            templateString: template,
            
            store: null,
            
            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',
            
            /**
             * 変数の初期化を行う。
             */
            constructor: function() {
                this.chain = DialogChain.get(this);
            },
            
            /**
             * 更新ボタンを押下した際の挙動。
             */
            putButtonClick: function() {
                try {
                    this._setEmployeeInfo().then(lang.hitch(this, function(value) {
                    // 実際の登録処理はPage側でregisterイベントを監視しているメソッドが実施
                    this.emit('update', {value: value});
                    }));
                } catch (e) {
                    console.error(e);
                }
            },
            
            /**
             * @return val 招集対象職員情報
             * 画面へ渡す値を作成する。
             */
            _setEmployeeInfo: function() {
                var deferred = new Deferred();
                var dpEmployeeCd = this.employeeCd.value;
                if (!dpEmployeeCd) {
                    deferred.resolve('');
                } else {
                    // コードによる職員情報の取得
                    Requester.get('/api/convoMails/employees/' + dpEmployeeCd)
                    .then(function(data) {
                            deferred.resolve({
                                parentName      : data.parentName,
                                divisionName    : data.divisionName,
                                employeeCd      : data.employeeCd,
                                name            : data.name
                            });
                        }
                    );
                }
                return deferred.promise;
            },
            
            /**
             * 削除ボタンを押下した際の挙動。
             */
            deleteButtonClick: function() {
                try {
                    var employeeCd = this.employeeCd.value;
                    // 実際の登録処理はPage側でdeleteイベントを監視しているメソッドが実施
                    this.emit('delete', {employeeCd: employeeCd});
                } catch (e) {
                    console.error(e);
                }
            }
        });
});
