/**
 * 職員所属課選択用入力パーツ。
 * @module app/convocation/view/employee/EmployeeDivisionSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'app/view/form/EmployeeDivisionSelector'
], function(module, declare,  EmployeeDivisionSelector) {
    /**
     * 職員所属課選択用入力パーツ。
     * @class EmployeeDivisionSelector
     * @extends module:idis/view/form/EmployeeDivisionSelector~EmployeeDivisionSelector
     */
    return declare(module.id.replace(/\//g, '.'), EmployeeDivisionSelector,
        /** @lends module:app/view/tree/EmployeeDivisionSelector~EmployeeDivisionSelector# */ {
        /**
         * ツリー選択されている要素をこのウィジェットのvalue値として設定する。
         * 要素が選択されていない場合は何もしない。
         */
        applySelectedItem: function() {
            var item = this.tree.selectedItem;
            if (item && item.name !== '長崎県') {
                this.set('value', this.tree.model.store.getIdentity(item));
            }
        }
    });
});
