/**
* 消防庁被害報告一覧画面用モジュール。
* @module app/damage/FDMAReportPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/FDMAReportPage.html',
    './_DamageReportPageBase',
    'idis/control/Router',
    'idis/store/IdisRest',
    'idis/view/dialog/DialogChain',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    './FDMAReportGrid'
], function(module, declare, lang, domStyle, template,
    _DamageReportPageBase, Router, IdisRest, DialogChain
) {
    /**
    * 消防庁被害報告一覧画面。
    * @class FDMADamageReportPage
    * @extends module:idis/view/page/_PageBase~_PageBase
    */
    return declare(module.id.replace(/\//g, '.'), _DamageReportPageBase,
        /** @lends module:app/damage/DamageReportPage~DamageReportPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--fdmareport',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        /**
         * 未登録フラグ
         * （フラグが立つ場合、消防庁被害報告ボタン表示）
         */
        _noRecordFlg: true,

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
        },

        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                target: '/api/fdmaReports',
            });
            this.chain = DialogChain.get(this);
        },

        postCreate: function() {
            this.own(this.showCorrectCancelCheck.on('change', lang.hitch(this, function() {
                this.updateGrid();
            })));
        },

        startup: function() {
            this.inherited(arguments);
            this.initGrid();
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.FDMAReportGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.onDetailButtonClick(evt.item);
            }));
            // グリッドを最新化
            this.updateGrid();
        },

        /**
         * グリッドの最新化
         */
        updateGrid: function() {
            console.log(this.form);
            // var value = this.form.get('value');
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();

            // 災害ID
            filter = filter.eq('disasterId', this._disasterId);
            
            //if (value.showCorrectCancelCheck.length !== 0) {
            if (this.showCorrectCancelCheck.checked) {
                filter = filter.eq('statusFlg', '');
            } else {
                filter = filter.eq('statusFlg', '0');
            }
            
            //} else {
                //filter = filter.eq('statusFlg', '0');
            //}

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.FDMAReportGrid.set('collection', collection);
            domStyle.set(this.FDMAReportGrid.domNode, 'display','block');
            this.borderContainer.resize();

            collection.fetch().then(lang.hitch(this, function(data) {
                var activeRecord = [];
                data.forEach(function(element){
                    if (element.statusFlg === "0") {
                        activeRecord.push(element);
                    }
                });
                if(activeRecord.length !== 0) {
                    // データがあれば報告ボタンを非表示にする
                    this._noRecordFlg = false;
                } else {
                    // データがなければ報告ボタンを表示にする
                    this._noRecordFlg = true;
                }
                this.changeButton();
            }));
        },

        /**
         * 消防庁被害報告ボタンの表示/非表示を切り替える
         */
        changeButton: function() {
            // 続報報告ボタン、更新ボタンの表示
            if(this._noRecordFlg){
                domStyle.set(this.fdmaReportButton, 'display','');
            } else {
                domStyle.set(this.fdmaReportButton, 'display','none');
            }
        },

        /**
         * 消防庁被害報告登録画面を表示
         */
        showFDMAReportRegisterPage: function() {
            //新規報告画面に移動
            Router.moveTo('fdmaReport/register');
        },

        /**
         * 詳細画面を表示
         */
        onDetailButtonClick: function(object) {
            // 被害状況・報告詳細画面へ遷移
            Router.moveTo('fdmaReport/detail',{
                fdmaReportId: object.fdmaReportId
            });
        },
    });
});
