/**
 * 災害情報詳細/更新画面用モジュール。
 * @module app/view/page/DisasterAdminRegisterPage
 */
define([
    'module',
    'dojo/json',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom',
    'dojo/dom-style',
    'dojo/dom-class',
    'dojo/_base/array',
    'dojo/text!./templates/DisasterAdminRegisterPage.html',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    // 以下、変数から参照されないモジュール
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/form/Textarea',
    'dijit/form/CheckBox',
    'dijit/form/DateTextBox',
    'dijit/form/Select',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/CheckGroup',
    'idis/view/form/RadioGroup',
    'idis/view/form/DateTimeInput'
], function (module, json, declare, lang, dom, domStyle, domClass, array, template, InfoDialog, _PageBase) {
    /**
     * 災害情報登録画面。
     * @class DisasterAdminRegisterPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/view/page/DisasterAdmintPage~DisasterAdminPage# */ {
            // テンプレート文字列
            templateString: template,

            // テンプレートHTML内内でformに対し、data-dojo-attach-event="onSubmit:onSubmit"でこのメソッドを紐付け
            // 登録ボタンをtype="submit"にしているので、ボタン押下時にフォームがsubmitされて呼ばれる
            onSubmit: function () {
                try {
                    // data-dojo-propsでの入力チェックと、画面独自の入力チェック
                    if (this.form.validate() && this._validateCheck()) {
                        // 入力チェックが成功したらsendイベントを発行
                        // 実際の登録処理はサンプル画面側でsendイベントを監視しているメソッドが実施
                        this.emit('send', {
                            // テンプレートHTML内でformに対し
                            // data-dojo-attach-point="form"を指定してthis.formとしてアクセス出来るようにしている
                            value: this.form.get('value')
                        });
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },
            // タイムライン登録の値が変更された時の処理
            // onChangeTimelineRegistFlg: function (evt) {
            //     // 基準時刻の表示を、タイムライン登録の有無によって表示・非表示を制御
            //     if(evt.target.value) {
            //         domStyle.set(this.baseTimestampDiv, 'display', evt.target.value === '1' ? '' : 'none');
            //         domStyle.set(this.registIdDiv, 'display', evt.target.value === '1' ? '' : 'none');
            //     }
            // },

            _validateCheck: function() {
                var infoDialogMsg = null;

                // 基準時刻、タイムライン入力チェック            
                // if(domStyle.get(this.baseTimestampDiv, 'display')!=='none'&&
                // (!this.baseTimestamp.validate() || !this.baseTimestamp._date.displayedValue ||
                // !this.baseTimestamp._time.displayedValue)) {
                //     infoDialogMsg = '「基準時刻」';
                // }
                // console.log(this.registId.value);
                // if(domStyle.get(this.registIdDiv, 'display')!=='none'&&
                // (!this.registId.validate() || this.registId.value === '' || this.registId.value === null)) {
                //     infoDialogMsg = infoDialogMsg === null ? '「タイムライン」' :
                //         infoDialogMsg + '及び「タイムライン」';
                // }
                console.log(infoDialogMsg);
                // エラーメッセージ表示
                if(infoDialogMsg){
                    infoDialogMsg += 'が入力されていません。';
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : infoDialogMsg
                    });
                    this.infoDialog.show();
                    return false;
                }
                return true;
            }
        });
});
