/**
 * <モジュールの説明>
 * @module app/view/page/<ウィジェット名>
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/dom-style',
    'dojo/json',
    'dojo/text!./templates/ShelterFormDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'app/model/DisasterInfo',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'app/model/Region',
    'app/config',
    'idis/util/FilesUtils',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'dijit/form/RadioButton',
    'app/view/form/CustomizableMunicipalitySelector',
    'dijit/form/Form'
], function(module, declare, lang, Deferred, domStyle, JSON, template, _PageBase,
    DisasterInfo, UserInfo, USER_TYPE, InfoDialog, DialogChain, Loader, Region, config/*, FilesUtils*/) {
    /**
     * <クラスの説明>
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {
        // テンプレート文字列
        templateString: template,
        /**
         * 災害ID
         */
        _disasterId: null,

        constructor: function(){
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
            // 災害IDを取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }
        },

        startup:function(){
            this.inherited(arguments);
            this.onSelectFlgChange();
            this.initDialog();
        },

        initDialog: function() {
            // 振興局セレクタの「クリア」ボタンを右寄せにする
            domStyle.set(this.condRegionCd.clearButton.domNode, 'float', 'right');

            // 振興局セレクタの「-」ボタンを非表示にする
            domStyle.set(this.condRegionCd.collapseAllButton.domNode, 'visibility', 'hidden');

//            if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
//                UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd) {
            if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
                (config.municInfo.cityDistrictMunicCds.indexOf(UserInfo.getMunicipalityCd()) === -1)) {
                // 市町ユーザの場合、自分の市町を設定
                this.municipalityCd.set('value', UserInfo.getMunicipalityCd());
                this.municipalityCd.set('noAllButton', true);
            // } else if(config.municInfo.cityDistrictMunicCds.indexOf(UserInfo.getMunicipalityCd()) !== -1) {
            //     // 行政区ユーザの場合、政令指定都市を設定
            //     this.municipalityCd.set('value', config.municInfo.cityMunicCd);
            }
        },

        // 出力帳票の種類を変更したときに「市町」と最新報表示の入力可否を変更する
        // selectFlg 0:避難所・避難場所状況概況 1:避難所状況一覧
        onSelectFlgChange: function() {
            if(this.form.get('value').selectFlg === '0'){
                domStyle.set(this.municipalityCdArea, 'display', 'none');
                domStyle.set(this.regionCdArea, 'display', '');
                domStyle.set(this.activeFlgArea, 'display', 'none');
            }else{
                domStyle.set(this.municipalityCdArea, 'display', '');
                domStyle.set(this.regionCdArea, 'display', 'none');
                domStyle.set(this.activeFlgArea, 'display', '');
            }
        },

        // 帳票出力を行う前に入力チェックを行う
        onOutputButtonClick : function() {
            // 選択された帳票種別によって分岐
            var disasterId = this._disasterId;
            var selectFlg = this.form.get('value').selectFlg;
            var repdate = this.repdatetime._date.displayedValue;
            var reptime = this.repdatetime._time.displayedValue;
            var activeflg = this.form.get('value').activeFlg;
            var regionOverView = this.form.get('value').condRegionCd;
            var municipalityCd = null;
            var regionCd = null;
//            var municipalityCd = this.form.get('value').municipalityCd;
//            var regionCd = this.form.get('value').condRegionCd;
            this.isRegion(this.municipalityCd.value).then(lang.hitch(this, function(isRegion){
                if(isRegion){
                    regionCd = this.municipalityCd.value;
                } else if(this.municipalityCd.value && 
                        this.municipalityCd.value !== config.municInfo.prefCd){
                    municipalityCd = this.municipalityCd.value;
                }
            }));
            //日時の入力チェック
            if(!this.repdatetime.validate() || !repdate || !reptime) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '日時が選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
            }//1:避難所状況一覧を選択している場合表示形式は必須項目
            else if (selectFlg === '1' && !activeflg) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '表示形式が選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
            }
            //北海道から、概況選択時振興局の未選択時は北海道全域で取得するように修正
            //0:避難所開設状況概況を選択している場合振興局は必須項目
//            else if (selectFlg === '0' && !regionCd){
//                if (!this.infoDialog) {
//
//                    this.infoDialog = new InfoDialog({
//                        title : 'エラー',
//                        content : '振興局が選択されていません'
//                    });
//                }
//                this.infoDialog.show();
//                this.infoDialog = null;
//            }
            //1:避難所状況一覧を選択している場合市町村は必須項目
            else if (selectFlg === '1' && !this.form.get('value').municipalityCd){
                if (!this.infoDialog) {

                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '市町村が選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
            } //1:避難所状況一覧を選択しており、市町村に振興局を選択している場合エラー
//            else if (selectFlg === '1' && municipalityCd.substr(2,1) === '0'){
//                if (!this.infoDialog) {
//
//                    this.infoDialog = new InfoDialog({
//                        title : 'エラー',
//                        content : '市町村を選択してください'
//                    });
//                }
//                this.infoDialog.show();
//                this.infoDialog = null;
//            }
            //2:避難所状況一覧(内閣府様式)を選択している場合市町村は必須項目
            else if (selectFlg === '2' && !this.form.get('value').municipalityCd){
                if (!this.infoDialog) {

                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '市町村が選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
            }
            else {
                this.downloadExcel(disasterId, selectFlg, repdate, reptime, activeflg,
                        regionOverView, municipalityCd, regionCd);
            }
        },

        //帳票出力
        downloadExcel : function(disasterId, selectFlg, repdate, reptime, activeflg,
                regionOverView, municipalityCd, regionCd) {
            //repdateをYYYYMMDD形式に変換
            repdate = repdate.replace(/\//g,'');
            //reptimeをhhss形式に変換
            reptime = reptime.replace(':', '');
            var reqid = null;
            var fileName = null;
            var paramList = [];
            // 概況帳票出力
            if (selectFlg === '0') {
                reqid = 'SHELTER_OVERVIEW_EX';
                fileName = 'ShelterOverView.xlsx';
                if (!regionOverView || regionOverView.length === 0) {
                    regionOverView = null;
                }
                paramList = [
                    {key: 'disid', value: disasterId},
                    {key: 'repdate', value: repdate},
                    {key: 'reptime', value: reptime},
                    {key: 'regionCd', value: regionOverView}
                ];
            // 一覧帳票出力
            }else if (selectFlg === '1') {
                reqid = 'SHELTER_LIST_EX';
                fileName = 'ShelterList.xlsx';
                paramList = [
                    {key: 'disid', value: disasterId},
                    {key: 'repdate', value: repdate},
                    {key: 'reptime', value: reptime},
                    {key: 'activeflg', value: activeflg},
                    {key: 'municipalityCd', value: municipalityCd},
                    {key: 'regionCd', value: regionCd}
                ];
             // 一覧帳票出力(内閣府様式)
            }else if (selectFlg === '2') {
	            reqid = 'SHELTER_LIST_CAO_STYLE_EX';
	            fileName = 'ShelterListCaoStyle.xlsx';
	            paramList = [
	                {key: 'disid', value: disasterId},
	                {key: 'repdate', value: repdate},
	                {key: 'reptime', value: reptime},
	                {key: 'activeflg', value: activeflg},
	                {key: 'municipalityCd', value: municipalityCd},
	                {key: 'regionCd', value: regionCd}
	            ];
	        }

            var data4xoblosDownload = {
                fileName: fileName,
                reqid: reqid,
                paramList: paramList
            };

            var promise = this.download(data4xoblosDownload).then(lang.hitch(this, function() {
                this.infoDialog = new InfoDialog({
                    title : 'ダウンロード完了',
                    content : 'ダウンロードが完了しました。'
                });
                this.infoDialog.show();

            }), lang.hitch(this, function(error) {
                console.error(error);
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : 'エラーが発生しました。管理者にお問い合わせください。'
                });
                this.infoDialog.show();
            }));

            Loader.wait(promise);
        },

        // 帳票のダウンロード
        download: function(data) {
            var deferred = new Deferred();

            var xhr = new XMLHttpRequest();
            xhr.open('POST', '/api/xoblos/download', true);
            xhr.responseType = 'arraybuffer';
            xhr.setRequestHeader( 'Content-Type', 'application/json' );
            xhr.onload = function() {

                // エラー時は処理を止める
                if(xhr.status !== 200){
                    deferred.reject('status error:'+ xhr.status);
                    return;
                }
                // ファイル名をレスポンスヘッダーから取り出す
                var contentDisposition = this.getResponseHeader('content-disposition');
                var inputFileName = contentDisposition.replace(/^.*"(.*)"$/, '$1');

                var arrayBuffer = this.response;
                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
                var baseFileName = '';
                switch (inputFileName) {
                    case 'ShelterOverView.xlsx':
                        baseFileName = '避難所開設状況概況.xlsx';
                        break;
                    case 'ShelterList.xlsx':
                        baseFileName = '避難所開設状況一覧.xlsx';
                        break;
                    case 'ShelterListCaoStyle.xlsx':
                        baseFileName = '避難所開設状況一覧(内閣府様式).xlsx';
                        break;
                }
                var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    // 擬似的にAタグを作成
                    var link = document.createElement('a');
                    link.style = 'display: none';
                    document.body.appendChild(link);

                    // AタグのURLにバイナリデータをセット
                    var url = window.URL.createObjectURL(blob);
                    link.href = url;

                    // ファイル名をセット
                    link.download = fileName;

                    // 擬似的にリンクをクリック
                    link.click();
                    // 参照を解放
                    window.URL.revokeObjectURL(url);
                    link.remove();
                }

                deferred.resolve();

            };
            xhr.send(JSON.stringify(data));

            return deferred.promise;
        },

        // 選択したコードがregionCdなのかmunicipalityCdなのか確かめる。
        // regionCdの場合はtrueが帰る
        isRegion: function(cd){
            return Region.store.get(cd).then(lang.hitch(this, function(region){
                return region ? true : false;
            }));
        },

        /**
         * 月や日付を2桁にゼロpaddingする
         */
        _zeroPadding: function(month) {
            return ('00' + month).slice(-2);
        },

        /**
         * キャンセルボタン押下
         */
        onCancel:function(){
            this.getParent().hide();
        }
    });
});
