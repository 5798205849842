/**
 * 被害種別選択ダイアログ画面用モジュール。
 * @module app/damage/damagetype/DamageTypeDialog
 */
define([
	'module',
	'dojo/_base/declare',
    'dojo/_base/array',
	'dojo/_base/lang',
    'dojo/topic',
    'dojo/text!./templates/DialogContent.html',
    'dstore/Memory',
    'app/model/DisasterInfo',
    'idis/view/dialog/InfoDialog',
	'idis/store/IdisRest',
    'idis/view/page/_PageBase',
    'idis/view/dialog/DialogChain',
    'app/damage/consts/DamageType',

    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    './DamageTypeGrid'
], function(module, declare, array, lang, topic, template, Memory, DisasterInfo, InfoDialog,
		IdisRest, WidgetBase, DialogChain, DamageType) {

    /**
     * 被害種別選択ダイアログ
     * @class DamageTypeDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), WidgetBase,
        /** @lends pp/damage/damagetype/DamageTypeDialog~DamageTypeDialog# */ {

        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'damagetype-selector',

		SELECTION_RELEASED: 'app/damage/damagetype/DamageTypeSelector::released',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,


        noUnderlay: true,
        title: '被害種別選択',
        hasData: false,

        _grid: null,

        /**
         * コンストラクタ
         */
        constructor: function() {
            this.inherited(arguments);

            this.store = new Memory({
                'idProperty': 'damageTypeId',
                'data': []
            });
        },

        /**
         * DOM生成
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * 画面生成完了
         */
        startup: function() {
            this.inherited(arguments);
            this._grid = this.grid;
            this.initContent();

            topic.subscribe(this.SELECTION_RELEASED, lang.hitch(this, function (dialog) {
                this._grid = dialog.getChildren()[0].grid;
                this.initContent();
            }));
        },

        /**
         * 画面を再表示
         */
        refresh: function() {
            this.container.resize();
            this.item = null;
        },

        /**
        * widget生成後, 画面表示前
        */
        postCreate: function () {
            this.inherited(arguments);
        },

        initContent: function() {

            var data = [];
            if(!this.hasData){
                array.forEach(Object.keys(DamageType), function(id) {
                    var obj = {};
                    var key = ('00' + String(id)).slice(-2); // iをゼロパディング
                    obj.damageTypeId = key;
                    obj.damageType = DamageType[key];
                    // 並び順をセット
                    obj.order = {
                        '01': 1,
                        '02': 2,
                        '03': 3 ,
                        '04': 4,
                        '05': 5,
                        '06': 6,
                        '07': 7,
                        '08': 8,
                        '09': 9,
                        '10': 10,
                        '14': 11,
                        '15': 12,
                        '11': 13,
                        '12': 14,
                        '13': 15
                    }[id];
                    data.push(obj);
                }, DamageType);
            }

            data.sort(function(a, b){
                return a.order - b.order;
            });


            this._grid.reset();

            array.forEach(data, lang.hitch(this, function(item) {
                this.store.addSync(item);
            }));

            this._grid.set('collection', this.store);
            this.hasData = true;
        },

        onConfirm: function() {
            this.inherited(arguments);

//            if (!this.grid.mainTypeId) {
//                InfoDialog.show('入力チェック', '主となる被害種別を指定してください');
//                return;
//            }

            if (this.grid.mainTypeIds.length > 3) {
            	InfoDialog.show('入力チェック', '3つ以下の被害種別を選択してください');
            	return;
            }

            this.emit('update', {
                data: {
                    mainTypeIds: this.grid.mainTypeIds
//                    subTypeIds: this.grid.subTypeIds
                }
            });

        },

        onCancel: function() {
            this.inherited(arguments);
            this.close();
        },

        show: function() {
            this.inherited(arguments);

            this.grid.resize();
            this.container.resize();
        },

        onHide: function() {
            this.inherited(arguments);
            this._newStore();
            this.grid.set('collection', this.store);
            this.hasData = false;
        },

        close: function() {
            this.inherited(arguments);
            this.hide();
        },

        _newStore: function () {
            if (this.store) {
                delete this.store;
            }

            this.store = new Memory({
                'idProperty': 'damageTypeId',
                'data': []
            });
        }
    });

});
