/**
 * 職員招集 自動招集条件新規登録画面用モジュール。
 * @module app/convocation/view/condition/ConvoAutoMailRegisterPage
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/text!./templates/ConvoAutoMailRegisterPage.html', // テンプレート文字列
    'idis/store/IdisRest',
    'dojo/dom-style',
    'idis/control/Locator',
    'idis/control/Router',
    'dojo/_base/lang',
    'dojo/_base/array',
    'idis/service/Requester',
    'idis/view/Loader',
    'app/convocation/view/condition/_ConvoAutoMailPageBase',// 基底クラス
     // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/ValidationTextBox',
    'dijit/form/CheckBox',
    'idis/view/form/AclButton',
    'idis/view/form/WordCountTextarea',
    'app/convocation/view/condition/ConvoAddGroupDialog',
    'app/convocation/view/condition/ConvoAutoMailRegisterGrid',
    'app/view/form/KisyoAreaMultiSelector',
    'app/view/form/PrefectureSelector',
    'app/view/form/WeatherWarnSelector'
], function(module, declare, template, IdisRest, domStyle, Locator,
        Router, lang, array, Requester, Loader, _ConvoAutoMailPageBase) {
    /**
     * 職員招集 自動招集条件新規登録画面。
     * @class ConvoAutoMailRegisterPage
     * @extends module:app/convocation/view/condition/_ConvoAutoMailPageBase~_ConvoAutoMailPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _ConvoAutoMailPageBase,
        /** @lends module:idis/view/page/ConvoAutoMailRegisterPage~ConvoAutoMailRegisterPage# */
        {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',

            /**
             * DOMノードを生成するためのメソッド
             */
            buildRendering: function() {
                this.inherited(arguments);
            },

            /**
             * 条件選択ボタンが押下された際に条件選択ダイアログを表示する。
             */
            onConditionButton: function() {
                this.conditionSelectDialog.show();
            },

            /**
             * グループ追加ボタンが押下された際にグループ選択ダイアログを表示する。
             */
            addGroupButtonClick: function() {
                this.selectGroupDialog.show();
                if (this.convoMailType.value) {
                    // グループ選択ダイアログを取得し、グループ種別によるフィルターをかける
                    var page = this.selectGroupDialog.getChildren()[0];
                    var selector = page.dpDistributionGroupCd;
                    selector.model.setFilter(lang.hitch(this, function(item, isLeaf, model) {
                        return isLeaf && item.groupType === this.convoMailType.value;
                    }));
                    // グループ選択ダイアログにグループ種別を渡す
                    selector.groupType = this.convoMailType.value;
                }
            },

            /**
             * 登録ボタンが押された際に条件を登録する。
             */
            onSubmit: function() {

                // 入力チェック
                if (!this.validate()) {
                    return false;
                }

                // 登録確認ダイアログの表示
                this.chain.confirmAdd(lang.hitch(this, function(chain) {
                    var value = this.form.get('value');
                    value.distType = '99';
                    value.groupCd = this.getGroupCdList();
                    this.setCheckBoxValue(value);
                    // 招集メールではない場合、質問文と回答文をクリアしておく。
                    if (value.convoMailType !== '1') {
                        this.resetQAText(value);
                    }
                    // リクエストの発行からレスポンス受信後の処理までをpromiseに格納
                    var promise = Requester.post('/api/convoMails/conditions', {
                        data: value
                    }).then(function(){
                        // 処理成功時は完了ダイアログを表示
                        chain.infoComplete(function() {
                            Router.moveTo('convocation/automail');
                        });
                    }, function(err) {
                        // 処理失敗時はエラーログを出力し、エラーダイアログを表示
                        console.error(err);
                        chain.infoError(err);
                  });
                  //ローダーの表示
                  Loader.wait(promise);
                }));
            },
            // パンくずリストのボタンを押下したときの挙動
            onConvoAutoMailAdminPageLinkClick : function(evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('convocation/automail');
            },

            /**
             * メール種別変更時の挙動
             */
            onChangeConvoMailType: function(event) {
                var oldValue = this.convoMailType.value;
                var newValue = event.target.value;
                // グループが0件の場合は確認せずに切り替える
                if (this.store.data.length === 0) {
                    // 招集メールの場合のみ質問と回答を表示
                    if (newValue === '1') {
                        // 招集メールの場合
                        domStyle.set(this.qaArea, 'display', '');
                    } else {
                        // 通知メールの場合
                        domStyle.set(this.qaArea, 'display', 'none');
                    }
                }
                // グループ一覧が1件以上の場合は確認ダイアログを表示する
                else {
                    var messege = 'メール種別を変更すると、選択されたグループは削除されます。よろしいですか？<br>' + '※この処理ではデータベース上の情報は削除されません。';
                    this.chain.confirm(messege, lang.hitch(this, function(chain) {
                        // storeからグループを削除
                        array.map(this.store.data, function(item) {
                            return item.groupCd;
                        }).forEach(lang.hitch(this, function(cd) {
                            this.store.remove(cd);
                        }));
                        // 削除した後のstoreをgridに設定
                        this.convoAutoMailRegisterGrid.set('collection', this.store);
                        // 設定内容を反映するため、gridを更新
                        this.convoAutoMailRegisterGrid.refresh();
                        // 招集メールの場合のみ質問と回答を表示
                        if (newValue === '1') {
                            // 招集メールの場合
                            domStyle.set(this.qaArea, 'display', '');
                        } else {
                            // 通知メールの場合
                            domStyle.set(this.qaArea, 'display', 'none');
                        }
                        // 確認ダイアログを閉じる
                        chain.hide();
                    }), lang.hitch(this, function(chain) {
                        // メール種別を元の値に戻す
                        this.convoMailType.set('value', oldValue);
                        // 確認ダイアログを閉じる
                        chain.hide();
                    }));                
                }
            },

            /**
             * 質問と回答のチェック連動グレーアウト
             */
            onQ1CheckBox: function() {
            	this.question1Text.setDisabled(true);
            	if (this.question1.checked) {
            		this.question1Text.setDisabled(false);
            	}
            },
            onA1aCheckBox: function() {
            	this.answer1aText.setDisabled(true);
            	if (this.answer1a.checked) {
            		this.answer1aText.setDisabled(false);
            	}
            },
            onA1bCheckBox: function() {
            	this.answer1bText.setDisabled(true);
            	if (this.answer1b.checked) {
            		this.answer1bText.setDisabled(false);
            	}
            },
            onA1cCheckBox: function() {
            	this.answer1cText.setDisabled(true);
            	if (this.answer1c.checked) {
            		this.answer1cText.setDisabled(false);
            	}
            },
            onA1dCheckBox: function() {
            	this.answer1dText.setDisabled(true);
            	if (this.answer1d.checked) {
            		this.answer1dText.setDisabled(false);
            	}
            },
            onA1eCheckBox: function() {
            	this.answer1eText.setDisabled(true);
            	if (this.answer1e.checked) {
            		this.answer1eText.setDisabled(false);
            	}
            },
            onQ2CheckBox: function() {
            	this.question2Text.setDisabled(true);
            	if (this.question2.checked) {
            		this.question2Text.setDisabled(false);
            	}
            },
            onA2aCheckBox: function() {
            	this.answer2aText.setDisabled(true);
            	if (this.answer2a.checked) {
            		this.answer2aText.setDisabled(false);
            	}
            },
            onA2bCheckBox: function() {
            	this.answer2bText.setDisabled(true);
            	if (this.answer2b.checked) {
            		this.answer2bText.setDisabled(false);
            	}
            },
            onA2cCheckBox: function() {
            	this.answer2cText.setDisabled(true);
            	if (this.answer2c.checked) {
            		this.answer2cText.setDisabled(false);
            	}
            },
            onA2dCheckBox: function() {
            	this.answer2dText.setDisabled(true);
            	if (this.answer2d.checked) {
            		this.answer2dText.setDisabled(false);
            	}
            },
            onA2eCheckBox: function() {
            	this.answer2eText.setDisabled(true);
            	if (this.answer2e.checked) {
            		this.answer2eText.setDisabled(false);
            	}
            },
            onQ3CheckBox: function() {
            	this.question3Text.setDisabled(true);
            	if (this.question3.checked) {
            		this.question3Text.setDisabled(false);
            	}
            },
            onA3aCheckBox: function() {
            	this.answer3aText.setDisabled(true);
            	if (this.answer3a.checked) {
            		this.answer3aText.setDisabled(false);
            	}
            },
            onA3bCheckBox: function() {
            	this.answer3bText.setDisabled(true);
            	if (this.answer3b.checked) {
            		this.answer3bText.setDisabled(false);
            	}
            },
            onA3cCheckBox: function() {
            	this.answer3cText.setDisabled(true);
            	if (this.answer3c.checked) {
            		this.answer3cText.setDisabled(false);
            	}
            },
            onA3dCheckBox: function() {
            	this.answer3dText.setDisabled(true);
            	if (this.answer3d.checked) {
            		this.answer3dText.setDisabled(false);
            	}
            },
            onA3eCheckBox: function() {
            	this.answer3eText.setDisabled(true);
            	if (this.answer3e.checked) {
            		this.answer3eText.setDisabled(false);
            	}
            },
            onQ4CheckBox: function() {
            	this.question4Text.setDisabled(true);
            	if (this.question4.checked) {
            		this.question4Text.setDisabled(false);
            	}
            },
            onA4aCheckBox: function() {
            	this.answer4aText.setDisabled(true);
            	if (this.answer4a.checked) {
            		this.answer4aText.setDisabled(false);
            	}
            },
            onA4bCheckBox: function() {
            	this.answer4bText.setDisabled(true);
            	if (this.answer4b.checked) {
            		this.answer4bText.setDisabled(false);
            	}
            },
            onA4cCheckBox: function() {
            	this.answer4cText.setDisabled(true);
            	if (this.answer4c.checked) {
            		this.answer4cText.setDisabled(false);
            	}
            },
            onA4dCheckBox: function() {
            	this.answer4dText.setDisabled(true);
            	if (this.answer4d.checked) {
            		this.answer4dText.setDisabled(false);
            	}
            },
            onA4eCheckBox: function() {
            	this.answer4eText.setDisabled(true);
            	if (this.answer4e.checked) {
            		this.answer4eText.setDisabled(false);
            	}
            },
            onQ5CheckBox: function() {
            	this.question5Text.setDisabled(true);
            	if (this.question5.checked) {
            		this.question5Text.setDisabled(false);
            	}
            },
            onA5aCheckBox: function() {
            	this.answer5aText.setDisabled(true);
            	if (this.answer5a.checked) {
            		this.answer5aText.setDisabled(false);
            	}
            },
            onA5bCheckBox: function() {
            	this.answer5bText.setDisabled(true);
            	if (this.answer5b.checked) {
            		this.answer5bText.setDisabled(false);
            	}
            },
            onA5cCheckBox: function() {
            	this.answer5cText.setDisabled(true);
            	if (this.answer5c.checked) {
            		this.answer5cText.setDisabled(false);
            	}
            },
            onA5dCheckBox: function() {
            	this.answer5dText.setDisabled(true);
            	if (this.answer5d.checked) {
            		this.answer5dText.setDisabled(false);
            	}
            },
            onA5eCheckBox: function() {
            	this.answer5eText.setDisabled(true);
            	if (this.answer5e.checked) {
            		this.answer5eText.setDisabled(false);
            	}
            }
        });
});
