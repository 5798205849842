/**
 * 防災体制・報告の新規登録・詳細ダイアログ用の基底モジュール。
 * @module app/disasterprevention/view/_DisasterPreventionDialogPageBase
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-style',
    'dojo/on',
    'dojo/request/iframe',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'exif-js/exif',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/model/UserInfo',
    'app/model/DisasterInfo',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    './model/DisasterType',
    './model/HqSetFlg',
    './model/HqType',
    '../../config',
    'dojo/store/Memory',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/SimpleTextarea',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dojox/form/Uploader',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/RadioGroup',
    'app/view/form/OrganizationSelector',
    'app/disasterprevention/view/DisasterPreventionConfirmDialog'
], function(module, declare, lang, domClass, domConstruct, domStyle, on, iframe,
    Menu, MenuItem, popup, TooltipDialog, exif, Requester, IdisRest, UserInfo, DisasterInfo,
    _PageBase, InfoDialog, Loader, DisasterType, HqSetFlg, HqType, config, Memory) {
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    {
        /**
         * 防災体制ID
         */
        _disasterPreventionId: null,

        /**
         * 災害ID
         */
        _disasterId: null,

        /**
         * 災害種別
         */
        _disasterType: null,

        /**
         * 添付ファイル一覧格納用オブジェクト
         */
        _attachFileList: null,

        /**
         * 呼び出し元
         */
        _parent: null,

        /**
         * 配信対象フラグ
         */
        _provideFlg: false,

        /**
         * 選択のための体制一覧
         */
        _prevStatusStore: null,

        /**
         * constructor
         */
        constructor: function() {
            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }

            //災害情報を取得する
            Requester.get('/api/disasters/' + this._disasterId)
            .then(lang.hitch(this, function(res) {
                this._disasterType = res.disasterType;
            }));
        },

        /**
         * フォームをリセットする。
         */
        reset: function() {
            this.form.reset();
        },

        /**
         * 入力値の妥当性を検証する。
         */
        validate: function() {
            var infoDialogMsg = null;
            // 報告日時は必須
            if(!this.reportTimestamp.validate() || !this.reportTimestamp._date.displayedValue ||
                    !this.reportTimestamp._time.displayedValue) {
                infoDialogMsg = '「報告日時」';
                console.debug('報告日時が入力されていません');
            }
            // 本部設置・解除の場合、設置日時は必須
            if(domStyle.get(this.hqSetTimestampArea, 'display')!=='none' &&
                    (!this.hqSetTimestamp.validate() ||
                    !this.hqSetTimestamp._date.displayedValue ||
                    !this.hqSetTimestamp._time.displayedValue)) {
                infoDialogMsg = infoDialogMsg===null ? '' : infoDialogMsg + '及び';
                infoDialogMsg += '「設置日時」';
                console.debug('設置日時が入力されていません');
            }
            // 本部解除の場合、解除日時は必須
            if(this.hqAbolishedTimestampArea &&
                !(this._lastDetailItem.seqNum === 1 && this._updateFlg) &&
                domStyle.get(this.hqAbolishedTimestampArea, 'display')!=='none' &&   // 第一報の訂正ではないこと
                    (!this.hqAbolishedTimestamp.validate() ||
                    !this.hqAbolishedTimestamp._date.displayedValue ||
                    !this.hqAbolishedTimestamp._time.displayedValue)) {
                infoDialogMsg = infoDialogMsg===null ? '' : infoDialogMsg + '及び';
                infoDialogMsg += '「廃止日時」';
                console.debug('廃止日時が入力されていません');
            }
            // 第一報の訂正の場合、解散不可
            if (this.form.get('value').hqSetFlg === HqSetFlg.RELEASE &&
                this._lastDetailItem.seqNum === 1 && this._updateFlg) {
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : '体制が解散された場合は、続報登録してください。<br>体制の設置が誤りだった場合は取消を実施してください。'
                });
                this.infoDialog.show();
                return false;
            }
            if(infoDialogMsg!==null){
                infoDialogMsg += 'が入力されていません';
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : infoDialogMsg
                });
                this.infoDialog.show();
                return false;
            }else if(!this.form.isValid()) {
                console.debug('無効な値が入力されています');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '無効な値が入力されています'
                    });
                }
                this.infoDialog.show();
                return false;
            }

            return true;
        },

        // NOTE:長崎県案件では添付ファイルを扱わない
        // /**
        //  * 添付ファイルをアップロードする。
        //  */
        // loadAttachFile: function() {
        //     //ファイルが空の場合は処理を中断
        //     if(this.attachFile._files.length === 0) {
        //         return;
        //     }

        //     var self = this;

        //     console.log('file change');

        //     var url = '/api/disasterPrevention/attachments';
        //     // ファイルをサーバーにPOSTする
        //     var promise = iframe.post(url, {
        //         form: this.form.id,
        //         handleAs: 'json'
        //     });
        //     // ローダーの表示
        //     Loader.wait(promise).then(lang.hitch(this, function(data) {
        //         console.log(data);
        //         // 添付ファイルの配列に追加
        //         self._attachFileList.push(data);
        //         // プレビューを表示する
        //         self.showPreview(data);
        //         // ファイルアップローダー部品をリセットする
        //         self.attachFile.reset();
        //     }), function(error) {
        //         console.log(error);
        //         self.attachFile.reset();
        //         self._parent.chain.infoError(error);
        //     });
        // },

        // /**
        //  * 添付ファイルのプレビューを表示する。
        //  */
        // showPreview: function(data, exifFlg) {

        //     var dataUri = data.attachFilePath.replace('out/', 'data/');
        //     var fileName = data.attachFileName;
        //     var fileId = data.disasterPrevAtcFileId;
        //     var self = this;

        //     // 画像ファイルの場合
        //     if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
        //     fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
        //     fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
        //     fileName.indexOf('.gif') !== -1) {
        //         var image = new Image();

        //         //JPEGファイルの場合、EXIFデータの取得を実行する
        //         if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
        //         fileName.indexOf('.JPG') !== -1 ||fileName.indexOf('.JPEG') !== -1) {
        //             var img = null;
        //             this.own(on(image, 'load', lang.hitch(this, function(e) {
        //                 console.log(e);
        //                 img = e.target;

        //                 if(exifFlg) {
        //                     this.getExifData(img, this);
        //                 }
        //             })));
        //         }
        //         image.src = dataUri;
        //         domClass.add(image, 'is-showPreview');
        //         domConstruct.place(image, this.preview);
        //         //メニューの作成
        //         this.createMenu(image, dataUri, fileName, fileId, self);

        //     } else if (fileName.indexOf('.xls') !== -1 || fileName.indexOf('.xlsx') !== -1) {
        //         var excel = new Image();
        //         excel.src = 'images/excelicon.png';
        //         domClass.add(excel, 'is-showPreview');
        //         domConstruct.place(excel, this.preview);
        //         //メニューの作成
        //         this.createMenu(excel, dataUri, fileName, fileId, self);
        //     } else if (fileName.indexOf('.pdf') !== -1) {
        //         var pdf = new Image();
        //         pdf.src = 'images/pdficon.png';
        //         domClass.add(pdf, 'is-showPreview');
        //         domConstruct.place(pdf, this.preview);
        //         //メニューの作成
        //         this.createMenu(pdf, dataUri, fileName, fileId, self);
        //     } else if (fileName.indexOf('.doc') !== -1 || fileName.indexOf('.docx') !== -1) {
        //         var word = new Image();
        //         word.src = 'images/wordicon.png';
        //         domClass.add(word, 'is-showPreview');
        //         domConstruct.place(word, this.preview);
        //         //メニューの作成
        //         this.createMenu(word, dataUri, fileName, fileId, self);
        //     } else {
        //         var other = new Image();
        //         other.src = 'images/othericon.png';
        //         domClass.add(other, 'is-showPreview');
        //         domConstruct.place(other, this.preview);
        //         //メニューの作成
        //         this.createMenu(other, dataUri, fileName, fileId, self);
        //     }
        // },

        // /**
        //  * JPEGファイルの位置情報を取得する
        //  */
        // getExifData: function(img, self) {
        //     console.log('getting exif data start');
        //     exif.getData(img, function(){

        //         var latitude = exif.getTag(this, 'GPSLatitude');
        //         var longitude = exif.getTag(this, 'GPSLongitude');

        //         if(typeof latitude === 'undefined' || typeof longitude === 'undefined'){
        //             console.log('GPS data is unavailable.');
        //         }else{
        //             console.log('GPS data is available.');
        //             var f = function(number){
        //                 return number[0].numerator + number[1].numerator /
        //                 (60 * number[1].denominator) + number[2].numerator / (3600 * number[2].denominator);
        //             };
        //             self._parent.chain.confirm('この画像の位置情報を使用しますか？', function(chain) {
        //                 // 位置情報を設定する
        //                 self.pointLat = f(latitude);
        //                 self.pointLng = f(longitude);
        //                 // 地図にマークして中心に移動する
        //                 self.addMark(self.pointLat, self.pointLng, self);
        //                 self.map.setView([self.pointLat, self.pointLng], 11);
        //                 //ダイアログを閉じる
        //                 chain.hide();
        //             });
        //         }
        //     });
        // },

        // /**
        //  * 添付ファイルのサムネイル上にメニューを作る
        //  */
        // createMenu: function(newNode, uri, fileName, id, self) {
        //     var menu = new Menu({
        //         targetNodeId: newNode
        //     });
        //     menu.set('style', {'border': 'none', 'box-shadow': 'none'});

        //     //ダウンロード操作用
        //     var download = null;
        //     var userAgent = window.navigator.userAgent.toLowerCase();
        //     if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
        //         var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
        //         // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
        //         // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
        //         download = domConstruct.create('a', {href: '#'});
        //         //クリックでファイル取得処理に入る
        //         download.onclick = function() {
        //             self.downloadFile(url, fileName);
        //         };
        //     }else{
        //         // FF, Chromeの場合、download属性でファイルダウンロード
        //         download = domConstruct.create('a', {
        //             href: uri,
        //             download: fileName
        //         });
        //     }

        //     // ファイル名とメニューとの境界線をセット
        //     var contentNode = domConstruct.create('div');
        //     contentNode.innerHTML = fileName;
        //     domConstruct.place('<hr color=#b0c4de>', contentNode);
        //     //メニューをセット
        //     domConstruct.place(menu.domNode, contentNode);
        //     var tooltip = new TooltipDialog({
        //         content: contentNode
        //     });
        //     //
        //     tooltip.containerNode.onmouseleave = function() {
        //         popup.close(tooltip);
        //     };

        //     // 画像ファイルの場合のみ'開く'をメニューに追加する
        //     if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
        //     fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
        //     fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
        //     fileName.indexOf('.gif') !== -1) {
        //         menu.addChild(new MenuItem({
        //             label: '開く',
        //             iconClass: 'dijitEditorIcon dijitEditorIconInsertImage',
        //             onClick: function() {
        //                 console.log('file open');
        //                 window.open(uri);
        //             }
        //         }));
        //     }

        //     menu.addChild(new MenuItem({
        //         label: 'ダウンロード',
        //         iconClass: 'dijitIconSave',
        //         onClick: function(e) {
        //             console.log('file download');
        //             console.log(e);
        //             //IE対策
        //             if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
        //                 download.onclick();
        //             } else {
        //                 download.click();
        //             }
        //         }
        //     }));

        //     menu.addChild(new MenuItem({
        //         label: '削除',
        //         iconClass: 'dijitIconDelete',
        //         onClick: function() {
        //             console.log('file delete');

        //             // 該当ファイルを削除
        //             for(var i=0; i<self._attachFileList.length; i++) {
        //                 if(self._attachFileList[i].disasterPrevAtcFileId === id) {
        //                     self._attachFileList.splice(i,1); //id:3の要素を削除
        //                 }
        //             }

        //             // サムネイルとメニューを削除
        //             domConstruct.destroy(newNode);
        //             popup.close(tooltip);
        //         }
        //     }));

        //     menu.startup();
        //     //メニュー表示処理
        //     this.own(on(newNode, 'mouseover', lang.hitch(this, function() {
        //         popup.open({
        //             popup: tooltip,
        //             around: newNode,
        //             orient: ['below-centered']
        //         });
        //     })));
        //     //画面破棄時に一緒に破棄する
        //     this.own(tooltip);
        // },

        // /**
        //  * 添付ファイルをダウンロードする。
        //  */
        // downloadFile: function(url, name) {
        //     // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
        //     // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
        //     var xhr = new XMLHttpRequest();
        //     xhr.open('GET', url, true);
        //     xhr.responseType = 'arraybuffer';
        //     xhr.onload = function() {

        //         var arrayBuffer = this.response;

        //         var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});

        //         // IE10+
        //         if(window.navigator.msSaveOrOpenBlob){
        //             window.navigator.msSaveOrOpenBlob(blob, name);
        //         }

        //     };
        //     xhr.send();

        //     return false;

        // },

        /**
         * 本部設置情報入力欄表示切替え、諸々の変数を初期化する
         * 切替え要否の判定は当ファンクション呼び出し元にて実施すること
         * @param {*} item 変更後の体制情報オブジェクト
         */
        _setHqInfNode: function(item) {
            // 変数の初期化
            this.hqSetTimestamp.set('value', null);
            if (this.hqAbolishedTimestamp) {
                this.hqAbolishedTimestamp.set('value', null);
            }
            this.hqSetPlace.set('value', null);
            this.hqSetFlgNot.set('value', true);
            // 切替え対象のノードを非表示に初期化
            domStyle.set(this.hqInfNode, 'display', 'none');
            domStyle.set(this.hqSetTimestampArea, 'display', 'none');
            if (this.hqAbolishedTimestampArea) {
                domStyle.set(this.hqAbolishedTimestampArea, 'display', 'none');
            }
            domStyle.set(this.hqSetPlaceArea, 'display', 'none');
            // 非表示中は必須チェックの対象外
            domClass.remove(this.hqSetTimestamp, 'is-required');  // 本部設置日時
            if (this.hqAbolishedTimestampArea) {
                domClass.remove(this.hqAbolishedTimestampArea, 'is-required');  // 本部廃止日時
            }
            this.hqSetFlgNot.set('disabled', false);
            this.hqSetFlgSet.set('disabled', false);
            if (this.hqSetFlgAbolished) {
                this.hqSetFlgAbolished.set('disabled', false);
            }
            switch(item.hqType) {
                case HqType.RESPONSE_HQ:
                    // 災害対策本部設置対象の体制の場合
                    // 下に続く
                case HqType.ALERT_HQ:
                    // 災害警戒本部設置対象の体制の場合
                    // 下に続く
                case HqType.OTHER_HQ:
                    // その他本部設置対象の場合
                    this.hqName.innerHTML = item.hqName;
                    if (this._lastDetailItem &&
                        this._lastDetailItem.hqSetFlg &&
                        this._lastDetailItem.status === item.code) {
                        // 直前報で体制状況が設定されていて、
                        // 直前報と変更後の体制状況が同一の場合は直前報の値をセットする
                        switch (this._lastDetailItem.hqSetFlg) {
                            case '0':
                                this.hqSetFlgNot.set('value', true);
                                break;
                            case '1':
                                this.hqSetFlgSet.set('value', true);
                                break;
                            case '2':
                                this.hqSetFlgAbolished.set('value', true);
                                break;
                        }
                        this._setHqSetInf(this._lastDetailItem.hqSetFlg);
                    }
                    domStyle.set(this.hqInfNode, 'display', '');
                    break;
                case HqType.NOT_YET:
                    break;
                case HqType.RELEASE:
                    if (this._lastDetailItem &&
                    (this._lastDetailItem.hqSetFlg === '1' ||
                    this._lastDetailItem.hqSetFlg === '2')) {
                        // 解除選択時に直前報の本部設置フラグが「設置」又は「廃止」の場合
                        // DOMは表示状態且つ「廃止」選択状態とし、
                        // 編集を不可とする。
                        // 細かく制御するため、_setHqSetInf関数は呼ばず、
                        // 当関数内で完結させる
                        this.hqSetFlgAbolished.set('value', true);
                        domClass.add(this.hqSetTimestamp, 'is-required');  // 本部設置日時
                        domClass.add(this.hqAbolishedTimestampArea, 'is-required');  // 本部廃止日時
                        domStyle.set(this.hqSetTimestampArea, 'display', '');
                        domStyle.set(this.hqAbolishedTimestampArea, 'display', '');
                        domStyle.set(this.hqSetPlaceArea, 'display', '');
                        domStyle.set(this.hqInfNode, 'display', '');
                        if (this._lastDetailItem &&
                            this._lastDetailItem.hqSetTimestamp) {
                            // 直前報で値が設定され、且つ直前報と体制状況が同一の場合、直前報の値をセット
                            this.hqSetTimestamp.set('value', this._lastDetailItem.hqSetTimestamp);
                        }
                        if (this._lastDetailItem &&
                            this._lastDetailItem.hqAbolishedTimestamp) {
                            // 直前報で値が設定され、且つ直前報と体制状況が同一の場合、直前報の値をセット
                            this.hqAbolishedTimestamp.set('value', this._lastDetailItem.hqAbolishedTimestamp);
                        }
                        if (this._lastDetailItem &&
                            this._lastDetailItem.hqSetPlace) {
                            // 直前報で値が設定され、且つ直前報と体制状況が同一の場合、直前報の値をセット
                            this.hqSetPlace.set('value', this._lastDetailItem.hqSetPlace);
                        }
                    }
                    break;
            }
        },

        /**
         * 本部設置状況変更に伴い、設置日時と設置場所の表示有無を切替え、初期化する
         * 切替え要否の判定は当ファンクション呼び出し元にて実施すること
         * @param {*} value 変更後の本部設置状況：0:未設置、1:設置、2:解除、null
         */
        _setHqSetInf: function(value) {
            // 切替え対象のノードを非表示に初期化
            domStyle.set(this.hqSetTimestampArea, 'display', 'none');
            if (this.hqAbolishedTimestampArea) {
                domStyle.set(this.hqAbolishedTimestampArea, 'display', 'none');
            }
            domStyle.set(this.hqSetPlaceArea, 'display', 'none');
            if (!value) {
                // nullの場合
                return;
            }
            switch (value) {
                case '0':
                    break;
                case '1':
                    domClass.add(this.hqSetTimestamp, 'is-required');  // 本部設置日時
                    domStyle.set(this.hqSetTimestampArea, 'display', '');
                    domStyle.set(this.hqSetPlaceArea, 'display', '');
                    if (this._lastDetailItem &&
                        this._lastDetailItem.hqSetTimestamp &&
                        this._lastDetailItem.status === this.status.get('value')) {
                        // 直前報で値が設定され、且つ直前報と体制状況が同一の場合、直前報の値をセット
                        this.hqSetTimestamp.set('value', this._lastDetailItem.hqSetTimestamp);
                    }
                    if (this._lastDetailItem &&
                        this._lastDetailItem.hqSetPlace &&
                        this._lastDetailItem.status === this.status.get('value')) {
                        // 直前報で値が設定され、且つ直前報と体制状況が同一の場合、直前報の値をセット
                        this.hqSetPlace.set('value', this._lastDetailItem.hqSetPlace);
                    }
                    break;
                case '2':
                    domClass.add(this.hqSetTimestamp, 'is-required');  // 本部設置日時
                    domClass.add(this.hqAbolishedTimestampArea, 'is-required');  // 本部廃止日時
                    domStyle.set(this.hqSetTimestampArea, 'display', '');
                    domStyle.set(this.hqAbolishedTimestampArea, 'display', '');
                    domStyle.set(this.hqSetPlaceArea, 'display', '');
                    if (this._lastDetailItem &&
                        this._lastDetailItem.hqSetTimestamp &&
                        this._lastDetailItem.status === this.status.get('value')) {
                        // 直前報で値が設定され、且つ直前報と体制状況が同一の場合、直前報の値をセット
                        this.hqSetTimestamp.set('value', this._lastDetailItem.hqSetTimestamp);
                    }
                    if (this._lastDetailItem &&
                        this._lastDetailItem.hqSetPlace &&
                        this._lastDetailItem.status === this.status.get('value')) {
                        // 直前報で値が設定され、且つ直前報と体制状況が同一の場合、直前報の値をセット
                        this.hqSetPlace.set('value', this._lastDetailItem.hqSetPlace);
                    }
                    if (this._lastDetailItem &&
                        this._lastDetailItem.hqAbolishedTimestamp &&
                        this._lastDetailItem.status === this.status.get('value')) {
                        // 直前報で値が設定され、且つ直前報と体制状況が同一の場合、直前報の値をセット
                        this.hqAbolishedTimestamp.set('value', this._lastDetailItem.hqAbolishedTimestamp);
                    }
                    break;
                
            }
        },

        // _setDomStyle: function(hqType){
        //     switch (hqSetFlg) {
        //         case HqSetFlg.ESTABLISH:
        //             // 本部設置の場合
        //             domStyle.set(this.hqInfNode, 'display', '');                    // 本部設置情報
        //             domClass.add(this.hqSetTimestampArea, 'is-required');           // 本部設置日時
        //             // 新規登録ダイアログは廃止日時なし
        //             if(this.hqAbolishedTimestampArea){
        //                 domClass.remove(this.hqAbolishedTimestampArea, 'is-required');  // 本部廃止日時
        //             }
        //             // // 設置する本部名をセット
        //             // this.hqName.innerHTML = PrevStatus.get(statusCd).hq;
        //             // 配信する
        //             this._provideFlg = true;

        //             // 詳細画面
        //             if (this._lastDetailItem) {
        //                 // // 更新前後の本部種別が一致するなら、配信しない
        //                 // var hqType = PrevStatus.get(statusCd).hqType;
        //                 // if(this._lastDetailItem.hqType===hqType){
        //                 //     this._provideFlg = false;
        //                 // }
        //                 // 更新前が本部設置なら、配信しない
        //                 if(this._lastDetailItem.hqSetFlg===HqSetFlg.ESTABLISH){
        //                     this._provideFlg = false;
        //                 }
        //             }
        //             break;
        //         case HqSetFlg.RELEASE:
        //             // 本部解除の場合
        //             domStyle.set(this.hqInfNode, 'display', '');                    // 本部設置情報
        //             domClass.add(this.hqSetTimestampArea, 'is-required');           // 本部設置日時
        //             domClass.add(this.hqAbolishedTimestampArea, 'is-required');  // 本部廃止日時
        //             // 配信する
        //             this._provideFlg = true;
        //             // 更新前が本部設置なら、配信しない
        //             if(this._lastDetailItem.hqSetFlg===HqSetFlg.RELEASE){
        //                 this._provideFlg = false;
        //             }
        //             break;
        //         case HqSetFlg.NOT_YET:
        //             // 本部未設置の場合
        //             domStyle.set(this.hqInfNode, 'display', 'none');                    // 本部設置情報
        //             // 配信しない
        //             this._provideFlg = false;
        //             // 更新前が本部設置なら、配信する
        //             if(this._lastDetailItem.hqSetFlg===HqSetFlg.ESTABLISH){
        //                 this._provideFlg = true;
        //             }
        //             break;
        //     }

        //     // 過去報の場合、配信しない
        //     if (this._lastDetailItem && this._lastDetailItem.activeFlg!==1) {
        //         this._provideFlg = false;
        //     }

        // },

        /**
         * 配信有無判定
         */

        /**
         * 体制セット
         */
        setStatus: function(status){
            // 新規登録時は解除を表示しない
            var postFlg = this._lastDetailItem ? false:true;
            // 自治体コード
            var areaSelVal = this.municipalitySelector.get('value')!==config.municInfo.prefMunicCd?
                this.municipalitySelector.get('value') : config.municInfo.prefCd;
            var disasterType = this._disasterType;
            var self = this;
            if (disasterType) {
                Requester.get('/api/disasterPrevention/statusForSelect?' +
                    'areaCd=' + areaSelVal + '&disasterType=' + disasterType +
                    '&includeRelease=' + !postFlg, {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(function (data) {
                    self._prevStatusStore = new Memory({
                        idProperty: 'code',
                        data: data.items
                    });
                    self.status.set('sortByLabel', false);
                    self.status.set('store', self._prevStatusStore);
                    self.status.set('value', status);
                    // 本部設置状況を初期化
                    self._setHqInfNode(self._prevStatusStore.get(self.status.value));
                }, function (err) {
                    console.log(err);
                    InfoDialog.show('エラー', '体制状況の取得に失敗しました。');
                });
            }
        },

        // NOTE:長崎県案件では添付ファイルを扱わない
//        /**
//         * プレビューをクリアする
//         **/
//        clearPreview: function() {
//
//            var length = this.preview.childNodes.length;
//            for(var i = 0; i < length; i++) {
//                domConstruct.destroy(this.preview.childNodes[0]);
//            }
//
//            if(this._attachFileList) {
//                this._attachFileList.splice(0, this._attachFileList.length);
//            }
//        }
    });
});
