/**
 * 施設登録画面用モジュール。
 * @module app/facility/FacilityRegisterPage
 */
define([
    'module',
    'app/config',
    'app/facility/FacilityDetailConfig',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-style',
    'dojo/text!./templates/FacilityRegisterPage.html',
    'dojo/on',
    './_FacilityPageBase',
    'idis/control/Router',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/ConfirmDialog',
    'idis/view/dialog/IdisDialog',
    'idis/map/IdisMap',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/service/GeoService',
    'idis/service/Requester',
    'app/map/baselayer/BaseLayerPane',
    'esri-leaflet-geocoder',
    'leaflet',
    'idis/control/Locator',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/FilteringSelect',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/form/NumberTextBox',
    'dijit/form/Textarea',
    'dijit/form/CheckBox',
    'dijit/form/RadioButton',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/TitlePane',
    'dijit/layout/TabContainer',
    'dojox/form/Uploader',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/WordCountTextarea',
    'app/view/form/CustomizableDistrictSelector',
    'app/view/form/ManageRegionMunicipalitySelector',
    'app/view/form/CustomizableFacilitiesSelector'
], function(module, config, detailConfig, array, declare, lang, domClass, domStyle, template, on,
    _FacilityPageBase, Router, DialogChain, InfoDialog, ConfirmDialog, IdisDialog, IdisMap, UserInfo,
    IdisRest, Loader, GeoService, Requester,
    BaseLayerPane, geocoder, leaflet, Locator ) {
    // GeoServiceを初期化
    var _geoService = new GeoService({
        url: config.geocode && config.geocode.url
    });

    /**
     * 施設登録画面
     * @class FacilityRegisterPage
     * @extends module:app/facility/_FacilityPageBase~_FacilityPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _FacilityPageBase,
    /** @lends module:app/facility/FacilityRegisterPage~FacilityRegisterPage# */ {
        // テンプレート文字列
        templateString: template,
        confirmDialog: null,

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        /**
         * 市町村コード
         */
        _municipalityCd: null,

        /**
         * 市町村名
         */
        _municipalityName: null,

        /**
         * 住所 緯度
         */
        _addressLat: null,

        /**
         * 住所 経度
         */
        _addressLng: null,

        /**
         * 背景地図ダイアログに対する参照
         * @type {module:idis/view/dialog/IdisDialog~IdisDialog}
         * @private
         */
        _baseLayerDialog: null,

        detailFlg: null,

        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'facilityId',
                target: '/api/facility'
            });
            // ダイアログ連鎖を登録
            // 引数に与えたウィジェットのthis.ownを呼び出し、
            // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
            this.chain = DialogChain.get(this);

            // 市町村コードを取得
            var municipalityCds = UserInfo.getMunicipalityCds();
            if(municipalityCds && municipalityCds.length > 0) {
                this._municipalityCd = municipalityCds[0];
            } else {
                this._municipalityCd = config.municInfo.prefMunicCd;
            }
            this.detailFlg = '1';
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            this.initSelectBox();
            //市町ユーザの場合管理主体の選択肢は一つになるので、予め市町をセット
            if(UserInfo.getUserType() === '03'){
                this.managementEntity.set('value',UserInfo.getMunicipalityCd());
            }
        },

        onSubmit: function() {
            var sendData = this.form.get('value');
            var districtCd = sendData.districtCd;
            var designatedEvacShFlg = sendData.designatedEvacShFlg.length;
            var eDesignatedEvacShFlg = sendData.eDesignatedEvacShFlg.length;
            var welfareEvacShFlg = sendData.welfareEvacShFlg.length;
            var temporaryEvacShFlg = sendData.temporaryEvacShFlg.length;
            var civilProtectionEvacShFlg = sendData.civilProtectionEvacShFlg.length;
            var otherEvacShFlg = sendData.otherEvacShFlg.length;
/*
            sendData.address02 = '';
*/
            //避難所以外では、詳細タブの項目が消えてしまうため、こちらで定義する。

            if(sendData.facilityTypeId !== '01'){
                sendData.heliportFlg = this.heliportFlg.value;
                sendData.elevatorFlg = this.elevatorFlg.value;
                sendData.roofFlg = this.roofFlg.value;
                this.structure.reset();
                sendData.structure = this.structure.value;
                sendData.petFlg = this.petFlg.value;

                sendData.electricityFlg = this.electricityFlg.value;
                sendData.gasFlg = this.gasFlg.value;
                sendData.waterFlg = this.waterFlg.value;
                sendData.phoneFlg = this.phoneFlg.value;
                sendData.airConditionerFlg = this.airConditionerFlg.value;
                sendData.pcFlg = this.pcFlg.value;
                sendData.showerFlg = this.showerFlg.value;
                sendData.bathFlg = this.bathFlg.value;
                sendData.cookingFlg = this.cookingFlg.value;
                sendData.laundryFlg = this.laundryFlg.value;
                sendData.powerFlg = this.powerFlg.value;
                sendData.toiletFlg = this.toiletFlg.value;
                sendData.menToiletNum = this.menToiletNum.reset();
                sendData.womenToiletNum = this.womenToiletNum.reset();
                sendData.multitoiletFlg = this.multitoiletFlg.value;
/*
                sendData.cookingFlg = sendData.welfareEvacShFlg;
                sendData.bathFlg = sendData.welfareEvacShFlg;
                sendData.showerFlg = sendData.welfareEvacShFlg;
                sendData.laundryFlg = sendData.welfareEvacShFlg;
                sendData.powerFlg = sendData.welfareEvacShFlg;
*/
                if(sendData.facilityTypeId !== '02'&& sendData.facilityTypeId !== '03'){
                    sendData.vehicleAccessibleFlg = this.vehicleAccessibleFlg.value;
                    sendData.accessibleVehicleCd = this.accessibleVehicleCd.value;
                }
            } else {
                sendData.managementEntity = this.managementEntity.reset();
            }


            // 入力チェック
/*
            if(districtCd === '' || !this.checkDistrict(districtCd)) {
                console.debug('地区が正しく選択されていません');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '地区が正しく選択されていません'
                    });
*/

            if(sendData.facilityTypeId === '01' || sendData.facilityTypeId === '02' || sendData.facilityTypeId === '03'){
                if(districtCd === '' || !this.checkDistrict(districtCd)) {
                    console.debug('地区が正しく選択されていません');
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : '振興局が選択されているか、<br>地区が正しく選択されていません'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }
/*
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
*/
            } else {
                // 避難所、物資拠点以外は地区をセットしない（画面入力なし）
                delete sendData.districtCd;
            }

            // 物資拠点・備蓄倉庫を選択した上で、管理主体がnullだとエラー
            if((sendData.facilityTypeId === '02' || sendData.facilityTypeId === '03') &&
             !sendData.managementEntity) {
                console.debug('管理主体が正しく選択されていません');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '管理主体が正しく選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            }

            if((sendData.facilityTypeId === '01') && designatedEvacShFlg === 0 && eDesignatedEvacShFlg === 0 &&
                welfareEvacShFlg === 0 && temporaryEvacShFlg === 0 && civilProtectionEvacShFlg === 0 && otherEvacShFlg === 0) {
                console.debug('避難所区分が正しく選択されていません');

                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '避難所区分が正しく選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            }


            //添付ファイルIDをセット
            if(this.attachFileList.length > 0) {
                var attachFileIds = [];
                for(var i=0; i<this.attachFileList.length; i++) {
                    attachFileIds.push(this.attachFileList[i].facilityAtcFileId);
                }
                sendData.attachFileIds = attachFileIds.join(',');
            } else {
                sendData.attachFileIds = '';
            }

            delete sendData.attachFile;
/*
            delete sendData.organizationName;
            delete sendData.municipalityName;
*/
            //対象災害の「その他」のチェックそのものは不要なため削除。
/*
            delete sendData.otherShFlg;
*/

            //今回不使用の項目に仮の値を挿入しておく。
/*
            console.log(sendData);
            sendData.supportFlg = sendData.cookingFlg;
            sendData.foreignerFlg = sendData.cookingFlg;
            sendData.floodFlg = sendData.cookingFlg;
            sendData.multitoiletFlg = sendData.cookingFlg;
            sendData.ostomateToiletFlg = sendData.cookingFlg;
            sendData.japaneseStyleRoomFlg = sendData.cookingFlg;
            sendData.airConditionerFlg = sendData.cookingFlg;
            sendData.lpgFlg = sendData.cookingFlg;
            sendData.cityGasFlg = sendData.cookingFlg;
            sendData.sewerFlg = sendData.cookingFlg;
            sendData.tankFlg = sendData.cookingFlg;
            sendData.slopeFlg = sendData.cookingFlg;
*/
            //

            // フォームのバリデーションを行う（共通部品）
            if(!this.form.validate()) {
                return false;
            }
            // フォームのバリデーションを行う（独自チェック）
            if(!this.validateForm(sendData)) {
                return false;
            }

            sendData.pointLat = this.pointLat;
            sendData.pointLng = this.pointLng;

            // 地図チェック
            if(sendData.pointLat === '' || sendData.pointLng === '' ||
                sendData.pointLat === null || sendData.pointLng === null) {
                // 地図マーカが無いので住所から緯度経度を求める
                this.addressToLatLng().then(lang.hitch(this, function(){
                    sendData.pointLat = this.pointLat;
                    sendData.pointLng = this.pointLng;
                    // 住所が正しく入力されていない場合確認ダイアログを表示する
                    if(sendData.pointLat === '' || sendData.pointLng === '' ||
                        sendData.pointLat === null || sendData.pointLng === null) {
                        console.debug('住所から緯度経度が取得できません');
                        this.chain.confirm('住所から緯度・経度が取得できませんでした。<br>' +
                        '緯度・経度の情報が必要な場合は、キャンセルして地図上で施設の位置をクリックしてください。<br>' +
                        '緯度・経度の情報が不要な場合は、OKボタンを押して登録を完了してください。',
                         function(chain){
                            // 追加処理と共にローディング表示
                            Loader.wait(this.store.add(sendData)).then(function() {
                                // 成功時（POST結果はグリッドが自動的に反映）
                                chain.infoComplete(function() {
                                    // 一覧画面に移動
                                    Router.moveTo('facility');
                                });
                            }, function(err) {
                                // 失敗時
                                chain.infoError(err);
                            });
                        });
                        return;
                    }
                }));
            }

            this.chain.confirmAdd(function(chain) {
                // OKが押された場合
                // 追加処理と共にローディング表示
                Loader.wait(this.store.add(sendData)).then(function() {
                    // 成功時（POST結果はグリッドが自動的に反映）
                    chain.infoComplete(function() {
                        // 一覧画面に移動
                        Router.moveTo('facility');
                    });
                }, function(err) {
                    // 失敗時
                    chain.infoError(err);
                });
            });

        },

        /**
         * マップを初期化する。
         */
        initMap: function() {
            // 市町村を取得
            var self = this;
            var promise = Requester.get('/api/municipalities/' + this._municipalityCd, {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(function(data) {
                self._municipalityName = data.municipalityName;
                if(self._municipalityCd !== config.municInfo.prefMunicCd) {
                    //デフォルトで県名+市町名をセットする。
                    self.address.set('value', config.municInfo.prefName + data.municipalityName);
                }

                // マップの生成
                var latlng = null;
                if(data.latitude && data.longitude) {
                    latlng = [data.latitude, data.longitude];
                } else {
                    latlng = [config.map.latitude, config.map.longitude];
                }

                self.map = new IdisMap(self.mapNode, {
                    config: config.map,
                    keyboard: false, // コメント時に+/-が使用できないため
                    touchExtend : false,
                    minZoom: 9,
                    drawControlTooltips:false}
                ).setView(latlng, 12);
                // destroy時にmapを破棄するよう設定
                self.own(self.map);
                self.own(on(self.map, 'click', lang.hitch(self, function(e) {
                    self.pointLat = e.latlng.lat;
                    self.pointLng = e.latlng.lng;
                    this.addMark(self.pointLat, self.pointLng, self);
                })));

                //初期化
                self.pointLat = '';
                self.pointLng = '';
                self.marker = '';

                // // 地図レイヤーの追加
                // leaflet.tileLayer(config.map.url, {
                //     maxZoom: 18
                // }).addTo(self.map);
            }, function(error) {
                self.chain.infoError(error);
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * 背景地図ダイアログを表示する。
         */
        showBaseLayerDialog: function() {
            if (!this._baseLayerDialog) {
                // 初回呼び出し時にインスタンス生成
                this._baseLayerDialog = new IdisDialog({
                    noUnderlay: true,
                    title: '背景地図',
                    content: new BaseLayerPane({map: this.map})
                });
                // 画面が破棄された際に連れて行く
                this.own(this._baseLayerDialog);
            }
            this._baseLayerDialog.show();
        },

        /**
         * 地図上でポイントされている位置の住所を設定します。
         */
        mapToAddress: function() {
            if(this.pointLat === '' || this.pointLng === '') {
                console.debug('not pointed map');
                this.chain.info('地図が選択されていません。', 'エラー');
                return;
            }

            _geoService.reverseGeocode(leaflet.latLng({
                lat: this.pointLat,
                lng: this.pointLng
            })).then(lang.hitch(this, function(res) {
                this.addMark(this.pointLat, this.pointLng);
                this.address.set('value',res.address.Address);
                this._addressLat = this.pointLat;
                this._addressLng = this.pointLng;
            }), lang.hitch(this, function() {
                this.chain.info('住所を取得できませんでした。', 'エラー');
            }));

            console.debug('end reverse geocoding (address: ' +
                this.address.value + ')');
        },

        /**
         * 住所の位置を地図上にポイントします。
         */
        addressToMap: function() {
            if(!this.address.value) {
                this.chain.info('住所を入力してください。', 'エラー');
                return;
            }
            //住所欄に県名が記載されていない場合、追加して検索する。
            var address = this.address.value;
            if(this.address.value.indexOf(config.municInfo.prefName) !== 0){
                address = config.municInfo.prefName + address;
            }
            _geoService.geocode(address).then(lang.hitch(this, function(results) {
                if(results.length > 0) {
                    var latlng = [results[0].latlng.lat, results[0].latlng.lng];
                    this.pointLat = results[0].latlng.lat;
                    this.pointLng = results[0].latlng.lng;
                    this.addMark(this.pointLat, this.pointLng);
                    this.map.setView(latlng, 14);
                    this._addressLat = results[0].latlng.lat;
                    this._addressLng = results[0].latlng.lng;
                } else {
                    console.debug('address is not correct');
                    this.chain.info('住所から位置情報を取得できませんでした。', 'エラー');
                }
            }));
        },

        /**
         * マーカーを追加する。
         */
        addMark: function(lat, lng) {
            this.removeMark();
            this.marker = leaflet.marker([lat, lng]).addTo(this.map);
        },

        /**
         * マーカーを削除する。
         */
        removeMark: function() {
            this.marker && this.map.removeLayer(this.marker);
        },

        /**
         * 住所の位置から緯度経度を求める。
         */
        addressToLatLng: function() {
            //住所欄に県名が記載されていない場合、追加して検索する。
            var address = this.address.value;
            if(this.address.value.indexOf(config.municInfo.prefName) !== 0){
                address = config.municInfo.prefName + address;
            }
            return _geoService.geocode(address).then(lang.hitch(this, function(results) {
                if(results.length > 0) {
                    this.pointLat = results[0].latlng.lat;
                    this._addressLat = results[0].latlng.lat;
                    this.pointLng = results[0].latlng.lng;
                    this._addressLng = results[0].latlng.lng;
                } else {
                    console.debug('address is not correct');
                    //this.chain.info('住所から位置情報を取得できませんでした。', 'エラー');
                }
            }));

        },

        /**
         * 入力値の妥当性をチェックする。
         */
        validateForm: function(data) {
            console.debug(data);
            return true;
        },

        // HTML上にウィジェットが設置されてから呼ばれる
        startup: function() {
            this.inherited(arguments);

            this.attachFileList = [];
            // 地図を初期化
            this.initMap();
            if(UserInfo.getAdminFlg()){
                this.publicFlgYes.set('readOnly', false);
                this.publicFlgNo.set('readOnly', false);
            }
            //初期表示する項目に絞る
            this.showShelterAdmIdInputForm();
        },

        // DOMノード生成後に呼ばれる
        postCreate: function() {
            this.inherited(arguments);
        },

        onFacilityLinkClick:function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('facility');
        },

        // 施設種別セレクトボックスの初期化を行う
        initSelectBox: function(){
            var promise =
                Requester.get('/api/facility/category');
            Loader.wait(promise).then(lang.hitch(this, function(data) {
                // セレクトボックスに設定するデータの配列を作成
                var newOptions = [{
                    label: '&nbsp;',
                    value: null
                }];
                // サーバーレスポンスの各要素をselectへ追加
                data.items.forEach(function(object){
                    newOptions.push({
                        label: object.facilityTypeName,
                        value: object.facilityTypeId
                    });
                });
                this.facilityTypeSelectBox.set('options',newOptions);

                // 避難所一覧または概況ページから遷移してきた場合は施設種別に避難所、避難所区分に臨時避難所をデフォルトセットしておく。
                if (Locator.getQuery().type === 'shelter') {
                    this.facilityTypeSelectBox.set('value','01');
                    // 避難所用管理項目の表示非表示切り替え
                    this.showShelterAdmIdInputForm(this.facilityTypeId);
                    this.temporaryEvacShFlg.set ('value','on');
                }
            }));
        },

        // 種別詳細セレクトボックスの初期化を行う
        initFacilityTypeDetailSelectBox: function(){
            this.facilityTypeDetail.reset();
            this.facilityTypeDetail.set('options','');
            var GOVERNMENT_AGENCY = '05'; // 行政機関
            var PUBLIC_INSTITUTION = '09'; // 公共機関
            var MEDICAL_INSTITUTION = '10'; // 医療機関
            var PRESCHOOL = '11'; // 幼稚園・保育園
            var SCHOOL = '12'; // 学校
            var NURSINGHOME = '13'; // 老人ホーム
            var LIFE_LINE = '14'; // ライフライン
            var HELIPORT = '15'; // ヘリポート
            var facilityTypeId = this.facilityTypeSelectBox.value;

            // セレクトボックスに設定するデータの配列を作成
            var newOptions = [{
                label: '未選択',
                value: ''
            }];
            switch(facilityTypeId) {
            case GOVERNMENT_AGENCY:
                detailConfig.governmentAgency.details.forEach(function(object){
                        newOptions.push({
                        label: object.label,
                        value: object.value
                        });
                });
                this.facilityTypeDetail.set('options',newOptions);
                break;

            case PUBLIC_INSTITUTION:
                detailConfig.publicInstitution.details.forEach(function(object){
                        newOptions.push({
                        label: object.label,
                        value: object.value
                        });
                });
                this.facilityTypeDetail.set('options',newOptions);
                break;

            case MEDICAL_INSTITUTION:
                detailConfig.medicalInstitution.details.forEach(function(object){
                        newOptions.push({
                        label: object.label,
                        value: object.value
                        });
                });
                this.facilityTypeDetail.set('options',newOptions);
                break;

            case PRESCHOOL:
                detailConfig.preschool.details.forEach(function(object){
                        newOptions.push({
                        label: object.label,
                        value: object.value
                        });
                });
                this.facilityTypeDetail.set('options',newOptions);
                break;

            case SCHOOL:
                detailConfig.school.details.forEach(function(object){
                        newOptions.push({
                        label: object.label,
                        value: object.value
                        });
                });
                this.facilityTypeDetail.set('options',newOptions);
                break;

            case NURSINGHOME:
                detailConfig.nursingHome.details.forEach(function(object){
                        newOptions.push({
                        label: object.label,
                        value: object.value
                        });
                });
                this.facilityTypeDetail.set('options',newOptions);
                break;

            case LIFE_LINE:
                detailConfig.lifeLine.details.forEach(function(object){
                        newOptions.push({
                        label: object.label,
                        value: object.value
                        });
                });
                this.facilityTypeDetail.set('options',newOptions);
                break;

            case HELIPORT:
                detailConfig.heliport.details.forEach(function(object){
                        newOptions.push({
                        label: object.label,
                        value: object.value
                        });
                });
                this.facilityTypeDetail.set('options',newOptions);
                break;

            default:
                break;
            }
        },

        // 対象災害で「その他」が押されたときに入力フォームを表示する。
        onOtherDisasterButtonClick: function() {
            if(this.form.get('value').otherShFlg[0] === 'on'){
                this.shNote.set('disabled',false);
            }else{
                this.shNote.set('disabled',true);
            }
        },

        // 詳細情報のラジオボタン(トイレ)で「あり」が選択されたときに入力フォームを表示する。
        selectToilet: function() {
            if(this.form.get('value').toiletFlg === '2'){
                this.menToiletNum.set('disabled', false);
                this.womenToiletNum.set('disabled', false);
                domStyle.set(this.multitoilet, 'display', '');
            }else{
                this.menToiletNum.set('disabled', true);
                this.womenToiletNum.set('disabled', true);
                domStyle.set(this.multitoilet, 'display', 'none');
            }
        },

        // 車両の通行可否で「否」が選択された時は最大車種を非表示にする。
        onVehicleButtonClick: function() {
            if(this.form.get('value').vehicleAccessibleFlg === '1'){
                this.accessibleVehicleCd.set('disabled',true);
            }else{
                this.accessibleVehicleCd.set('disabled',false);
            }
        },

        // リストボックスで避難所が選択された場合は避難所関連の入力フォームを表示する
        showShelterAdmIdInputForm: function(id){
            // '01'=避難所、'02'=備蓄倉庫、'03'=物資拠点'
            if(typeof id === 'undefined' || id === null) {
                domStyle.set(this.stocklabel, 'display', '');
                this.form.set('value', {publicFlg: '1'});
                domStyle.set(this.publicLabel, 'display', 'none');
                domStyle.set(this.municipalityAuthorizeLabel, 'display', 'none');
                this.municipalityAuthorizeFlg.reset();
                // 避難所・物資拠点・備蓄倉庫の共通項目を非表示
                this.folkliftNum.reset();
                domStyle.set(this.folkliftNumInput, 'display', 'none');
                this.form.set('value', {vehicleAccessibleFlg: '0'});
                this.accessibleVehicleCd.reset();
                this.onVehicleButtonClick();
                domStyle.set(this.vehicleInfo, 'display', 'none');
                // 避難所の管理項目を非表示
                this.designatedEvacShFlg.reset();
                this.eDesignatedEvacShFlg.reset();
                this.welfareEvacShFlg.reset();
                this.temporaryEvacShFlg.reset();
                this.civilProtectionEvacShFlg.reset();
                this.otherEvacShFlg.reset();
                domStyle.set(this.shelterCategory, 'display', 'none');
                this.capacity.reset();
                domStyle.set(this.capacityInput, 'display', 'none');
                this.capacityCalcStandard.reset();
                domStyle.set(this.capacityCalcStandardInput, 'display', 'none');
                this.floodShFlg.reset();
                this.sedimentDisasterShFlg.reset();
                this.stormSurgeShFlg.reset();
                this.earthquakeShFlg.reset();
                this.tsunamiShFlg.reset();
                this.fireShFlg.reset();
                this.landsideWaterShFlg.reset();
                this.volcanoShFlg.reset();
                this.otherShFlg.reset();
                this.shNote.reset();
                this.onOtherDisasterButtonClick();
                domStyle.set(this.shelterType, 'display', 'none');
                // 備蓄倉庫・物資拠点の項目を非表示
                domStyle.set(this.municipalitySelector, 'display', '');
                // 地区・種別詳細の項目
                this.districtCd.reset();
                domStyle.set(this.districtSelector, 'display', 'none');
                this.facilityTypeDetail.reset();
                domStyle.set(this.facilityTypeDetailSelectBox, 'display', 'none');

                //詳細タブを表示する。
                this.closeDetailTab(this.detailFlg);
            } else if(id === '01') {
                //「利用可否」「開設可否」の記載
                domStyle.set(this.stocklabel, 'display', '');
                domStyle.set(this.publicLabel, 'display', '');
                domStyle.set(this.municipalityAuthorizeLabel, 'display', '');
                // 避難所・備蓄倉庫・それ以外の施設の共通項目を表示
                domStyle.set(this.folkliftNumInput, 'display', '');
                domStyle.set(this.vehicleInfo, 'display', '');
                // 避難所の管理項目
                domStyle.set(this.shelterCategory, 'display', '');
                this.temporaryEvacShFlg.set('value', 'on'); // 初期設定で臨時避難所にチェックを入れる
                domStyle.set(this.capacityInput, 'display', '');
                domStyle.set(this.capacityCalcStandardInput, 'display', '');
                domStyle.set(this.shelterType, 'display', '');
                // 備蓄倉庫・それ以外の施設の項目
                // 管理主体をリセットすると市町村ユーザが管理主体を変更できなくなるため
                // ここではリセットしない
                domStyle.set(this.municipalitySelector, 'display', 'none');
                // 地区・種別詳細の項目
                this.facilityTypeDetail.reset();
                domStyle.set(this.facilityTypeDetailSelectBox, 'display', 'none');
                domStyle.set(this.districtSelector, 'display', '');

                // 詳細タブを表示する。
                this.showDetailTab(this.detailFlg);
                // トイレ関係
                this.selectToilet();
            } else if(id === '02' || id ==='03'){
                //「利用可否」「開設可否」の記載
                domStyle.set(this.stocklabel, 'display', '');
                // 施設種別を避難所に変更した時初期値にするため
                this.form.set('value', {publicFlg: '1'});
                domStyle.set(this.publicLabel, 'display', 'none');
                domStyle.set(this.municipalityAuthorizeLabel, 'display', 'none');
                // 避難所・備蓄倉庫・それ以外の施設の共通項目を表示
                domStyle.set(this.folkliftNumInput, 'display', '');
                domStyle.set(this.vehicleInfo, 'display', '');
                // 避難所の管理項目を非表示
                this.designatedEvacShFlg.reset();
                this.eDesignatedEvacShFlg.reset();
                this.welfareEvacShFlg.reset();
                this.temporaryEvacShFlg.reset();
                this.civilProtectionEvacShFlg.reset();
                this.otherEvacShFlg.reset();
                domStyle.set(this.shelterCategory, 'display', 'none');
                this.capacity.reset();
                domStyle.set(this.capacityInput, 'display', 'none');
                this.capacityCalcStandard.reset();
                domStyle.set(this.capacityCalcStandardInput, 'display', 'none');
                this.floodShFlg.reset();
                this.sedimentDisasterShFlg.reset();
                this.stormSurgeShFlg.reset();
                this.earthquakeShFlg.reset();
                this.tsunamiShFlg.reset();
                this.fireShFlg.reset();
                this.landsideWaterShFlg.reset();
                this.volcanoShFlg.reset();
                this.otherShFlg.reset();
                this.shNote.reset();
                this.onOtherDisasterButtonClick();
                domStyle.set(this.shelterType, 'display', 'none');
                // 備蓄倉庫・それ以外の施設の項目
                domClass.add(this.municipalitySelector, 'is-required');
                domStyle.set(this.municipalitySelector, 'display', '');
                // 地区・種別詳細の項目
                this.facilityTypeDetail.reset();
                domStyle.set(this.facilityTypeDetailSelectBox, 'display', 'none');
                domStyle.set(this.districtSelector, 'display', '');
                // 詳細タブを非表示にする。
                this.closeDetailTab(this.detailFlg);
            } else {
                //「利用可否」「開設可否」の記載
                domStyle.set(this.stocklabel, 'display', '');
                // 施設種別を避難所に変更した時初期値にするため
                this.form.set('value', {publicFlg: '1'});
                domStyle.set(this.publicLabel, 'display', 'none');
                domStyle.set(this.municipalityAuthorizeLabel, 'display', 'none');
                // 避難所・備蓄倉庫・それ以外の施設の共通項目を表示
                this.folkliftNum.reset();
                domStyle.set(this.folkliftNumInput, 'display', 'none');
                this.form.set('value', {vehicleAccessibleFlg: '0'});
                this.accessibleVehicleCd.reset();
                this.onVehicleButtonClick();
                domStyle.set(this.vehicleInfo, 'display', 'none');
                // 避難所の管理項目を非表示
                this.designatedEvacShFlg.reset();
                this.eDesignatedEvacShFlg.reset();
                this.welfareEvacShFlg.reset();
                this.temporaryEvacShFlg.reset();
                this.civilProtectionEvacShFlg.reset();
                this.otherEvacShFlg.reset();
                domStyle.set(this.shelterCategory, 'display', 'none');
                this.capacity.reset();
                domStyle.set(this.capacityInput, 'display', 'none');
                this.capacityCalcStandard.reset();
                domStyle.set(this.capacityCalcStandardInput, 'display', 'none');
                this.floodShFlg.reset();
                this.sedimentDisasterShFlg.reset();
                this.stormSurgeShFlg.reset();
                this.earthquakeShFlg.reset();
                this.tsunamiShFlg.reset();
                this.fireShFlg.reset();
                this.landsideWaterShFlg.reset();
                this.volcanoShFlg.reset();
                this.otherShFlg.reset();
                this.shNote.reset();
                this.onOtherDisasterButtonClick();
                domStyle.set(this.shelterType, 'display', 'none');
                // 備蓄倉庫・物資拠点の項目を非表示
                domClass.remove(this.municipalitySelector, 'is-required');
                domStyle.set(this.municipalitySelector, 'display', '');
                // 地区・種別詳細の項目
                if(id >= '30'){
                    domStyle.set(this.facilityTypeDetailSelectBox, 'display', 'none');
                } else{
                    domStyle.set(this.facilityTypeDetailSelectBox, 'display', '');
                }
                this.districtCd.reset();
                domStyle.set(this.districtSelector, 'display', 'none');

                this.initFacilityTypeDetailSelectBox();
                // 詳細タブを非表示にする。
                this.closeDetailTab(this.detailFlg);
            }
        },

        // 詳細タブを表示にする
        showDetailTab: function(detailFlg){
            if(detailFlg === '0'){
                this.tabContainer.addChild(this.mainFacilityInfo);
                this.detailFlg = '1';
                this.tabContainer.selectChild(this.mainFacilityInfo);
            }
        },

        // 詳細タブを非表示にする
        closeDetailTab: function(detailFlg){
            if(detailFlg === '1'){
                this.tabContainer.removeChild(this.mainFacilityInfo);
                this.detailFlg = '0';
            }
        },

        /**
         * 地区が選択されていることを検証する
         */
        checkDistrict :function(districtCd) {
            var result = false;
            this.districtCd.model.store.get(districtCd).then(
                lang.hitch(this, function(item){
                    // item.typeが'MDistrict'の場合はツリーの子要素であるため地区として選択可能にする
                    if(item.type === 'MDistrict') {
                        result = true;
                    } else {
                        result = false;
                    }
                }
            ));
            return result;
        },

        /**
         * 外部公開ラジオボタン切り替えイベント
         */
        onChangePublicFlg: function() {
            // 外部公開可の場合、自市区町村のみ閲覧制限不可
            if (this.form.get('value').publicFlg === '1') {
                this.municipalityAuthorizeFlg.reset();
                this.municipalityAuthorizeFlg.set('disabled', true);
            // 外部公開不可の場合、自市区町村のみ閲覧制限可
            } else {
                this.municipalityAuthorizeFlg.set('disabled', false);
            }
        }
    });
});
