/**
 * 施設情報帳票出力画面用モジュール。
 * @module app/facility/FacilityReportDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/text!./templates/FacilityReportDialog.html',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'app/model/Region',
    '../config',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'dojo/dom-style',
    // 以下、変数から参照されないモジュール
    'dijit/form/Form',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/RadioGroup',
    'app/view/form/ManageRegionMunicipalitySelector'
], function(module, declare, lang, Deferred, JSON, template, _PageBase,
        InfoDialog, Loader, DialogChain, Region, config, UserInfo, USER_TYPE, domStyle) {
    /**
     * 施設情報帳票出力画面のイベント処理。
     * @class DeliveredMailReportDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/_PageBase~idis/view/page/_PageBase# */ {
        // テンプレート文字列
        templateString: template,
        // 帳票出力分岐用の定数
        FACILITY: '0',
        FACILITY_CAO: '1',

        constructor: function() {
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        initDialog: function() {
            // 札幌市ユーザの場合、自治体選択ダイアログのクリアボタンを非表示にする
            if (UserInfo.getUserType() === USER_TYPE.REGION ||
                UserInfo.getMunicipalityCd() === config.municInfo.cityMunicCd){
                domStyle.set(this.municipalityCd.clearButton.domNode, 'display', 'none');
            }
        },
        /**
         * 入力チェックを行う
         */
        validate: function() {
            var formValues = this.form.get('value');

            if (!formValues.reportType) {
                InfoDialog.show('入力チェック', '出力情報が選択されていません');
                return false;
            }
            return true;
        },

        /**
         * 出力ボタン押下時の処理を行う
         */
        onSubmit: function() {
            try {
                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }
                // 画面固有のバリデーションを実施
                if (!this.validate()) {
                    return false;
                }

                this.isRegion(this.municipalityCd.value).then(lang.hitch(this, function(isRegion){
                    // 帳票対象の道・市町村・振興局のコードを取得する
                    var municipalityCd = null;
                    var regionCd = null;
                    if(isRegion){
                        regionCd = this.municipalityCd.value;                    
                    } else if(this.municipalityCd.value && 
                            this.municipalityCd.value !== config.municInfo.prefCd){
                        municipalityCd = this.municipalityCd.value;
                    } else if (this.municipalityCd.value &&
                            this.municipalityCd.value === config.municInfo.prefCd) {
                        municipalityCd = config.municInfo.prefMunicCd;
                    }

                    // ラジオボタンの値を取得
                    var reportType = this.form.get('value').reportType;

                    var reqid = null;
                    var fileName = null;
                    var paramList = [];

                    // 帳票ダイアログのラジオボタンに応じて帳票を変更
                    if (reportType === this.FACILITY) {
                        reqid = 'FACILITY_LIST_EX';
                        fileName = 'FacilityList.xlsx';
                        paramList = [
                            {key: 'municipalityCd', value: municipalityCd},
                            {key: 'regionCd', value: regionCd}];
                    } else if (reportType === this.FACILITY_CAO) {
                        reqid = 'FACILITY_LIST_CAO_STYLE_EX';
                        fileName = 'FacilityListCaoStyle.xlsx';
                        paramList = [
                            {key: 'municipalityCd', value: municipalityCd},
                            {key: 'regionCd', value: regionCd}];
                    }

                    var data4xoblosDownload = {
                        fileName: fileName,
                        reqid: reqid,
                        paramList: paramList
                    };

                    var promise = this.download(data4xoblosDownload).then(lang.hitch(this, function() {
                        this.infoDialog = new InfoDialog({
                            title : 'ダウンロード完了',
                            content : 'ダウンロードが完了しました。'
                        });
                        this.infoDialog.show();

                    }), lang.hitch(this, function(error) {
                        console.error(error);
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : 'エラーが発生しました。管理者にお問い合わせください。'
                        });
                        this.infoDialog.show();
                    }));

                    Loader.wait(promise);

                }));
            } catch (e) {
                console.error(e);
            }
        },

        // 選択したコードがregionCdなのかmunicipalityCdなのか確かめる。
        // regionCdの場合はtrueが帰る
        isRegion: function(cd){
            return Region.store.get(cd).then(lang.hitch(this, function(region){
                return region ? true : false;
            }));
        },

            // 帳票のダウンロード
        download: function(data) {
            var deferred = new Deferred();
            var xhr = new XMLHttpRequest();
            xhr.open('POST', '/api/xoblos/download', true);
            xhr.responseType = 'arraybuffer';
            xhr.setRequestHeader( 'Content-Type', 'application/json' );
            xhr.onload = function() {

                // エラー時は処理を止める
                if(xhr.status !== 200){
                    deferred.reject('status error:'+ xhr.status);
                    return;
                }
                // ファイル名をレスポンスヘッダーから取り出す
                var contentDisposition = this.getResponseHeader('content-disposition');
                var fileName = contentDisposition.replace(/^.*"(.*)"$/, '$1');

                var arrayBuffer = this.response;
                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
                var baseFileNameJp = '';
                switch (fileName) {
                    case 'FacilityList.xlsx':
                        baseFileNameJp = '施設情報一覧.xlsx';
                        break;
                    case 'FacilityListCaoStyle.xlsx':
                        baseFileNameJp = '施設情報一覧(内閣府様式).xlsx';
                        break;
                }
                fileName = baseFileNameJp.replace(/^.*"(.*)"$/, '$1');

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    // 擬似的にAタグを作成
                    var link = document.createElement('a');
                    link.style = 'display: none';
                    document.body.appendChild(link);

                    // AタグのURLにバイナリデータをセット
                    var url = window.URL.createObjectURL(blob);
                    link.href = url;

                    // ファイル名をセット
                    link.download = fileName;

                    // 擬似的にリンクをクリック
                    link.click();
                    // 参照を解放
                    window.URL.revokeObjectURL(url);
                    link.remove();
                }
                deferred.resolve();
            };
            xhr.send(JSON.stringify(data));
            return deferred.promise;
        }
    });
});

