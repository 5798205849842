/**
 * 職員招集・参集状況管理画面用モジュール。
 *
 * @module app/convocation/view/ConvocationTopPage
 */
define(['module',
        'dojo/_base/declare',
        'dojo/_base/lang',
        'dojo/text!./templates/ConvocationTopPage.html',
        'dojo/dom-style',
        'idis/view/dialog/DialogChain',
        'idis/view/page/_PageBase',
        // 以下、変数で受けないモジュール
        'dijit/layout/BorderContainer',
        'dijit/layout/ContentPane',
        'dijit/layout/TabContainer',
        'idis/view/form/AclButton',
        'app/convocation/view/mail/ConvocationMailAdminPage',
        'app/convocation/view/employee/ConvocationEmployeeAdminPage',
        'app/convocation/view/group/ConvocationGroupAdminPage',
        'app/convocation/view/condition/ConvocationAutoCondAdminPage'],
    function(module, declare, lang, template, domStyle, DialogChain, _PageBase) {
        /**
         * 参集状況管理画面
         *
         * @class ConvocationTopPage
         * @extends module:idis/view/page/_PageBase~_PageBase
         */
        return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/convocation/view/ConvocationTopPage~ConvocationTopPage# */
        {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',
            
            /**
             * 変数の初期化。
             */
            constructor: function() {
                this.chain = DialogChain.get(this);
                // 招集抑止ステータスの設定
                //TODO テーブル参照の結果を設定する
                this.isSuppressed = '1';
            },
            
            /**
             * DOMノードの生成。
             */
            buildRendering: function() {
                // 基底モジュールの処理を実施
                this.inherited(arguments);
                // 画面破棄時に各タブも破棄する
                this.own(this.mailAdminPage);
                this.own(this.employeeAdminPage);
                this.own(this.groupAdminPage);
                this.own(this.conditionAdminPage);
                
                this.initSuppressItems();
            },
            
            /**
             * 招集抑止ステータスを画面上部のフィールドに反映する。
             */
            initSuppressItems :function() {
                // 招集抑止ステータスが'1'の場合は抑止中とみなす
                if (this.isSuppressed === '1') {
                    this.suppressField.innerHTML = '抑止中';
                    domStyle.set(this.suppressField, {
                        'background-color': '#CCFFFF',
                        'display': 'inline-block'
                    });
                    this.suppressButton.setLabel('参集抑止解除');
                } else {
                    // 招集抑止ステータスが'1'でなければ抑止なしとみなし、フィールドを非表示
                    domStyle.set(this.suppressField, 'display', 'none');
                    this.suppressButton.setLabel('参集抑止');
                }
            },
            
            /**
             * 抑止ボタンが押下された際の挙動。
             */
            onSuppressButton: function() {
                // 確認ダイアログに出力するメッセージ
                var message = '地震による参集メールの配信を停止します。<br>' + 'よろしいですか？';
                // 抑止されていない場合はメッセージを変更
                if (this.isSuppressed !== '1') {
                    message = '地震による参集メールの配信を抑止します。<br>' + 'よろしいですか？';
                }
                this.chain.confirm(message, lang.hitch(this, function(chain) {
                    // 招集抑止ステータスの変更
                    this.isSuppressed = !this.isSuppressed ? '1' : '';
                    // 画面上部のフィールドへ変更内容を反映
                    this.initSuppressItems();
                    // 確認ダイアログを閉じる
                    chain.hide();
                }));
            }
        });
    });
