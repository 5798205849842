/**
 * 避難発令テンプレート詳細ダイアログ
 * @module app/evacorder/EvacOrderTemplateDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/text!./templates/EvacOrderTemplateDetailDialog.html',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    '../config',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'idis/view/form/Button',
    'dijit/form/Select',
    'app/view/form/CustomizableMunicipalitySelector',
    'dijit/form/ValidationTextBox',
    'idis/view/form/WordCountTextarea'
], function(module, declare, lang, json, template, Requester, DialogChain, InfoDialog, _PageBase, Loader,
            UserInfo, USER_TYPE, config) {
    /**
     * 避難発令テンプレート詳細ダイアログ
     * @class EvacOrderTemplateDetailDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
                   /** @lends module:app/view/page/EvacOrderTemplateDetailDialog~EvacOrderTemplateDetailDialog# */ {
        // テンプレート文字列
        templateString: template,

        baseClass: 'idis-Page idis-Page--evacorder',

        _fileId: null,

        infoDialog : null,

        templateId: null,

        _municipalityCd: null,


        constructor: function() {
            // ベースクラスのコンストラクタを呼ぶ
            this.inherited(arguments);
            this.chain = DialogChain.get(this);
        },

        buildRendering: function() {
            this.inherited(arguments);
        },

        resize: function(changeSize, resultSize) {
            // ウィジェット内最上位要素のresizeを呼ぶことで、ダイアログ内のサイズ調整を行う。
            // このウィジェットはダイアログ内最上位のウィジェットであるため、
            // ダイアログを開く際にresizeメソッドが無いとサイズ調整が行われない
            this.form.resize(changeSize, resultSize);
        },

        /**
         * ダイアログ内容を初期化する。
         */
        initContent: function(item) {
            this.form.reset();

            this.templateId = item.evacOrderTemplateId;
            this._municipalityCd = item.municipalityCd;
            this.form.set('value', item);

            // ダイアログ内の市町セレクターをセットを行う
            // 市町ユーザーの場合(政令指定都市を除く)編集不可
            if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
                UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
                this.municipalitySelectorNode.set('noAllButton', true);
            }

        },

        onUpdate: function(){
            var data = this.form.get('value');
            if (!data.municipalityCd) {
                InfoDialog.show('入力エラー', '市町を入力してください。');
                return false;
            }
            // フォームのバリデーションを実施
            if (!this.form.validate()) {
                return false;
            }
            this.chain.confirm('更新します。よろしいですか？', lang.hitch(this, function(chain) {
                var jsonStr = data;
                jsonStr.evacOrderTemplateId = this.templateId;
                var promise = Requester.put('/api/evacorders/template/' + this.templateId, {
                    data: jsonStr
                }).then(lang.hitch(this, function() {
                    chain.infoComplete(lang.hitch(this, function() {
                        this.chain.hide();
                        this.getParent().hide();
                        this.emit('complete', {
                            value: this.form.get('value')
                        });
                    }));
                }), lang.hitch(this, function(error) {
                    if (error.response.data && typeof (error.response.data) === 'string') {
                        error.response.data = json.parse(error.response.data);
                    }
                    this.chain.infoError(error);
                }));
                //ローダーの表示
                Loader.wait(promise);
            }));
        },

        onDelete: function(){
            this.chain.confirmDel(lang.hitch(this, function(chain) {
                var promise = Requester.del('/api/evacorders/template/' + this.templateId, {
                }).then(lang.hitch(this, function() {
                    chain.infoComplete(lang.hitch(this, function() {
                        this.chain.hide();
                        this.getParent().hide();
                        this.emit('complete', {
                            value: this.form.get('value')
                        });
                    }));
                }), lang.hitch(this, function(error) {
                    if (error.response.data && typeof (error.response.data) === 'string') {
                        error.response.data = json.parse(error.response.data);
                    }
                    chain.infoError(error);
                }));
                //ローダーの表示
                Loader.wait(promise);
            }));
        },
    });
});