/**
 * 職員招集・グループ一覧画面用モジュール。
 *
 * @module app/convocation/view/group/ConvocationGroupAdminPage
 */
define(['module',
        'dojo/_base/declare',
        'dojo/_base/lang',
        'dijit/form/Select',
        'idis/control/Router',
        'idis/service/Requester',
        'dojo/text!./templates/ConvocationGroupAdminPage.html',
        'idis/store/IdisRest',
        'app/convocation/_ConvocationPageBase',
        'idis/model/UserInfo',
        // 以下、変数で受けないモジュール
        'dijit/Dialog',
        'dijit/form/Form',
        'dijit/form/TextBox',
        'dijit/layout/BorderContainer',
        'dijit/layout/ContentPane',
        'idis/view/form/AclButton',
        'app/convocation/view/group/ConvoGroupListGrid'],
    function(module, declare, lang, Select, Router, Requester,
        template, IdisRest, _PageBase, UserInfo) {
        /**
         * グループ一覧画面
         *
         * @class ConvocationGroupAdminPage
         * @extends module:idis/view/page/_PageBase~_PageBase
         */
        return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/convocation/view/group/ConvocationGroupAdminPage~ConvocationGroupAdminPage# */
        {
            // テンプレート文字列
            templateString: template,

            store: null,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',

            constructor: function() {
                // データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'groupCd',
                    target: '/api/convoMails/groups'
                });
            },

            /**
             * DOMノードを生成するためのメソッド
             */
            buildRendering: function() {
                this.inherited(arguments);
                this.initConvoGroupListGrid();
            },

            startup: function() {
                this.inherited(arguments);
                this.setButtonStatus();
            },

            /**
             * グループ一覧画面内のグリッドを初期化する
             */
            initConvoGroupListGrid: function() {
                // 初期表示時の検索条件を設定する
                var filter = new this.store.Filter();
                var divisionCd = UserInfo.getDivisionCd();
                if (!this.isAdminUser()) {
                    filter = filter.eq('dpDivisionCd', divisionCd);
                }
                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);
                // gridに表示するデータを持つstoreはconstructorで定義済み
                this.convoGroupListGrid.set('collection', collection);
                 //グリッドの詳細ボタンクリック時の動作を設定する
                 //helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.convoGroupListGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                    //helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                    this.moveConvoGroupDetailPage(evt.item);
                }));
            },

            /**
             * グループ新規作成画面へ遷移する。
             */
            moveConvoGroupRegisterPage: function(){
                Router.moveTo('convocation/groupRegister');
            },

            /**
             * グループ詳細画面へ遷移する
             */
            moveConvoGroupDetailPage: function(item){
                Router.moveTo('convocation/groupDetail', {
                    id: item.groupCd
                });
            },

            /**
             * 検索ボタンが押下された際の挙動。
             */
            onSubmit: function() {
                var value = this.form.get('value');
                this.updateGroupGridQuery(value);
                return false;
            },

            /**
             * グループ一覧画面用の検索
             * @param value groupFormの情報
             */
            updateGroupGridQuery: function(value) {
                // 入力値を元にグリッド用フィルターを作成
                var groupFilter = new this.store.Filter();
                if (value.groupName) {
                    groupFilter = groupFilter.eq('groupName', value.groupName);
                }
                var divisionCd = UserInfo.getDivisionCd();
                if (!this.isAdminUser()) {
                    groupFilter = groupFilter.eq('dpDivisionCd', divisionCd);
                }
                // filterに対応するcollectionを取得
                var collection = this.store.filter(groupFilter);
                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.convoGroupListGrid.set('collection', collection);
            },

            /**
             * ウィンドウの幅に合わせてBorderContainerをリサイズし、タブ内のレイアウトを整理する。
             * (startup同様、画面描画時に呼ばれる)
             * @param changeSize
             * @param resultSize
             */
            resize: function(changeSize, resultSize) {
                // 中のContentPaneも追随してリサイズするため、レイアウト崩れを防止できる。
                this.borderContainer.resize(changeSize, resultSize);
            },

            setButtonStatus: function(){
                this.moveConvoGroupRegisterPageButton.setDisabled(true);
                if (this.isAdminUser()) {
                    this.moveConvoGroupRegisterPageButton.setDisabled(false);
                }
            }
        });
    });
