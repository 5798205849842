/**
* 定時報告一覧画面用モジュール。
* @module app/damage/ScheduledReportPage
*/
define([
    'module',
    'dojo',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/json',
    'dojo/dom-style',
    'dojo/dom-class',
    'dojo/text!./templates/ScheduledReportPage.html',
    'dojo/request/iframe',
    'dstore/Memory',
    'dstore/Rest',
    'dijit/registry',
    'idis/view/grid/IdisGrid',
    './_DamageReportPageBase',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'app/model/DisasterInfo',
    'idis/consts/ACL',
    'idis/view/form/AclButton',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'app/config',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/RadioButton',
    'dojox/form/Uploader',
    '../view/form/DisasterSelector',
    '../view/form/OrganizationSelector',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/RadioGroup',
    './ScheduledReportMuniGrid',
    './ScheduledReportDeptGrid',
    './ScheduledReportRequestDialog',
    'app/view/form/MunicipalitySelector',
    'app/damage/DamageMunicipalitySelector',
    'app/view/form/CustomizableMunicipalitySelector',
    'app/damage/ScheduledReportFormDialog'
], function(module, dojo, declare, lang, array, json, domStyle, domClass, template, iframe, Memory, Rest, registry,
    IdisGrid, _DamageReportPageBase, Locator, Router, Requester, IdisRest, UserInfo, UserType, DisasterInfo, ACL,
    AclButton, Loader, DialogChain, config) {

    /**
    * 検索条件格納用オブジェクト
    * @type {Object}
    */
    var reportFilterStore = {};

    /**
    * 定時報告一覧画面。
    * @class ScheduledReportPage
    * @extends module:idis/view/page/_PageBase~_PageBase
    */
    return declare(module.id.replace(/\//g, '.'), _DamageReportPageBase,
        /** @lends module:app/damage/DamageReportPage~DamageReportPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--scheduledreport',

        reportType: null,

        isMunicFlg: false,


        shownGrid: null,

        selectedGrid: null,

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            this.municipalityCd.set('clear', true);
            console.log(this.municipalityCd);

            this.own(this.scheduledReportRequestDialog);
            this.own(this.formDialog);

            this.initFormDialog();
        },

        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'scheduledReportId',
                target: '/api/scheduledReports',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            this.chain = DialogChain.get(this);

        },

        startup: function() {
            this.inherited(arguments);

            // 定時報告依頼ダイアログにイベントリスナーを設定
            var requestPage = this.innerDialog;
            requestPage.on('send', lang.hitch(this, function(evt) {
                this.requestScheduledReport(evt);
            }));

            // 定時報告メニューを初期化する
            this.initMenu();
            // グリッドを初期化する
            this.initGrid();
        },

        /**
         * 被害情報出力ダイアログ初期化処理
         */
        initFormDialog: function() {
            var options = [];

            // 定時報告一覧（区市町村）画面で出力できる帳票を追加
            options.push(
                {value: '8', label: '消防庁様式（その１）'},
                {value: '2', label: '消防庁4号様式（その２）'},
                {value: '5', label: '災害報告取扱要領（様式３）'},
                {value: '9', label: 'プレス資料様式'}
            );

            // 詳細ダイアログの最初の子要素が詳細画面
            var dialog = this.formDialog;
            var page = dialog.getChildren()[0];
            page.initOptions(options);
        },

        initMenu: function() {
            // メニューの出し分けをしたい。
            //デフォルトのreportTypeを決定する

            if(UserInfo.getUserType() === UserType.MUNICIPALITY){
                //市町ユーザならばタブ・市町セレクタを操作できないようにする
                this.reportType = '01';
                this.isMunicFlg = true;
                this.municipalityCd.set('value', UserInfo.getMunicipalityCd());
                if(UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
                    //政令指定都市以外はセレクタ固定
                    this.municipalityCd.set('noAllButton', true);
                }else{
                    //政令指定都市は選択を外せない
                    domStyle.set(this.municipalityCd.clearButton.domNode, 'display', 'none');
                }
                domStyle.set(this.allReportLabel, 'display','none');
                domStyle.set(this.allReportCheck, 'display','none');
                domStyle.set(this.scheduledReportPrefLabel, 'display', 'none');
            }else{
                //関係機関ユーザの場合、「集計」リンクを非表示
                if(UserInfo.getUserType() === UserType.OTHER_ORGAN) {
                    domStyle.set(this.scheduledReportPrefLabel, 'display', 'none');
                }
                //市町ユーザでないならばデフォルトのタブだけ設定する
                var towerCd = UserInfo.getTowerCd();
                var towerToReportTypeMap =
                    {   '01' : '01',
                        '02' : '02',
                        '03' : '03',
                        '04' : '04',
                        '05' : '05',
                        '06' : '06',
                        '07' : '07',
                        '08' : '08',
                        '09' : '09'
                    };
                if(towerToReportTypeMap[towerCd]){
                    this.reportType = towerToReportTypeMap[towerCd];
                }else{
                    //デフォルトの報告種別は01とする
                    this.reportType = '01';
                }
            }

        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.ScheduledReportMuniGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.onDetailButtonClick(evt.item);
            }));

            this.ScheduledReportDeptGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.onDetailButtonClick(evt.item);
            }));

            //デフォルトgridを設定
            if(this.reportType === '01'){
                this.selectedGrid = this.ScheduledReportMuniGrid;
            }else{
                this.selectedGrid = this.ScheduledReportDeptGrid;
            }

            // 市町村ユーザーの場合(政令指定都市を除く)概況リンクを不可視
            // if(UserInfo.getUserType() === UserType.MUNICIPALITY &&
            //     UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
            //     domStyle.set(this.overviewLabel, 'display', 'none');
            // }

            // 保管した検索条件をセットする
            this.setFilterData();

            this.shownGrid = this.selectedGrid;
            this.changeGrid();

            //this.updateGridQuery(this.form.get('value'));
        },

        /**
         * 検索
         */
        onSubmit: function() {
            try {
                if (this.form.validate()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery(this.form.get('value'));
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

            /**
             * 検索パラメーターの設定
             */
            updateGridQuery: function(value) {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();

            // 災害ID
            filter = filter.eq('disasterId', this._disasterId);
            filter = filter.eq('reportType', this.reportType);

            // 市町コード
            if (this.reportType === '01' && this.municipalityCd.value) {
                filter = filter.eq('municipalityCd', this.municipalityCd.value);
            }

            // 集計時点FROM
            if (value.aggrDateTimeFrom) {
                var aggrDateTimeFrom = new Date(value.aggrDateTimeFrom).getTime();
                filter = filter.eq('aggrDateTimeFrom', aggrDateTimeFrom);
            }

            // 集計時点TO
            if (value.aggrDateTimeTo) {
                var aggrDateTimeTo = new Date(value.aggrDateTimeTo).getTime();
                filter = filter.eq('aggrDateTimeTo', aggrDateTimeTo);
            }

            // 最新報のみフラグ
            // if (value.latestReport.length > 0) {
            //     filter = filter.eq('latestReportFlg', '1');
            // }

            // 未報告のみフラグ
            // if (value.requestingReport.length > 0) {
            //     filter = filter.eq('requestingReportFlg', ' 1');
            // }

            // 全件表示フラグ
            if (value.allReport.length > 0 || UserInfo.getUserType() === UserType.MUNICIPALITY) {
                filter = filter.eq('allReportFlg', '1');
            }


            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.selectedGrid.set('collection', collection);
            domStyle.set(this.selectedGrid.domNode, 'display','block');
            this.shownGrid = this.selectedGrid;

            this.borderContainer.resize();

        },

        /**
         * 定時報告新規登録画面を表示
         */
        showScheduledReportPage: function() {
            //検索条件の保管
            this.setFilterStore();

            //新規報告画面に移動
            Router.moveTo('report/sche/register', {municipalityCd: this.municipalityCd.value});
        },

        showMuniGrid: function() {
            if(!this.isMunicFlg && this.reportType !== '01'){
                this.reportType = '01';
                this.selectedGrid = this.ScheduledReportMuniGrid;
                this.changeGrid();
            }
        },

        showHealthGrid: function() {
            if(!this.isMunicFlg && this.reportType !== '02'){
                this.reportType = '02';
                this.selectedGrid = this.ScheduledReportDeptGrid;
                this.changeGrid();
            }
        },

        showEnvGrid: function() {
            if(!this.isMunicFlg && this.reportType !== '03'){
                this.reportType = '03';
                this.selectedGrid = this.ScheduledReportDeptGrid;
                this.changeGrid();
            }
        },

        showBizGrid: function() {
            if(!this.isMunicFlg && this.reportType !== '04'){
                this.reportType = '04';
                this.selectedGrid = this.ScheduledReportDeptGrid;
                this.changeGrid();
            }
        },

        showAgriGrid: function() {
            if(!this.isMunicFlg && this.reportType !== '05'){
                this.reportType = '05';
                this.selectedGrid = this.ScheduledReportDeptGrid;
                this.changeGrid();
            }
        },

        showCivilGrid: function() {
            if(!this.isMunicFlg && this.reportType !== '06'){
                this.reportType = '06';
                this.selectedGrid = this.ScheduledReportDeptGrid;
                this.changeGrid();
            }
        },

        showCompGrid: function() {
            if(!this.isMunicFlg && this.reportType !== '07'){
                this.reportType = '07';
                this.selectedGrid = this.ScheduledReportDeptGrid;
                this.changeGrid();
            }
        },

        showEducGrid: function() {
            if(!this.isMunicFlg && this.reportType !== '08'){
                this.reportType = '08';
                this.selectedGrid = this.ScheduledReportDeptGrid;
                this.changeGrid();
            }
        },

        showOtherGrid: function() {
            if(!this.isMunicFlg && this.reportType !== '09'){
                this.reportType = '09';
                this.selectedGrid = this.ScheduledReportDeptGrid;
                this.changeGrid();
            }
        },

        changeGrid: function() {
            //メニューのクラス名を付け替え
            var gridOptionNodes = this.gridOptions.childNodes;
            array.forEach(gridOptionNodes, function(gridOptionNode){
                domClass.remove(gridOptionNode, 'selectedGrid');
            });
            var selectedGrid = dojo.byId('gridOption' + this.reportType);
            domClass.add(selectedGrid, 'selectedGrid');


            //市町村セレクタの表示切り替え
            if(this.reportType === '01'){
                domStyle.set(this.municipalityForm, 'display','');
            }else{
                domStyle.set(this.municipalityForm, 'display','none');
            }

            //すでに表示されているshownGridを消去の上、新たなselectedGridで検索実行
            if(this.shownGrid !== this.selectedGrid){
                domStyle.set(this.shownGrid.domNode, 'display','none');
            }
            this.onSubmit();
        },

        //定時報告依頼ダイアログを表示する
        showScheduledReportRequestDialog: function() {
            this.innerDialog.initDialog();
            this.scheduledReportRequestDialog.show();
        },

        //定時報告を依頼する
        requestScheduledReport: function(evt) {
            var reportTypeList = [];
            var municipalityCdList = [];
            array.forEach(evt.checkedLeafs, function(leaf){
                if(leaf.reportType && leaf.reportType !== '01'){
                    reportTypeList.push(leaf.reportType);
                }
                //政令指定都市は葉ではないので詰めない
                if(leaf.municipalityCd && leaf.municipalityCd !== config.municInfo.cityMunicCd){
                    municipalityCdList.push(leaf.municipalityCd);
                }
            });

            // 県を依頼先に必ず含むようにする
            municipalityCdList.push(config.municInfo.prefMunicCd);

            if(municipalityCdList){
                reportTypeList.push('01');
            }

            var sendData = {
                disasterId: this._disasterId,
                aggrTimestamp: evt.aggrTimestamp,
                reportTypes: reportTypeList,
                municipalityCds: municipalityCdList
            };

            var jsonStr = json.stringify(sendData);
            var requestDialog = this.scheduledReportRequestDialog;
            this.chain.confirm('定時報告を依頼します。よろしいですか？', function(chain) {
                var promise = Requester.post('/api/scheduledReports/request', {
                    data: jsonStr,
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function() {
                    console.debug('success request ScheduledReport');
                    chain.infoComplete();
                    requestDialog.hide();
                    //グリッドを更新する
                    this.onSubmit();
                }), function(error) {
                    console.log('error request ScheduledReport');
                    chain.infoError(error);
                    requestDialog.hide();
                });
                //ローダーの表示
                Loader.wait(promise);
            });
        },

        /**
         * 検索条件を保管する
         */
        setFilterStore: function() {
            //初期化する
            reportFilterStore = {};

            if(this.reportType){
                reportFilterStore.reportType = this.reportType;
            }

            var value = this.form.get('value');

            // 市町
            if (value.municipalityCd) {
                reportFilterStore.municipalityCd = value.municipalityCd;
            }

            // 集計時点FROM
            if (value.aggrDateTimeFrom) {
                reportFilterStore.aggrDateTimeFrom = value.aggrDateTimeFrom;
            }

            // 集計時点TO
            if (value.aggrDateTimeTo) {
                reportFilterStore.aggrDateTimeTo = value.aggrDateTimeTo;
            }

            // 最新報のみ表示
            // if (value.latestReport.length > 0) {
            //     reportFilterStore.latestReport = 'checked';
            // }

            // 未報告のみ表示
            // if (value.requestingReport.length > 0) {
            //     reportFilterStore.requestingReport = 'checked';
            // }

            // 全件表示フラグ
            if (value.allReport.length > 0) {
                reportFilterStore.allReport = 'checked';
            }
        },

        /**
         * 保管した検索条件をフォームにセットする
         */
        setFilterData: function() {
            //保管されていれば値をセット

            // 市町
            if(reportFilterStore.municipalityCd) {
                this.municipalityCd.set('value', reportFilterStore.municipalityCd);
            }

            // 報告日時FROM
            if(reportFilterStore.aggrDateTimeFrom) {
                this.aggrDateTimeFrom.set('value', reportFilterStore.aggrDateTimeFrom);
            }

            // 報告日時TO
            if(reportFilterStore.aggrDateTimeTo) {
                this.aggrDateTimeTo.set('value', reportFilterStore.aggrDateTimeTo);
            }

            // 最新報のみ表示
            // if(reportFilterStore.latestReport) {
            //     this.latestReport.set('checked', 'checked');
            // }

            // 未報告のみ表示
            // if(reportFilterStore.requestingReport) {
            //     this.requestingReport.set('checked', 'checked');
            // }

            // 全件表示フラグ
            if(reportFilterStore.allReport) {
                this.allReport.set('checked', 'checked');
            }

            // 報告種別
            if(reportFilterStore.reportType) {
                this.reportType = reportFilterStore.reportType;
                if(this.reportType === '01'){
                    this.selectedGrid = this.ScheduledReportMuniGrid;
                }else{
                    this.selectedGrid = this.ScheduledReportDeptGrid;
                }
            }
        },

        /**
         * 詳細画面を表示
         */
        onDetailButtonClick: function(object) {
            //検索条件の保管
            this.setFilterStore();

            // 被害状況・報告詳細画面へ遷移
            Router.moveTo('report/sche/detail',{
                scheduledReportId: object.scheduledReportId
            });
        },
    });
});
