/**
 * 施設種別登録ダイアログ用モジュール。
 * @module app/facility/FacilityTypeDetailDialog
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/on',
    'dojo/text!./templates/FacilityTypeDetailDialog.html',
    'idis/consts/ACL',
    'idis/model/UserInfo',
    'app/facility/_FacilityTypeBase',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/consts/ACL',
    'idis/view/form/AclButton',
    'dijit/layout/BorderContainer',
    'app/sample/view/SampleGrid',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/form/Form',
    'idis/view/form/DateTimeInput',
    'dijit/layout/AccordionContainer',
    'dijit/TitlePane',
    'dijit/form/Textarea',
    'app/view/form/ShelterSelector',
    'dijit/TitlePane',
    '../draw/DrawSelectIconDialog'
], function(module, array, declare, lang, domStyle, on, template, ACL, UserInfo, _FacilityTypeBase) {

    /**
     * 施設種別詳細ダイアログ
     * @class FacilityTypeDetailDialog
     * @extends module:app/facility/_FacilityTypeBase~_FacilityTypeBase
     */
    return declare(module.id.replace(/\//g, '.'), _FacilityTypeBase,
                   /** @lends module:app/facility/FacilityTypeDetailDialog~FacilityTypeDetailDialog# */ {
        // テンプレート文字列
        templateString: template,

        //更新・削除の対象外にする施設種別の施設種別ID
/*
        DEFAULT_FACILITY_TYPE: ['01', '02', '03'],
*/
        DEFAULT_FACILITY_TYPE: ['01', '02', '03', '04', '05',
                                '06', '07', '08', '09', '10',
                                '11', '12', '13', '14', '15',
                                '16', '17'],

        setPage: function(object){
            //サーバーから取得したアイコンURLを設定
            //プロトタイプでは、アイコンは取得できないのでif文で飛ばす
            if(object && object.iconUrl){
              this.selectedIcon.src = this._defaultIconPath + object.iconUrl;
              this._iconUrl = object.iconUrl;
            }else{
              this.selectedIcon.src = this._defaultIconPath +  '080.png';
              this._iconUrl = '080.png';
            }

            //FIXME
            var index = array.indexOf(this.DEFAULT_FACILITY_TYPE, object.facilityTypeId);
            // var index = -1;

            //もともと存在する施設種別である場合、更新・削除ボタンを無効化する
            if(index !== -1){
                this.submitButton.set('disabled',true);
                this.deleteFacilityTypeButton.set('disabled',true);
            } else {
                this.submitButton.set('disabled',false);
                this.deleteFacilityTypeButton.set('disabled',false);
            }

            // ユーザー権限が「部局ユーザ」「閲覧ユーザ」の場合は更新・削除ボタンを非活性に
            var role = UserInfo.getRoleCd();
        	if (role === ACL.DEPT_USER || role === ACL.VIEW_USER) {
                this.submitButton.set('disabled',true);
                this.deleteFacilityTypeButton.set('disabled',true);
        	}


            this.facilityTypeIcon.set('value',this._iconUrl);
            this.onChangeIcon();
        },

        onSubmit: function() {
            console.log('[更新]ボタンがクリックされました。');
            try{
                if(this.form.validate()) {
                    var value = this.form.get('value');
                    if (this._iconUrl) {
                        value.iconUrl = this._iconUrl;
                    } else {
                        // 選択されていない場合デフォルトのアイコンURLをセットする
                        value.iconUrl = '080.png';
                    }

                    delete value.facilityTypeIcon;
                    this.emit('sendupdate',{value : value});
                }
                return false;
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        deleteFacilityTypeButtonClick: function() {
            console.log('[削除]ボタンがクリックされました。');
            var value = this.form.get('value');
            this.emit('senddelete', {value: value});
            return false;
        }
    });
});
