define({
    governmentAgency: {
        details: [
            {
                label:'北海道',
                value:'01'
            },
            {
                label:'市町村',
                value:'02'
            },
            {
                label:'指定地方行政機関',
                value:'03'
            },
            {
                label:'保健所',
                value:'04'
            }
       ]
    },
    publicInstitution: {
        details: [
            {
                label:'指定公共機関',
                value:'01'
            },
            {
                label:'防災関係機関',
                value:'02'
            }
       ]
    },
    medicalInstitution: {
        details: [
            {
                label:'災害拠点病院',
                value:'01'
            },
            {
                label:'救急告示医療機関',
                value:'02'
            },
            {
                label:'感染症指定医療機関',
                value:'03'
            },
            {
                label:'医師会・歯科医師会',
                value:'04'
            }
       ]
    },
    preschool: {
        details: [
            {
                label:'保育所',
                value:'01'
            },
            {
                label:'幼稚園',
                value:'02'
            }
       ]
    },
    school: {
        details: [
            {
                label:'小学校',
                value:'01'
            },
            {
                label:'中学校',
                value:'02'
            },
            {
                label:'高等学校',
                value:'03'
            },
            {
                label:'特別支援学校',
                value:'04'
            }
       ]
    },
    nursingHome: {
        details: [
            {
                label:'特別養護老人ホーム',
                value:'01'
            },
            {
                label:'養護老人ホーム',
                value:'02'
            },
            {
                label:'経費老人ホーム',
                value:'03'
            }
       ]
    },
    lifeLine: {
        details: [
            {
                label:'水道',
                value:'01'
            },
            {
                label:'下水道',
                value:'02'
            },
            {
                label:'電気ガス',
                value:'03'
            },
            {
                label:'中核SS',
                value:'04'
            }
       ]
    },
    heliport: {
        details: [
            {
                label:'開発局・警察・航空室',
                value:'01'
            },
            {
                label:'病院',
                value:'02'
            }
       ]
    }
});
