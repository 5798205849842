/**
* 被害状況詳細画面用モジュール。
* @module app/damage/DamageReportDetailPage
*/
define([
    'module',
    'app/model/LayerStore',
    'dojo/_base/declare',
    'dojo/_base/array',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/data/ItemFileWriteStore',
    'dojo/dom',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-geometry',
    'dojo/dom-style',
    'dojo/json',
    'dojo/on',
    'dojo/query',
    'dojo/request/iframe',
    'dojo/text!./templates/DamageReportDetailPage.html',
    'dojo/topic',
    'dojo/window',
    'dstore/Rest',
    'dstore/Memory',
    'dstore/Trackable',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'dijit/registry',
    'idis/consts/ACL',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/map/IdisMap',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/IdisDialog',
    'idis/view/draw/_DrawUtil',
    'idis/view/Loader',
    'idis/store/IdisRest',
    './_DamageReportRegisterPageBase',
    '../config',
    'leaflet',
    'app/damage/consts/DamageType',
    'idis/view/dialog/InfoDialog',
    'dojox/lang/functional/array',
    'idis/consts/USER_TYPE',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/RadioButton',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dijit/form/SimpleTextarea',
    'dijit/form/ValidationTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/TabContainer',
    'dijit/TitlePane',
    'dijit/layout/ContentPane',
    'dojox/form/Uploader',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/RadioGroup',
    'idis/view/form/WordCountTextarea',
    '../view/form/LayerDirectorySelector',
    '../view/form/DisasterSelector',
    '../view/form/OrganizationSelector',
    'app/view/form/CustomizableMunicipalitySelector',
    'app/view/form/HldOrganizationMultiSelector',
    'app/damage/DamageMunicipalitySelector',
    './DamageReportActionGrid',
    './DamageReportUpdateInfoDialog',
    './DamageReportUpdateStatusDialog',
    './DamageReportDeleteDialog',
    './DamageReportActionForm'
], function(module, LayerStore, declare, array, lang, Deferred, JSON, ItemFileWriteStore, dom, domClass,
    domConstruct, domGeometry, domStyle, json, on, query, iframe, template, topic, winUtils, Rest, Memory,
    Trackable, Menu, MenuItem, popup, TooltipDialog, registry, ACL, Locator, Router, IdisMap, UserInfo,
    Requester, DialogChain, IdisDialog, DrawUtil, Loader, IdisRest, _DamageReportRegisterPageBase, config,
    leaflet, DamageType, InfoDialog, df, USER_TYPE) {
        /**
        * 被害状況詳細画面。
        * @class DamageReportDetailPage
        * @extends module:idis/view/page/_DamageReportPageBase~_DamageReportPageBase
        */
        return declare(module.id.replace(/\//g, '.'), _DamageReportRegisterPageBase,
        /** @lends module:app/damage/DamageReportDetailPage~DamageReportDetailPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--damage',

        // 続報番号セレクトボックス初期化フラグ
        //initSeqNumSelectFlg: true,

        // 初回表示時
        //firstFlg: true,

        // 続報番号一覧
        seqNumStore: null,

        // 対応情報のストア
        chronologyStore: null,
        actionStore: null,

        //被害基本情報を保持
        damageBasicInfo: {
            admNum: null,
            humanDamageFlg: null,
            notifierCategory: null,
            notifierName: null,
            notifierContact: null,
            damageTimestamp: null,
            hldOrganization: null,
            urgencyType: null,
            damageType: null,
            content: null
        },

        /**
         * 親災害ID
         */
        _parentDamageId: null,

        PARENT_DAMAGE_SELECT: 'app/damage/ParentDamageSelector::selected',
        PARENT_DAMAGE_RELEASE: 'app/damage/ParentDamageSelector::released',

        //過去報と違う項目のリスト
        _updatedPropertiesList: [],

        //報告状況
        _reportStatus: null,

        /**
         * @type {Object}
         */
        _timer: null,

        //対応情報登録のためにユーザの組織名を保持
        organizationCd: null,
        organizationName: null,

        changedDamageType: null,

        // 災害シミュレーション用
        _simulateFlg: false,
        _scenarioId: null,
        _eventId: null,

        constructor: function() {
            this.chain = DialogChain.get(this);
            // URLから被害状況IDを取得
            this._damageReportId = Locator.getQuery().damageReportId;
            // データ格納用オブジェクト
            this.actionStore = new IdisRest({
                    idProperty: 'chronologyActionId',
                    target: '/api/chronologies/action'
            });

            // URLから災害シミュレーション用のパラメータを取得
            this._simulateFlg = Locator.getQuery().simulateFlg === 'true';
            this._scenarioId = Locator.getQuery().scenarioId;
            this._eventId = Locator.getQuery().eventId;
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.damageReportUpdateInfoDialog);
            this.own(this.damageReportUpdateStatusDialog);
            this.own(this.damageReportDeleteDialog);

        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);

            // 被害状況詳細画面を初期化
            this.initDetailPage(true);

            // 親管理番号選択ダイアログから親管理番号を受け取る。
            this.own(topic.subscribe(this.PARENT_DAMAGE_SELECT, lang.hitch(this, function(payload) {
                this._parentDamageId = payload;
            })));

            // 親管理番号選択ダイアログから親管理番号を削除する。
            this.own(topic.subscribe(this.PARENT_DAMAGE_RELEASE, lang.hitch(this, function() {
                this._parentDamageId = null;
            })));

            // 被害情報更新ダイアログにイベントリスナーを設定
            var infoDialog = this.damageReportUpdateInfoDialog;
            var infoPage = this.innerInfoDialog;
            infoPage.on('send', lang.hitch(this, function(evt) {
                var form = evt.form;
                // 人的被害が「なし」→「あり」に変更したことを判定する
                var prevHumanDamageFlg = this.damageBasicInfo.humanDamageFlg;
                var changedHumanDamageFlg = (prevHumanDamageFlg &&
                        prevHumanDamageFlg === '1' && form.humanDamageFlg === '0') ? true : false;

                // 被害種別が変更されたことを判定する
                var prevDamageType = this.damageBasicInfo.damageTypeList;
                this.changedDamageType = evt.form.damageTypeList;
                var changedDamageTypeFlg =
                    (this.changedDamageType && prevDamageType !== this.changedDamageType) ? true : false;

                //Dialogのフォーム内容をdamageBasicInfo(変数), HTMLの両方に反映
                lang.hitch(this, this.setDamageInfoForm(form));

                //被害種別が変更、または人的被害が「あり」に変更されていたら、詳細タブを開く
                if(changedDamageTypeFlg || changedHumanDamageFlg){
                    this.tabContainer.selectChild(this.tab2);
                }
                //被害種別が変更されていたら、詳細項目を変更する
                if(changedDamageTypeFlg){
                    this.changeDamageDetailContents(this.changedDamageType, this._updatedPropertiesList);
                    this._damageType = this.changedDamageType.split(',')[0];
                    this._damageTypeList = this.changedDamageType;
                }
                this.changedDamageType = null;
                infoDialog.hide();
            }));

            // 県のユーザ、4機関ユーザ以外の場合、概況リンクを不可視
            // if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
            //     UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
            // if(!(UserInfo.getUserType() === USER_TYPE.PREFECTURE ||
            //     UserInfo.is4OrgsRole())) {
            //     domStyle.set(this.overviewLabel, 'display', 'none');
            // }

//            infoPage.damageType.on('change', lang.hitch(this, function(value) {
//                //Dialogの被害種別が変更された場合、変更後のコード値を変数で管理
//                this.changedDamageType = value;
//            }));

            // 市町ユーザの場合、概況ページのリンクを非表示
            if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY){
                domStyle.set(this.overviewLabel, 'display', 'none');
            }
            //続報セレクタに変更イベントを設定
            this.seqNumSelect.on('change', lang.hitch(this, function(evt) {
                console.log();
                this.onSeqNumChange(evt);
            }));

            //削除対象選択ダイアログに変更イベントを追加
            var deletePage = this.innerDeleteDialog;
            deletePage.on('send', lang.hitch(this, function(value) {
                this.deleteDamageReport(value.deleteTarget);
            }));

            // tipsを付与
            this.setHldStatusTips();
            this.setContentTips();

            //対応履歴フォームのイベントリスナーをセット
            this.initDamageReportActionForm();

        },

        // 詳細画面の初期表示
        initDetailPage: function(isFirstTime) {
            //初期化
            this.pointLat = '';
            this.pointLng = '';
            if (typeof this.marker === 'undefined') {
                this.marker = '';
            }

            this.attachFileList = [];
            this.updatedPropertiesList = [];

            // プレビューを初期化
            this.clearPreview();

            //過去報に切り替える場合は、強調表示を無効化する
            if(!isFirstTime && this._updatedPropertiesList.length > 0){
                array.forEach(this._updatedPropertiesList, function(propName) {
                    // if (propName === 'damageName') {
                    //     query('.damageNameLabel').forEach(function(node) {
                    //         domClass.remove(node, 'is-changed');
                    //     });
                    // } else {
                    if(propName === 'notifierCategory' || propName === 'notifierName'){
                        propName = 'notifier';
                    }
                    if(propName === 'organization' || propName === 'reportAuthorName'){
                        propName = 'preAuthor';
                    }
                    if(propName === 'reportUpdTimestamp'){
                        propName = 'preReportTimestamp';
                    }
                    if(dom.byId(propName + 'Label')){
                        domClass.remove(dom.byId(propName + 'Label'), 'is-changed');
                    }
                    // }
                });
            }
            var self = this;
            if(this._simulateFlg){
                // 被害情報を取得
                Requester.get('/data/simulation/' + this._eventId + '.json')
                .then(function(item) {
                    var data = item.damageReportPostForm;
                    self.initDetailPageValue(data, isFirstTime);
                }, function(error) {
                    console.log(error);
                    self.chain.info('被害イベントの取得に失敗しました。', 'エラー');
                }).then(function(){
                    //前報から変更があった項目リストを強調表示する
                    if(self.updatedPropertiesList.length > 0){
                        array.forEach(self.updatedPropertiesList, function(propName) {
                            if(propName === 'notifierCategory' || propName === 'notifierName'){
                                propName = 'notifier';
                            }
                            if(propName === 'organization' || propName === 'reportAuthorName'){
                                propName = 'preAuthor';
                            }
                            if(propName === 'reportTimestamp'){
                                propName = 'preReportTimestamp';
                            }
                            if(dom.byId(propName + 'Label')){
                                domClass.add(dom.byId(propName + 'Label'), 'is-changed');
                            }
                            // }
                        });
                    }
                });
            } else {
                // 被害情報を取得
                Requester.get('/api/damageReports/' + this._damageReportId, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(function(data) {
                    self.initDetailPageValue(data, isFirstTime);
                }, function(error) {
                    console.log(error);
                    self.chain.info('情報の取得に失敗しました。', 'エラー');
                }).then(function(){
                    //前報から変更があった項目リストを強調表示する
                    if(self.updatedPropertiesList.length > 0){
                        array.forEach(self.updatedPropertiesList, function(propName) {
                            // if (propName === 'damageName') {
                            //   query('.damageNameLabel').forEach(function(node) {
                            //       domClass.add(node, 'is-changed');
                            //   });
                            // } else {
                            if(propName === 'notifierCategory' || propName === 'notifierName'){
                                propName = 'notifier';
                            }
                            if(propName === 'organization' || propName === 'reportAuthorName'){
                                propName = 'preAuthor';
                            }
                            if(propName === 'reportTimestamp'){
                                propName = 'preReportTimestamp';
                            }
                            if(dom.byId(propName + 'Label')){
                                domClass.add(dom.byId(propName + 'Label'), 'is-changed');
                            }
                            // }
                        });
                    }
                });
            }

        },

        // 詳細画面の初期値設定
        initDetailPageValue: function(item, isFirstTime) {
            var self = this;

            //報告者・報告日時は「最終報告者」「最終報告日時」に移し替える
            self.preAuthorOrg.innerHTML = item.organizationName || '&nbsp';
            self.preAuthorName.innerHTML = item.reportAuthorName || '&nbsp';
            if(item.reportUpdTimestamp){
                var datetime = new Date(item.reportUpdTimestamp);
                var date = datetime.getFullYear() + '/' + (datetime.getMonth()+1) + '/' +  datetime.getDate();
                var time = datetime.getHours() + ':' + ('0' + datetime.getMinutes()).slice(-2);
                self.preReportTimestamp.innerHTML = date + ' ' + time;
            } else{
                self.preReportTimestamp.innerHTML = '&nbsp';
            }
            delete item.reportCrtTimestamp;
            delete item.organization;
            // 市町名をHTMLに移す
            self.municipalityName.innerHTML = item.municipalityName || '&nbsp';

            // 「マスコミ公開可能」のものは、報告状況を変更不可能
            if(item.reportStatus === '41'){
                self.updateStatusButton.set('disabled', true);
            }

            // 報告部署にログインユーザの組織情報を設定
            var orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
            UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
            UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');
            self.organization._setValueAttr(orgCd);

            //対応履歴登録時のため、ユーザの組織情報を取得
            if(isFirstTime){
                self.organizationCd = item.userOrganizationCd;
                self.organizationName = item.userOrganizationName;
            }

            //対応課セレクタを初期化の上、ツリーを再構築
            self.hldOrganization.set('value', []);
            // その他関係機関（4機関共有）の被害の場合、対応課を救出救助統括室に固定
            if(item.municipalityCd === config.municInfo.otherRelationOrg){
                self.hldOrganization.onMunicipalityChange(config.municInfo.prefMunicCd);
                self.hldOrganization.set('value', ['SS10101']);
            } else {
                self.hldOrganization.onMunicipalityChange(item.municipalityCd);
                if (item.hldOrganization && item.hldOrganization.length) {
                    self.hldOrganization.set('value', item.hldOrganization);
                } else {
                    self.hldOrganization.set('value', []);
                }
            }
            // // 4機関ユーザ、その他関係機関の場合、市町村セレクターを不可視
            // if(UserInfo.is4OrgsRole() || UserInfo.getUserType() === USER_TYPE.OTHER_ORGAN){
            //     self.hldOrganization.set('noAllButton', true);
            // }

            // 組織カテゴリコードをセット
            this._damageDeptCd = item.damageDeptCd;

            // hldOrganizationをform.setの対象に含めるとエラーが出るため、このタイミングで取り除く
            delete item.hldOrganization;

            // 被害詳細データをセット
            self.form.set('value', item);

            // 人的被害数の変更前値セット
            self.deadNumOld.set('value', item.deadNum);
            self.unknownNumOld.set('value', item.unknownNum);
            self.seriousNumOld.set('value', item.seriousNum);
            self.mildNumOld.set('value', item.mildNum);
            self.unknownSympNumOld.set('value', item.unknownSympNum);

            // 報告日時欄に、現在時刻を自動セットする
            this.reportTimestamp.set('value', new Date().getTime());

            // 訂正・続報登録・削除ボタンの活性/非活性切り替え制御
            self.setButtonStatus(item);

            //被害情報(基本情報)を保存
            //self.damageBasicInfo = item;
            lang.hitch(self, self.setDamageInfoForm(item));

            //対応状況をセット
            self.setHldStatusStyle(self.hldStatus.domNode, item.hldStatus);
            // 対応履歴フォームの対応状況にセット
            self.actionForm.setHldStatus(item.hldStatus);

            if(isFirstTime){
                //（初回のみ）ユーザ・対応状況に応じてタブ表示を制御
                var defaultTab = self.tab2;
                if(UserInfo.getMunicipalityCd() &&
                    (UserInfo.getMunicipalityCd() === item.municipalityCd ||
                        (UserInfo.getMunicipalityCd() === config.municInfo.cityMunicCd &&
                        config.municInfo.wardList.indexOf(item.municipalityCd) !== -1 ) )){
                    // 当該市町ユーザの場合
                    if(item.hldStatus === '2'){
                        //「対応中」の場合のみ「対応履歴」タブを開く
                        defaultTab = self.tab3;
                        //対応状況アコーディオンも開く
                        // self.hldTitlePane.set('open',true);
                    }
                }
                // else if(!UserInfo.isSysAdmin()){
                //     // 当該市町でないユーザの場合（システム管理者以外）、「対応履歴」タブは閲覧不可
                //     self.tabContainer.removeChild(self.tab3);
                // }
                //少し時間を空けないとタブの挙動がおかしくなるので回避
                setTimeout(function() {
                    self.tabContainer.selectChild(defaultTab);
                }, 1500);
            }

            //(初回のみ)報告状況をセット
            if(isFirstTime){
                self.initReportStatus(item.reportStatus);
            }

            // 親管理番号を画面で保持して、設定されている場合はセレクターの表示を切り替える。
            self._parentDamageId = item.parentAdmNum;
            if (self._parentDamageId) {
                topic.publish(module.id + '::display', self._parentDamageId);
            } else {
                topic.publish(module.id + '::release');
            }
            self.innerParentDamageSelector.set('municipalityCd', item.municipalityCd);
            self.innerParentDamageSelector.set('admNum', item.admNum);

            //地図の中心座標を取得
            var lat = config.map.latitude;
            var lng = config.map.longitude;
            var markerIsSet = false;
            // 市町コードが組織カテゴリコードの場合は、「その他関係機関」の市町コードをセット
            // self._municipalityCd = item.municipalityCd;
            self._municipalityCd = (item.municipalityCd.substring(0,1) === 'D' ?
                config.municInfo.otherRelationOrg : item.municipalityCd);

            if(item.damageLatitude && item.damageLongitude) {
                lat = item.damageLatitude;
                lng = item.damageLongitude;
                markerIsSet  = true;
                //初回は初期化を、2回目以降はsetViewのみを行う
                if (isFirstTime) {
                    self.initMap(lat, lng);
                    self.initSubMap(lat, lng);
                }else{
                    self.map.setView([lat, lng], 14);
                    self.submap.setView([lat, lng], 14);
                }
            // 緯度経度の情報がない場合には、市町の自治体所在地に遷移(初回のみ)
            } else if(self._municipalityCd && isFirstTime) {
                Requester.get('/api/municipalities/' + self._municipalityCd, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(function(municipality) {
                    // マップを初期化
                    if(municipality.latitude && municipality.longitude){
                        lat = municipality.latitude;
                        lng = municipality.longitude;
                    }
                    self.initMap(lat, lng);
                    self.initSubMap(lat, lng);
                }, function(error) {
                    console.log(error);
                    self.chain.info('情報の取得に失敗しました。', 'エラー');
                });
            }
            //マーカーがセットされている場合の処理
            if(markerIsSet){
                self.pointLat = lat;
                self.pointLng = lng;
                self._damageAddressPointLat = lat;
                self._damageAddressPointLng = lng;
                self.addMark(lat, lng);
            }else{
                self.removeMark();
            }

            // その他情報を保持
            self._seqNum = item.seqNum;
            self._extAdmNum = item.extAdmNum;
            self._damageType = item.damageType;
            self._damageTypeList = item.damageTypeList;
            var damageTypeList = item.damageTypeList.split(',');

            // 被害詳細フォームを変更
            self.changeMultipleDamageDetailContents(damageTypeList);

            // 添付ファイルを設定
            if(item.attachFiles) {
                for(var i=0; i<item.attachFiles.length; i++) {
                    if(item.attachFiles[i].delFlg === 'false') {
                        // 削除されていない画像のみ表示
                        self.attachFileList.push(item.attachFiles[i]);
                        self.showPreview(item.attachFiles[i], false);
                    }
                }
            }

            //前報から変更があった項目リストを設定
            if(item.updatedProperties) {
                for(var j=0; j<item.updatedProperties.length; j++) {
                    self.updatedPropertiesList.push(item.updatedProperties[j]);
                }
                self._updatedPropertiesList = self.updatedPropertiesList;
            }

            // 対応情報グリッドを初期化
            self._chronologyId = item.chronologyId;
            self.chronologyStore = new IdisRest({
                idProperty: 'chronologyId',
                target: '/api/chronologies/action/' + item.chronologyId
            });
            self.initDamageReportActionGrid();


            // 続報リストを取得・続報セレクタにセット
            if(item.delFlg !== 'true'){
                Requester.get('/api/damageReports/' + item.admNum + '/damageInfos', {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(function(data) {
                    var selectData = [];
                    for(var i = 0; i < data.total; i++) {
                        selectData.push({
                            damageReportId  :data.items[i].damageReportId,
                            seqNum          :String(data.items[i].seqNum),
                            seqNumStr       :'第' + String(data.items[i].seqNum) + '報'
                        });
                    }

                    var selectContent = {
                        identifier  :'damageReportId',
                        label       :'seqNumStr',
                        items       :selectData
                    };

                    var damageReportItemFileWriteStore = new ItemFileWriteStore({
                            data:  selectContent
                        });
                    self.seqNumStore = damageReportItemFileWriteStore;
                    self.seqNumSelect.set('sortByLabel', false);
                    self.seqNumSelect.set('store', damageReportItemFileWriteStore);
                    self.seqNumSelect.set('value', self._damageReportId);

                    // if(self.initSeqNumSelectFlg) {
                    //     self.seqNumSelect.on('change', function(evt) {
                    //         self.onSeqNumChange(evt);});
                    //     self.initSeqNumSelectFlg = false;
                    // }
                }, function(err) {
                    console.log(err);
                });
            }

            //削除済み被害の場合の報数セレクタ設定
            if(item.delFlg){
                var damageReportItemFileWriteStore = new ItemFileWriteStore({
                        data:  {
                            identifier  :'damageReportId',
                            label       :'seqNumStr',
                            items       :[{
                                damageReportId  :self._damageReportId,
                                seqNumStr       :'第' + String(item.seqNum) + '報'
                            }]
                        }
                });
                self.seqNumSelect.set('store', damageReportItemFileWriteStore);
                domStyle.set(self.toggleDamageTransitionButton.domNode, 'display', 'none');
            }

            //セレクタへの値のセットは毎回実施
            self.seqNumSelect.set('value', self._damageReportId);

            //以下、被害情報IDに無関係のため初回のみ実施
            if(isFirstTime){
                //周辺被害表示ボタンを押下可能に
                self.enabledAroundDamageButton = true;
                //同じ災害に紐づく、周辺の被害情報を地図に描画する
                var param = '?disasterId=' +  self._disasterId + '&municipalityCd=' + self._municipalityCd;
                param += '&activeFlg=1&hasNoParentFlg=0';
                console.log(param);
                Requester.get('/api/damageReports' + param, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(function(data) {
                    var items = data.items;
                    self.setDamageReportMarkers(items, self._damageReportId);
                    //周辺被害はデフォルト表示
                    self.showDamageReportMarkers();

                }, function(error) {
                    self.chain.infoError(error);
                });
            }

            if(item.farmUnknownTypeFlg === '1'){
                self.farmUnknownTypeCheck.set('checked', true);
            }

            // 市町コードを再セット（その他関係機関の場合、組織カテゴリコードを再設定）
            self._municipalityCd = item.municipalityCd;

            // 作図jsonの読み込み
            // /data/out/damage/[disaster-id]/draws-[admnum]-[seqnum].geojson
            var drawFileUrl = 'data/damage/' + item.disasterId + '/' +
                'draws-' + item.admNum + '-' + item.seqNum + '.geojson';

            Requester.get(drawFileUrl, {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true

            }).then(function(data) {
                topic.publish(self.REMOVE_ALL);
                topic.publish(self.DRAW_BY_JSON, data);

            });
        },

        /**
         * 被害情報項目のHTMLをセット・変数に保持
         */
        setDamageInfoForm: function(item){
            //人的被害
            if(item.humanDamageFlg === '0') {
                this.humanDamage.style.display = '';
            } else {
                this.humanDamage.style.display = 'none';
            }

            this.admNum.innerHTML = item.admNum || '&nbsp';
            this.humanDamageFlg.innerHTML = {'0':'あり', '1': 'なし'}[item.humanDamageFlg] || '&nbsp';
            this.content.innerHTML = item.content || '&nbsp';
            this.notifierName.innerHTML = item.notifierName || '&nbsp';
            this.notifierContact.innerHTML = item.notifierContact || '&nbsp';

            if(item.damageTimestamp){
                var datetime = new Date(item.damageTimestamp);
                var date = datetime.getFullYear() + '/' + (datetime.getMonth() + 1) + '/' +  datetime.getDate();
                var time = datetime.getHours() + ':' + ('0' + datetime.getMinutes()).slice(-2);
                this.damageTimestamp.innerHTML = date + ' ' + time;
            }else{
                this.damageTimestamp.innerHTML = '&nbsp';
            }

            var notifierCtg = {
                '01': '住民',
                '02': '現地調査',
                '03': '消防団',
                '04': 'SNS',
                '05': '警察',
                '06': '消防',
                '07': '自衛隊',
                '08': '県',
                '09': '電力等事業者',
                '10': 'その他'
            };
            if(item.notifierCategory){
                this.notifierCategory.innerHTML = notifierCtg[item.notifierCategory];
            }

            if(item.urgencyType === '0'){
                this.urgencyType.innerHTML = '-';
                this.urgencyType.style.backgroundColor = '#ffffff';
                this.urgencyType.style.color = '#000000';
            }
            else if(item.urgencyType === '1'){
                this.urgencyType.innerHTML = '低';
                this.urgencyType.style.backgroundColor = '#ffffff';
                this.urgencyType.style.color = '#000000';
            }else if(item.urgencyType === '2'){
                this.urgencyType.innerHTML = '中';
                this.urgencyType.style.backgroundColor = '#ffff00';
                this.urgencyType.style.color = '#000000';
            }else if(item.urgencyType === '3'){
                this.urgencyType.innerHTML = '高';
                this.urgencyType.style.backgroundColor = '#cc2800';
                this.urgencyType.style.color = '#ffffff';
            }

            if(this.damageType.innerHTML === '&nbsp;' || item.changeFlg === true) {
                var damageTypeArr = item.damageTypeList.split(',');
                if(damageTypeArr) {
                    for(var dmCount=0; dmCount<damageTypeArr.length; dmCount++) {
                        damageTypeArr[dmCount] = DamageType[damageTypeArr[dmCount]];
                    }
                    this.damageType.innerHTML = damageTypeArr.join('、');
                }
            }

            //フォーム内容を保管
            lang.mixin(this.damageBasicInfo, item);

        },


        /**
         * 訂正・続報登録・削除・復元ボタンの活性/非活性・表示/非表示を初期化する。
         */
        setButtonStatus: function(item){
            var updUserId  = item.updUserId;
            var municipalityCd = item.municipalityCd;
            var activeFlg = item.activeFlg;
            var deleteFlg = item.delFlg;
            var releaseFlg = item.reportStatus === '41' ? true : false;

            var updatable = false;
            var restorable = false;

            // ユーザーの市町村情報をセット
            var _userMunicipalityCds = UserInfo.getMunicipalityCds()[0]?
                UserInfo.getMunicipalityCds() : [UserInfo.getMunicipalityCd()];

            // // 4機関ユーザまたはその他関係機関の場合は、ユーザの組織カテゴリコードをセット
            // if(UserInfo.is4OrgsRole() ||
            //     _userMunicipalityCds.toString() === config.municInfo.otherRelationOrg){
            //     _userMunicipalityCds = [UserInfo.getOrganization().deptCd];
            // }
            // 当該被害の市町村が管理対象である場合は更新・削除を可能に
            _userMunicipalityCds.forEach(lang.hitch(this,function(value){
                if(value === municipalityCd){
                    updatable = true;
                }
            }));
            // 振興局ユーザは県被害についても更新・削除を可能にする
            if (UserInfo.getUserType() === USER_TYPE.REGION && municipalityCd === config.municInfo.prefMunicCd) {
                updatable = true;
            }

            //削除済み被害の場合は更新ではなく復元
            if(updatable && deleteFlg !== 'false'){
                updatable = false;
                // 復元可能なのは、削除した本人orシステム管理者のみ
                if(UserInfo.getId() === updUserId || UserInfo.isSysAdmin()){
                    restorable = true;
                }
            }

            // 災害IDが選択中の災害と不一致の場合、更新不可エラーダイアログを表示
            // （監視ページの別災害レイヤーのポップアップから遷移時発生）
            if (Number(item.disasterId) !== Number(this._disasterId)) {
                domStyle.set(this.correctButton.domNode, 'display',  'none');
                domStyle.set(this.subButton.domNode, 'display', 'none');
                domStyle.set(this.deleteButton.domNode, 'display', 'none');
                domStyle.set(this.restoreButton.domNode, 'display', 'none');
                this.chain.info('現在選択中の災害に紐づかない情報のため、編集・更新ができません。<br>' +
                    'この情報を編集・更新する場合、監視ページに戻り、災害名を変更してください。', 'エラー');
                return;
            }
            domStyle.set(this.correctButton.domNode, 'display', updatable ? '' : 'none');
            domStyle.set(this.subButton.domNode, 'display', (updatable && activeFlg !== '0') ? '' : 'none');
            domStyle.set(this.deleteButton.domNode, 'display', updatable && !releaseFlg ? '' : 'none');
            domStyle.set(this.restoreButton.domNode, 'display', restorable ? '' : 'none');

            domStyle.set(this.buttonsPane.domNode, 'display', '');

            if(this._simulateFlg){
                domStyle.set(this.correctButton.domNode, 'display',  '');
                domStyle.set(this.subButton.domNode, 'display', 'none');
                domStyle.set(this.deleteButton.domNode, 'display', 'none');
                domStyle.set(this.restoreButton.domNode, 'display', 'none');
            }
            // // その他関係機関、4機関ユーザの場合、各種ボタンを不可視（4機関共有ユーザは可視。暫定）
            // if (UserInfo.getUserType() === USER_TYPE.OTHER_ORGAN ||
            //     UserInfo.is4OrgsRole()) {
            //     if(!UserInfo.isShareRole()){
            //         domStyle.set(this.correctButton.domNode, 'display', 'none');
            //         domStyle.set(this.subButton.domNode, 'display', 'none');
            //         domStyle.set(this.deleteButton.domNode, 'display', 'none');
            //         domStyle.set(this.restoreButton.domNode, 'display', 'none');
            //     }
            // } else
            if (UserInfo.getUserType() === USER_TYPE.PREFECTURE &&
                item.municipalityCd.substring(0,1) === 'D'){
                // ログインユーザが長崎県かつ選択された被害報告がその他関係機関の場合、各種ボタンを不可視（暫定）
                domStyle.set(this.correctButton.domNode, 'display', 'none');
                domStyle.set(this.subButton.domNode, 'display', 'none');
                domStyle.set(this.deleteButton.domNode, 'display', 'none');
                domStyle.set(this.restoreButton.domNode, 'display', 'none');
            }

            // 被害情報の管轄が県以外(任意の市町管轄)の場合
            if (item.municipalityCd !== config.municInfo.prefMunicCd) {
                // 削除ボタン表示フラグ
                var deleteButtonFlg = 'none';
                // 訂正ボタン表示フラグ
                var correctButtonFlg = 'none';
                // 続報登録ボタン表示フラグ
                var subButtonFlg = 'none';
                // 復元ボタン表示フラグ
                var restoreButtonFlg = 'none';

                //管理者権限がある場合、または、関係機関・特殊関係機関の場合
                if(UserInfo.getAdminFlg() || UserInfo._userInfo.roleCd === 'R04011'){
                    // 関係機関・特殊関係機関の場合は更新・削除を可能する
                    if (UserInfo._userInfo.roleCd === 'R04011') {
                        updatable = true;
                    }

                    // 最新報の場合は下記ボタンを表示する
                    if (updatable && activeFlg !== '0') {
                        // 削除ボタン表示フラグ
                        deleteButtonFlg = '';
                        // 訂正ボタン表示フラグ
                        correctButtonFlg = '';
                        // 続報登録ボタン表示フラグ
                        subButtonFlg = '';
                    }
                    // 過去報の場合は下記ボタンを表示する
                    else if (updatable && !releaseFlg) {
                        // 削除ボタン表示フラグ
                        deleteButtonFlg = '';
                        // 訂正ボタン表示フラグ
                        correctButtonFlg = '';
                    }
                    // 削除済の場合は下記ボタンを表示する
                    else if (restorable) {
                        // 復元ボタン表示フラグ
                        restoreButtonFlg = '';
                    }
                }
                // 市町ユーザの場合
                else if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY){
                    // 最新報の場合は下記ボタンを表示する
                    if (updatable && activeFlg !== '0') {
                        // 削除ボタン表示フラグ
                        deleteButtonFlg = '';
                        // 訂正ボタン表示フラグ
                        correctButtonFlg = '';
                        // 続報登録ボタン表示フラグ
                        subButtonFlg = '';
                    }
                    // 過去報の場合は下記ボタンを表示する
                    else if (updatable && !releaseFlg) {
                        // 削除ボタン表示フラグ
                        deleteButtonFlg = '';
                        // 訂正ボタン表示フラグ
                        correctButtonFlg = '';
                    }
                }

                // 削除ボタン
                domStyle.set(this.deleteButton.domNode, 'display', deleteButtonFlg);
                // 訂正ボタン
                domStyle.set(this.correctButton.domNode, 'display', correctButtonFlg);
                // 続報登録ボタン
                domStyle.set(this.subButton.domNode, 'display', subButtonFlg);
                // 復元ボタン
                domStyle.set(this.restoreButton.domNode, 'display', restoreButtonFlg);
            }
        },

        /**
         * 被害状況を訂正する。
         */
        correctDamageReport: function() {
            console.debug('[訂正]ボタンがクリックされました。');
            this.updateDamageReport(false);
        },

        /**
         * 被害状況（続報）を登録する。
         */
        followupDamageReport: function() {
            console.debug('[続報登録]ボタンがクリックされました。');
            this.updateDamageReport(true);
        },

        /**
         * 被害状況を復元する。
         */
        restoreDamageReport: function() {
            var msg = '削除済みの被害情報を復元します。<br>よろしいですか？<br>(変更した内容は反映されません。)';
            this.chain.confirm(msg, function(chain) {
                var version = this.form.get('value').version;
                var promise = Requester.put('/api/damageReports/restore/' + this._damageReportId,{
                    query:{
                        version: version
                    }
                })
                .then(function() {
                    chain.infoComplete(function() {
                        //ツリーの更新
                        LayerStore.refreshAll();
                        // 一覧画面に移動
                        Router.moveTo('report', {municipalityCd: this._municipalityCd});
                    });
                }, function(error) {
                    console.log(error);
                    chain.infoError(error);
                });
                //ローダーの表示
                Loader.wait(promise);
            });
            console.debug('[復元]ボタンがクリックされました。');
        },

        /**
         * 被害状況を更新する。
         */
        updateDamageReport: function(followupFlg) {

            if (this.farmUnknownTypeCheck.get('checked')) {
                this.farmUnknownTypeFlg.set('value', '1');
            } else {
                this.farmUnknownTypeFlg.set('value', '0');
            }

            var sendData = this.form.get('value');

            // 「人的被害あり、または緊急度：中/高」を選んだ場合、「県報告（マスコミ公開不可）」を選ばねばならない。
            if((this.damageBasicInfo.humanDamageFlg === '0' || this.damageBasicInfo.urgencyType === '2' ||
                this.damageBasicInfo.urgencyType === '3' ) && this._reportStatus === '11') {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '人的被害あり、または緊急度：中/高を選んだ場合は、<br>' +
                                    '「庁内共有（県へ報告しない）」を選べません。'
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
                return false;
            }

            //作図をJSON化
            topic.publish(this.JSONIZE_RQST);

            if(!sendData.reportName) {
                this.setReportName(sendData);
            }

            // if(sendData.damageAddress) {
            //     sendData.damageLatitude = this._damageAddressPointLat;
            //     sendData.damageLongitude = this._damageAddressPointLng;
            // } else {
            //     sendData.damageLatitude = '';
            //     sendData.damageLongitude = '';
            // }

           if(this.pointLat && this.pointLng){
             sendData.damageLatitude = this.pointLat;
             sendData.damageLongitude = this.pointLng;
           }else{
             sendData.damageLatitude = '';
             sendData.damageLongitude = '';
           }

            //フォーム上にない項目をセット
            sendData.parentAdmNum = this._parentDamageId;
            sendData.disasterId = this._disasterId;
            // 市町コードが組織カテゴリコードの場合は市町コードを設定
            //sendData.municipalityCd = this._municipalityCd;
            sendData.municipalityCd = (this._municipalityCd.toString().substring(0,1) === 'D' ?
                    // 県ユーザによるその他関係機関の代理登録はないので、ログインユーザの市町コードを設定する
                    UserInfo.getMunicipalityCd() : this._municipalityCd);
            sendData.damageDeptCd = this._damageDeptCd;
            sendData.seqNum = this._seqNum;
            sendData.extAdmNum = this._extAdmNum;
            sendData.damageType = this._damageType;
            sendData.damageTypeList = this._damageTypeList;
            sendData.reportStatus = this._reportStatus;

            //HTMLフォームの中身をセット
            sendData.humanDamageFlg = this.damageBasicInfo.humanDamageFlg;
            sendData.content = this.damageBasicInfo.content;
            sendData.notifierName = this.damageBasicInfo.notifierName;
            sendData.notifierContact = this.damageBasicInfo.notifierContact;
            sendData.damageTimestamp = this.damageBasicInfo.damageTimestamp;
            sendData.notifierCategory = this.damageBasicInfo.notifierCategory;
            sendData.urgencyType = this.damageBasicInfo.urgencyType;
            // sendData.damageType = this.damageBasicInfo.damageType;

            // 「確定フラグ」「本部確認状況フラグ」は現段階では使わないので固定値をセット
            sendData.fixFlg = '1';
            sendData.headquarterCheckFlg = '0';

            sendData.organization = this.organizationCd;

            // 対応課：葉ノードしかとれない問題を回避
            var hldOrganization = [];
            var organizationMap = this.hldOrganization.tree._checkMap;
            var excludeMap = {};
            var tree = this.hldOrganization.tree;
            df.forIn(organizationMap, function(item) {
                if(tree.isEveryChildChecked(item)){
                    tree.getItemChildren(item).then(lang.hitch(this, function(items) {
                        if(!items){
                            return false;
                        } else if (items.length !== 0){
                            // 子を登録して親を登録対象外とする場合
                            // 対象に子が存在する場合、親は登録対象外とする
                            excludeMap[tree.model.store.getIdentity(item)] = true;
                            return false;
                            }
                        // array.forEach(items, function(item){
                        array.forEach(items, function () { // 未使用変数エラー回避のため
                            // 親を登録して子を登録対象外とする場合
                            // 対象に紐づく子はすべて登録対象外とする
                            // excludeMap[tree.model.store.getIdentity(item)] = true;
                        }, this);
                    }));
                }
            }, this);
            df.forIn(organizationMap, function(item) {
                var cd = tree.model.store.getIdentity(item);
                if(!excludeMap[cd]){
                    hldOrganization.push(cd);
                }
            }, this);
            sendData.hldOrganization = hldOrganization;

            // 被害詳細データをセット
            this.cleanSendData(sendData);

            // 添付ファイルIDをセット
            if(this.attachFileList.length > 0) {
                var attachFileIds = [];
                for(var i=0; i<this.attachFileList.length; i++) {
                    attachFileIds.push(this.attachFileList[i].damageReportAtcFileId);
                }
                sendData.attachFileIds = attachFileIds.join(',');
            } else {
                sendData.attachFileIds = '';
            }

            delete sendData.organizationName;
            delete sendData.municipalityName;
            delete sendData.attachFile;

            // フォームのバリデーションを行う（共通部品）
            if(!this.form.validate()) {
                return false;
            }
            // フォームのバリデーションを行う（独自チェック）
            if(!this.validateForm(sendData)) {
                return false;
            }

            var self = this;
            if(followupFlg) {
                sendData.damageReportId = this._damageReportId;
                //続報登録時はversionを送信しない
                delete sendData.version;
                var jsonStr = json.stringify(sendData);
                //登録確認ダイアログを表示
                this.chain.confirmAdd(function(chain) {
                    var promise = Requester.post('/api/damageReports', {
                        data: jsonStr,
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(function(data) {
                        console.debug('success register DamageReport');
                        // 作図情報を登録
                        var param = '?damageReportId=' + data.damageReportId;
                        return Requester.post('/api/damageReports/uploadjson' + param, {
                            data: self.drawJson,
                            headers: {'Content-Type': 'application/json; charset=utf-8'},
                            handleAs: 'json',
                            preventCache : true
                        }).then(function() {
                            console.debug('success upload json');
                            chain.infoComplete(function() {
                                //ツリーの更新
                                LayerStore.refreshAll();
                                // 一覧画面に移動
                                Router.moveTo('report', {municipalityCd: this._municipalityCd});
                            });
                        }, function(err) {
                            console.log(err);
                            chain.info('作図ファイルの登録に失敗しました。', 'エラー', function() {
                                // 一覧画面に移動
                                Router.moveTo('report', {municipalityCd: this._municipalityCd});
                            });
                        });
                    }, function(error) {
                        console.log('error register damagereport');
                        chain.infoError(error);
                    });
                    //ローダーの表示
                    Loader.wait(promise);
                });
            } else if(this._simulateFlg){
                delete sendData.version;
                //更新確認ダイアログを表示
                var message = 'イベントを訂正します。<br>よろしいですか。';
                this.chain.confirmPut(message, function(chain) {
                    var jsonStr = json.stringify({
                        damageReportPostForm: sendData,
                        eventId: this._eventId});
                    var promise = Requester.put('/api/simulations/event/'+ this._eventId, {
                        data: jsonStr,
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(function() {
                        console.debug('success update DamageRport event');
                        chain.infoComplete(function() {
                            Router.moveTo('simulation/event', {scenarioId: this._scenarioId});
                        });
                    }, function(error) {
                        console.log(error);
                        chain.infoError(error);
                    });
                    //ローダーの表示
                    Loader.wait(promise);
                });
            } else {
                //更新確認ダイアログを表示
                this.chain.confirmPut(function(chain) {
                    var jsonStr = json.stringify(sendData);
                    var promise = Requester.put('/api/damageReports/' + this._damageReportId, {
                        data: jsonStr,
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(function(data) {
                        console.debug('success update DamageRport');
                        // 作図情報を登録
                        var param = '?damageReportId=' + data.damageReportId;
                        return Requester.post('/api/damageReports/uploadjson' + param, {
                            data: self.drawJson,
                            headers: {'Content-Type': 'application/json; charset=utf-8'},
                            handleAs: 'json',
                            preventCache : true
                        }).then(function() {
                            console.debug('success upload json');
                            chain.infoComplete(function() {
                                //ツリーの更新
                                LayerStore.refreshAll();
                                // 一覧画面に移動
                                Router.moveTo('report', {municipalityCd: this._municipalityCd});
                            });
                        }, function(err) {
                            console.log(err);
                            chain.info('作図ファイルの登録に失敗しました。', 'エラー', function() {
                            // 一覧画面に移動
                            Router.moveTo('report', {municipalityCd: this._municipalityCd});
                            });
                        });
                    }, function(error) {
                        console.log(error);
                        chain.infoError(error);
                    });
                    //ローダーの表示
                    Loader.wait(promise);
                });
            }
        },

        /**
         * 被害状況を削除する。
         */
        deleteDamageReport: function(deleteTarget) {
            var version = this.form.get('value').version;
            var uri = '/api/damageReports/' + this._damageReportId;

            if(deleteTarget && deleteTarget === '2'){
                // 過去報ごと削除
                uri = '/api/damageReports/all/' + this.damageBasicInfo.admNum;
            }
            //削除確認ダイアログの表示
            this.chain.confirmDel(lang.hitch(this, function(chain) {
                var promise = Requester.del(uri, {
                    query:{
                        version: version,
                        displayId: this._damageReportId
                    }
                })
                .then(lang.hitch(this, function() {
                    chain.infoComplete(lang.hitch(this, function() {
                        //ツリーの更新
                        LayerStore.refreshAll();
                        // 一覧画面に移動
                        Router.moveTo('report', {municipalityCd: this._municipalityCd});
                    }));
                }), lang.hitch(this, function(error) {
                    console.log(error);
                    chain.infoError(error);
                }));
                //ローダーの表示
                Loader.wait(promise);
            }));
            console.debug('[削除]ボタンがクリックされました。');
        },

        showDeleteDialog: function() {
            // 削除対象を選択できるのは、最新報かつ第2報以上のときとする
            if(this.damageBasicInfo.activeFlg === '1' && this.damageBasicInfo.seqNum > 1){
                this.damageReportDeleteDialog.show();
            }else{
                this.deleteDamageReport();
            }

        },

        /**
         * 過去報を表示する。
         */
        onSeqNumChange: function(evt) {
            if(this._damageReportId !== String(evt)) {
                this._damageReportId = String(evt);
                this.initDetailPage(false);
                this.initDamageReportActionGrid();
            }
        },

        /**
         * 続報のリストを、子オブジェクトseqNumを基準にソートする。
         */
        sortList: function(itemList){
            itemList.sort(function(a,b){
                if(parseInt(a.seqNum, 10) < parseInt(b.seqNum, 10)){
                    return -1;
                }
                if(parseInt(a.seqNum, 10) > parseInt(b.seqNum, 10)){
                    return 1;
                }
                return 0;
            });
        },

        /**
         *
         * @param {number} itemList 要素一覧
         */
        _progressTransition: function(itemList) {
            // 先頭を取り出す
            var item = itemList[0];
            // 要素が存在＆未遷移なら処理を実行
            if (item && this.domNode) {
                //処理を実行
                this.seqNumSelect.set('value', itemList[0].damageReportId);


                // 2番目以降の要素に対して同じ処理を実行
                this.timer = setTimeout(lang.hitch(this, function() {
                    this._progressTransition(itemList.slice(1));
                }), 1000);
            } else {
                this._timer = null;
            }
        },

        /**
         * 被害再生ボタン押下時の処理, コンスタントにサーバーにリクエストを送って、画面を再生する
        */
        toggleDamageTransitions: function() {
            if (!this._timer) {
                // 連続切替時を考慮し、最初に仮のtimerを設定しておく
                this._timer = setTimeout(lang.hitch(this, function() {
                    // 要素一覧を取得して連続実行開始
                    this.seqNumStore.fetch({
                        onComplete : lang.hitch(this, function(itemList) {
                            //itemListは、デフォルトでは続報番号の降順になっているので、昇順にソートする。
                            this.sortList(itemList);
                            this._progressTransition(itemList);
                        })
                    });
                }));
            } else {
                // timerを解除
                clearTimeout(this._timer);
                this._timer = null;
            }
        },

        /**
         * 対応状況のStyleをセットする
         */
        setHldStatusStyle: function(hldStatusDomNode, hldStatus){
            if(hldStatus === '0'){
                domStyle.set(hldStatusDomNode.children[0], 'color', 'black');
                domStyle.set(hldStatusDomNode.children[0], 'font-weight', 'bold');
                domStyle.set(hldStatusDomNode.children[1], 'color', 'gray');
                domStyle.set(hldStatusDomNode.children[2], 'color', 'gray');
                domStyle.set(hldStatusDomNode.children[3], 'color', 'gray');
            }else if(hldStatus === '1'){
                domStyle.set(hldStatusDomNode.children[0], 'color', 'blue');
                domStyle.set(hldStatusDomNode.children[1], 'color', 'black');
                domStyle.set(hldStatusDomNode.children[1], 'font-weight', 'bold');
                domStyle.set(hldStatusDomNode.children[2], 'color', 'gray');
                domStyle.set(hldStatusDomNode.children[3], 'color', 'gray');
            }else if(hldStatus === '2'){
                domStyle.set(hldStatusDomNode.children[0], 'color', 'blue');
                domStyle.set(hldStatusDomNode.children[1], 'color', 'blue');
                domStyle.set(hldStatusDomNode.children[2], 'color', 'black');
                domStyle.set(hldStatusDomNode.children[2], 'font-weight', 'bold');
                domStyle.set(hldStatusDomNode.children[3], 'color', 'gray');
            }else if(hldStatus === '3'){
                domStyle.set(hldStatusDomNode.children[0], 'color', 'blue');
                domStyle.set(hldStatusDomNode.children[1], 'color', 'blue');
                domStyle.set(hldStatusDomNode.children[2], 'color', 'blue');
                domStyle.set(hldStatusDomNode.children[3], 'color', 'black');
                domStyle.set(hldStatusDomNode.children[3], 'font-weight', 'bold');
            }
        },

        /**
         * 対応状況を変更したら、対応履歴の対応状況にも同じ値をセットする
         */
        onChangeHldStatus: function(evt){
            this.actionForm.setHldStatus(evt.target.value);
        },

        /**
         * 対応状況グリッドを初期化する。
         * startup時、続報セレクタ選択時、対応状況の登録更新削除時
         */
        initDamageReportActionGrid: function() {
            console.debug('initDamageReportActionGrid');
            this.grid.set('collection', this.chronologyStore.filter());

            this.grid.on('updateActionButtonClick', lang.hitch(this, function(evt) {
                //[編集中]メッセージの付け替え
                if(this.actionForm.selectedAction && this.actionForm.selectedAction.editingMsg){
                    domStyle.set(this.actionForm.selectedAction.editingMsg, 'display', 'none');
                }
                this.actionForm.selectedAction = evt.selectedAction;
                domStyle.set(this.actionForm.selectedAction.editingMsg, 'display', '');
                //フォームのアップデート
                this.actionForm.showUpdateActionForm(evt.item);
            }));
        },

        /**
         * 対応履歴編集フォームを初期化する
         */
        initDamageReportActionForm: function() {
            // 編集フォームのregisterイベントを受け取る
            this.actionForm.on('register', lang.hitch(this, function(evt) {
                // 入力チェック
                if(!evt.value.content  || evt.value.content === ''){
                    this.chain.info('コメントが入力されていません。', 'エラー');
                    return false;
                }
                var sendData = {
                    chronologyId : this._chronologyId,
                    content : evt.value.content,
                    hldStatus : evt.value.hldStatus,
                    name : evt.value.name
                };
                // 添付ファイルIDをセット
                var attachFileIds = [];
                array.forEach(evt.value.attachFiles, function(attachFile){
                    attachFileIds.push(attachFile.chronologyAtcFileId);
                });
                sendData.attachFileIds = attachFileIds.join(',');
                // 登録確認ダイアログ
                this.chain.confirmAdd(lang.hitch(this, function(chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(this.actionStore.add((sendData))
                        .then(lang.hitch(this, function() {
                            this.actionForm.clearActionForm();
                            this.grid.set('collection', this.chronologyStore.filter());
                            this.setHldStatusStyle(this.hldStatus.domNode, sendData.hldStatus);
                            chain.infoComplete();
                            //グリッド更新後、位置を最下段にうつす
                            this.grid.scrollTo({ y: this.grid.bodyNode.scrollHeight });
                        }), function(err) {
                            chain.infoError(err);
                    }));
                }));
            }));

            // 編集フォームのupdateイベントを受け取る
            this.actionForm.on('update', lang.hitch(this, function(evt) {
                if(!evt.value.content  || evt.value.content === ''){
                    this.chain.info('コメントが入力されていません。', 'エラー');
                    return false;
                }
                var sendData = {
                    chronologyActionId : evt.value.chronologyActionId,
                    chronologyId : this._chronologyId,
                    content : evt.value.content,
                    hldStatus : evt.value.hldStatus,
                    name : evt.value.name
                };
                // 添付ファイルIDをセット
                var attachFileIds = [];
                array.forEach(evt.value.attachFiles, function(attachFile){
                    attachFileIds.push(attachFile.chronologyAtcFileId);
                });
                sendData.attachFileIds = attachFileIds.join(',');
                // 更新確認ダイアログ
                this.chain.confirmPut(lang.hitch(this, function(chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(this.actionStore.put((sendData))
                        .then(lang.hitch(this, function() {
                            this.actionForm.clearActionForm();
                            this.grid.set('collection', this.chronologyStore.filter());
                            //グリッド更新後、位置を最下段にうつす
                            this.grid.scrollTo({ y: this.grid.bodyNode.scrollHeight });
                            this.setHldStatusStyle(this.hldStatus.domNode, sendData.hldStatus);
                            chain.infoComplete();
                        }), function(err) {
                            chain.infoError(err);
                    }));
                }));
            }));

            // 編集フォームのdeleteイベントを受け取る
            this.actionForm.on('delete', lang.hitch(this, function(evt) {
                // 削除確認ダイアログの表示
                this.chain.confirmDel(lang.hitch(this, function(chain) {
                    // 削除処理完了まではローディング画面を表示
                    Loader.wait(this.actionStore.remove(evt.value.chronologyActionId)
                        .then(lang.hitch(this, function() {
                            // 削除処理成功時
                            this.actionForm.clearActionForm();
                            this.grid.set('collection', this.chronologyStore.filter());
                            var y1 = this.grid.bodyNode.scrollTop;
                            this.grid.scrollTo({ y: y1 });
                            // 完了ダイアログの表示
                            chain.infoComplete();
                        }), function(error) {
                            // 削除処理失敗時
                            // エラーダイアログの表示
                            chain.infoError(error);
                    }));
                }));
            }));
        },

        /**
         * 被害情報編集ダイアログを表示
         */
        showUpdateInfoDialog: function(){
            this.innerInfoDialog.initDialog(this.damageBasicInfo);
            this.damageReportUpdateInfoDialog.show();
        },

        /**
         * 月や日付を2桁にゼロpaddingする
         */
        _zeroPadding: function(month) {
            return ('00' + month).slice(-2);
        }
    });
});
