/**
* 被害状況集計画面用モジュール。
* @module app/damege/DamageReportAdminPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/text!./templates/DamageReportAdminPage.html',
    'dojo',
    'dojo/dom',
    'dojo/dom-style',
    'dojo/topic',
    'dstore/Memory',
    'dstore/Rest',
    'idis/view/grid/IdisGrid',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'app/model/DisasterInfo',
    'idis/consts/ACL',
    'idis/view/form/AclButton',
    'idis/view/dialog/DialogChain',
    'idis/map/IdisMap',
    '../config',
    './_DamageReportPageBase',
    'leaflet',
    'leaflet.markercluster',
    // 以下、変数として受け取らないモジュール
    'dijit/form/Form',
    'dijit/form/CheckBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    '../view/form/DisasterSelector',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    './DamageReportAdminGrid',
    'app/damage/DamageReportFormDialog'
], function (module, declare, lang, array, template, dojo, dom, domStyle, topic, Memory, Rest, IdisGrid,
    Locator, Router, Requester, IdisRest, UserInfo, UserType, DisasterInfo, ACL, AclButton, DialogChain,
    IdisMap, config, _DamageReportPageBase, leaflet) {

    var damageFilterStore = {};

    /**
    * 被害報画面。
    * @class DamageReportAdminPage
    * @extends module:idis/view/page/_PageBase~_PageBase
    */
    return declare(module.id.replace(/\//g, '.'), _DamageReportPageBase,
        /** @lends module:app/damege/DamageReportAdminPage~DamageReportAdminPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--damage',

            /**
            * データ格納用オブジェクト
            * @type {module:dstore/Store}
            */
            store: null,

            KUMAMOTO_PREF_LATLNG: { LAT: 34.661909, LNG: 133.934671 },
            MAP_ZOOM: 11,

            /**
             * 地図を動的に描画・操作するために発行するトピック
             */
            DRAW_CIRCLE: 'app.damage.DamageReportAdminPage' + '::drawCircle',

            /**
             * 地図上に描画された市町
             */
            drawnDamageIds: [],
            markerGroupList: [],

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
                this.own(this.formDialog);
            },

            constructor: function () {
                this.inherited(arguments);
                // データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'municipalityCd',
                    target: '/api/damageReports/overview'
                });
                this.chain = DialogChain.get(this);
                //地図アイコンに関する情報を初期化
                this.drawnDamageIds = [];
                this.markerGroupList = [];

                console.debug('現在のログインユーザID：' + UserInfo.getId());
                console.debug('現在の災害ID：' + DisasterInfo.getDisasterId());
            },

            startup: function () {
                this.inherited(arguments);

                this.initButtonStatus();

                // グリッドを初期化する
                this.initGrid();

                // 地図を初期化する
                this.initMap(this.INIT_LATLNG.lat, this.INIT_LATLNG.lng);

                // グリッドの概況を地図上に図示するためのトピック
                var drawCircleEvent = topic.subscribe(this.DRAW_CIRCLE, lang.hitch(this, function (arg) {
                    this.addCircle(arg);
                }));
                this._events.push(drawCircleEvent);
            },

            // TODO:要確認 新規登録ボタンについては、FUNC_CDとは別に「関係機関は表示しない」という暫定措置をとる
            // その他関係機関、4機関ユーザの場合、新規登録ボタンを不可視（4機関共有ユーザは可視。暫定）
            initButtonStatus: function () {
                // if (UserInfo.getUserType() === UserType.OTHER_ORGAN) {
                // if (UserInfo.getUserType() === UserType.OTHER_ORGAN ||
                //     UserInfo.is4OrgsRole()) {
                //     if(!UserInfo.isShareRole()){
                //         domStyle.set(this.registerButton.domNode, 'display', 'none');
                //     }
                // }

                // 政令指定都市ユーザは、市町村ユーザの中で唯一概況画面を見られるが、「すべての市町村」は表示できない。
                if (UserInfo.getUserType() === UserType.MUNICIPALITY &&
                    UserInfo.getMunicipalityCd() === config.municInfo.cityMunicCd) {
                    domStyle.set(this.showNoDamageMunicFlgArea, 'display', 'none');
                }

                // 閲覧権限以上を持っていないユーザは「定時報告」ボタンを非表示とする
                if(!UserInfo.hasAuthz('F05009')){
                    domStyle.set(this.ScheduledReportButton.domNode, 'display','none');
                }

                if(!UserInfo.hasWriteAuthz('F05019')){
                    domStyle.set(this.fdmaReportButton.domNode, 'display','none');
                }
            },

            /**
            * グリッドを初期化する。
            */
            initGrid: function () {
                // グリッドの詳細ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'list'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.damageReportAdminGrid.on('listButtonClick', lang.hitch(this, function (evt) {
                    this.onListButtonClick(evt);
                }));

                this.damageReportAdminGrid.on('dgrid-select', lang.hitch(this, function (evt) {
                    this.onSelectRecord(evt.rows[0].data);
                }));

                // 保管した検索条件をセットする
                this.setFilterData();

                this.updateGridQuery();

            },

            /**
             * マップを初期化する。
             */
            initMap: function (latitude, longitude) {
                var latlng = [latitude, longitude];

                this.map = new IdisMap(this.mapNode, {
                    config: config.map,
                    keyboard: false, // コメント時に+/-が使用できないため
                    touchExtend: false,
                    maxZoom: 18,
                    minZoom: 9,
                    drawControlTooltips: false
                }
                ).setView(latlng, this.MAP_ZOOM);

                // destroy時にmapを破棄するよう設定
                this.own(this.map);

            },

            /**
             * グリッドのレコードをクリックした時の処理。
             */
            onSelectRecord: function (arg) {
                // 選択した市町村に地図位置を合わせる
                var lat = arg.latitude;
                var lng = arg.longitude;

                if (lat && lng) {
                    this.map.setView([lat, lng], this.MAP_ZOOM);
                } else {
                    this.map.setView([this.INIT_LATLNG.lat, this.INIT_LATLNG.lng], this.MAP_ZOOM);
                }

                // 画面右下の内訳表示Paneを操作する
                domStyle.set(this.guideMessage, 'display', 'none');
                domStyle.set(this.aggregateResults, 'display', '');
                domStyle.set(this.damageDetails, 'display', 'none');

                this.selectedMuniName.innerHTML = arg.municipalityName || '(県内全域)';
                this.damageNum.innerHTML = arg.damageNum;

                //Pane中身(被害種別ごとの集計)の初期化
                for (var i = 1; i <= 15; i++) {
                    var type = ('00' + i).slice(-2);
                    dojo.byId('damageType' + type + 'Aggr').style.display = 'none';
                }

                var damageTypeAggr = {};
                var damageWithLatLngNum = 0;
                array.forEach(arg.damageReportList, function (damage) {
                    // hldStatusはCHAR(2)で送られてきてしまうため、加工する
                    var hldStatus = damage.hldStatus.slice(0, 1);
                    if (!damageTypeAggr[damage.damageType]) {
                        damageTypeAggr[damage.damageType] = {};
                    }
                    if (!damageTypeAggr[damage.damageType][hldStatus]) {
                        damageTypeAggr[damage.damageType][hldStatus] = 0;
                    }
                    damageTypeAggr[damage.damageType][hldStatus] += 1;
                    if (damage.latitude && damage.longitude) {
                        damageWithLatLngNum += 1;
                    }
                });
                //緯度経度の情報があり、地図上に表示される件数
                this.damageWithLatLngNum.innerHTML = damageWithLatLngNum;

                Object.keys(damageTypeAggr).forEach(function (type) {
                    dojo.byId('damageType' + type + 'Aggr').style.display = '';
                    var html = '';
                    //var startFlg = false;
                    if (damageTypeAggr[type]['0']) {
                        html += '確認中 ' + damageTypeAggr[type]['0'] + '件 ';
                    }
                    if (damageTypeAggr[type]['1']) {
                        html += '対応待ち ' + damageTypeAggr[type]['1'] + '件 ';
                    }
                    if (damageTypeAggr[type]['2']) {
                        html += '対応中 ' + damageTypeAggr[type]['2'] + '件 ';
                    }
                    if (damageTypeAggr[type]['3']) {
                        html += '対応済 ' + damageTypeAggr[type]['3'] + '件 ';
                    }
                    dojo.byId('damageType' + type + 'Content').innerHTML = html || '&nbsp';

                }, damageTypeAggr);
            },


            /**
             * 地図上に概況を図示する
             */
            addCircle: function (arg) {
                //市町名がない場合は合計行なので、地図上の図示は行わない
                if (!arg.municipalityName) {
                    return;
                }

                //マーカーグループの作成
                var markerGroup = new leaflet.markerClusterGroup({
                    iconCreateFunction: function (cluster) {
                        var markers = cluster.getAllChildMarkers();
                        var humanDamageFlg = false;
                        dojo.some(markers, function (marker) {
                            if (marker.humanDamageFlg === '0') {
                                humanDamageFlg = true;
                                return false;
                            }
                        });
                        var color = 'rgba( 85, 176, 250, 0.7 )';
                        if (humanDamageFlg) {
                            color = 'rgba( 240, 105, 60, 0.7 )';
                        }
                        var radius = Math.min(cluster.getChildCount() * 10, 4 * 10);
                        var html = '';
                        html += '<div class="leaflet-marker-icon marker-cluster ';
                        html += 'marker-cluster-small leaflet-zoom-animated leaflet-clickable"';
                        html += 'style="margin-left: ' + (50 - radius) + 'px; margin-top: ' + (50 - radius) + 'px;';
                        html += 'font-size: ' + radius + 'px;';
                        html += 'line-height: ' + (radius * 2) + 'px;';
                        html += 'background-color: ' + color + ';';
                        html += 'width: ' + (radius * 2) + 'px; height: ' + (radius * 2) + 'px;';
                        html += 'border-radius:' + radius + 'px;';
                        html += '"><span>' + cluster.getChildCount() + '</span></div>';
                        // html += '<div style="width:100px; height:100px;border-radius:50px;"><span>';
                        // html += cluster.getChildCount() + '</span></div></div>';

                        return leaflet.divIcon({
                            html: html,
                            className: 'marker-cluster',
                            iconSize: new leaflet.Point(100, 100)
                        });
                    }
                });

                var damageReportList = arg.damageReportList;
                //被害単位のマーカー作成
                array.forEach(damageReportList, function (damageReport) {
                    var lat = damageReport.latitude;
                    var lng = damageReport.longitude;
                    var marker = [];
                    // すでに描画されていた場合は戻る。
                    if (this.drawnDamageIds.indexOf(damageReport.damageReportId) !== -1) {
                        return false;
                    }
                    if (lat && lng) {
                        // 概況画面では対応状況が2桁で取得されてしまうので、切り出し
                        damageReport.hldStatus = damageReport.hldStatus.slice(0, 1);
                        marker = this.makeDamageMarker(damageReport);
                        marker.humanDamageFlg = damageReport.humanDamageFlg;
                        var self = this;
                        markerGroup.addLayer(marker);
                        this.markerClick(marker, damageReport, self);
                    }
                }, this);

                // マップの描画が追いつかない場合があるため、タイミングを調整
                setTimeout(lang.hitch(this, function() {
                    this.map.addLayer(markerGroup);
                }), 1000);
                //追加したマーカーを管理
                this.markerGroupList.push(markerGroup);
                this.drawnDamageIds.push(arg.damageReportId);

            },

            markerClick: function (marker, item, self) {
                marker.on('click', function () {
                    self.clickMarker(item);
                });
            },
            //地図上のマーカー押下時の挙動
            clickMarker: function (arg) {
                // 画面右下の詳細表示Paneを操作する
                if (this.guideMessage) {
                    domStyle.set(this.guideMessage, 'display', 'none');
                }
                if (this.aggregateResults) {
                    domStyle.set(this.aggregateResults, 'display', 'none');
                }
                if (this.damageDetails) {
                    domStyle.set(this.damageDetails, 'display', '');
                    this.selectedAddress.innerHTML = arg.damageAddress ? arg.damageAddress + (arg.damageAddressNum || '') : '(未登録)';
                    this.selectedContent.innerHTML = arg.content ? arg.content : '原因/内容は未登録です。';
                    this.selectedHldContent.innerHTML = arg.hldContent ? arg.hldContent : '対応内容は未登録です。';
                }
            },

            /**
             * 地図上のアイコンを削除する
             */
            removeCircles: function () {
                var map = this.map;
                array.forEach(this.markerGroupList, function (group) {
                    map.removeLayer(group);
                });
                this.markerGroupList.length = 0;
                this.drawnDamageIds.length = 0;
            },

            /**
            * 検索
            */
            onSubmit: function () {
                try {
                    if (this.form.isValid()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGridQuery();
                    } else {
                        console.debug('エラーあり');
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
            *検索パラメーターの設定
            */
            updateGridQuery: function () {
                //
                this.removeCircles();
                // 入力値を元にグリッド用フィルターを作成
                var filter = new this.store.Filter();
                var value = this.form.get('value');

                // 災害ID
                filter = filter.eq('disasterId', this._disasterId);

                // 集計日時
                if (value.aggregateDateTime) {
                    var aggregateDateTime = new Date(value.aggregateDateTime).getTime();
                    filter = filter.eq('aggregateDateTime', aggregateDateTime);
                }
                // すべての市区町村を表示：
                if (value.showNoDamageMunicFlg.length !== 0) {
                    var showNoDamageMunicFlg = '1';
                    filter = filter.eq('showNoDamageMunicFlg', showNoDamageMunicFlg);
                }
                // } else if (UserInfo.is4OrgsRole()) {
                //     //4機関ユーザだった場合、デフォルト表示はその他関係機関とする
                //     filter = filter.eq('municipalityCd', UserInfo.getMunicipalityCd());
                if (UserInfo.getUserType() === UserType.PREFECTURE){
                    // 県ユーザだった場合、デフォルト表示は都内各区とする
                    filter = filter.eq('municipalityCd', config.municInfo.prefMunicCd);
                } else if (UserInfo.getUserType() === UserType.MUNICIPALITY &&
                    UserInfo.getMunicipalityCd() === config.municInfo.cityMunicCd) {
                    // 政令指定都市ユーザだった場合、デフォルト表示は市内各区とする
                    filter = filter.eq('municipalityCd', config.municInfo.cityMunicCd);
                } else if (UserInfo.getUserType() === UserType.REGION) {
                    // 県民局ユーザだった場合、デフォルト表示は市内各区とする
                    filter = filter.eq('regionCd', UserInfo.getRegionCd());
                }

                // システム管理者かどうか（可視範囲が変わる)
                filter = filter.eq('isSysAdm', UserInfo.isSysAdmin());

                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);

                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.damageReportAdminGrid.set('collection', collection);

                // 画面右下の詳細表示Paneを戻す
                domStyle.set(this.guideMessage, 'display', '');
                domStyle.set(this.aggregateResults, 'display', 'none');
                domStyle.set(this.damageDetails, 'display', 'none');

                this.setFilterStore();
            },

            /**
             * 検索条件を保管する
             */
            setFilterStore: function () {
                //初期化する
                damageFilterStore = {};
                var value = this.form.get('value');

                // 集計日時
                if (value.aggregateDateTime) {
                    damageFilterStore.aggregateDateTime = value.aggregateDateTime;
                }

                // すべての市町村表示
                if (value.showNoDamageMunicFlg.length > 0) {
                    damageFilterStore.showNoDamageMunicFlg = 'checked';
                }
            },

            /**
             * 保管した検索条件をフォームにセットする
             */
            setFilterData: function () {
                // 集計日時
                if (damageFilterStore.aggregateDateTime) {
                    this.aggregateDateTime.set('value', damageFilterStore.aggregateDateTime);
                }
                // すべての市町村表示
                if (damageFilterStore.showNoDamageMunicFlg) {
                    this.showNoDamageMunicFlg.set('checked', 'checked');
                }
            },

            /**
             * 新規登録画面を表示
             */
            showRegisterPage: function () {
                // 新規登録画面へ遷移
                this._removeEvents();
                this.setFilterStore();
                Router.moveTo('report/register');

            },

            /**
            *一覧ボタン押下時
            */
            onListButtonClick: function (evt) {
                // 被害状況画面へ遷移
                this._removeEvents();
                this.setFilterStore();
                Router.moveTo('report', { municipalityCd: evt.item.municipalityCd });
            },

            /**
             * 被害情報出力ダイアログを表示
             */
            outputListExcel: function () {
                this.formDialog.show();
            }

        });
});
