define([
    'module',
    'dojo',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/date/locale',
    'dojo/dom-class',
    'dojo/dom-style',
    'dojo/topic',
    'dojo/on',
    'dojox/lang/functional/object',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/IdisSelector',
    'idis/view/grid/helper',
    './ChronologyFormatter',
    './ChronologyLinks',
    'dijit/form/CheckBox',
    'dijit/form/Button',
    './consts/ChronologyType',
    './ChronologyStore',
    './consts/ChronologyMunicInfos',
    // 以下、変数で受けないモジュール
    'dojox/form/Uploader',
    'dijit/Destroyable'

], function(module, dojo, declare, lang, array, locale, domClass, domStyle, topic, on, df, IdisGrid, IdisSelector,
    helper, ChronologyFormatter, ChronologyLinks, CheckBox, Button, ChronologyType,
    ChronologyStore, ChronologyMunicInfos) {
    return declare(module.id.replace(/\//g, '.'), [IdisGrid, IdisSelector], {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--chronology',

        // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
        rowClassName: function(item) {
            return item && (item.read === false) ? 'red-row' : '';
        },

        /**
         * 各列の定義
         * @type {Object[]}
         */

        selectedId: null,

        storedItem: {},

        renderRow: function(item){
            var div = this.inherited(arguments);
            if(item.chronologyId === this.selectedId){
                this.storedItem = item;
                topic.publish(module.id + '::open', item);
            }
        return div;
        },

        columns: [
            // ボタン
            // helper.buttonColumn('detail', '詳細'),
            {field: 'chronologyId', label: 'No.', sortable: true,
                id: 'chronologyId',
                className: lang.hitch(this, function(item) {
                    // ヘッダーの場合はclassNameを返さない
                    if (!item) {
                        return;
                    }else{
                        if (item.urgencyType === '3') {
                            return 'is-high';
                        }
                        if (item.urgencyType === '2') {
                            return 'is-middle';
                        }
                        if (item.urgencyType === '1') {
                            return 'is-low';
                        }
                        return '';
                    }
                }),
                renderCell: function(item){
                    var actions = dojo.create('div');

                    var gridNode = this.grid.domNode;
                    // クロノロジ管理番号をリンク化して登録
                    var explainDiv = dojo.create('a', {href: '#'});
                    explainDiv.innerHTML = item.chronologyId;
                    explainDiv.onclick = function() {
                        on.emit(gridNode, 'showDetailPage', {item: item});
                    };
                    actions.appendChild(explainDiv);
                    // HTMLとしてウィジェットに紐付くDOMノードを返す
                    return actions;
                }
            },
            {field: 'updTimestamp', label: '最終更新日時', sortable: true,
                id: 'updTimestamp',
                formatter: lang.hitch(this, function(item){
                    if(item) {
                        return locale.format(new Date(item));
                    }
                    return '';
                })
            },
            {field: 'registerTimestamp', label: '発信日時', sortable: true,
                id: 'registerTimestamp',
                formatter: lang.hitch(this, function(item){
                    if(item) {
                        return locale.format(new Date(item));
                    }
                    return '';
                })
            },
            {field: 'seqNum', label: '続報番号', sortable: false,
                renderCell: function(item,value,node){
                    if (item.actualFlg === '0') {
                        node.innerHTML = '仮';
                    } else {
                        if (!item.seqNum || item.seqNum === null) {
                            node.innerHTML = '-';
                        } else {
                            node.innerHTML = item.seqNum;
                        }
                    }
                    return;
                }
            },
            helper.column('chronologyType', '情報種別',
            {
                formatMap: {
                    '00': 'その他',
                    '01': '防災気象情報',
                    '02': '国民保護',
                    '03': '観測',
                    '22': 'タイムライン',
                    '23': '作図',
                    '24': '避難',
                    '25': '体制',
                    '26': '避難所',
                    '27': '被害',
                    '28': '要請・措置',
                    '29': '災害名管理',
                    '30': '要請',
                    '31': '組織内情報',
                    '32': '部隊活動',
                    '33': '通行規制',
                    '34': '情報提供',
                    '41': '定時報告依頼',
                    '42': '定時報告',
                    '99': 'お知らせ'
                }
            }),
            {field: 'content', label: '内容',
                className: lang.hitch(this, function(item) {
                    // ヘッダーの場合はclassNameを返さない
                    if (!item) {
                        return;
                    }else{
                        return 'chronology-content';
                    }
                }),
                renderCell: function(item){
                    var gridNode = this.grid.domNode;
                    var actions = dojo.create('div');
                    // 内容
                    var explainSpan= dojo.create('span');
                    var text = item.content;
                    if (ChronologyStore.getProp() && text.length>95){
                        text = text.substring(0,95) + '...';
                    }
                    explainSpan.innerHTML = ChronologyFormatter.format(text);
                    actions.appendChild(explainSpan);
                    // リンク
                    array.forEach(ChronologyLinks, function(type){
                        if (type.id === item.chronologyType){
                            array.forEach(type.name, function(name, i){
                                item.sourceUrl = (type.url[i] === 'sourceUrl') ? item.sourceUrl : type.url[i];
                                var urlA = dojo.create('a', {
                                    href: '#',
                                    innerHTML: name,
                                    onclick: function() {
                                        on.emit(gridNode, 'showContentDetailPage', {item: item});
                                    }
                                });
                                actions.appendChild(urlA);
                            });
                        }
                    });
                    // サムネイル
                    var imageSrc = ChronologyFormatter.getImgUri(item);
                    if(imageSrc){
                        var imageA = dojo.create('a', {
                            href: imageSrc
                        });
                        imageA.appendChild(dojo.create('img', {
                            src: imageSrc,
                            width: '80'
                        }));
                        actions.appendChild(imageA);
                    }
                    // HTMLとしてウィジェットに紐付くDOMノードを返す
                    return actions;
                }
            },
            {field: 'chronologyActions', label: '対応履歴', sortable: false,
                className: lang.hitch(this, function(item) {
                    // ヘッダーの場合はclassNameを返さない
                    if (!item) {
                        return;
                    }else{
                        return 'chronology-action';
                    }
                }),
                renderCell: function(item){
                    var actions = dojo.create('div');
                    var len = item.chronologyActions.length;
                    var actionList = item.chronologyActions;
                    // 直近2件を表示
                    var showLength = ChronologyStore.getProp() ? Math.min(len, 2) : len;

                    for (var i=0; i<showLength; i++){
                        var action = dojo.create('div');
                        var crtTime = locale.format(new Date(actionList[i].crtTimestamp),{
                            datePattern: 'M/d'
                        });
                        action.innerHTML += '[' + crtTime + '] ';
                        var text = actionList[i].content;
                        if (ChronologyStore.getProp() && text.length>23){
                            text = text.substring(0,23) + '...';
                        }
                        action.innerHTML += text;

                        var imageSrc = ChronologyFormatter.getImgUri(actionList[i]);
                        if(imageSrc){
                            var aTag = dojo.create('a', {href: imageSrc});
                            var imgTag = dojo.create('img', {src: imageSrc, width: '80'});
                            aTag.appendChild(imgTag);
                            action.appendChild(aTag);
                        }

                        actions.appendChild(action);
                    }
                    var gridNode = this.grid.domNode;
                    if (ChronologyStore.getProp() && len > 2){
                        var explainWrapDiv = dojo.create('div');
                        var explainDiv = dojo.create('a', {href: '#'});
                        domStyle.set(explainDiv, 'color', '#999');
                        explainDiv.innerHTML = 'さらに' + (len-2) + '件の対応履歴';
                        explainDiv.onclick = function() {
                            on.emit(gridNode, 'showDetailPage', {item: item});
                        };
                        explainWrapDiv.appendChild(explainDiv);
                        actions.appendChild(explainWrapDiv);
                    }
                    // 対応履歴追加ボタン
                    var button = new Button({
                        style: 'text-align:right; float:right;',
                        label: '+',
                        onClick: function() {
                            on.emit(gridNode, 'ChronologyActionsButtonClick', {item: item});
                        }
                    });
                    actions.appendChild(button.domNode);
                    actions.style ='text-align:left';
                    // HTMLとしてウィジェットに紐付くDOMノードを返す
                    return actions;
                }
            },

            {field: 'hldStatus', label: '対応状況',
                id: 'hldStatus',
                formatter: lang.hitch(this, function(value){
                    if(!value){
                        return;
                    }
                    switch (value) {
                        case '9':
                            return '-';
                        case '0':
                            return '確認中';
                        case '1':
                            return '対応待ち';
                        case '2':
                            return '対応中';
                        case '3':
                            return '対応完了';
                        default:
                            return '';
                    }
                })
            },

            {field: 'municipalityNames', label: '宛先',
                id: 'municipalityNames',
                sortable: false,
                formatter: lang.hitch(this, function(value, item){
                    var destinationList = [];
                    array.forEach(item.chronologyShares, function(share){
                        if(share.destinationFlg === '1' &&
                            (!item.targetSendingSeqNum || share.sendingSeqNum === item.targetSendingSeqNum )){
                            // 宛先フラグが'ON'、且つ
                            // 対象発信グループ番号が設定されていない、又は、対象発信グループ番号と一致する
                            destinationList.push(share.shareName);
                        }
                    }, this);

                    if (!destinationList ||
                        destinationList.length === ChronologyMunicInfos.ALL_MUNICIPALITY_REGION_COUNT) {
                        return '全体';
                    }else if(destinationList.length > 2){
                        return destinationList[0] + '<br>他' + (destinationList.length-1) + '件';
                    }
                    return destinationList.join('<br>');
                })
            },

            // {field: 'chronologyShareNames', label: '宛先',
            //     id: 'chronologyShareNames',
            //     sortable: false,
            //     formatter: lang.hitch(this, function(value){
            //         if (!value || value.length === 0) {
            //             return '全体';
            //         }else if(value.length > 2){
            //             return value[0] + ' 他' + (value.length-1) + '件';
            //         }
            //         return value.join(',');
            //     })
            // },

            helper.column('senderName','配信元' , {
                sortable: false,
                formatter: lang.hitch(this, function(value, item){
                    var senderList = [];
                    array.forEach(item.chronologyShares, function(share){
                        if(share.senderFlg === '1' &&
                        ((!item.targetDestSendingSeqNum &&
                            (!share.sendingSeqNum || share.sendingSeqNum === 1)) || 
                            share.sendingSeqNum === item.targetDestSendingSeqNum )){
                            // 配信元フラグが'ON'、且つ
                            // 対象となる発信グループ番号が設定されていないで
                            //   - 発信グループ番号が設定されていない場合(外部からの登録)
                            //   - 発信グループ番号が1のばあい(クロノロジからの登録)
                            // 又は、対象発信グループ番号と一致する
                            senderList.push(share.shareName);
                        }
                    }, this);

                    if (!senderList || senderList.length === 0) {
                        // 配信元が登録されていない場合は外部システムからの送信
                        return '外部システム';
                    }else if(senderList.length > 2){
                        return senderList[0] + '<br>他' + (senderList.length-1) + '件';
                    }
                    return senderList.join('<br>');
                })
            }),

            // 表示・非表示になる列
            // display:noneを使うために普通のチェックボックスを使わず
            // 要素を入れ子にして作成している。
            {field: 'checkbox', label: '選択',
                id: 'checkbox',
                renderCell: function(item){
                    var checked = '';
                    if(item.displayAlwaysFlg==='1'){
                        checked = 'checked';
                    }
                    var checkBox = new CheckBox({
                        checked: checked,
                        name:'gridCheckbox'
                    });
                    var parent = dojo.create('div');
                    parent.className = 'checkboxParent';
                    // クロノロジIDを隠し持つ
                    var id = dojo.create('div');
                    id.innerHTML = item.chronologyId;
                    id.style = 'display:none';
                    id.name = 'id';
                    parent.appendChild(id);
                    parent.appendChild(checkBox.domNode);
                    return parent;
                }
            }
        ]
    });
});
