/**
 * 「雨量情報」画面用のモジュール
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom',
    'dojo/_base/lang',
    'dojo/text!./templates/BeginnerAdminPage.html',
    'dojo/topic',
    'dojo/on',
    'idis/control/Locator',
    'idis/view/page/_PageBase',
    'idis/view/dialog/DialogChain',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'dojo/dom-style',
    'dojo',
    'dojo/dom-construct',
    'idis/view/dialog/ConfirmDialog',
    'app/model/DisasterInfo',
    'dojo/Deferred',
    'dojo/promise/all',
    'dijit/popup',
    'dijit/TooltipDialog',
    'dijit/_WidgetBase',
    'dijit/_TemplatedMixin',
    './_BeginnerPageBase',
    // 以下、変数で受けないモジュール,
    'app/beginner/BeginnerLoginDialog',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/form/Textarea',
    'dijit/form/SimpleTextarea',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    './ShelterDetail',
    './MapDetail',
    './ReportDetail',
    './DisasterChanger'
], function(module, declare, dom, lang, template, topic, on, Locator, _PageBase, DialogChain,
        Router, Requester, UserInfo, UserType, domStyle, dojo, domConstruct, ConfirmDialog, DisasterInfo, Deferred, all,
        popup, TooltipDialog, _WidgetBase, _TemplatedMixin, _BeginnerPageBase) {

    return declare(module.id.replace(/\//g, '.'), _BeginnerPageBase, {
        // テンプレート文字列
        templateString: template,

		// ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--beginner/beginneradminpage',

        /**
         * マニュアルファイルPATH
         */
        FAIL_PATH: '/data/manual/',

        _hideDialog: false,

        constructor: function() {
            // 他の画面から戻ってきた時は、ダイアログを表示したくない
            this._hideDialog = Locator.getQuery().hideDialog;
        },

        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.beginnerLoginDialog);

            // dialogで災害名が変更された場合は、親ページに反映する
            this.own(topic.subscribe('app/beginner/DisasterChanger::updated',
            lang.hitch(this, function () {
                this.updateParentPage();
            })));
        },

        /**
         * DOM構築後に呼ばれる
         */
        postCreate: function() {
            this.inherited(arguments);

            this.initMenu();

            // ダイアログを表示する
            this.showDialog();

            // 掲示板の未読件数を取得して表示する
            this.setUnreadInfo();
        },

        startup: function() {
            this.inherited(arguments);
            this.setDisasterNameTips();

            if (UserInfo.getLoginMode() === '訓練') {
                // 訓練
                domStyle.set(this.labelForProd, 'display', 'none');
                domStyle.set(this.labelForTraining, 'display', '');
            } else {
                // 実災害
                domStyle.set(this.labelForProd, 'display', '');
                domStyle.set(this.labelForTraining, 'display', 'none');
            }
        },

        // destroy: function() {
        //     this.inherited(arguments);
        //     this.beginnerLoginDialog.destroy();
        // },

        initMenu: function() {

            domStyle.set(this.shelterArea.domNode, 'display', 'none');
            domStyle.set(this.reportArea.domNode, 'display', 'none');

            // 権限に応じて被害情報管理/避難所管理を表示切替
            switch (UserInfo.getUserType()) {
                // 県ユーザーの場合
                case UserType.PREFECTURE:{

                    switch (UserInfo.getRoleCd()) {

                         // 県 土木・農林・水産・その他部局
                        case 'R01014':
                        case 'R01015':
                        case 'R01016':
                        case 'R01017':
                                domStyle.set(this.reportArea.domNode, 'display', '');
                                break;
                        // 県・健康福祉
                        case 'R01013':
                                domStyle.set(this.shelterArea.domNode, 'display', '');
                                break;
                    }
                break;}

                //振興局ユーザーの場合
                case UserType.REGION:{
                    switch (UserInfo.getRoleCd()) {

                        // 振興局 土木・農林・水産・その他部局
                        case 'R02014':
                        case 'R02015':
                        case 'R02016':
                        case 'R02017':
                                domStyle.set(this.reportArea.domNode, 'display', '');
                                break;
                       // 振興局 健康福祉
                        case 'R02013':
                                domStyle.set(this.shelterArea.domNode, 'display', '');
                                break;
                    }
                break;}

                //市町ユーザーの場合
                case UserType.MUNICIPALITY:{
                    switch (UserInfo.getRoleCd()) {
                    // 市町 土木・農林・水産・その他部局
                    case 'R03014':
                    case 'R03015':
                    case 'R03016':
                    case 'R03017':
                            domStyle.set(this.reportArea.domNode, 'display', '');
                            break;
                    // 市町 健康福祉
                    case 'R03013':
                            domStyle.set(this.shelterArea.domNode, 'display', '');
                            break;
                    }
                    break;}

                //その他機関ユーザーの場合
                case UserType.OTHER_ORGAN:{
                        domStyle.set(this.reportArea.domNode, 'display', '');
                        break;}

                //間違えて初心者モードに入ってしまったユーザ用。被害エリアを設定する
                default:{
                    domStyle.set(this.reportArea.domNode, 'display', '');
                    break;}
            }
        },

        showDialog: function() {

            // 権限に応じて初期表示ダイアログを変更
            var isBeginner = false;
            if(this._hideDialog){
                // ダイアログを表示したくない場合（すでにログイン済みで、他の画面から遷移した時）
                return;
            }
            switch (UserInfo.getUserType()) {
                // 県ユーザーの場合
                case UserType.PREFECTURE:{

                    switch (UserInfo.getRoleCd()) {

                         // 県 土木・農林・水産・その他部局
                        case 'R01014':
                        case 'R01015':
                        case 'R01016':
                        case 'R01017':
                        // 県・健康福祉
                        case 'R01013':
                            isBeginner = true;
                            break;
                    }
                break;
            }

                //振興局ユーザーの場合
                case UserType.REGION:{
                    switch (UserInfo.getRoleCd()) {

                        // 振興局 土木・農林・水産・その他部局
                        case 'R02014':
                        case 'R02015':
                        case 'R02016':
                        case 'R02017':
                       // 振興局 健康福祉
                        case 'R02013':
                            isBeginner = true;
                            break;
                    }
                break;}

                //市町ユーザーの場合
                case UserType.MUNICIPALITY:{
                    switch (UserInfo.getRoleCd()) {
                    // 市町 土木・農林・水産・その他部局
                    case 'R03014':
                    case 'R03015':
                    case 'R03016':
                    case 'R03017':
                    // 市町 健康福祉
                    case 'R03013':
                        isBeginner = true;
                        break;
                    }
                    break;
                }

                //その他機関ユーザーの場合
                case UserType.OTHER_ORGAN:
                    isBeginner = true;
                    break;

                //間違えて初心者モードに入ってしまったユーザ用。初心者モード提供ではない。
                default:
                    break;
            }
            if (isBeginner){
                // 初心者モード提供対象者だった場合、素直に災害名選択をしてもらう。
                this.beginnerLoginDialog.getChildren()[0].initDialog({isBeginner: true});
                this.beginnerLoginDialog.set({
                                        title: '災害名選択',
                                        showCancel: false});
                this.beginnerLoginDialog.show();
            } else {
               // 初心者モード提供対象外のユーザには、ログアウトを促す。
                this.beginnerLoginDialog.getChildren()[0].initDialog({isBeginner: false});
                this.beginnerLoginDialog.set({
                                        title: 'エラー',
                                        showCancel: false});
                this.beginnerLoginDialog.show();
            }
        },

        updateParentPage: function() {
            location.reload();
        },

        onBbs: function(evt){
            evt.preventDefault();
            console.debug('[避難所情報：開設中の避難所を確認したい。]がクリックされました');
            // 掲示板画面へ遷移
            Router.moveTo('bbs');
        },

        setUnreadInfo: function () {
            var self = this;

            var bbsCount = 0;

            all({
                bbs: this.getBbsUnreadInfo()
            }).then(lang.hitch(this, function (result) {
                if (result.bbs) {
                    bbsCount = result.bbs.unreadCount;
                }
                self.setUnreadInfoList(bbsCount);
            }));

        },

        setUnreadInfoList: function(bbsCount){

            console.log(`未読件数は${bbsCount}件です。`);

            var unreadInfoList = dojo.byId('unreadInfoList');
            // domConstruct.empty(unreadInfoList);

            var openFlg = false;

            if (bbsCount > 0) {
                openFlg = true;
                var li = domConstruct.create('li');
                var u = domConstruct.create('u');
                var a = domConstruct.create('a',{
                    innerHTML: bbsCount + '件',
                    href: '#',
                    onclick: function() {
                        Router.moveTo('bbs');
                    }
                });
                var s1 = domConstruct.create('span', { innerHTML: '掲示板に未読情報が'});
                var s2 = domConstruct.create('span', { innerHTML: 'あります。'});
                domConstruct.place(a, u);
                domConstruct.place(s1, li);
                domConstruct.place(u, li);
                domConstruct.place(s2, li);
                domConstruct.place(li, unreadInfoList);
            } else {
                var li = domConstruct.create('li');
                var u = domConstruct.create('u');

                var s1 = domConstruct.create('span', { innerHTML: '掲示板に未読情報はありません。'});
                domConstruct.place(s1, li);
                domConstruct.place(u, li);
                domConstruct.place(li, unreadInfoList);
                domStyle.set(this.bbsLabel, 'background-color', '#E0E0E0');
                domStyle.set(this.bbsDetail, 'display', 'none');
            }
        },

        /**
         * 掲示板の未読件数取得
         */
        getBbsUnreadInfo: function() {
            var dfd = new Deferred();

            // F07002：掲示板（掲示板利用）
            if (!UserInfo.hasAuthz('F07002')) {
                dfd.resolve({"unreadCount":0});
                return dfd.promise;
            }

            var now = new Date();
            var registerTimestampTo = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
                now.getHours(), now.getMinutes());
            var pubTimestampFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
                now.getHours(), now.getMinutes());

            // 未読情報のみを取得する
            var url = '/api/bbses?disasterId=' + DisasterInfo.getDisasterId() +
                        '&notifyFlg2=02' + // 宛先ユーザへの通知：有
                        '&notifyFlg=1' + // 自分宛かつ要通知
                        '&unreadFlg=1' +
                        '&registerTimestampTo=' + registerTimestampTo.getTime() +
                        '&pubTimestampFrom=' + pubTimestampFrom.getTime();

            Requester.get(url).then(lang.hitch(this, function (data) {
                console.log('掲示板の未読件数：' + JSON.stringify(data.items.length));
                dfd.resolve({"unreadCount":data.items.length});
            }), function (error) {
                if (error.response.status === 401) {
                    // 自動ログアウトのメッセージをダイアログで表示
                    new ConfirmDialog({
                        title: '自動ログアウト',
                        content: '一定時間が経過した為、自動的にログアウトされました。<br>ログインしなおしてください。',
                        showCancel: false,
                        onOK: function() {
                            if (document.logout) {
                                // ログアウトフォームをサブミット
                                document.logout.submit();
                            }
                        }
                    }).show();
                } else {
                    console.error('掲示板の未読件数取得でエラー発生', error);
                    dfd.resolve({"unreadCount":0});
                }
            });
            return dfd.promise;
        },


        /**
         * 「すべての機能のマニュアルを確認する」がクリックされた時の挙動。
         */
        onAllManualDlLink: function() {
            var failname = 'Manual_for_User.pdf';
            // pdfを別タブで開く
            window.open(this.FAIL_PATH + failname, '全体マニュアル');
        },


    });
});
