/**
 * 親管理番号設定ダイアログ用グリッド
 * @module app/damage/ParentDamageSelectorGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/date/locale',
    'dgrid/Selector',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, locale, Selector, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selector],
        /** @lends module:app/damage/ParentDamageSelectorGrid~ProvideTemplatePage# */ {

        /**
         * 各列の定義
         * @type {Object[]}
         */
        baseClass: 'parentdamage-selector',

        collection: this.store,
        columns: [
            helper.column('admNum','管理番号'),
            helper.column('seqNum','報番号'),
            {field: 'reportCrtTimestamp', label: '報告日時'},
            helper.column('damageAddress','発生場所')
        ]
    });
});
