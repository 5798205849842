/**
* 被害状況の新規登録・詳細・続報登録画面用の基底モジュール。
* @module app/damage/_DamageReportRegisterPageBase
*/
define([
    'module',
    'app/model/LayerStore',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/date/locale',
    'dojo/dom',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-geometry',
    'dojo/dom-style',
    'dojo/json',
    'dojo/on',
    'dojo/query',
    'dojo/request/iframe',
    'dojo/topic',
    'dojo/window',
    'dstore/Rest',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'dijit/registry',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/map/IdisMap',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'app/model/DisasterInfo',
    'idis/service/GeoService',
    'idis/service/Requester',
    'idis/util/FilesUtils',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/IdisDialog',
    'idis/view/draw/_DrawUtil',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'leaflet',
    '../config',
    'app/map/baselayer/BaseLayerPane',
    'esri-leaflet-geocoder',
    'exif-js/exif',
    '../draw/DrawPanel',
    'app/damage/consts/DamageType',
    './_DamageReportPageBase',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dojox/form/Uploader',
    'dojox/layout/FloatingPane',
    'idis/view/form/Button',
    'idis/view/form/RadioGroup',
    '../view/form/LayerDirectorySelector',
    '../view/form/DisasterSelector',
    '../view/form/OrganizationSelector',
    '../view/form/CustomizableMunicipalitySelector',
    '../view/form/MunicipalitySelector',
    './ParentDamageSelector'
], function(module, LayerStore, declare, lang, array, locale, dom, domClass, domConstruct, domGeometry, domStyle,
    json, on, query, iframe, topic, winUtils, Rest, Menu, MenuItem, popup, TooltipDialog, registry, Locator, Router,
    IdisMap, UserInfo, UserType, DisasterInfo, GeoService, Requester, FilesUtils, DialogChain, IdisDialog, DrawUtil,
    _PageBase, Loader, leaflet, config, BaseLayerPane, geocoder, exif, DrawPanel, DamageType, _DamageReportPageBase) {
    /* globals LLtoUSNG */
    /* globals USNGtoLL */
    // GeoServiceを初期化
    var _geoService = new GeoService({url: config.geocode && config.geocode.url});

    /**
     * 被害状況新規登録・詳細・続報登録画面。
     * @class _DamageReportPageBase
     * @extends module:idis/view/page/_DamageReportPageBase~_DamageReportPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _DamageReportPageBase,
        /** @lends module:app/damage/_DamageReportPageBase~_DamageReportPageBase# */ {

        //作図ダイアログ
        drawPanel : null,

        DRAW_INIT_ID: '/app/draw/DrawPanel::SettingDrawEvents',
        JSONIZE_RQST: '/app/draw/DrawPanel::drawnDataJsonizeRequest',
        JSONIZE_DONE: '/app/draw/DrawPanel::drawnDataJsonizeResponse',
        DRAW_BY_JSON: '/app/draw/DrawPanel::drawGeoJSONToLayer',
        REMOVE_ALL  : '/app/draw/DrawPanel::removeAllLayers',
        //イベントは破棄しない、全作図モードをOFFにする
        DISABLE_DRAW: '/app/draw/DrawPanel::hideAndDisableDraw',
        //mapに取り付けた全てのイベントを破棄する。
        DRAW_EVT_OFF: '/app/draw/DrawPanel::removeDrawAllEvent',
        // forIE anchorにOnclickでPublishをして、msSaveへ情報を渡す。
        DOWNLOAD_4IE   : '/app/damage/MapPage::download4IE',

        DAMAGE_TYPE_SELECTED: 'app/damage/damagetype/DamageTypeSelector::selected',
        DAMAGE_TYPE_RELEASED: 'app/damage/damagetype/DamageTypeSelector::released',

        /**
         * 被害状況ID
         * @private
         */
        _damageReportId: null,

        /**
         * 続報番号
         * @private
         */
        _seqNum: null,

        /**
         * 外部管理番号
         * @private
         */
        _extAdmNum: null,

        /**
         * 被害種別 リスト
         * @private
         */
        _damageType: null,
        _damageTypeList: null,

        /**
         * 発生場所 緯度
         * @private
         */
        _damageAddressPointLat: null,

        /**
         * 発生場所 経度
         * @private
         */
        _damageAddressPointLng: null,

        _hasResizedSubMap: false,

        _DETAIL_FIELD_KEY_MAP: {
            '01' : 'home',
            '02' : 'life',
            '03' : 'educ',
            '04' : 'hosp',
            '05' : 'gbld',
            '06' : 'sedi',
            '07' : 'fire',
            '08' : 'rivr',
            '09' : 'road',
            '10' : 'agri',
            '14' : 'forest',
            '11' : 'othr'
        },

        /**
         * leave
         */
        leave: function() {
            //一覧画面に移動
            Router.moveTo('report', {municipalityCd: this._municipalityCd});
        },

        /**
         * 地図を初期化する。
         */
        initMap: function(latitude, longitude) {
            var latlng = null;
            if(latitude && longitude) {
                latlng = [latitude, longitude];
            } else {
                latlng = [config.map.latitude, config.map.longitude];
            }

            this.map = new IdisMap(this.mapNode, {
                config: config.map,
                keyboard: false, // コメント時に+/-が使用できないため
                touchExtend : false,
                minZoom: 9,
                drawControlTooltips:false}
            ).setView(latlng, 12);
                // destroy時にmapを破棄するよう設定
                this.own(this.map);
                this.own(on(this.map, 'click', lang.hitch(this, function(e) {
                    // 作図ダイアログが閉じられているとき住所取得モードとする
                    if(this.drawPanel._ActiveMode === null) {
                        this.pointLat = e.latlng.lat;
                        this.pointLng = e.latlng.lng;
                        this.addMark(this.pointLat, this.pointLng, this);
                    }
                })
            ));


            // 作図パネルを生成
            this.createDrawPanel();
            topic.publish(this.DRAW_INIT_ID, this.map);
            topic.subscribe(this.JSONIZE_DONE, lang.hitch(this, function(args){
                this.drawJson = args;
            }));


            // IE対応
            // イベントを管理する人は各Mapに必要。
            // TODO pub/subの方がよいか？
            if(DrawUtil._isIE()){DrawUtil._setPopupEvtForMap(this);}
            this.borderContainer.resize();
        },

        /**
         * 対応状況のTipsを付与する。
         */
        setHldStatusTips: function() {
            // 避難理由
            var hldStatusTips = new TooltipDialog({
                id: 'hldStatusTips',
                style: 'width: 300px;',
                content: '<p>詳細な対応内容は、画面右上の「対応履歴」タブ内で' +
                    '登録・編集が可能です。</p>'
            });
            this.own(hldStatusTips);
            on(dom.byId('hldStatusLabel'), 'mouseover', function(){
                popup.open({
                    popup: hldStatusTips,
                    around: dom.byId('hldStatusLabel')
                });
            });
            on(dom.byId('hldStatusLabel'), 'mouseleave', function(){
                popup.close(hldStatusTips);
            });
        },

         /**
          * 原因、内容のtips
          */
        setContentTips: function() {
            var contentTips = new TooltipDialog({
                id: 'contentTips',
                style: 'width: 300px;',
                content: '<p>対応については、<br>' +
                    '対応履歴タブのコメント欄に入力してください。<br>' +
                    '対応履歴タブは、被害を登録後<br>' +
                    '詳細画面を開いた際に、画面右側に表示されます。</p>' 
            });
            this.own(contentTips);
            on(dom.byId('contentLabel'), 'mouseover', function(){
                popup.open({
                    popup: contentTips,
                    around: dom.byId('contentLabel')
                });
            });
            on(dom.byId('contentLabel'), 'mouseleave', function(){
                popup.close(contentTips);
            });
        },

        /**
         * サブ地図を初期化する。
         */
        initSubMap: function(latitude, longitude) {
            var latlng = null;
            if(latitude && longitude) {
                latlng = [latitude, longitude];
            } else {
                latlng = [config.map.latitude, config.map.longitude];
            }

            this.submap = new IdisMap(this.subMapNode, {
                config: config.map,
                keyboard: false, // コメント時に+/-が使用できないため
                touchExtend : false,
                maxZoom: 18,
                minZoom: 9,
                drawControlTooltips:false}
            ).setView(latlng, 12);

            // destroy時にmapを破棄するよう設定
            this.own(this.submap);
            this.own(on(this.submap, 'click', lang.hitch(this, function(e) {
              // 作図ダイアログが閉じられているとき住所取得モードとする
                if(this.drawPanel._ActiveMode === null) {
                    this.pointLat = e.latlng.lat;
                    this.pointLng = e.latlng.lng;
                    this.addMark(this.pointLat, this.pointLng, this);
                }
            })));

        },

        /**
         * サブ地図から「地図を拡大」ボタンでメイン地図に切り替える。
         */
        onClickExpandMapButton: function(evt){
            evt.preventDefault();
            this.tabContainer.selectChild(this.tab1);
        },

        /**
         * 人的被害の数値項目が変更された場合の処理
         */
        onChangeDeadNum: function(evt) {
            // 変更前の値取得
            var oldNum = this.deadNumOld.value;
            // 値が変更されている場合、old側に変更後の値をセット
            if (this.changeHumanDamageNum(oldNum, evt, 1)) {
                this.deadNumOld.set('value', evt);
            }
        },

        /**
         * 人的被害の数値項目が変更された場合の処理
         */
        onChangeUnknownNum: function(evt) {
            // 変更前の値取得
            var oldNum = this.unknownNumOld.value;
            // 値が変更されている場合、old側に変更後の値をセット
            if (this.changeHumanDamageNum(oldNum, evt, 2)) {
                this.unknownNumOld.set('value', evt);
            }
        },

        /**
         * 人的被害の数値項目が変更された場合の処理
         */
        onChangeSeriousNum: function(evt) {
            // 変更前の値取得
            var oldNum = this.seriousNumOld.value;
            // 値が変更されている場合、old側に変更後の値をセット
            if (this.changeHumanDamageNum(oldNum, evt, 3)) {
                this.seriousNumOld.set('value', evt);
            }
        },

        /**
         * 人的被害の数値項目が変更された場合の処理
         */
        onChangeMildNum: function(evt) {
            // 変更前の値取得
            var oldNum = this.mildNumOld.value;
            // 値が変更されている場合、old側に変更後の値をセット
            if (this.changeHumanDamageNum(oldNum, evt, 4)) {
                this.mildNumOld.set('value', evt);
            }
        },

        /**
         * 人的被害の数値項目が変更された場合の処理
         */
        onChangeUnknownSympNum: function(evt) {
            // 変更前の値取得
            var oldNum = this.unknownSympNumOld.value;
            // 値が変更されている場合、old側に変更後の値をセット
            if (this.changeHumanDamageNum(oldNum, evt, 5)) {
                this.unknownSympNumOld.set('value', evt);
            }
        },

        /**
         * 人的被害の数値項目が変更された場合の処理
         */
        changeHumanDamageNum: function(oldNum, newNum, status) {

            // 変更後の値と変更前の値が数値かチェック
            if (isNaN(oldNum) || isNaN(newNum)) {
                // 数値以外がセットされている場合は処理終了
                return false;
            }

            // ブランクの場合、明示的に0をセット
            if (oldNum === '') {
                oldNum = 0;
            }
            if (newNum === '') {
                newNum = 0;
            }

            var oldNumInt  = parseInt(oldNum, 10);
            var newNumInt = parseInt(newNum, 10);
            // 変更後の値と変更前の値を比較
            if (oldNumInt < newNumInt) {
                // 人数が増加している場合、人的被害詳細に入力フォーマットを追加
                for (var i = oldNumInt + 1; i <= newNumInt; i++) {
                    // 数値が複数増加する場合も考慮し、変更前 < 変更後の場合に繰り返し処理を行う
                    var value = this.createHumanDamageDetail(status, i);
                    this.setHumanDamageDetail(value);
                }

                // 人的被害詳細の入力文字数が4000字を超過している場合、ダイアログを表示
                if (this.humanDamageDetail.value.length > 4000) {
                    this.chain.info('人的被害詳細について、最大文字数を超過しています。<br>当項目は4000文字以内で入力してください。', '警告');
                }
                return true;
            } else if (oldNumInt > newNumInt) {
                // 人数が減少している場合、ダイアログを表示
                this.chain.info('人的被害詳細欄を編集してください', '警告');
                return true;
            } else if (oldNumInt === newNumInt) {
                // 人数の増減がない場合、何もせず処理終了
                return false;
            }
            return false;
        },

        /**
         * 人的被害の数値項目が変更された場合の処理
         */
        createHumanDamageDetail: function(status, num) {
            var value = '';

            switch (status) {
                case 1:
                    // 死者数変更
                    value = value + '死者（' + num + '人目）\n';
                    value = value + '　性別：\n';
                    value = value + '　年代：\n';
                    value = value + '　発見時の状況：\n';
                    value = value + '　備考：\n';
                    break;
                case 2:
                    // 行方不明者数変更
                    value = value + '行方不明者（' + num + '人目）\n';
                    value = value + '　性別：\n';
                    value = value + '　年代：\n';
                    value = value + '　発見時の状況：\n';
                    value = value + '　備考：\n';
                    break;
                case 3:
                    // 重傷者数変更
                    value = value + '重傷者（' + num + '人目）\n';
                case 4:
                    // 軽傷者数変更
                    if (status !== 3) {
                        value = value + '軽傷者（' + num + '人目）\n';
                    }
                    value = value + '　性別：\n';
                    value = value + '　年代：\n';
                    value = value + '　負傷時の状況：\n';
                    value = value + '　負傷した部位、程度：\n';
                    value = value + '　備考：\n';
                    break;
                case 5:
                    // 調査中数変更
                    value = value + '調査中（' + num + '人目）\n';
                    value = value + '　備考：\n';
                    break;
                default:
                    // その他は存在しない想定
                    break;
            }
            return value;
        },

        /**
         * 人的被害の数値項目が変更された場合の処理
         */
        setHumanDamageDetail: function(value) {
            var old = this.humanDamageDetail.value;
            if (old !== '') {
                old = old + '\n';
            }
            this.humanDamageDetail.set('value', old + value);
        },

        /**
         * タブを切り替える際に、メイン地図とサブ地図の座標・縮尺を整合させる
         */
        showTab1: function(){
            if(this.map && this.submap){
                var latlng = this.submap.getCenter();
                var zoom = this.submap.getZoom();
                this.map.setView(latlng, zoom);
            }
        },

        showTab2: function(){
            if(this.map && this.submap){
                var latlng = this.map.getCenter();
                var zoom = this.map.getZoom();
                this.submap.setView(latlng, zoom);
                // 初めての遷移時は、サブ地図のリサイズをおこなう
                if(!this._hasResizedSubMap){
                    this.submap.invalidateSize();
                    this._hasResizedSubMap = true;
                }

            }
        },

        /**
         * 作図ペインを生成する。
         */
        createDrawPanel: function() {
            if(this.drawPanel === null){
                this.own(
                    this.drawPanel = new DrawPanel({
                        map     : this.map,
                        'class'  : 'drawPanel-NonModal',
                        dispType : 'damage'
                    }));
            }
        },

        /**
         * 人的被害関連入力フィールドの表示/非表示切り替え制御する。
         */
        onChangeHumanDamageFlg: function (evt) {
            // フラグ値を制御
            console.log('onChangeHumanDamageFlg');
            console.log('evt.target.value:',evt.target.value);
            if(evt.target.value) {
                if(evt.target.value === '0') {
                    this.humanDamage.style.display = '';
                    this.tabContainer.selectChild(this.tab2);
                }else{
                    this.humanDamage.style.display = 'none';
                }
            }
        },

        /**
         * 被害種別変更時のイベントを設定
         */
        controlDamageDetailContents: function() {
        if(registry.byId('damageType')){
            registry.byId('damageType').on('change', lang.hitch(this, function(value){
                this.tabContainer.selectChild(this.tab2);
                this.changeDamageDetailContents(value);
            }));
        }
        },

        setDamageType: function(data){
            this.tabContainer.selectChild(this.tab2);
            this._damageType = data.mainTypeIds[0]; //FIXME: 一旦DBに送るのはメインの被害種別のみ
            this._damageTypeList = data.mainTypeIds.join(',');
            this.changeMultipleDamageDetailContents(data.mainTypeIds);
//             var damageTypeList = [];
//             damageTypeList.push(data.mainTypeId);
//             array.forEach(data.subTypeIds, function(id){
//                 damageTypeList.push(id);
//             });
//
//             this.changeMultipleDamageDetailContents(damageTypeList);
        },

        releaseDamageType: function(){
            this._damageType = null;
            this._damageTypeList = null;
            this.changeMultipleDamageDetailContents([]);
        },

        /**
         * 対応状況編集ダイアログを表示
         */
        showUpdateStatusDialog: function(){
            this.innerStatusDialog.setDialog({
                reportStatus: this._reportStatus,
                municipalityCd : this._municipalityCd
            });
            this.damageReportUpdateStatusDialog.show();
        },

        /**
         * 報告状況を初期化する
         */
        initReportStatus: function(reportStatus){
            if(!reportStatus){
                // 報告状況が与えられていない場合、ユーザ種別によってデフォルト値を決定
                switch(UserInfo.getUserType()){
                    case UserType.PREFECTURE:
                    case UserType.REGION:
                    case UserType.OTHER_ORGAN:
                        reportStatus = '31';
                        break;
                    case UserType.MUNICIPALITY:
                    default:
                        reportStatus = '11';
                        break;
                }
            }

            // 報告状況に応じて変更ボタンの出し分けをする
            if(UserInfo.isSysAdmin()){
                // システム管理者は常に変更可
                domStyle.set(this.updateStatusButton.domNode, 'display', '');
            // }else if(UserInfo.is4OrgsRole()){
            //     // 4機関ユーザ：[11,31,41]から[11,31,41]に変更可
            //     if(!(reportStatus === '11' || reportStatus === '31' || reportStatus === '41')){
            //         domStyle.set(this.updateStatusButton.domNode, 'display', 'none');
            //     }
            }else if(UserInfo.getUserType() === UserType.PREFECTURE){
                //県ユーザ：[31,41]から[31,41]に変更可
                if(!(reportStatus === '31' || reportStatus === '41')){
                    domStyle.set(this.updateStatusButton.domNode, 'display', 'none');
                }
            }else if(UserInfo.getUserType() === UserType.REGION){
                //県民局ユーザ：[31,41]から[31,41]に変更可
                if(!(reportStatus === '31' || reportStatus === '41')){
                    domStyle.set(this.updateStatusButton.domNode, 'display', 'none');
                }
            }else if(UserInfo.getUserType() === UserType.MUNICIPALITY){
                //市町ユーザ：[11,31,41]から[11,31,41]に変更可
                if(!(reportStatus === '11' || reportStatus === '31' || reportStatus === '41')){
                    domStyle.set(this.updateStatusButton.domNode, 'display', 'none');
                }
            }else if(UserInfo.getUserType() === UserType.OTHER_ORGAN){
                //その他関係機関ユーザ：[11,31,41]から[11,31,41]に変更可
                if(!(reportStatus === '11' || reportStatus === '31' || reportStatus === '41')){
                    domStyle.set(this.updateStatusButton.domNode, 'display', 'none');
                }
            }else{
                //その他：変更不可
                domStyle.set(this.updateStatusButton.domNode, 'display', 'none');
            }

            // 報告状況更新ダイアログにイベントリスナーを設定
            var stasusDialog = this.damageReportUpdateStatusDialog;
            var statusPage = this.innerStatusDialog;
            statusPage.on('send', lang.hitch(this, function(evt) {
                //Dialogのフォーム内容をreportStatus(変数), HTMLの両方に反映
                var reportStatus = evt.reportStatus;
                lang.hitch(this, this.setReportStatus(reportStatus));
                stasusDialog.hide();
            }));

            // 対応状況をセットする
            this.setReportStatus(reportStatus);
            // ダイアログのラジオボタン制御をする
            this.innerStatusDialog.initDialog();
        },


        /**
         * 報告状況を更新する
         */
        setReportStatus: function(reportStatus){
            this._reportStatus = reportStatus;
            if(reportStatus === '11'){
                this.reportedStatus11.style.display = '';
                this.reportedStatus31.style.display = 'none';
                this.reportedStatus41.style.display = 'none';
            }else if(reportStatus === '31'){
                this.reportedStatus11.style.display = 'none';
                this.reportedStatus31.style.display = '';
                this.reportedStatus41.style.display = 'none';
            }else if(reportStatus === '41'){
                this.reportedStatus11.style.display = 'none';
                this.reportedStatus31.style.display = 'none';
                this.reportedStatus41.style.display = '';
            }
        },

        /**
         * 被害詳細関連入力フィールドの表示/非表示を切り替える。
         */
        changeDamageDetailContents: function(value, updList) {
            var typeList = value.split(',');
            var validTypeFlg = false;
            array.forEach(Object.keys(this._DETAIL_FIELD_KEY_MAP), lang.hitch(this, function(key) {
                var keyName = this._DETAIL_FIELD_KEY_MAP[key];
                for(var i = 0; i < typeList.length; i++) {
                    if(key === typeList[i]){
                        this[keyName].style.display = '';
                        validTypeFlg = true;
                        break;
                    }else{
                        this[keyName].style.display = 'none';
                    }
                }
            }));

            // 被害種別を変更する場合は、強調表示を無効化する
            if(updList.length > 0) {
                array.forEach(updList, function(propName) {
                    if(propName === 'damageName') {
                        query('.damageNameLabel').forEach(function(node) {
                            domClass.remove(node, 'is-changed');
                        });
                    } else {
                        if(propName === 'notifierCategory' || propName === 'notifierName'){
                            propName = 'notifier';
                        }
                        if(propName === 'organization' || propName === 'reportAuthorName'){
                            propName = 'preAuthor';
                        }
                        if(propName === 'reportUpdTimestamp'){
                            propName = 'preReportTimestamp';
                        }
                        if(dom.byId(propName + 'Label')){
                            domClass.remove(dom.byId(propName + 'Label'), 'is-changed');
                        }
                    }
                });
            }

            // 被害種別が選択されていない場合、説明文を表示
            if(validTypeFlg){
                this.damageDescription.style.display = 'none';
            }else{
                this.damageDescription.style.display = '';
            }
        },

        /**
         * 被害詳細関連入力フィールドの表示/非表示を切り替える。（複数の被害を許容する場合）
         */
        changeMultipleDamageDetailContents: function(list) {
            var validTypeFlg = false;
            array.forEach(Object.keys(this._DETAIL_FIELD_KEY_MAP), lang.hitch(this, function(key) {
                var keyName = this._DETAIL_FIELD_KEY_MAP[key];
                if(list.indexOf(key) !== -1){ //含まれる場合
                    this[keyName].style.display = '';
                    validTypeFlg = true;
                }else{
                    this[keyName].style.display = 'none';
                }
            }));

            // 被害種別が選択されていない場合、説明文を表示
            if(validTypeFlg){
                this.damageDescription.style.display = 'none';
            }else{
                this.damageDescription.style.display = '';
            }
        },

        /**
         * 作図ダイアログを表示する。
         */
        showDrawPanelDialog: function(){
            this.drawPanel.show();
        },


        /**
         * UTMグリッドの表示を切り替える。
         */
        toggleUtmLayers: function () {
            var isActive = this.map.toggleUtmLayers();
            domClass.toggle(this.utmGridButton, 'is-checked', isActive);
        },

            /**
         * 入力値の妥当性をチェックする。
         */
        validateForm: function(data) {
            if (!data.municipalityCd || data.municipalityCd === '') {
                this.chain.info('市町を選択してください。', '入力エラー');
                return false;
            }
            if (!data.damageType || data.damageType === '') {
                this.chain.info('種別を選択してください。', '入力エラー');
                return false;
            }
            if (!data.reportAuthorName || data.reportAuthorName === '') {
                this.chain.info('報告者氏名を入力してください。', '入力エラー');
                return false;
            }


            var lat = data.damageLatitude;
            var lng = data.damageLongitude;
            // 緯度経度が片方しか入力されていない場合
            if((lat && !lng) || (!lat && lng)){
                this.chain.info('緯度・経度の範囲が不正です。', '入力エラー');
                return false;
            }
            // 緯度経度がともに入力されているにも関わらず、値が不正である場合
            if(lat && lng && !this.validateLatLng(lat, lng)){
                this.chain.info('緯度・経度の範囲が不正です。', '入力エラー');
                return false;
            }
            if (!data.reportTimestamp || data.reportTimestamp === '') {
                this.chain.info('報告日時を入力してください。', '入力エラー');
                return false;
            }
            if (!data.reportTimestamp || data.reportTimestamp === '') {
                var now = new Date();
                this.reportTimestamp._setValueAttr(now);
                data.reportTimestamp = this.reportTimestamp._getValueAttr();
            }
            return true;
        },

        /**
         * 緯度経度が不正でないかチェックする(変換ボタン用)
         */
        validateLatLng: function(lat, lng) {
            if(!lat || !lng){
                console.debug('緯度または経度が入力されていません');
                return false;
            }
            if(isNaN(lat) || isNaN(lng)){
                console.debug('緯度または経度が数字ではありません');
                return false;
            }
            // //TODO: 閾値は決め打ちなので妥当性を要検討
            // var latMin = config.map.latitude - 0.5;
            // var latMax = config.map.latitude + 0.5;
            // var lngMin = config.map.longitude - 0.7;
            // var lngMax = config.map.longitude + 0.7;
            // if(lat < latMin || latMax < lat || lng < lngMin || lngMax < lng){
            //     console.debug('緯度・経度の範囲が不正です');
            //     return false;
            // }

            return true;
        },

        /**
         * 緯度経度 → 地図・住所・UTM
         */
        damageLatLngToMap: function() {
            var lat = this.damageLatitude.get('value');
            var lng = this.damageLongitude.get('value');
            // 緯度経度が不正であれば何もしない
            if(!this.validateLatLng(lat, lng)){
                this.chain.info('緯度経度が正しくありません。', 'エラー');
                return false;
            }
            //地図にプロット
            this.pointLat = lat;
            this.pointLng = lng;
            this.addMark(lat, lng);
            var latlng = [lat, lng];
            this.map.setView(latlng);
            this.submap.setView(latlng);
            //UTMコードをセット
            var utmCode = this.pointToUtm();
            this.damageUtm.set('value', utmCode);
            //住所をセット
            this.pointToAddress(this.damageAddress, this.damageAddressNum);
        },

        /**
         * UTM → 地図・住所・緯度経度
         */
        damageUtmToMap: function() {
            var latlng = [];
            var code = this.damageUtm.get('value');
            // UTMコードが未入力であれば何もしない
            if(!code){
                this.chain.info('UTMコードが入力されていません。', 'エラー');
                return false;
            }
            USNGtoLL(code, latlng);
            // 返り値の形式が不正であれば何もしない
            if(latlng.length !== 2){
                this.chain.info('UTMコードを読み取れませんでした。', 'エラー');
                return false;
            }
            var lat = latlng[0];
            var lng = latlng[1];
            // 返ってきた緯度経度が不正であれば何もしない
            if(!this.validateLatLng(lat, lng)){
                this.chain.info('UTMコードを読み取れませんでした。', 'エラー');
                return false;
            }
            //地図にプロット
            this.pointLat = lat;
            this.pointLng = lng;
            this.addMark(lat, lng);
            this.map.setView(latlng);
            this.submap.setView(latlng);
            //緯度経度をセット
            this.damageLatitude.set('value', this.pointLat);
            this.damageLongitude.set('value', this.pointLng);
            //住所をセット
            this.pointToAddress(this.damageAddress, this.damageAddressNum);
        },

        /**
         * 地図 → 住所・緯度経度・UTM
         */
        mapToDamageAddress: function(evt) {
            //aタグ経由になったのでイベント抑止
            evt.preventDefault();
            //地図がポイントされていなければ何もしない
            if(this.pointLat === '' || this.pointLng === '') {
                this.chain.info('地図が選択されていません。', 'エラー');
                return;
            }
            //住所をセット
            this.pointToAddress(this.damageAddress, this.damageAddressNum);
            //緯度経度をセット
            this.damageLatitude.set('value', this.pointLat);
            this.damageLongitude.set('value', this.pointLng);
            //UTMコードをセット
            var utmCode = this.pointToUtm();
            this.damageUtm.set('value', utmCode);
        },

        /**
         * pointLat/Lng → UTMコード
         */
        pointToUtm: function() {
            //pointがセットされていなければ何もしない
            if(this.pointLat === '' || this.pointLng === '') {
                console.debug('not pointed map');
                this.chain.info('地図が選択されていません。', 'エラー');
                return;
            }
            var lat = this.pointLat;
            var lng = this.pointLng;

            // 経度は-180度から180度までの間に収める
            if(lng > 180){
                lng -= 360;
            }
            if(lng < -180){
                lng += 360;
            }
            // UTMを取得（経度は-180 < x < 360の範囲に収めないとエラーになる）
            var utmCode = LLtoUSNG(lat, lng, 3).replace(/\s+/g, '');
            return utmCode;
        },

        /**
         * pointLat/Lng → 住所
         */
        pointToAddress: function(addressWidget, addressNumWidget) {
            //pointがセットされていなければ何もしない
            if(this.pointLat === '' || this.pointLng === '') {
                console.debug('not pointed map');
                this.chain.info('地図が選択されていません。', 'エラー');
                return;
            }
            console.debug('start reverse geocoding');

            _geoService.reverseGeocode(leaflet.latLng({
                lat: this.pointLat,
                lng: this.pointLng
            })).then(lang.hitch(this, function(res) {

                var address = res.address.Address;
                if(address){
                    var prefIndex = address.indexOf('県');
                    var addStartIndex = -1;
                    //県名はカット。
                    if(prefIndex !== -1){
                        addStartIndex = prefIndex;
                    }
                    var addressNoPref = address.substring(addStartIndex + 1);
                    // 番地っぽい文字列を抽出する正規表現
                    var pattern = /([0-9０-９]+|[一二三四五六七八九十百千万]+)*(([0-9０-９]+|[一二三四五六七八九十百千万]+)|(丁目|丁|番地|番|号|-|‐|ー|−|の|東|西|南|北){1,2})*(([0-9０-９]+|[一二三四五六七八九十百千万]}+)|(丁目|丁|番地|番|号){1,2})/;
                    var addressNumResult = addressNoPref.match(pattern);
                    // 地番以外をセット
                    addressWidget.set('value',  addressNoPref.substring(0, addressNumResult.index));
                    // 地番をセット
                    addressNumWidget.set('value', addressNumResult[0]);
                }else{
                    addressWidget.set('value', '');
                    addressNumWidget.set('value', '');
                    this.chain.info('住所を取得できませんでした。', 'エラー');
                }

            }), lang.hitch(this, function() {
                addressWidget.set('value', '');
                addressNumWidget.set('value', '');
                this.chain.info('住所を取得できませんでした。', 'エラー');
            }));

            console.debug('end reverse geocoding (address: ' +
            		addressWidget.value + ')');
        },

        /**
         * 住所 → 地図・緯度経度・UTM
         */
        damageAddressToMap: function() {
            var address = this.damageAddress.get('value') + this.damageAddressNum.get('value');

            console.debug('start geocoding (address: ' + address + ')');
            // 住所がなければ何もしない
            if(!address) {
                console.log('not input address');
                this.chain.info('住所を入力してください。', 'エラー');
                return;
            }
            var prefIndex = address.indexOf('県');
            //県名がない場合、他県の地名を探し出す可能性があるので「長崎県」をつける
            if(prefIndex === -1){
                address = '長崎県' + address;
            }
            _geoService.geocode(address).then(lang.hitch(this, function(results) {
                if(results.length > 0) {
                    // 緯度経度がうまく返ってきた場合にだけ処理する
                    //地図をセット
                    var latlng = [results[0].latlng.lat, results[0].latlng.lng];
                    this.pointLat = results[0].latlng.lat;
                    this.pointLng = results[0].latlng.lng;
                    this.addMark(this.pointLat, this.pointLng);
                    this.map.setView(latlng, 11);
                    this.submap.setView(latlng, 11);

                    // 緯度経度をセット
                    this.damageLatitude.set('value', this.pointLat);
                    this.damageLongitude.set('value', this.pointLng);

                    //UTMコードをセット
                    var utmCode = this.pointToUtm();
                    this.damageUtm.set('value', utmCode);
                } else {
                    console.debug('address is not correct');
                    this.chain.info('住所から位置情報を取得できませんでした。', 'エラー');
                }
            })
            );

            console.debug('end reverse geocoding (latitude: ' +
            		this.pointLat + ', longitude: ' + this.pointLng + ')');
        },
        
        
        /**
         * 登録・更新時、位置情報を自動補完する。
         */
        damageAddressAutoComplete: function(sendData) {
            var address = sendData.damageAddress + sendData.damageAddressNum;
            var lat = sendData.damageLatitude;
            var lng = sendData.damageLongitude;
            var utm = sendData.damageUtm;

            // 住所がなければ何もしない
            if(!address) {
                return false;
            }
            // 緯度経度・UTMがあるならば何もしない
            if(lat && lng && utm) {
                return false;
            }
            var prefIndex = address.indexOf('県');
            //県名がない場合、他県の地名を探し出す可能性があるので「長崎県」をつける
            if(prefIndex === -1){
                address = '長崎県' + address;
            }
            var dfd = _geoService.geocode(address).then(lang.hitch(this, function(results) {
                if(results.length > 0) {
                    // 緯度経度がうまく返ってきた場合にだけ処理する
                    this.pointLat = results[0].latlng.lat;
                    this.pointLng = results[0].latlng.lng;

                    // 緯度経度をセット
                    if(!lat || ! lng){
                        sendData.damageLatitude = this.pointLat;
                        sendData.damageLongitude = this.pointLng;
                        //一応画面上にも表示
                        this.damageLatitude.set('value', this.pointLat);
                        this.damageLongitude.set('value', this.pointLng);
                    }
                    
                    //UTMコードをセット
                    if(!utm){
                        var utmCode = this.pointToUtm();
                        sendData.damageUtm = utmCode;
                        //一応画面上にも表示
                        this.damageUtm.set('value', utmCode);
                    }
                        
                } else {
                    console.debug('住所から緯度経度を取得できませんでした。');
                }
            })
            );
            return dfd;
        },

        /**
         * マーカーを追加する。サブ地図がある場合は両方の地図でマーカーを共有する
         */
        addMark: function(lat, lng) {
            this.removeMark();
            this.marker = leaflet.marker([lat, lng]).addTo(this.map);
            this.submarker = leaflet.marker([lat, lng]).addTo(this.submap);
        },

        /**
         * マーカーを削除する。
         */
        removeMark: function() {
            if(this.marker){
                this.map.removeLayer(this.marker);
            }
            if(this.submarker){
                this.submap.removeLayer(this.submarker);
            }
        },

        /**
         * 周辺の被害情報を地図に描画する
         */
        setDamageReportMarkers: function(items , selfDamageId) {
            this.layerGroup = new leaflet.featureGroup();
            this.layerGroupForSubMap = new leaflet.featureGroup();

            for(var i=0; i<items.length; i++){
                var item = items[i];
                var lat = item.latitude;
                var lng = item.longitude;

                //緯度経度があるもののみ表示
                if(!lat || !lng){
                    continue;
                }
                //自分自身は表示させない
                if(selfDamageId && Number(item.damageReportId) === Number(selfDamageId)){
                    continue;
                }
                //子被害は表示させない
                if(item.parentAdmNum) {
                    continue;
                }

                //ポップアップ
                var content = '';
                if (item.damageType) {
                    content = '<tr><td style="color:#FF0000"><b>被害位置</b></td></tr>';
                    content += '<tr><td></td></tr>';
                    content += '<tr><td>管理番号:</td><td>' + item.admNum + '</td></tr>';
                    content += '<tr><td>被害種別:</td><td>' + DamageType[item.damageType] + '</td></tr>';
                    if (item.damageAddress) {
                        content += '<tr><td>住所:</td><td>' + item.damageAddress + (item.damageAddressNum || '') + '</td></tr>';
                    }

                    if (item.hldStatus !== null) {
                        var hldStatusMap = {
                            '0': '確認中',
                            '1': '対応待ち',
                            '2': '対応中',
                            '3': '対応済'
                        };
                        content += '<tr><td>対応状況:</td><td>' + hldStatusMap[item.hldStatus] + '</td></tr>';
                    }

                    if (item.urgencyType !== null) {
                        var urgencyTypeMap = {
                            '0': '-',
                            '1': '低',
                            '2': '中',
                            '3': '高'
                        };
                        content += '<tr><td>緊急度:</td><td>' + urgencyTypeMap[item.urgencyType] + '</td></tr>';
                    }

                    if (item.humanDamageFlg) {
                        var humanDamage;
                        if (item.humanDamageFlg === '0') {
                            humanDamage = 'あり';
                        } else {
                            humanDamage = 'なし';
                        }
                        content += '<tr><td>人的被害:</td><td>' + humanDamage + '</td></tr>';
                    }

                    content += '<tr><td></td></tr>';

                } else {
                    if (item.description) {
                        content += '<tr><td>' + item.content + '</td></tr>';
                    }
                }


                var marker = this.makeDamageMarker(item);
                var subMarker = this.makeDamageMarker(item);

                //メイン地図にのみポップアップを設定
                if(content){
                    marker.bindPopup('<table>'+content+'</table>', {maxWidth:1000, closeButton: false});
                }

                this.layerGroup.addLayer(marker);
                this.layerGroupForSubMap.addLayer(subMarker);

            }

        },

        /**
         * 「被害状況表示」ボタンによって、地図中のアイコンの表示有無を切り替える
         */
        showDamageReportMarkers: function(){
            // フラグが立っていない場合は操作不可
            if(!this.enabledAroundDamageButton){
                return false;
            }
            // 地図が初期化されていない場合は操作せず返す
            if(!this.map || !this.submap){
                return false;
            }

            if(this.showMarkersFlg){
                this.map.removeLayer(this.layerGroup);
                this.submap.removeLayer(this.layerGroupForSubMap);
                domStyle.set(this.showMarkersButton,'background-color','rgba(0, 0, 0, 0.7)');
                domStyle.set(this.showMarkersButtonSub,'background-color','rgba(0, 0, 0, 0.7)');
                this.showMarkersFlg = false;

            }else{
                this.map.addLayer(this.layerGroup);
                this.submap.addLayer(this.layerGroupForSubMap);
                domStyle.set(this.showMarkersButton,'background-color','rgba(100, 100, 100, 0.7)');
                domStyle.set(this.showMarkersButtonSub,'background-color','rgba(100, 100, 100, 0.7)');
                this.showMarkersFlg = true;
            }
        },


        /**
         * 添付ファイルをアップロードする。
         */
        loadAttachFile: function() {
            FilesUtils.uploadFile(this, '/api/damageReports/uploadFile');
        },

        /**
         * 添付ファイルのプレビューを表示する。
         */
        showPreview: function(data, exifFlg) {

            var dataUri = data.attachFilePath.replace('out/', 'data/');
            var fileName = data.attachFileName;
            var fileId = data.damageReportAtcFileId;
            var self = this;

            // 画像ファイルの場合
            if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
            fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
            fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
            fileName.indexOf('.gif') !== -1) {
                var image = new Image();

                //JPEGファイルの場合、EXIFデータの取得を実行する
                if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
                fileName.indexOf('.JPG') !== -1 ||fileName.indexOf('.JPEG') !== -1) {
                    var img = null;
                    this.own(on(image, 'load', lang.hitch(this, function(e) {
                        console.log(e);
                        img = e.target;

                        if(exifFlg) {
                            this.getExifData(img, this);
                        }
                    })));
                }
                image.src = dataUri;
                domClass.add(image, 'is-showPreview');
                domConstruct.place(image, this.preview);
                //メニューの作成
                this.createMenu(image, dataUri, fileName, fileId, self);

            } else if (fileName.indexOf('.xls') !== -1 || fileName.indexOf('.xlsx') !== -1) {
                var excel = new Image();
                excel.src = 'images/excelicon.png';
                domClass.add(excel, 'is-showPreview');
                domConstruct.place(excel, this.preview);
                //メニューの作成
                this.createMenu(excel, dataUri, fileName, fileId, self);
            } else if (fileName.indexOf('.pdf') !== -1) {
                var pdf = new Image();
                pdf.src = 'images/pdficon.png';
                domClass.add(pdf, 'is-showPreview');
                domConstruct.place(pdf, this.preview);
                //メニューの作成
                this.createMenu(pdf, dataUri, fileName, fileId, self);
            } else if (fileName.indexOf('.doc') !== -1 || fileName.indexOf('.docx') !== -1) {
                var word = new Image();
                word.src = 'images/wordicon.png';
                domClass.add(word, 'is-showPreview');
                domConstruct.place(word, this.preview);
                //メニューの作成
                this.createMenu(word, dataUri, fileName, fileId, self);
            } else {
                var other = new Image();
                other.src = 'images/othericon.png';
                domClass.add(other, 'is-showPreview');
                domConstruct.place(other, this.preview);
                //メニューの作成
                this.createMenu(other, dataUri, fileName, fileId, self);
            }
        },

        /**
         * JPEGファイルの位置情報を取得する
         */
        getExifData: function(img, self) {
            console.log('getting exif data start');
            exif.getData(img, function(){

                var latitude = exif.getTag(this, 'GPSLatitude');
                var longitude = exif.getTag(this, 'GPSLongitude');

                if(typeof latitude === 'undefined' || typeof longitude === 'undefined'){
                    console.log('GPS data is unavailable.');
                }else{
                    console.log('GPS data is available.');
                    var f = function(number){
                        return number[0].numerator + number[1].numerator /
                        (60 * number[1].denominator) + number[2].numerator / (3600 * number[2].denominator);
                    };
                    self.chain.confirm('この画像の位置情報を使用しますか？', function(chain) {
                        // 位置情報を設定する
                        self.pointLat = f(latitude);
                        self.pointLng = f(longitude);
                        // 地図にマークして中心に移動する
                        self.addMark(self.pointLat, self.pointLng, self);
                        self.map.setView([self.pointLat, self.pointLng], 11);
                        //ダイアログを閉じる
                        chain.hide();
                    });
                }
            });
        },

        /**
         * 添付ファイルのサムネイル上にメニューを作る
         */
        createMenu: function(newNode, uri, fileName, id, self) {
            var menu = new Menu({
                targetNodeId: newNode
            });
            menu.set('style', {'border': 'none', 'box-shadow': 'none'});

            //ダウンロード操作用
            var download = null;
            var userAgent = window.navigator.userAgent.toLowerCase();
            if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
                // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                download = domConstruct.create('a', {href: '#'});
                //クリックでファイル取得処理に入る
                download.onclick = function() {
                    self.downloadFile(url, fileName);
                };
            }else{
                // FF, Chromeの場合、download属性でファイルダウンロード
                download = domConstruct.create('a', {
                    href: uri,
                    download: fileName
                });
            }

            // ファイル名とメニューとの境界線をセット
            var contentNode = domConstruct.create('div');
            contentNode.innerHTML = fileName;
            domConstruct.place('<hr color=#b0c4de>', contentNode);
            //メニューをセット
            domConstruct.place(menu.domNode, contentNode);
            var tooltip = new TooltipDialog({
                content: contentNode
            });
            //
            tooltip.containerNode.onmouseleave = function() {
                popup.close(tooltip);
            };

            // 画像ファイルの場合のみ'開く'をメニューに追加する
            if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
            fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
            fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
            fileName.indexOf('.gif') !== -1) {
                menu.addChild(new MenuItem({
                    label: '開く',
                    iconClass: 'dijitEditorIcon dijitEditorIconInsertImage',
                    onClick: function() {
                        console.log('file open');
                        window.open(uri);
                    }
                }));
            }

            menu.addChild(new MenuItem({
                label: 'ダウンロード',
                iconClass: 'dijitIconSave',
                onClick: function(e) {
                    console.log('file download');
                    console.log(e);
                    //IE対策
                    if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                        download.onclick();
                    } else {
                        download.click();
                    }
                }
            }));

            menu.addChild(new MenuItem({
                label: '削除',
                iconClass: 'dijitIconDelete',
                onClick: function() {
                    console.log('file delete');

                    // 該当ファイルを削除
                    for(var i=0; i<self.attachFileList.length; i++) {
                        if(self.attachFileList[i].damageReportAtcFileId === id) {
                            self.attachFileList.splice(i,1); //id:3の要素を削除
                        }
                    }

                    // サムネイルとメニューを削除
                    domConstruct.destroy(newNode);
                    popup.close(tooltip);
                }
            }));

            menu.startup();
            //メニュー表示処理
            this.own(on(newNode, 'mouseover', lang.hitch(this, function() {
                popup.open({
                    popup: tooltip,
                    around: newNode,
                    orient: ['below-centered']
                });
            })));
            //画面破棄時に一緒に破棄する
            this.own(tooltip);
        },

        /**
         * 添付ファイルをダウンロードする。
         */
        downloadFile: function(url, name) {
            // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
            // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function() {

                var arrayBuffer = this.response;

                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, name);
                }

            };
            xhr.send();

            return false;

        },

        /**
         * プレビューをクリアする
         **/
        clearPreview: function() {

            var length = this.preview.childNodes.length;
            for(var i = 0; i < length; i++) {
                domConstruct.destroy(this.preview.childNodes[0]);
            }

            if(this._attachFileList) {
                this._attachFileList.splice(0, this._attachFileList.length);
            }
        },

        /**
         * 報告名を設定する
         */
        setReportName: function(obj) {
            var currentDate = new Date();
            var prefix = locale.format(currentDate, {
                selector: 'date',
                formatLength: 'long',
                locale: 'zh'
            });

            obj.reportName = prefix + '被害';
        },

        /**
         * 被害種別に応じて不要な入力データをクリアする。
         */
        cleanSendData : function(obj) {
            // キーが合致しないとき、データリストが含む項目名を全て空にする
            var typeList = obj.damageTypeList.split(',');

            array.forEach(Object.keys(this._DETAIL_FIELD_KEY_MAP), lang.hitch(this, function(key) {
                var formKeyNames = this[this._DETAIL_FIELD_KEY_MAP[key] + 'Data']();
                array.forEach(typeList, function() {
                    if(typeList.indexOf(key) === -1){
                        array.forEach(formKeyNames, function(column){
                            obj[column] = column.slice(-1) === 'I' ? 0 : '';
                        });
                    }
                });
            }));

            // 人的被害がないならば人的被害項目をクリア。
            if(obj.humanDamageFlg === '1'){
                array.forEach(this.humanData(), function(column){
                    obj[column] = '';
                }, this);
            }
        },

        /**
         * 人的被害の入力データを返す。
         */
        humanData :function() {
            return ['deadNum','unknownNum','seriousNum','mildNum','unknownSympNum'];
        },

        /**
         * 被害種別ごとの項目名リストを返す。
         */
        //住家被害
        homeData :function() {
            return ['fullDestlHm','fullDestlHh','fullDestlHr',
                    'halfDestlHm','halfDestlHh','halfDestlHr',
                    'partDestlHm','partDestlHh','partDestlHr',
                    'fldAbvHm','fldAbvHh','fldAbvHr',
                    'fldBlwHm','fldBlwHh','fldBlwHr',
                    'lhalfDestlHm','lhalfDestlHh','lhalfDestlHr',
                    'unknownHm','unknownHh','unknownHr',
                    'pubDestl','nhomeOtr','unknownNhome'];
        },
        // ライフライン
        lifeData :function() {
            return ['elecMax','elecCurr','watFailMax','watFailCurr',
                    'watRedMax','watRedCurr','telMax','telCurr','gusMax','gusCurr'];
        },
        //文教施設（学校等）
        educData :function() {
            return ['educDamageName','eduDmgType'];
        },
        //病院
        hospData :function() {
            return ['hospDamageName','hspDmgType'];
        },
        //庁舎等施設
        gbldData :function() {
            return ['gbldDamageName','gvmDmgType'];
        },
        //土砂災害
        sediData :function() {
            return ['sdiDmgType','sdiHmDmgFlg'];
        },
        //火災
        fireData :function() {
            return ['totalLossHm','totalLossHh','totalLossHr',
                    'halfLossHm','halfLossHh','halfLossHr',
                    'partLossHm','partLossHh','partLossHr',
                    'nhomeLossHm','pubLossHm','dangerNum'];
        },
        //河川
        rivrData :function() {
            return ['rivrDamageName','rivDmgType','rivPlntDmgFlg',
                    'rivEscOrdFlg','rivHmDmgFlg'];
        },
        //道路
        roadData :function() {
            return ['roadDamageName','rodDmgType','rodGroupType','rodDmgFlg',
                    'rodClsStName','rodClsEdName','rodClsExLen','rodClsRegStTs','rodClsRegEdTs'];
        },
        //農地・農業用施設被害
        agriData :function() {
            return ['paddylow','paddyFlood','farmFlow','farmFlood', 'agriForestFishFacil'];
        },
        //林野被害
        forestData :function() {
            return ['frstLndDmgType','frstLndDmgArea','frstLndDmgSpot','frstFcltDmgType',
                    'frstFcltRoute','frstFcltDmgSpot','frstPrdcDmgType','frstPrdcDmgArea',
                    'frstPrdcDmgKg','frstPrdcDmgNum','frstPrdcDmgSpot'];
        },
        //その他被害
        othrData :function() {
            return ['othrDamageName','otrDmgType'];
        }
    });
});
