/**
 * 避難状況登録画面用モジュール。
 * @module app/view/page/EvacOrderRegisterPage
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/promise/all',
    'dojo/topic',
    'dojo/text!./templates/EvacOrderRegisterPage.html',
    'dojo/json',
    'app/evacorder/_EvacOrderRegisterPageBase',
    'idis/view/Loader',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/store/IdisRest',
    // 以下、変数で受けないモジュール
    'idis/view/form/DateTimeInput',
    'dijit/layout/TabContainer',
    'app/shelter/ShelterOpeningGrid',
    'app/evacorder/EvacOrderMunicipalitySelector',
    'app/provide/ProvideEvacOrderRegisterDialog',
    'app/evacorder/EvacOrderTemplateSelectorDialog',
    './EvacOrderImportDialog',
    './EvacOrderPopulationGrid'
], function (module, array, declare, lang, domStyle, all, topic, template,
    json, _EvacOrderRegisterPageBase, Loader, Locator, Router, Requester, IdisRest) {

    /**
     * 避難状況登録画面。
     * @class EvacOrderRegisterPage
     * @extends module:app/evacorder/_EvacOrderPageBase~_EvacOrderPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _EvacOrderRegisterPageBase,
    /** @lends module:app/evacorder/EvacOrderRegisterPage~EvacOrderRegisterPage# */ {
            // テンプレート文字列
            templateString: template,

            /**
             * 各開設フラグ
             */
            _floodShFlg: '0',
            _sedimentDisasterShFlg: '0',
            _stormSurgeShFlg: '0',
            _earthquakeShFlg: '0',
            _tsunamiShFlg: '0',
            _fireShFlg: '0',
            _landsideWaterShFlg: '0',
            _volcanoShFlg: '0',
            _selectedItem: null,

            isFixPage: false,

            _districtArray: [],

            // 緊急速報メール配信の判断に用いる。
            // 避難情報が自主避難より格上であり、格上げまたは範囲拡大の場合trueとする。
            _isUrgentMailTarget: false,

            constructor: function () {
                // ベースクラスのコンストラクタを呼ぶ
                this.inherited(arguments);


                // form用のストアを生成する。
                this.formStore = new IdisRest({
                    idProperty: 'evacOrderId',
                    target: '/api/evacorders'
                });

                // 避難所一覧用のストアを生成する。
                this.shelterStore = new IdisRest({
                    idProperty: 'facilityId',
                    target: '/api/shelters/opening/districts'
                });

                // テンプレート一覧用のストアを生成する。
                this.store = new IdisRest({
                    idProperty: 'evacOrderTemplateId',
                    target: '/api/evacorders/template'
                });
            },

            // HTML上にウィジェットが設置されてから呼ばれる
            startup: function () {
                this.inherited(arguments);

                this.setEvacOrderTypeSelector(null);

                // 市町村コードを設定する。
                this.municipalitySelector.set('value', this._municipalityCd);

                // 避難情報ツリーを追加する。
                this.initTree().then(lang.hitch(this, function () {
                    // 避難情報選択レイヤーを追加する
                    this.initDistrictLayer();

                    // 避難所一覧グリッドを初期化する。
                    this.initShelterGrid();

                })).then(lang.hitch(this, function () {
                    // 準備が整ったら選択中の各地区をチェック状態にする
                    this._selectDistricts(Locator.getQuery().districtCds);
                    // 現況状況レイヤーの初期値をONにする。
                    this._latestLayerState = this.LATEST_LAYER_STATE.ON;
                }));
                // ファイル取り込みダイアログ初期化
                this.initFileImportDialog();
            },

            /**
             * 市町村システム連携ファイル読み込みダイアログの初期化
             */
            initFileImportDialog: function() {
                var page = this.fileImportDialog.getChildren()[0];
                // JSONに変換したデータを受け取る
                page.on('import', lang.hitch(this, function(evt) {
                    // 共通項目は最初の行から取得する
                    var firstData = evt.data[0];
                    // 読み込んだデータの共通部分をフォームにセット
                    this.form.set('value', firstData);
                    // 倉敷市を選択し、地区情報を取得
                    this.municipalitySelector.selectedItem(evt.municipalityCd);
                    // 正しい地区の情報を保持
                    var importData = [];
                    // 不正な地区を保持
                    var invalidDistrictCds = [];
                    this.initTree().then(lang.hitch(this, function() {
                        // 正しい地区のデータを取得
                        importData = array.filter(evt.data, function(data) {
                            var isValid = array.some(this._districtTreeCache, function(dist) {
                                return dist.districtCd === data.districtCd;
                            });
                            if (!isValid) {
                                invalidDistrictCds.push(data.districtCd);
                            }
                            return isValid;
                        }, this);
                        this._initialDistrictCds = array.map(importData, function(data) {
                            return data.districtCd;
                        });
                    })).then(lang.hitch(this, 'initDistrictLayer')).then(lang.hitch(this, function() {
                        // 準備が整ったら選択中の各地区をチェック状態にする
                        this._selectDistricts(this._initialDistrictCds);
                    })).then(lang.hitch(this, function() {
                        // 入力があれば「避難世帯数・避難者数」タブに反映
                        array.forEach(importData, function(data){
                            this.populationStore.get(data.districtCd).then(lang.hitch(this, function(item) {
                                item.districtFreeName = data.districtName || item.districtFreeName;
                                item.evaqueeNum = data.evaqueeNum || item.evaqueeNum;
                                item.evacHouseholdNum = data.evacHouseholdNum || item.evacHouseholdNum;
                                this._putWithEvacNumForReason(firstData.issueReasonType, lang.mixin(null, item));
                            }));
                        }, this);
                        // 発令理由のChangeイベントで上書きされた項目を再度セット
                        this.evacOrderType.set('value', firstData.evacOrderType);
                        // 発令理由も入力があれば反映
                        if (firstData.issueReason) {
                            this.issueReason.set('value', firstData.issueReason);
                        }
                    })).then(lang.hitch(this, function() {
                        // 結果のダイアログを表示する
                        if (invalidDistrictCds.length === 0) {
                            this.chain.infoComplete(function() {
                                // 確認ダイアログを閉じる
                                this.chain.hide();
                            });
                        } else {
                            this.chain.info(
                                '下記の地区コードを確認してください。<br>&emsp;&emsp;不明な地区：' + invalidDistrictCds.join(', '),
                                'エラー', function() {
                                this.chain.hide();
                            });
                        }
                        this.fileImportDialog.hide();
                    }));
                }));
            }
        });
});
