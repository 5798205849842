/**
 * <モジュールの説明>
 * @module app/view/page/<ウィジェット名>
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/text!./templates/ScheduledReportFormDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'app/model/DisasterInfo',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    '../config',
    // 'idis/util/FilesUtils',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'dijit/form/RadioButton',
    'dijit/form/Form'
], function(module, declare, lang, Deferred, JSON, template,
    _PageBase, DisasterInfo, InfoDialog, DialogChain, Loader, UserInfo/*, USER_TYPE, config*/) {
    /**
     * <クラスの説明>
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {
        // テンプレート文字列
        templateString: template,
        _disasterId: null,

        constructor: function(){
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
            // 災害IDを取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }
        },

        startup:function(){
            this.onSelectFlgChange();
            //this.initDialog();
        },

        // initDialog: function() {
        //     switch (UserInfo.getUserType()) {
        //         // 市町村・県民局ユーザの場合はデフォルトだけ設定
        //         case USER_TYPE.MUNICIPALITY:
        //             this.municipalityCd.set('value', UserInfo.getMunicipalityCd());
        //             break;
        //         case USER_TYPE.REGION:
        //             this.municipalityCd.set('value', UserInfo.getRegionCd());
        //             break;
        //         default:
        //             break;
        //     }
        // },

        /**
         * セレクトボックスのオプションをセットする。
         * 画面から呼ばれる
         */
        initOptions: function(options) {
            this.selectFlg.set('options', options);
            // 一番先頭の選択肢を選択済みにセットする
            this.form.set('value', {selectFlg: this.selectFlg.options[0].value});
        },

        /**
         * 出力帳票の種類を変更したときに「市町村」の入力可否を変更する
         *  (★が現在表示している選択肢。それ以外は現在表示していない)
         *  0:被害情報一覧, 1:被害情報概況, ★2: 消防庁4号様式
         *  3: 区市町村被害状況報告（都様式2号）, 4: 災害年報
         *  5: 災害報告取扱要領（様式３）, ★6: とりまとめ様式
         *  7: 都全域定時状況報告一覧, 8: 消防庁様式（その１）
         *  9: プレス資料様式
         */
        onSelectFlgChange: function() {
            var selectFlg = this.form.get('value').selectFlg;
            console.debug('項目の入力要否を変更します。');
            if(selectFlg === '2'){
                this.repdatetimeArea.style.display='none';
                this.reportYearArea.style.display='none';
            }else if(selectFlg === '4'){
                this.repdatetimeArea.style.display='';
                this.reportYearArea.style.display='none';
            }else if(selectFlg === '5'){
                this.repdatetimeArea.style.display='none';
                this.reportYearArea.style.display='';
            }else if(selectFlg === '6'){
                this.repdatetimeArea.style.display='';
                this.reportYearArea.style.display='none';
            }else if(selectFlg === '7'){
                this.repdatetimeArea.style.display='';
                this.reportYearArea.style.display='none';
            }else if(selectFlg === '8'){
                this.repdatetimeArea.style.display='none';
                this.reportYearArea.style.display='none';
            }else if(selectFlg === '9'){
                this.repdatetimeArea.style.display='none';
                this.reportYearArea.style.display='none';
            }
        },

        // 帳票出力を行う前に入力チェックを行う
        onOutputButtonClick: function() {
            if(!this._disasterId){
                this.chain.info('災害名が正しく選択されていません。', 'エラー');
                return false;
            }
            var selectFlg = this.form.get('value').selectFlg;
            var repdate = this.repdatetime._date.displayedValue;
            var reptime = this.repdatetime._time.displayedValue;
            //var activeFlg = this.form.get('value').activeFlg;
            // ユーザ情報
            //var municipalityCd = this.form.get('value').municipalityCd;

            var reqid = '';
            var fileName ='';
            var paramList = [];

            //repdateをYYYYMMDD形式に変換
            repdate = repdate.replace(/\//g, '');
            //reptimeをhhss形式に変換
            reptime = reptime.replace(':', '');

            if(selectFlg === '2'){
                //消防庁4号様式
                reqid = 'DAMAGE_NO4STYLE_EX';
                fileName ='DamageNo4style.xlsx';
                paramList = [
                    {key: 'disid', value: this._disasterId}
                ];

            }else if(selectFlg === '4'){
                //各部局別被害報告（県様式3号）
                // 日時は必須項目
                if(!this.repdatetime.validate() || !repdate || !reptime) {
                    this.chain.info('日時が選択されていません。', 'エラー');
                    return false;
                }
                var userName = UserInfo.getName();

                reqid = 'DAMAGE_PREF_NO3STYLE_EX';
                fileName ='DamagePrefNo3style.xlsx';
                paramList = [
                    {key: 'disid', value: this._disasterId},
                    {key: 'repdate', value: repdate},
                    {key: 'reptime', value: reptime},
                    {key: 'username', value: userName}
                ];

            }else if(selectFlg === '5'){
                //災害報告取扱要領（様式３）
                // 年は必須項目
                var reportYear = this.form.get('value').reportYear;
                if(!reportYear) {
                    this.chain.info('対象年が選択されていません。', 'エラー');
                    return false;
                }
                reqid = 'DISASTER_REPORT_NO3STYLE_EX';
                fileName ='DisasterReportNo3style.xlsx';
                paramList = [
                    {key: 'disid', value: this._disasterId},
                    {key: 'reportYear', value: reportYear}
                ];

            }else if(selectFlg === '6'){
                // とりまとめ様式
                // 日時は必須項目
                if(!this.repdatetime.validate() || !repdate || !reptime) {
                    this.chain.info('日時が選択されていません。', 'エラー');
                    return false;
                }

                reqid = 'DAMAGE_PREF_SUMMARY_EX';
                fileName ='DamagePrefSummary.xlsx';
                paramList = [
                    {key: 'disid', value: this._disasterId},
                    {key: 'repdate', value: repdate},
                    {key: 'reptime', value: reptime}
                ];

            }else if(selectFlg === '7'){
                // 都全域定時状況報告一覧
                // 日時は必須項目
                if(!this.repdatetime.validate() || !repdate || !reptime) {
                    this.chain.info('日時が選択されていません。', 'エラー');
                    return false;
                }

                reqid = 'SCHEDULED_DAMAGE_LIST_EX';
                fileName ='ScheduledDamageList.xlsx';
                paramList = [
                    {key: 'disid', value: this._disasterId},
                    {key: 'repdate', value: repdate},
                    {key: 'reptime', value: reptime}
                ];

            }else if(selectFlg === '8'){
                // 消防庁様式（その１）
                reqid = 'DAMAGE_NO4STYLE_VOL1_EX';
                fileName ='DamageNo4styleVol1.xlsx';
                paramList = [
                    {key: 'disid', value: this._disasterId}
                ];

            }else if(selectFlg === '9'){
                // ①プレス資料様式
                reqid = 'PRESS_FILE_NO1STYLE_EX';
                fileName ='PressFileNo1style.xlsx';
                paramList = [
                    {key: 'disid', value: this._disasterId},
                    {key: 'repdate', value: repdate},
                    {key: 'reptime', value: reptime}
                ];
            }

            var data4xoblosDownload = {
                fileName: fileName,
                reqid: reqid,
                paramList: paramList
            };


            var promise = this.download(data4xoblosDownload, fileName).then(lang.hitch(this, function() {
                this.infoDialog = new InfoDialog({
                    title : 'ダウンロード完了',
                    content : 'ダウンロードが完了しました。'
                });
                this.infoDialog.show();

            }), lang.hitch(this, function(error) {
                console.error(error);
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : 'エラーが発生しました。管理者にお問い合わせください。'
                });
                this.infoDialog.show();
            }));

            Loader.wait(promise);

        },

        //帳票出力
        download : function(data, baseFileName) {
            var deferred = new Deferred();

            var xhr = new XMLHttpRequest();
            xhr.open('POST', '/api/xoblos/download', true);
            xhr.responseType = 'arraybuffer';
            xhr.setRequestHeader( 'Content-Type', 'application/json' );
            xhr.onload = function() {

                // エラー時は処理を止める
                if(xhr.status !== 200){
                    deferred.reject();
                    return;
                }

                // バイナリデータを取得
                var arrayBuffer = this.response;
                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
                var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    // 擬似的にAタグを作成
                    var link = document.createElement('a');
                    link.style = 'display: none';
                    document.body.appendChild(link);

                    // AタグのURLにバイナリデータをセット
                    var url = window.URL.createObjectURL(blob);
                    link.href = url;

                    // ファイル名をセット
                    link.download = fileName;

                    // 擬似的にリンクをクリック
                    link.click();
                    // 参照を解放
                    window.URL.revokeObjectURL(url);
                    link.remove();
                }

                deferred.resolve();

            };
            xhr.send(JSON.stringify(data));

            return deferred.promise;
        },

        /**
         * 月や日付を2桁にゼロpaddingする
         */
        _zeroPadding: function(month) {
            return ('00' + month).slice(-2);
        },

        /**
         * キャンセルボタン押下
         */
        onCancel:function(){
            this.getParent().hide();
        }
    });
});
