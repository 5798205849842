/**
* 被害状況登録画面用モジュール。
* @module app/damage/DamageReportRegisterPage
*/
define([
    'module',
    'app/model/LayerStore',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/data/ItemFileWriteStore',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-geometry',
    'dojo/dom-style',
    'dojo/json',
    'dojo/on',
    'dojo/request/iframe',
    'dojo/text!./templates/DamageReportRegisterPage.html',
    'dojo/topic',
    'dojo/window',
    'dstore/Rest',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'dijit/registry',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/map/IdisMap',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'app/common/consts/FunctionCd',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/IdisDialog',
    'idis/view/draw/_DrawUtil',
    'idis/view/Loader',
    '../config',
    'leaflet',
    './_DamageReportRegisterPageBase',
    'dojox/lang/functional/array',
    'idis/view/dialog/InfoDialog',
    'idis/service/GeoService',
    'app/damage/consts/DamageType',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dijit/form/SimpleTextarea',
    'dijit/form/ValidationTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/TitlePane',
    'dijit/layout/AccordionContainer',
    'dijit/layout/TabContainer',
    'dojox/form/Uploader',
    'dojox/layout/FloatingPane',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    'idis/view/form/RadioGroup',
    'idis/view/form/WordCountTextarea',
    '../view/form/LayerDirectorySelector',
    '../view/form/DisasterSelector',
    '../view/form/OrganizationSelector',
    './DamageReportUpdateStatusDialog',
    'app/view/form/HldOrganizationMultiSelector',
    'app/view/form/CustomizableMunicipalitySelector',
    'app/damage/damagetype/DamageTypeSelector'
], function(module, LayerStore, declare, lang, array, ItemFileWriteStore, domClass, domConstruct, domGeometry,
     domStyle, json, on, iframe, template, topic, winUtils, Rest, Menu, MenuItem, popup, TooltipDialog,
    registry, Locator, Router, IdisMap, UserInfo, UserType, FunctionCd, Requester, DialogChain, IdisDialog, DrawUtil,
    Loader, config, leaflet, _DamageReportRegisterPageBase, df, InfoDialog, GeoService, DamageType) {
        // GeoServiceを初期化
        var _geoService = new GeoService();
        /**
        * 被被害状況新規登録画面。
        * @class DamageReportRegisterPage
        * @extends module:app/damage/_DamageReportRegisterPageBase~_DamageReportRegisterPageBase
        */
        return declare(module.id.replace(/\//g, '.'), _DamageReportRegisterPageBase,
        /** @lends module:app/damage/DamageReportRegisterPage~DamageReportRegisterPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--damage',

        // 道路交通規制情報取込フラグ
        trafficImportFlg: 0,

        _municCdForSpecialCity: null,

        _confirmContext: null,

        _oldMunicipalityCd: null,

        // （特別市ユーザー）市町村セレクターと発生場所の市町村が同じかどうか
        _diffMunicFlg: false,

        // 災害シミュレーション用
        _scenarioId: null,
        _simulateFlg: false,
        _eventType: null,
        _content: null,
        _timing: null,

        /**
         * constructor
         */
        constructor: function() {
            // 災害シミュレーション用パラメータ値保持
            this._scenarioId = Locator.getQuery().scenarioId;
            this._simulateFlg = Locator.getQuery().simulateFlg === 'true';
            this._eventType = Locator.getQuery().eventType;
            this._content = Locator.getQuery().content;
            this._timing = Locator.getQuery().timing;
        },

        /**
         * startup
         */
        startup: function() {
            console.debug('start startup');

            this.inherited(arguments);

            this.initMunicipalitySelector();

            this.own(topic.subscribe(this.DAMAGE_TYPE_SELECTED, lang.hitch(this, function (data) {
                this.setDamageType(data);
            })));

            this.own(topic.subscribe(this.DAMAGE_TYPE_RELEASED, lang.hitch(this, function (data) {
                this.releaseDamageType(data);
            })));

            // 市町ユーザの場合、概況ページのリンクを非表示
            if(UserInfo.getUserType() === UserType.MUNICIPALITY){
                domStyle.set(this.overviewLabel, 'display', 'none');
            }

            this.own(this.innerDamageTypeSelector);

            // URLの変更を監視
            this.own(Locator.on('change', lang.hitch(this, this.onLocationChanged)));

            // 被害状況新規登録画面を初期化
            this.initRegisterPage();

            // tipsを付与
            this.setContentTips();
        },

        /**
         * 市町セレクタを設定する。
         */
        initMunicipalitySelector: function(){
            if(UserInfo.getUserType() === UserType.MUNICIPALITY &&
                UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
                //市町ユーザ(政令指定都市除く)なら自分の市町に固定
                this._municipalityCd = UserInfo.getMunicipalityCd();
                this.municipalityCd.set('value', this._municipalityCd );
                this.municipalityCd.set('noAllButton', true);
                // 組織カテゴリコードをセット
                this._damageDeptCd = UserInfo.getOrganization().deptCd;
                // 市町村セレクターを不可視
                domStyle.set(this.municipalitySelectForm, 'display', 'none');
            } else if(UserInfo.getMunicipalityCd() === config.municInfo.cityMunicCd){
                // 政令指定都市ユーザーの場合、特別市を初期設定
                this.municipalityCd.set('value', config.municInfo.cityMunicCd);
            // } else if(UserInfo.is4OrgsRole()){
            //     // 4機関ユーザの場合、市町村セレクターを不可視
            //     domStyle.set(this.municipalitySelectForm, 'display', 'none');
            //     //  対応課を救出救助統括室に固定
            //     this.hldOrganization.set('noAllButton', true);
            //     this.hldOrganization.onMunicipalityChange(config.municInfo.prefMunicCd);
            //     this.hldOrganization.set('value', ['SS10101']);
            //     // 市町コードをセット
            //     this._municipalityCd = UserInfo.getMunicipalityCd();
            //     // 組織カテゴリコードをセット
            //     this._damageDeptCd = UserInfo.getOrganization().deptCd;
            } else if (UserInfo.hasWriteAuthz(FunctionCd.DAMAGE) && !UserInfo.hasProxyWriteAuthz(FunctionCd.DAMAGE)) {
                // 編集権限はあるが、代理編集権限まではない（市町ユーザ以外）
                this._municipalityCd = UserInfo.DEFAULT_MUNICIPALITY_CD;
                this.municipalityCd.set('value', this._municipalityCd );
                this.municipalityCd.set('noAllButton', true);
                // 組織カテゴリコードをセット
                this._damageDeptCd = UserInfo.getOrganization().deptCd;
                // 市町村セレクターを不可視
                domStyle.set(this.municipalitySelectForm, 'display', 'none');
            } else if(Locator.getQuery().municipalityCd){
                //そうでない場合はURLからデフォルトを取得
                this._municipalityCd = Locator.getQuery().municipalityCd;
                this.municipalityCd.set('value', this._municipalityCd );
            }else{
                // 市町が決まっていなければ、対応課セレクタを非表示
                domStyle.set(this.hldOrganization.domNode, 'display', 'none');
                domStyle.set(this.hldOrganizationAlert, 'display', '');
            }

        },

        /**
         * 市町村コードを再セットする。
         */
        onMunicipalityChange: function(){
            console.debug('市町村が変更されました');
            this._municipalityCd = this.municipalityCd.get('value');
            var self = this;

            //地図の緯度経度と住所情報を変更
            // 道路交通規制情報取込の場合は、変更しない
            if(this.map && this.submap && this.trafficImportFlg!==1 &&
                // 市町コードが組織カテゴリコードの場合も変更しない
                this._municipalityCd.toString().substring(0,1) !== 'D'){
                // 特別市ユーザーかつ市町村セレクターと発生場所の市町村が異なる場合は、変更しない
                if(UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd &&
                    this._diffMunicFlg !== true) {
                    Requester.get('/api/municipalities/' + self._municipalityCd, {
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(function(municipality) {
                        // マップを初期化
                        if(municipality.latitude && municipality.longitude){
                            self.map.setView([municipality.latitude,municipality.longitude]);
                            self.submap.setView([municipality.latitude,municipality.longitude]);
                        }
                        //住所を設定
                        if(!self.damageAddress.get('value')){
                            self.damageAddress.set('value', municipality.municipalityName);
                        }
                    }, function(error) {
                        console.log(error);
                        self.chain.info('情報の取得に失敗しました。', 'エラー');
                    });
                }
            }

            // 地区・市町村を変更した場合
            if(this._oldMunicipalityCd !== this._municipalityCd) {
                //対応課の入力情報を初期化の上、ツリーを再構築
                this.hldOrganization.set('value', []);
                //ログインユーザの所属組織
                this.hldOrganization.onMunicipalityChange(this._municipalityCd);
                // 長崎県、市町を含めた組織
                // this.hldOrganization.onMunicipalityChange(config.municInfo.prefMunicCd);
                //対応課セレクタを表示
                domStyle.set(this.hldOrganizationAlert, 'display', 'none');
                domStyle.set(this.hldOrganization.domNode, 'display', '');
            }
            this._oldMunicipalityCd = this._municipalityCd;
            // 市町コードが組織カテゴリコードの場合は組織カテゴリコードにも値をセット
            if(this._municipalityCd.toString().substring(0,1) === 'D'){
                this._damageDeptCd = this._municipalityCd;
            }

            //「周辺被害表示」ボタンがアクティブになっていたら、一旦閉じる
            if(this.showMarkersFlg){
                this.showDamageReportMarkers();
            }
            //周辺被害を再取得
            var param = '?disasterId=' +  this._disasterId + '&municipalityCd=' + this._municipalityCd;
            param += '&activeFlg=1&hasNoParentFlg=0';
            Requester.get('/api/damageReports' + param, {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(function(data) {
                self.enabledAroundDamageButton = true;
                var items = data.items;
                self.setDamageReportMarkers(items);
                //周辺被害はデフォルト表示
                self.showDamageReportMarkers();

            }, function(error) {
                self.chain.infoError(error);
            });
            //「周辺被害表示」ボタンを表示
            domStyle.set(this.showMarkersButton, 'display', '');
            domStyle.set(this.showMarkersButtonSub, 'display', '');
            // 管轄が県で庁内管理を選択の場合、県報告に変更
            if (this._municipalityCd === config.municInfo.prefMunicCd &&
                this._reportStatus === '11') {
                this.setReportStatus('31');
            }
        },

        /**
         * 政令指定都市ユーザーの場合、発生場所から市町村、地区を取得する
         */
        onDamageAddressChange: function(){
            var childrenOfSpecialCity = config.municInfo.childrenOfSpecialCity;
            var specialCityName = config.municInfo.specialCityName;
            var munic = null;
            if(UserInfo.getUserType() === UserType.MUNICIPALITY &&
                UserInfo.getMunicipalityCd() === config.municInfo.cityMunicCd){
                var promise = _geoService.geocode(this.damageAddress.get('value') + (this.damageAddressNum.get('value') || '')).
                    then(lang.hitch(this,function(results) {
                    if(results.length > 0) {
                        // 発生場所が管轄外の場合、政令指定都市を市町村に設定する。
                        if(results[0].properties.MetroArea.indexOf(specialCityName) === -1){
                            this._diffMunicFlg = true;
                            this._municipalityCd = config.municInfo.cityMunicCd;
                            this.municipalityCd.set('value', config.municInfo.cityMunicCd);
                            this._confirmContext = '発生場所が管轄と異なるため<br>' +
                                                    specialCityName +'の被害として登録します。';
                        } else {
                            this._diffMunicFlg = false;
                            // 各地区を設定する。
                            Object.keys(childrenOfSpecialCity).forEach(function(key){
                                if(results[0].properties.City === key){
                                    munic = childrenOfSpecialCity[key];
                                }
                            });
                            this._municipalityCd = munic;
                            this.municipalityCd.set('value', munic);
                        }
                    } else {
                        // 住所取得に失敗した場合、政令指定都市を市町村に設定する。
                        this._municipalityCd = config.municInfo.cityMunicCd;
                        this.municipalityCd.set('value', config.municInfo.cityMunicCd);
                        this._confirmContext = '発生場所取得に失敗したため<br>' +
                                                specialCityName + 'の被害として登録します。';
                    }
                }));
                Loader.wait(promise.then(lang.hitch(this, function(){
                    this._municCdForSpecialCity = this._municipalityCd;
                    if(this._confirmContext !== null){
                        this.chain.info(this._confirmContext, '確認', function(chain) {
                            // 確認ダイアログでOKを押した場合
                            chain.hide();
                        });
                    }
                    this._confirmContext = null;
                })));
            }
        },

        onLocationChanged: function() {
            // urlにoverReportを含む場合、簡易報告を選択する
            if(location.href.indexOf('overReport') !== -1){
                this.innerDamageTypeSelector.change({mainTypeIds: ['12']});
            }
        },

        /**
         * 被害状況新規登録画面を初期化する。
         */
        initRegisterPage: function() {
            //周辺被害を非表示として初期化
            this.showMarkersFlg = false;

            var self = this;

            // // 県のユーザ、4機関ユーザ以外の場合、概況リンクを不可視
            // // if(UserInfo.getUserType() === UserType.MUNICIPALITY &&
            // //     UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
            // if(!(UserInfo.getUserType() === UserType.PREFECTURE ||
            //     UserInfo.is4OrgsRole())) {
            //     domStyle.set(this.overviewLabel, 'display', 'none');
            // }

            // 市町が与えられている場合、市町の位置情報を受け取る
            if(this._municipalityCd){
                Requester.get('/api/municipalities/' + this._municipalityCd, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(function(data) {
                    var latitude = config.map.latitude;
                    var longitude = config.map.longitude;
                    if(data && data.latitude && data.longitude){
                        latitude = data.latitude;
                        longitude = data.longitude;
                    }
                    // 地図を初期化
                    self.initMap(latitude, longitude);
                    self.initSubMap(latitude, longitude);
                    // 発生場所に市町村までデフォルト入力
                    if(UserInfo.getUserType() === UserType.MUNICIPALITY){
                        self.damageAddress.set('value', data.municipalityName);
                    }
                }, function(error) {
                    self.chain.infoError(error);
                });
            }else{
                // 地図が与えられていない場合は県の代表点で初期化
                this.initMap(config.map.latitude, config.map.longitude);
                this.initSubMap(config.map.latitude, config.map.longitude);
            }

            //諸変数の初期化
            this.pointLat = '';
            this.pointLng = '';
            this.marker = '';
            this.attachFileList = [];

            //報告状況を初期化する
            this.initReportStatus();

            // 報告日時欄に、現在時刻を自動セットする
            this.reportTimestamp.set('value', new Date().getTime());

            // プレビューを初期化
            this.clearPreview();

            // 報告部署にログインユーザの組織情報を設定
            var orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
                UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
                UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');
            this.organization.set('value', orgCd);

            // 被害種別・人的被害有無の変更時イベントを設定
            this.controlDamageDetailContents();
            // this.controlHumanDamageContents();

            if (!this._municipalityCd) {
                // 市町が与えられていない場合、「周辺被害表示」ボタンは非表示
                domStyle.set(this.showMarkersButton, 'display', 'none');
                domStyle.set(this.showMarkersButtonSub, 'display', 'none');
            }

            // 交通規制情報の取込の場合、規制情報をセット
            this.setTrafficRegulations(Locator.getQuery());
            // 災害シミュレーションの場合添付ファイル、作図を不可視にする
            if(this._simulateFlg){
                domStyle.set(this.attachFileItem, 'display', 'none');
                domStyle.set(this.showDrawButton, 'display', 'none');
            }
        },

        /**
         * 被害情報項目のHTMLをセット・変数に保持
         */
        setDamageInfoForm: function(item){
            //人的被害
            if(item.humanDamageFlg === '0') {
                this.humanDamage.style.display = '';
            } else {
                this.humanDamage.style.display = 'none';
            }

            this.admNum.innerHTML = item.admNum || '&nbsp';
            this.humanDamageFlg.innerHTML = {'0':'あり', '1': 'なし'}[item.humanDamageFlg] || '&nbsp';
            this.content.innerHTML = item.content || '&nbsp';
            this.notifierName.innerHTML = item.notifierName || '&nbsp';
            this.notifierContact.innerHTML = item.notifierContact || '&nbsp';

            if(item.damageTimestamp){
                var datetime = new Date(item.damageTimestamp);
                var date = datetime.getFullYear() + '/' + (datetime.getMonth() + 1) + '/' +  datetime.getDate();
                var time = datetime.getHours() + ':' + ('0' + datetime.getMinutes()).slice(-2);
                this.damageTimestamp.innerHTML = date + ' ' + time;
            }else{
                this.damageTimestamp.innerHTML = '&nbsp';
            }

            var notifierCtg = {
                '01': '住民',
                '02': '現地調査',
                '03': '消防団',
                '04': 'SNS',
                '05': '警察',
                '06': '消防',
                '07': '自衛隊',
                '08': '県',
                '09': '電力等事業者',
                '10': 'その他'
            };
            if(item.notifierCategory){
                this.notifierCategory.innerHTML = notifierCtg[item.notifierCategory];
            }

            if(item.urgencyType === '0'){
                this.urgencyType.innerHTML = '-';
                this.urgencyType.style.backgroundColor = '#ffffff';
                this.urgencyType.style.color = '#000000';
            }
            else if(item.urgencyType === '1'){
                this.urgencyType.innerHTML = '低';
                this.urgencyType.style.backgroundColor = '#ffffff';
                this.urgencyType.style.color = '#000000';
            }else if(item.urgencyType === '2'){
                this.urgencyType.innerHTML = '中';
                this.urgencyType.style.backgroundColor = '#ffff00';
                this.urgencyType.style.color = '#000000';
            }else if(item.urgencyType === '3'){
                this.urgencyType.innerHTML = '高';
                this.urgencyType.style.backgroundColor = '#cc2800';
                this.urgencyType.style.color = '#ffffff';
            }

            if(this.damageType.innerHTML === '&nbsp;' || item.changeFlg === true) {
                var damageTypeArr = item.damageTypeList.split(',');
                if(damageTypeArr) {
                    for(var dmCount=0; dmCount<damageTypeArr.length; dmCount++) {
                        damageTypeArr[dmCount] = DamageType[damageTypeArr[dmCount]];
                    }
                    this.damageType.innerHTML = damageTypeArr.join('、');
                }
            }

            //フォーム内容を保管
            lang.mixin(this.damageBasicInfo, item);

        },

        /**
         * 対応状況のStyleをセットする
         */
        setHldStatusStyle: function(hldStatusDomNode, hldStatus){
            if(hldStatus === '0'){
                domStyle.set(hldStatusDomNode.children[0], 'color', 'black');
                domStyle.set(hldStatusDomNode.children[0], 'font-weight', 'bold');
                domStyle.set(hldStatusDomNode.children[1], 'color', 'gray');
                domStyle.set(hldStatusDomNode.children[2], 'color', 'gray');
                domStyle.set(hldStatusDomNode.children[3], 'color', 'gray');
            }else if(hldStatus === '1'){
                domStyle.set(hldStatusDomNode.children[0], 'color', 'blue');
                domStyle.set(hldStatusDomNode.children[1], 'color', 'black');
                domStyle.set(hldStatusDomNode.children[1], 'font-weight', 'bold');
                domStyle.set(hldStatusDomNode.children[2], 'color', 'gray');
                domStyle.set(hldStatusDomNode.children[3], 'color', 'gray');
            }else if(hldStatus === '2'){
                domStyle.set(hldStatusDomNode.children[0], 'color', 'blue');
                domStyle.set(hldStatusDomNode.children[1], 'color', 'blue');
                domStyle.set(hldStatusDomNode.children[2], 'color', 'black');
                domStyle.set(hldStatusDomNode.children[2], 'font-weight', 'bold');
                domStyle.set(hldStatusDomNode.children[3], 'color', 'gray');
            }else if(hldStatus === '3'){
                domStyle.set(hldStatusDomNode.children[0], 'color', 'blue');
                domStyle.set(hldStatusDomNode.children[1], 'color', 'blue');
                domStyle.set(hldStatusDomNode.children[2], 'color', 'blue');
                domStyle.set(hldStatusDomNode.children[3], 'color', 'black');
                domStyle.set(hldStatusDomNode.children[3], 'font-weight', 'bold');
            }
        },

        /**
         * 被害状況を登録する。
         */
        registerDamageReport: function() {
            if(this.farmUnknownTypeCheck.get('checked')){
                this.farmUnknownTypeFlg.set('value', '1');
            }else {
                this.farmUnknownTypeFlg.set('value', '0');
            }
            var sendData = this.form.get('value');

            // 入力チェック
            if(!this._damageType) {
                console.debug('被害種別が選択されていません');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '被害種別が選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
                return false;
            }

            // 「人的被害あり、または緊急度：中/高」を選んだ場合、「県報告（マスコミ公開不可）」or「県報告（マスコミ公開可能）」を選ばねばならない。
            if((sendData.humanDamageFlg === '0' || sendData.urgencyType === '2' || sendData.urgencyType === '3' ) &&
                this._reportStatus === '11') {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '人的被害あり、または緊急度：中/高を選んだ場合は、<br>' +
                                    '「庁内共有（県へ報告しない）」を選べません。'
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
                return false;
            }

            sendData.damageType = this._damageType;
            sendData.damageTypeList = this._damageTypeList;

            // 作図をJSON化する
            topic.publish(this.JSONIZE_RQST);

            // if(sendData.damageAddress) {
            //     sendData.damageLatitude = this._damageAddressPointLat;
            //     sendData.damageLongitude = this._damageAddressPointLng;
            // } else {
            //     sendData.damageLatitude = '';
            //     sendData.damageLongitude = '';
            // }
            if(this.pointLat && this.pointLng){
              sendData.damageLatitude = this.pointLat;
              sendData.damageLongitude = this.pointLng;
            }else{
              sendData.damageLatitude = '';
              sendData.damageLongitude = '';
            }

            //フォーム上で入力させない項目を格納
            if(!sendData.reportName) {
                this.setReportName(sendData);
            }
            sendData.disasterId = this._disasterId;

            // if(UserInfo.getUserType() === UserType.MUNICIPALITY &&
            //     UserInfo.getMunicipalityCd() === config.municInfo.cityMunicCd){
            //     // 政令指定都市ユーザーの場合
            //     sendData.municipalityCd = this._municCdForSpecialCity;
            // } else {
                sendData.municipalityCd = this._municipalityCd;
            // }

            // 被害報告組織カテゴリコード
            sendData.damageDeptCd = this._damageDeptCd;

            sendData.seqNum = 1;
            sendData.parentAdmNum = null;
            sendData.extAdmNum = null;
            sendData.reportStatus = this._reportStatus;
            // 「確定フラグ」「本部確認状況フラグ」は現段階では使わないので固定値をセット
            sendData.fixFlg = '1';
            sendData.headquarterCheckFlg = '0';
            sendData.organization = this.organization._getValueAttr();
            // 対応課：葉ノードしかとれない問題を回避
            var hldOrganization = [];
            var organizationMap = this.hldOrganization.tree._checkMap;
            var excludeMap = {};
            var tree = this.hldOrganization.tree;
            df.forIn(organizationMap, function(item) {
                if(tree.isEveryChildChecked(item)){
                    tree.getItemChildren(item).then(lang.hitch(this, function(items) {
                        if(!items){
                            return false;
                        } else if (items.length !== 0){
                            // 子を登録して親を登録対象外とする場合
                            // 対象に子が存在する場合、親は登録対象外とする
                            excludeMap[tree.model.store.getIdentity(item)] = true;
                            return false;
                        }
                        // array.forEach(items, function(item){
                        array.forEach(items, function () { // 未使用変数エラー回避のため
                            // 親を登録して子を登録対象外とする場合
                            // 対象に紐づく子はすべて登録対象外とする
                            // excludeMap[tree.model.store.getIdentity(item)] = true;
                        }, this);
                    }));
                }
            }, this);
            df.forIn(organizationMap, function(item) {
                var cd = tree.model.store.getIdentity(item);
                if(!excludeMap[cd]){
                    hldOrganization.push(cd);
                }
            }, this);
            sendData.hldOrganization = hldOrganization;

            // 被害詳細データをセット
            this.cleanSendData(sendData);

            //添付ファイルIDをセット
            if(this.attachFileList.length > 0) {
                var attachFileIds = [];
                for(var i=0; i<this.attachFileList.length; i++) {
                    attachFileIds.push(this.attachFileList[i].damageReportAtcFileId);
                }
                sendData.attachFileIds = attachFileIds.join(',');
            } else {
                sendData.attachFileIds = '';
            }

            delete sendData.attachFile;

            // フォームのバリデーションを行う（共通部品）
            if(!this.form.validate()) {
                return false;
            }
            // フォームのバリデーションを行う（独自チェック）
            if(!this.validateForm(sendData)) {
                return false;
            }
            // 4機関の場合、一覧への戻り遷移のため、市町コードに組織カテゴリコードを再セット
            if(this._municipalityCd === config.municInfo.otherRelationOrg){
                this._municipalityCd = this._damageDeptCd;
            }
            var jsonStr = null;
            // 災害シミュレーションのイベントとして登録する場合
            if(this._simulateFlg){
                var subData = {};
                subData.municipalityName = this.municipalityCd.domNode.innerText.replace('選択', '');
                subData.damageTypeNameList = this.damageTypeSelector.innerText.replace('選択', '').replace('解除', '');
                subData.urgencyTypeName = this.urgencyType.domNode.innerText;
                subData.hldOrganizationName = this.hldOrganization.domNode.innerText.replace('選択', '');
                subData.reportStatusName = this['reportedStatus' + this._reportStatus].innerHTML;
                subData.hldStatusName = ['確認中', '対応待ち', '対応中', '対応済'][this.form.value.hldStatus] || '確認中';
                jsonStr = json.stringify({
                    damageReportPostForm: sendData,
                    damageReportConversionForm: subData,
                    scenarioId: this._scenarioId,
                    eventType: this._eventType,
                    content: this._content,
                    timing: this._timing
                });
                var message = 'イベント登録します。';
                this.chain.confirm(message, function(chain) {
                    var promise = Requester.post('/api/simulations/event/', {
                        data: jsonStr,
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(lang.hitch(this, function() {
                        console.debug('success register Chronology event');
                        chain.infoComplete(function() {
                            Router.moveTo('simulation/event', {scenarioId: this._scenarioId});
                        });
                    }), function(error) {
                        console.log('error register Chronology event');
                        chain.infoError(error);
                    });
                    //ローダーの表示
                    Loader.wait(promise);
                });
            } else {
                jsonStr = json.stringify(sendData);
                var self = this;
                //登録確認ダイアログを表示
                this.chain.confirmAdd(function(chain) {
                    var promise = Requester.post('/api/damageReports', {
                        data: jsonStr,
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(function(data) {
                        console.debug('success register DamageReport');
                        // 作図情報を登録
                        var param = '?damageReportId=' + data.damageReportId;
                        return Requester.post('/api/damageReports/uploadjson' + param, {
                            data: self.drawJson,
                            headers: {'Content-Type': 'application/json; charset=utf-8'},
                            handleAs: 'json',
                            preventCache : true
                        }).then(function() {
                            console.debug('success upload json');
                            chain.infoComplete(function() {
                                //ツリーの更新
                                LayerStore.refreshAll();
                                // 一覧画面に移動
                                Router.moveTo('report', {municipalityCd: this._municipalityCd});
                            });
                        }, function(err) {
                            console.log(err);
                            chain.info('作図ファイルの登録に失敗しました。', 'エラー', function() {
                                // 一覧画面に移動
                                Router.moveTo('report', {municipalityCd: this._municipalityCd});
                            });
                        });
                    }, function(error) {
                        console.log('error register damagereport');
                        chain.infoError(error);
                    });
                    //ローダーの表示
                    Loader.wait(promise);
                });
                console.debug('[登録]ボタンがクリックされました。');
            }
        },

        setTrafficRegulations: function(data){
            if(!data.id){
                return;
            }
            this.trafficImportFlg = 1;
            // 被害種別に道路(09)をセット＆変更不可
            this.innerDamageTypeSelector.change({mainTypeIds: ['09']});
            domStyle.set(this.innerDamageTypeSelector.clearButton.domNode, 'display', 'none');
            // 道路名＝路線名
            this.roadDamageName.set('value', data.roadName);
            // 道路種別＝路線名の頭で判断
            var rodGroupType = {
                '市町道' : 1,
                '県道' : 2,
                '国道' : 3,
                '農道' : 4
            }[data.roadName.slice(0, 2)] || 5;
            this.rodGroupType.set('value', rodGroupType);
            // 道路被害種別＝規制種別
            var rodDmgType = {
                1 : 2, //全面通行禁止
                2 : 6, //大型車通行止め
                3 : 3, //片側通行禁止
                4 : 7, //車線・路肩規制
                5 : 8  //歩道規制
            }[data.kind] || 9;
            this.rodDmgType.set('value', rodDmgType);
            // 備考
            if(data.note){
                this.comment.set('value', data.note);
                this.commonTitlePane.set('open', true);
            }
            // 項目のない連携情報は原因／内容に記載
            var content = [];
            content.push('[規制区間]' + data.address);
            content.push('[原因]' + data.reason);
            content.push('[規制期間]' + data.period);
            content.push('[管理者等]' + data.management);
            this.content.set('value', content.join('\n'));
            // 緯度経度をセット
            this.pointLat = data.lat;
            this.pointLng = data.lng;
            this.addMark(this.pointLat, this.pointLng);
            this.map.setView([this.pointLat, this.pointLng]);
            this.submap.setView([this.pointLat, this.pointLng]);
            this.mapToDamageAddress();
        }

    });
});
