/**
 * 職員情報新規登録ダイアログ用モジュール。
 * @module app/convocation/view/employee/ConvoEmployeeRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/ConvoEmployeeRegisterDialog.html',
    'app/convocation/view/employee/_ConvoEmployeePageBase',
    'idis/view/dialog/DialogChain',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'idis/view/form/AclButton'
], function(module, declare, template, _PageBase, DialogChain) {
    /**
     * 職員情報新規登録ダイアログ。
     * @class ConvoEmployeeRegisterDialog
     * @extends module:app/convocation/view/employee/_ConvoEmployeePageBase~_ConvoEmployeePageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/convocation/view/employee/ConvoEmployeeRegisterDialog~ConvoEmployeeRegisterDialog# */ {
        // テンプレート文字列
        templateString: template,
        
        /**
         * 変数の初期化。
         */
        constructor: function() {
            // ダイアログの連鎖関係を取得
            this.chain = DialogChain.get(this);
        },
        
        /**
         * 登録ボタンが押下された際の挙動。
         */
        onSubmit: function() {
            try {
                // data-dojo-propsを利用した必須チェック
                if (!this.form.validate()) {
                    return false;
                }
                //　モジュール固有の入力チェック
                if (!this.validate()) {
                    return false;
                }
                // 登録処理を行うPage.js側にformの情報を渡す
                var sendData = this.form.get('value');
                this.emit('register', {value: sendData});
            } catch(error) {
                // 処理失敗時はエラーログを出力
                console.error(error);
            }
            return false;
        }
    });
});
