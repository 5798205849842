/**
 * 定時報告新規登録確認ダイアログ用モジュール。
 * @module app/damage/ScheduledReportRegistDialog
 */
define([
    'module',
    'dojo/json',
    'dojo/_base/declare',
    'dojo/text!./templates/ScheduledReportRegistDialog.html',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'app/model/DisasterInfo',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'idis/view/form/Button',
    'idis/view/form/RadioGroup'
], function(module, json, declare, template, Locator,
        Router, Requester, DialogChain, _PageBase, Loader, DisasterInfo) {
    /**
     * 定時報告新規登録確認ダイアログ
     * @class DamageReportRegistDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
                   /** @lends module:app/view/page/DamageReportPage~DamageReportPage# */ {
        // テンプレート文字列
        templateString: template,

        baseClass: 'idis-Page--safety-dialog',

        /**
         * 報告ID
         */
        scheduledReportId: null,

        /**
         * 登録画面の入力値
         */
        sendData: null,

        _disasterId: null,

        constructor: function() {
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
            // 災害IDを取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }

            this.scheduledReportId = Locator.getQuery().scheduledReportId;
        },

        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * 登録画面の入力値(json変換済み)を取得、設定する
         * 呼び元の画面で呼ばれる
         */
        setDetailData: function(detailData) {
            this.sendData = detailData;
        },

        /**
         * 登録処理実行
         */
        onSubmit: function() {
            // フォームのバリデーションを行う（共通部品）
            if(!this.form.validate()) {
                return false;
            }

            var self = this;
            if(this.form.get('value').finalChangeFlg === '0') {
                // 変更見込みありの場合：selected_flgを0にセット(未報告のまま、一時保存)
                self.sendData.reportedFlg = '0';
            }

            var jsonStr = json.stringify(self.sendData);
            var promise = Requester.put('/api/scheduledReports/' + self.scheduledReportId, {
                data: jsonStr,
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(function() {
                console.debug('success update ScheduledRport');
                self.chain.infoComplete(function(){
                    Router.moveTo('report/sche');
                });
            }, function(error) {
                console.log(error);
                self.chain.infoError(error);
            });
            //ローダーの表示
            Loader.wait(promise);
        }
    });
});
