/**
 * 避難世帯数・避難者数を管理するためのグリッド。
 * @module app/evacorder/EvacOrderPopulationGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/dom-class',
    'dijit/form/ValidationTextBox',
    'dgrid/Editor',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    './EvacOrderType'
], function(module, declare, lang, array, domClass, ValidationTextBox, Editor,
    IdisGrid, helper, EvacOrderType) {
    // 編集対象列用のオプション
    var editorColumnOptions = {
        sortable: false,
        editor: ValidationTextBox,
        autoSave: true,
        editorArgs: {
            type: 'number',
            pattern: '(0|[1-9][0-9]*)',
            invalidMessage: '0以上の整数を入力してください'
        }
    };

    // 対象地区列用のオプション
    var editorDistrictColumnOptions = {
        sortable: false,
        editor: ValidationTextBox,
        autoSave: true,
        editorArgs: {
            type: 'text',
            pattern: '(^.{0,64})',
            invalidMessage: '64文字以内で入力してください'
        }
    };

    return declare(module.id.replace(/\//g, '.'), [IdisGrid, Editor], {
        noDataMessage: '地区・発令理由が選択されていません',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.column('districtNameGroup', '地区', {
                sortable: false,
                children: [
                    helper.column('districtName', '地区名', {sortable: false}),
                    helper.column('districtFreeName', '対象', editorDistrictColumnOptions),
                    helper.column('districtFreeNameKana', '対象（かな）', editorDistrictColumnOptions)
                ]
            }),
            helper.column('evacOrderInfo', '避難情報', {
                sortable: false,
                children: [
                    helper.column('currentStatus', '現況', {sortable: false}),
                    helper.column('executingEvacOrderType', '発令', {
                        formatter: lang.hitch(this, function(item){
                            // ヘッダーの場合はclassNameを返さない
                            if(!item){
                              return;
                            }
                            if(item){
                                return EvacOrderType.get(item).simpleLabel;
                            }
                        }),
                        classMap: {
                            '01': 'is-prepare',
                            '02': 'is-advisory',
                            '03': 'is-order',
                            '04': 'is-occur',
                            '09': 'is-caution',
                            '11': 'is-prepare',
                            '12': 'is-advisory',
                            '13': 'is-order',
                            '14': 'is-occur',
                            '19': 'is-caution',
                            '80': 'is-cancelled',
                            '90': 'is-cancelled'
                        }
                    }, {sortable: false})
                ]
            }),
            helper.column('householdGroup', '世帯数', {
                sortable: false,
                children: [
                    helper.column('householdsNum', '全体', {sortable: false}),
                    helper.column('evacHouseholdNum', '対象', editorColumnOptions)
                ]
            }),
            helper.column('populationGroup', '人数', {
                sortable: false,
                children: [
                    helper.column('population', '全体', {sortable: false}),
                    helper.column('evaqueeNum', '対象', editorColumnOptions)
                ]
            })
        ],

        // DOMノードを構築する
        buildRendering: function() {
            this.inherited(arguments);
            domClass.add(this.domNode, 'evacorder-PopulationGrid');
        }
    });
});
