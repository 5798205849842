define({
    'MONITORING': 'F01001',                      // トップ画面
    'MAP': 'F02001',                             // 災害情報マップ
    'KISYO_1': 'F03001',                         // 防災気象情報
    'KISYO_2': 'F03002',                         // 地震・津波・火山情報
    'OBSERVATION': 'F04001',                     // 観測情報
    'CHRONOLOGY': 'F05001',                      // クロノロジー
    'DISASTERPREVENTION': 'F05002',              // 体制情報管理
    'EMPLOYGATHERING': 'F05003',                 // 参集状況管理
    'EVACORDER': 'F05004',                       // 避難情報管理
    'EVACRECOMMEND': 'F05005',                   // 避難情報発令判断支援
    'SHELTER': 'F05006',                         // 避難所管理
    'DAMAGE': 'F05007',                          // 被害情報管理
    'DAMAGE_SCHE_REQ': 'F05008',                 // 定時報告依頼
    'DAMAGE_SCHE_ADM': 'F05009',                 // 定時報告管理
    'RESCUE': 'F05010',                          // 部隊活動情報
    'TRAFFIC': 'F05011',                         // 交通状況情報管理
    'LIFELINE': 'F05016',                        // ライフライン情報
    'SENDING': 'F06001',                         // 情報配信
    'PREFSITE_SENDING': 'F06002',                // 防災マップ情報配信
    'BUSSHI': 'F06005',                          // 物資要請
    'SPECTEE': 'F06008',                         // Spectee情報管理
    'BBS': 'F07001',                             // 掲示板
    'CONVOCATION_MAIL': 'F08001',                // 職員参集状況管理
    'DISASTER': 'F09001',                        // 災害名管理
    'FOLDER': 'F09002',                          // フォルダ（地図情報）管理
    'TIMELINE': 'F09003',                        // タイムライン管理
    'GEOFILE': 'F09004',                         // 空撮画像解析（地図タイル化）
    'FACILITY': 'F10001',                        // 施設情報管理
    'FACILITY_TYPE': 'F10002',                   // 施設種別管理
    'ORGANIZATION': 'F10004',                    // 組織管理情報
    'USER': 'F10003',                            // 利用者管理
    'SIMULATION': 'F12001'                       // 災害シミュレーション
});
