/**
 * 職員招集・自動招集条件タブ用モジュール。
 *
 * @module app/convocation/view/condition/ConvocationAutoCondAdminPage
 */
define(['module',
        'dojo/_base/declare',
        'dojo/_base/lang',
        'idis/control/Router',
        'dojo/text!./templates/ConvocationAutoCondAdminPage.html',
        'idis/store/IdisRest',
        'idis/view/page/_PageBase',
        'idis/model/UserInfo',
        'idis/consts/ACL',
        // 以下、変数で受けないモジュール
        'dijit/Dialog',
        'dijit/form/Form',
        'dijit/layout/BorderContainer',
        'dijit/layout/ContentPane',
        'idis/view/form/AclButton',
        'app/convocation/view/condition/ConvoAutoMailListGrid'],
    function(module, declare, lang, Router, template,
        IdisRest, _PageBase, UserInfo, ACL) {
        /**
         * ・自動招集条件タブ
         *
         * @class ConvocationAutoCondAdminPage
         * @extends module:idis/view/page/_PageBase~_PageBase
         */
        return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/view/page/ConvocationAutoCondAdminPage~ConvocationAutoCondAdminPage# */
        {
            // テンプレート文字列
            templateString: template,
            
            store: null,
            
            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',
            
            /**
             * 変数初期化メソッド
             */
            constructor: function() {
                // グループ管理タブ用データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'settingId',
                    target: '/api/convoMails/conditions'
                });
            },
            
            /**
             * DOMノードを生成するためのメソッド
             */
            buildRendering: function() {
                this.inherited(arguments);
                this.initConvoAutoMailListGrid();
            },
            
            startup: function() {
                this.inherited(arguments);
                this.setButtonStatus();
            },

            /**
             * 自動招集条件タブ用Gridを初期化する。
             */
            initConvoAutoMailListGrid: function() {
                // gridに条件一覧を設定する
                this.convoAutoMailListGrid.set('collection', this.store);
                // グリッドの詳細ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.convoAutoMailListGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                    this.showDetailPage(evt.item);
                }));
            },

            /**
             * @param item gridのstore1行分
             * 詳細画面に遷移する。
             */
            showDetailPage: function(item) {
                Router.moveTo('convocation/autoMailDetail', {
                    id: item.settingId
                });
            },

            /**
             * 自動配信設定新規登録画面へ遷移する。
             */
            moveAutoMailRegisterPage: function() {
                console.log('[新規登録]ボタンがクリックされました。');
                // テンプレート管理画面へ遷移
                Router.moveTo('convocation/autoMailRegister');
            },
            
            /**
             * ウィンドウの幅に合わせてBorderContainerをリサイズし、タブ内のレイアウトを整理する。
             * (startup同様、画面描画時に呼ばれる)
             * @param changeSize 
             * @param resultSize
             */
            resize: function(changeSize, resultSize) {
                // 中のContentPaneも追随してリサイズするため、レイアウト崩れを防止できる。
                this.borderContainer.resize(changeSize, resultSize);
            },
            setButtonStatus: function(){
                var role = UserInfo.getRoleCd();
                this.moveAutoMailRegisterPageButton.setDisabled(true);
                if ( role === ACL.ADMIN_USER) {
                    this.moveAutoMailRegisterPageButton.setDisabled(false);
                }
            }
        });
    });
