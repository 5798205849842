/**
* 災害情報画面用モジュール。
* @module app/disaster/view/DisasterAdminPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/DisasterAdminPage.html',
    'dojo/topic',
    'dojo/data/ItemFileWriteStore',
    'idis/consts/USER_TYPE',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/service/Requester',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    './DisasterDialogChain',
    'app/model/Disaster',
    'dojo/_base/array',
    'app/model/DisasterInfo',
    'dojo/cookie',
    // 以下、変数として受け取らないモジュール
    'dijit/layout/BorderContainer',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'idis/view/form/DateInput',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    '../../view/form/LayerDirectorySelector',
    '../../view/form/OrganizationSelector',
    './DisasterGrid',
    './DisasterAdminRegisterPage',
    './DisasterAdminDetailPage'
], function(module, declare, lang, domStyle, template, topic, ItemFileWriteStore,
    USER_TYPE, UserInfo, IdisRest, Requester, _PageBase, Loader, DisasterDialogChain, Disaster, array, 
    DisasterInfo, cookie) {
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/view/page/DisasterAdmintPage~DisasterAdminPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--disaster',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        _actionPlanList: null,

        /**
         * 変数の初期化。
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'disasterId',
                target: '/api/disasters',
                sortParam: 'sort',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            // 連鎖ダイアログを登録
            this.chain = DisasterDialogChain.get(this);

            // 参集抑止ステータスの設定
            // this.isSuppressed = true;
            this.isSuppressed = Disaster.getSuppressInfoList().then(lang.hitch(this, function(isSuppressed) {
                return isSuppressed;
            }), function(error) {
                console.error(error);
                return true;
            });

            // 梅雨モードステータスの設定
            // this.isRainySeason = true;
            this.isRainySeason = Disaster.getRainySeasonInfo().then(lang.hitch(this, function(isRainySeason) {
                return isRainySeason;
            }), function(error) {
                console.error(error);
                return true;
            });
            this.setActionPlanOptions();
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateGridQuery: function(value) {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();

            if (value.disasterName) {
                filter = filter.eq('disasterName', value.disasterName);
            }

            if (this.water.checked) {
                filter = filter.eq('waterFlg', this.water.value);
            }

            if (this.earthquake.checked) {
                filter = filter.eq('earthquakeFlg', this.earthquake.value);
            }

            // if (this.tsunami.checked) {
            //     filter = filter.eq('tsunamiFlg', this.tsunami.value);
            // }

            if (this.volcano.checked) {
                filter = filter.eq('volcanoFlg', this.volcano.value);
            }

            if (this.protection.checked) {
                filter = filter.eq('protectionFlg', this.protection.value);
            }

            if (this.other.checked) {
                filter = filter.eq('otherFlg', this.other.value);
            }

            if (value.disasterStartDate) {
                filter = filter.eq('disasterStartDate', value.disasterStartDate);
            }

            if (value.disasterEndDate) {
                filter = filter.eq('disasterEndDate', value.disasterEndDate);
            }

            // // 市町村ユーザーの場合、所属市町村で登録した災害のみ表示
            // if(UserInfo.getUserType()===USER_TYPE.MUNICIPALITY){
            //     filter = filter.eq('onlyMunicipalityFlg', true);
            // }

            filter = filter.eq('openOnlyFlg', false);

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);
        },


        setActionPlanOptions: function () {
            this._actionPlanList = null;
            //  var list = [];
            //  var promise = Requester.get('/api/timeline/plan', {
            //      headers: {'Content-Type': 'application/json; charset=utf-8'},
            //      handleAs: 'json',
            //      preventCache : false
            //  }).then(lang.hitch(this, function(data) {
            //      array.forEach(data, function(plan) {
            //          var planList = {value: plan.registId, 
            //          label: plan.managementId + ':' + plan.name};
            //          list.push(planList);
            //      });
            //      list.unshift({value: null,label: '&nbsp;'});
            //      this._actionPlanList = list;
            //  }));
            // Loader.wait(promise);
        },

        /**
         * 検索ボタンが押されたときに呼び出される。
         * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
         * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
         */
        onSubmit: function() {
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery(this.form.get('value'));
                }
            } catch (e) {
                console.error(e);
            } finally {
                return false;
            }
        },

        /**
         * 新規登録ダイアログを表示する。
         * テンプレートHTMLでdata-dojo-attach-eventプロパティーを用い、
         * 新規登録ボタンのクリックイベントにこのメソッドを紐付けている。
         */
        showRegisterDialog: function() {
            // ダイアログの最初の子要素が登録画面
            var page = this.dialog.getChildren()[0];
            // 画面上のフォームをリセット
            // （ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み）
            page.form.reset();
            // cookieの訓練フラグから訓練モードを表示する。
            // FIXME 表示だけcookieを利用しており、実際の訓練フラグの登録はsession情報を利用している。
            page.loginMode.innerHTML = UserInfo.getLoginMode();
            // タイムラインリストを更新
            // page.registId.set('options', this._actionPlanList);
            // タイムラインなしで登録後、非表示の状態なので、表示
            // domStyle.set(page.baseTimestampDiv, 'display', '');
            // domStyle.set(page.registIdDiv, 'display', '');
            // ダイアログを表示
            this.dialog.show();
        },

        /**
         * 詳細ダイアログを表示する。
         * @param {Object} item 参照する行のデータ
         */
        showDetailDialog: function(item) {
            // ダイアログの最初の子要素が詳細画面
            var page = this.detailDialog.getChildren()[0];
            // 画面上のフォームをリセット
            // ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み
            page.form.reset();
            // ダイアログ初期化
            page.initDialog(item);
            page.disasterNo.innerHTML = item.disasterId;
            page._disasterId = item.disasterId;
            page._grid = this.grid;
            // cookieの訓練フラグから訓練モードを表示する。
            // FIXME 表示だけcookieを利用しており、実際の訓練フラグの登録はsession情報を利用している。
            page.loginMode.innerHTML = UserInfo.getLoginMode();
            page._trainingFlg = item.trainingFlg;
            // 詳細ダイアログを表示
            this.detailDialog.show();
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            var filter = new this.store.Filter();
            // // 市町村ユーザーの場合、所属市町村で登録した災害のみ表示
            // if(UserInfo.getUserType()===USER_TYPE.MUNICIPALITY){
            //     filter = filter.eq('onlyMunicipalityFlg', true);
            // }
            // 全件表示用のcollectionをグリッドにセットする
            // （テンプレートHTML内のdata-dojo-attach-point="grid"でグリッドをthis.gridに紐付け済み）
            this.grid.set('collection', this.store.filter(filter));
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.showDetailDialog(evt.item);
            }));
        },

        /**
         * 登録画面のフォームが投稿された際の動作を設定する。
         */
        initRegisterPage: function() {
            // 登録ダイアログの最初の子要素が登録画面
            var dialog = this.dialog;
            var page = dialog.getChildren()[0];
            // 登録画面のsendイベントを受け取る
            page.on('send', lang.hitch(this, function(evt) {

                this.chain.confirmAdd(function(chain) {

                    var promise = this.store.add(evt.value);
                    Loader.wait(promise).then(lang.hitch(this,function(item) {
                        // 災害情報登録可の時は体制状況登録画面への遷移ダイアログ表示
                        if (item.reportFlg === false) {
                            chain.completeAndMove();
                        }else{
                            chain.hide();
                        }
                        // 一覧のグリッドをリフレッシュする
                        this.grid.refresh();
                        // 災害名登録時に付番された災害IDをDisasterSelectorにpubする
                        topic.publish(module.id + '::changed', item.disasterId);
                        dialog.hide();
                    }), function(err) {
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 詳細画面のフォームが投稿された際の動作を設定する。
         */
        initDetailPage: function() {
            // 詳細ダイアログの最初の子要素が詳細画面
            var dialog = this.detailDialog;
            var page = dialog.getChildren()[0];
            // 詳細画面のsendイベントを受け取る
            page.on('send', lang.hitch(this, function(evt) {
                this.chain.confirmPut(function(chain) {
                    var promise = this.store.put(evt.value);
                    Loader.wait(promise).then(lang.hitch(this,function(item) {
                        chain.infoComplete();
                        // ダイアログを閉じる
                        dialog.hide();
                        // 一覧のグリッドをリフレッシュする
                        this.grid.refresh();

                        // 更新した災害が、現在設定されている災害だった場合
                        if(DisasterInfo.getDisasterId() === String(item.disasterId)){
                            var data = {
                                disasterId : item.disasterId,
                                disasterName : item.disasterName
                            };
                            // 災害名をDisasterChangerにpubする（ヘッダーに表示されている災害名を変更する）
                            topic.publish(module.id + '::updated', data);
                        }
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));

            page.on('senddelete', lang.hitch(this, function(evt) {
                this.chain.confirmDel(function(chain) {
                    var deletedDisId = evt.value.disasterId;
                    var promise = Requester.del(this.store.target + '/' + deletedDisId, {    
                        query:{
                            version: evt.value.version
                        }
                    });
                    Loader.wait(promise).then(lang.hitch(this,function() {
                        chain.infoComplete();
                        // ダイアログを閉じる
                        dialog.hide();
                        // 一覧のグリッドをリフレッシュする
                        this.grid.refresh();

                        var currentDisId = parseInt(cookie('DISASTER_ID'),10);
                        //削除する災害名がヘッダーに表示されている場合、
                        if(deletedDisId === currentDisId){
                            //クッキーから災害名を削除
                            cookie('DISASTER_ID', '');
                            Requester.get('/api/disasters/disaster4Cookie').then(lang.hitch(this, function() {
                              var id = DisasterInfo.getDisasterId();
                              // 現状での最新の災害IDをDisasterSelectorにpubする
                              topic.publish(module.id + '::changed', id);
                            }, function(err) {
                                // 失敗時
                                chain.infoError(err);
                            }));
                        } 
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 抑止ステータスを画面上部のフィールドに反映する。
         */
        initSuppressItems :function() {
            Disaster.getSuppressInfoList().then(lang.hitch(this, function(isSuppressed) {
                // 災害種別ごとの抑止状態ステータスリスト
                // 11: 風水害, 12: 地震・津波, 15: 火山, 14: 国民保護
                this.isSuppressed = isSuppressed;
                var val;
                // 風水害
                if(this.isSuppressed[0].type === '11'){
                    val = this.getSuppressItemAttribute(this.isSuppressed[0].suppressed);
                    this.suppressField1.innerHTML = val.fieldText;
                    domStyle.set(this.suppressField1, {'background-color': val.backColor});
                    this.suppressButton1.setLabel(val.buttonLabel);
                }
                // 地震・津波
                if(this.isSuppressed[1].type === '12'){
                    val = this.getSuppressItemAttribute(this.isSuppressed[1].suppressed);
                    this.suppressField2.innerHTML = val.fieldText;
                    domStyle.set(this.suppressField2, {'background-color': val.backColor});
                    this.suppressButton2.setLabel(val.buttonLabel);
                }
                // 火山
                if(this.isSuppressed[2].type === '15'){
                    val = this.getSuppressItemAttribute(this.isSuppressed[2].suppressed);
                    this.suppressField3.innerHTML = val.fieldText;
                    domStyle.set(this.suppressField3, {'background-color': val.backColor});
                    this.suppressButton3.setLabel(val.buttonLabel);
                }
                // 国民保護
                if(this.isSuppressed[3].type === '14'){
                    val = this.getSuppressItemAttribute(this.isSuppressed[3].suppressed);
                    this.suppressField4.innerHTML = val.fieldText;
                    domStyle.set(this.suppressField4, {'background-color': val.backColor});
                    this.suppressButton4.setLabel(val.buttonLabel);
                }
            }), function(error) {
                console.error(error);
            });
        },
        /**
         * 抑止ステータスの表示属性を返却する
         */
        getSuppressItemAttribute: function(isSuppressed){  
            if(isSuppressed){
                // 抑止中の場合
                return {fieldText: '抑止中', backColor: '#FFFF00', buttonLabel: '解除'};
            }else{
                // 抑止されていない場合
                return {fieldText: '-', backColor: '#FFFFFF', buttonLabel: '登録抑止'};
            }
        },

        /**
         * 抑止ボタンが押下された際の挙動。
         */
        onSuppressButton: function(evt) {
            if(evt.target.value){
                var type = evt.target.value;
                // 押下された災害種別名
                var typeName = type==='11' ? '風水害' : type==='12' ? '地震・津波' : 
                            type==='15' ? '火山' : type==='14' ? '国民保護' : '' ; 

                // 選択された災害種別の抑止状態を抽出
                var isSuppressedOfType = true;
                for (var i = 0; i < this.isSuppressed.length; i++) {
                    if(this.isSuppressed[i].type === type) {
                        isSuppressedOfType = this.isSuppressed[i].suppressed;
                    }
                }

                // 確認ダイアログに出力するメッセージ
                var message = '災害名（'+ typeName +'）の自動登録抑止を解除します。<br>' + 'よろしいですか？';

                // 抑止されていない場合はメッセージを変更
                if(!isSuppressedOfType){
                    message = '災害名（'+ typeName +'）の自動登録を抑止します。<br>' + 'よろしいですか？';
                }
                
                this.chain.confirm(message, lang.hitch(this, function(chain) {
                    // 自動登録抑止ステータスの変更
                    Disaster.suppressByType(!isSuppressedOfType, type).then(lang.hitch(this, function() {
                        // 画面上部のフィールドへ変更内容を反映
                        this.initSuppressItems();
                        // 確認ダイアログを閉じる
                        chain.infoComplete();
                    }), function(error) {
                        chain.infoError(error);
                        console.error(error);
                    });
                }));
            }
        },

        /**
         * 梅雨モード変更ボタンが押下された際の挙動。
         */
        onRainySeasonButton: function() {
            // 確認ダイアログに出力するメッセージ
            // 非梅雨モード時
            var message = '梅雨モードを解除します。<br>' +'非梅雨モード時は大雨注意報・洪水注意報発生時でも<br>' + '災害名が自動登録されません。<br>' + 'よろしいですか？';
            // 梅雨モードのときされていない場合はメッセージを変更
            if (!this.isRainySeason) {
                message = '梅雨モードに変更します。<br>' + '梅雨モード時は、大雨注意報・洪水注意報発生時に<br>' + '災害名が自動登録されます。<br>' + 'よろしいですか？';
            }
            this.chain.confirm(message, lang.hitch(this, function(chain) {
                // 梅雨モードステータスの変更
                Disaster.rainySeason(!this.isRainySeason).then(lang.hitch(this, function() {
                    // 画面上部のフィールドへ変更内容を反映
                    this.initRainySeasonItems();
                    // 確認ダイアログを閉じる
                    chain.infoComplete();
                }), function(error) {
                    chain.infoError(error);
                    console.error(error);
                });
            }));
        },

        /**
         * 梅雨モードを画面上部のフィールドに反映する。
         */
        initRainySeasonItems :function() {
            Disaster.getRainySeasonInfo().then(lang.hitch(this, function(isRainySeason) {
                this.isRainySeason = isRainySeason;
                // ステータスがtrueの場合は梅雨モード中とみなす
                if (isRainySeason) {
                    this.rainySeasonField.innerHTML = '梅雨モード';
                    domStyle.set(this.rainySeasonField, {
                        'background-color': '#CCFFFF',
                        'display': 'inline-block'
                    });
                    this.rainySeasonButton.setLabel('梅雨モード解除');
                } else {
                    // ステータスがtrueでなければ非梅雨モードとみなし、フィールドを非表示
                    domStyle.set(this.rainySeasonField, 'display', 'none');
                    this.rainySeasonButton.setLabel('梅雨モード設定');
                }
            }), function(error) {
                console.error(error);
            });
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            // （ダイアログは初期化時にbody直下へ移動するため、明示的に指定しないと消えずに残ってしまう）
            this.own(this.dialog);
            this.own(this.detailDialog);
            // グリッドを初期化する
            this.initGrid();
            // 登録画面内のフォームがsubmitされた際の動作を設定
            this.initRegisterPage();
            // 詳細画面内のフォームがsubmitされた際の動作を設定
            this.initDetailPage();

            // 災害名自動登録抑止ステータス           
            this.initSuppressItems();
            // 梅雨モードステータス
            this.initRainySeasonItems();
        }
    });
});
