/**
 * 災害名表示、変更ダイアログ用モジュール。
 * @module app/view/form/DisasterChanger
 */
define([
  'module',
  'dojo/_base/declare',
  'dojo/_base/lang',
  'dojo/_base/array',
  'dojo/topic',
  'dojo/text!./templates/DisasterChanger.html',
  'app/model/DisasterInfo',
  'idis/view/dialog/InfoDialog',
  'idis/control/Locator',
  'idis/control/Router',
  'idis/store/IdisRest',
  'idis/view/_IdisWidgetBase',
  // 以下、変数として受け取らないモジュール
  'dijit/Dialog',
  './DisasterChangerDialog'
], function(module, declare, lang, array, topic, template, DisasterInfo, InfoDialog,
  Locator, Router, IdisRest, WidgetBase) {

  /**
   * 災害名表示、変更ダイアログ表示用ウィジェット
   * @class DisasterChanger
   * @extends module:idis/view/page/_PageBase~_PageBase
   */
  return declare(module.id.replace(/\//g, '.'), WidgetBase,
    /** @lends app/view/Header~Header# */ {

    // テンプレート文字列
    templateString: template,

    // ルートに付与されるCSS
    baseClass: 'idis-Page idis-Page--beginner/disaster-changer',

    /**
     * データ格納用オブジェクト
     * @type {module:dstore/Store}
     */
    store: null,

    event: 'app/disaster/view/DisasterAdminPage::changed',

    eventUpd: 'app/disaster/view/DisasterAdminPage::updated',

    // OKボタンが押された際のイベントが追加済みであるか
    _eventFlg: false,

    /**
     * コンストラクタ
     */
    constructor: function() {
      // データ格納用オブジェクト
      this.store = new IdisRest({
          idProperty: 'disasterId',
          target: '/api/disasters'
      });
    },

    /**
     * DOM生成
     */
    buildRendering: function() {
      this.inherited(arguments);
      // 初期化処理
      this.init();
    },

    /**
     * 初期化
     */
    init: function() {
      // クッキーから設定されている災害IDを取得する
      DisasterInfo.load().then(lang.hitch(this, function() {
        var id = DisasterInfo.getDisasterId();
        // 災害情報を取得し画面へ表示
        this.getDisasterInfo(id);
      }));

      // ローケションが変更された場合、災害変更ボタンの表示・非表示を切り替える
      this.own(Locator.on('change', lang.hitch(this, this.toggleButton)));

      this.own(this.dialog);

      // 災害名登録/削除がPubされた際にツリーを最新の情報に更新する
      topic.subscribe(this.event, lang.hitch(this, function(id) {
          this.getDisasterInfo('' + id);
      }));

      // 災害名更新がPubされた際にツリーの情報を更新する
      topic.subscribe(this.eventUpd, lang.hitch(this, function(data) {
        this.change(data);
      }));

    },

    /**
     * 災害情報を取得し、画面へセットする
     */
    getDisasterInfo: function(id) {
      // 災害情報を取得
      this.store.get(id).then(lang.hitch(this, function(item) {
        this.change({
          disasterId: item.disasterId,
          disasterName: item.disasterName,
          extraDisasterId: item.extraDisasterId,
          disasterType: item.disasterType,
          declareTimestamp: item.declareTimestamp
        });
      }));
    },

    /**
     * 災害変更ボタンの表示・非表示を切り替える
     */
    toggleButton: function() {
      // 監視画面のみ災害名選択を表示する
      var display =
        array.indexOf(['monitoring', 'login%2Ftraining', '', null], Router.getCurrentPath()) !== -1 ? '' : 'none';
      this.selectButton.style.display = display;

    },

    /**
     * 画面生成完了
     */
    startup: function() {
      this.inherited(arguments);
      // このウィジェット消滅時にダイアログも削除
      this.own(this.dialog);
    },

    /**
     * 災害変更ダイアログを表示する
     */
    showDialog: function() {
      // ダイアログを表示
      this.dialog.show();
      // ダイアログ中のページウィジェットを取得
      var page = this.dialog.getChildren()[0];

      // イベントが追加済みの場合は何もしない
      if (this._eventFlg) {
        return;
      }

      // ダイアログ側でOKボタンが押された際のイベント
      page.on('update', lang.hitch(this, function(evt) {

        // イベント追加済み
        this._eventFlg = true;

        // 災害変更
        this.change(evt.data);
        //緯度経度を指定してMonitorページにPubする
        topic.publish(module.id + '::updated');

        // ダイアログを非表示にする
        this.dialog.hide();
        InfoDialog.show('災害を変更しました');
      }));
      // ダイアログの画面を再表示する
      page.refresh();

    },

    /**
     * 災害を変更する
     */
    change: function(data) {
      // 変更された災害名を画面へセット
      // this.disasterName.innerText = data.disasterName;
      var disasterName = data.disasterName;
      if(disasterName){
        if(disasterName.length > 40){
          disasterName = disasterName.substring(0, 20) + '...';
        }
      }
      this.disasterName.innerText = disasterName;
      // 変更された災害IDをクッキーへセット
      DisasterInfo.setDisasterId(data.disasterId);
      // 変更された長崎県災害IDをクッキーへセット
      DisasterInfo.setExtraDisasterId(data.extraDisasterId);
      // 変更された災害種別をクッキーへセット
      DisasterInfo.setDisasterType(data.disasterType);
      // 変更された災害モード宣言をクッキーへセット
      DisasterInfo.setDeclareTimestamp(data.declareTimestamp);
    }
  });

});