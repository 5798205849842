define([
    'module',
    'dojo/_base/lang',
    'dojo/on',
    'dojo/_base/declare',
    'idis/error/InvalidArgumentException',
    'idis/view/form/Button'
], function(module, lang, on, declare, InvalidArgumentException, Button) {
    return {
        buttonColumn: function(field, label, buttonLabel) {
            // 引数チェック
            var message;
            if (!/^[a-zA-Z][a-zA-Z0-9]*$/.test(field)) {
                message = '不正なフィールド名です: field="' + field + '"';
            } else if (!label || !lang.isString(label)) {
                message = '長さ1以上の文字列を渡してください: label="' + label + '"';
            }
            if (message) {
                throw new InvalidArgumentException(module.id + '#buttonColumn: ' + message);
            }

            if(!buttonLabel) {
                buttonLabel = label;
            }

            // カラムを返す
            return {
                field: field,
                label: label,
                sortable: false,
                renderCell: function(item) {
                    var disCheck = true;
                    if(item.defaultFlg){
                        if(item.defaultFlg === '0'){
                            disCheck = true;
                        } else {
                            disCheck = false;
                        }
                    }
                    var gridNode = this.grid.domNode;
                    var button = new Button({
                        label: buttonLabel,
                        disabled: disCheck,
                        onClick: function() {
                            on.emit(gridNode, field + 'ButtonClick', {item: item});
                        }
                    });
                    // HTMLとしてウィジェットに紐付くDOMノードを返す
                    return button.domNode;
                }
            };
        }
    };
});
