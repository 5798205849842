/**
 * 被害情報更新画面における被害情報更新ダイアログ
 * @module app/view/page/<ウィジェット名>
 */
define([
  'module', // モジュールのパスを返す
  'dojo/_base/declare', // Dojoのクラス定義用モジュール
  'dojo/_base/lang',
  'dojo/text!./templates/DamageReportUpdateStatusDialog.html', // テンプレート文字列
  'idis/view/page/_PageBase', // 共通基底クラス
  'app/model/DisasterInfo',
  'idis/view/dialog/InfoDialog',
  'idis/view/dialog/DialogChain',
  'idis/util/FilesUtils',
  'idis/model/UserInfo',
  'idis/consts/USER_TYPE',
  'dojo/dom-style',
  'app/config',
  // 以下、変数で受けないモジュール
  'idis/view/form/Button',
  'idis/view/form/DateTimeInput',
  'dijit/form/RadioButton',
  'app/view/form/CustomizableMunicipalitySelector',
  'app/view/form/MunicipalitySelector',
  'dijit/form/Form'
], function(module, declare, lang, template, _PageBase, DisasterInfo, InfoDialog, DialogChain, FileUtils,
    UserInfo, UserType, domStyle, config) {
  /**
   * 被害情報更新画面における被害情報更新ダイアログ
   * @class <ウィジェット名>
   * @extends module:idis/view/page/_PageBase~_PageBase
   */
  return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */
    {
      // テンプレート文字列
      templateString: template,

      constructor: function() {
        // ダイアログ連鎖を登録
        this.chain = DialogChain.get(this);
      },

      // 初期化時、ユーザ種別によって設定可能な選択肢を出し分ける。
      initDialog: function() {
          if(UserInfo.isSysAdmin()){
              //システム管理ユーザ：全てに変更可
              domStyle.set(this.reportStatus11, 'display', '');
              domStyle.set(this.reportStatus31, 'display', '');
              domStyle.set(this.reportStatus41, 'display', '');

          }else if(UserInfo.getUserType() === UserType.PREFECTURE){
              //県ユーザ：[31,41]から[31,41]に変更可
              domStyle.set(this.reportStatus11, 'display', 'none');
              domStyle.set(this.reportStatus31, 'display', '');
              domStyle.set(this.reportStatus41, 'display', '');

          }else if(UserInfo.getUserType() === UserType.REGION){
              //県民局ユーザ：[31,41]から[31,41]に変更可
              domStyle.set(this.reportStatus11, 'display', 'none');
              domStyle.set(this.reportStatus31, 'display', '');
              domStyle.set(this.reportStatus41, 'display', '');

          }else if(UserInfo.getUserType() === UserType.MUNICIPALITY){
              //市町ユーザ：[11,31,41]から[11,31,41]に変更可
              domStyle.set(this.reportStatus11, 'display', '');
              domStyle.set(this.reportStatus31, 'display', '');
              domStyle.set(this.reportStatus41, 'display', '');

            }else if(UserInfo.getUserType() === UserType.OTHER_ORGAN){
              //その他関係機関ユーザ：[31,41]から[31,41]に変更可
              domStyle.set(this.reportStatus11, 'display', 'none');
              domStyle.set(this.reportStatus31, 'display', '');
              domStyle.set(this.reportStatus41, 'display', '');
          }
      },

      setDialog: function(item) {
        this._municipalityCd = item.municipalityCd;
        this.form.set('value', {reportStatus: item.reportStatus});
        
        // システム管理ユーザ
        if(UserInfo.isSysAdmin()){
          // 管轄が県の場合、庁内管理非表示
          domStyle.set(this.reportStatus11, 'display',
           item.municipalityCd === config.municInfo.prefMunicCd ? 'none' : '');
        }
      },

      onSubmit: function() {
        console.log(this);
        var reportStatus = this.form.get('value').reportStatus;
        this.emit('send', {
          reportStatus: reportStatus
        });
      }


    });
});