/**
 * 避難状況・報告管理画面用モジュール。
 * @module app/view/page/EvacOrderDistrictPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom',
    'dojo/dom-style',
    'dojo/text!./templates/EvacOrderDistrictPage.html',
    'dojo/request/iframe',
    'dijit/Dialog',
    'idis/control/Router',
    'idis/control/Locator',
    'idis/view/page/_PageBase',
    'idis/store/IdisRest',
    'idis/consts/ACL',
    'idis/view/Loader',
    'idis/view/grid/helper',
    'idis/view/dialog/DialogChain',
    'idis/model/UserInfo',
    'app/model/DisasterInfo',
    'idis/map/IdisMap',
    'app/config',
    'idis/consts/USER_TYPE',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/CheckBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    'idis/view/form/DateTimeInput',
    'app/view/form/MunicipalitySelector',
    'app/evacorder/EvacOrderFormDialog',
    'app/evacorder/EvacOrderFileUploadDialog',
    'app/evacorder/EvacOrderDistrictGrid',
    'app/evacorder/EvacOrderDistrictFileUploadDialog',
    'app/view/form/DistrictSelector',
    'app/view/page/MapPage'
], function (module, declare, lang, dom, domStyle, template, iframe, Dialog,
    Router, Locator, _PageBase, IdisRest, ACL, Loader, helper,
    DialogChain, UserInfo, DisasterInfo, IdisMap, config, USER_TYPE, Util, topic) {

    /**
     * 検索条件格納用オブジェクト
     * @type {Object}
     * @private
     */
    var _filterData = {};

    /**
     * 避難状況・報告管理画面
     * @class EvacOrderDistrictPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/view/page/EvacOrderDistrictPage~EvacOrderDistrictPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--evacorder',

            // ストア
            store: null,

            // 災害ID
            _disasterId: null,

            // ログインユーザーの代表市町村コード
            _municipalityCd: null,

            constructor: function () {
                this.store = new IdisRest({
                    idProperty: 'evacOrderId',
                    target: '/api/evacorders/district',
                    rangeStartParam: 'offset',
                    rangeCountParam: 'count'
                });

                // 連鎖ダイアログを登録
                this.chain = DialogChain.get(this);
            },

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
                this.own(this.formDialog);
            },

            /**
             * グリッドを初期化する。
             * @function module:app/view/page/EvacOrderDistrictPage~EvacOrderDistrictPage#~startup
             */
            startup: function () {
                this.inherited(arguments);
                // 災害IDをCookieから取得する。
                this._disasterId = DisasterInfo.getDisasterId();
                // 避難情報一覧画面から渡された市町村コードをセットする。
                //this.municipalityCd.set('value', Locator.getQuery().municipalityCd);
                this.municipalityCd = Locator.getQuery().municipalityCd;
                if (this.municipalityCd) {
                    this.districtSelector.set('value', this.municipalityCd.substr(2, 3) + '000');
                }
                // 政令指定都市以外の市町村ユーザーの場合、概況リンクを不可視にする。
                if (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
                    UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd) {
                    domStyle.set(this.overviewLabel, 'display', 'none');
                }

                // グリッドを初期表示する。
                this.initGrid();
                // マップを初期表示する
                // this.initMap();
            },

            /**
             * マップを初期化する。
             */
            initMap: function () {
                var latlng = [config.map.latitude, config.map.longitude];

                this.map = new IdisMap(this.mapNode, {
                    config: config.map,
                    keyboard: false, // コメント時に+/-が使用できないため
                    touchExtend: false,
                    minZoom: 9,
                    drawControlTooltips: false
                }
                ).setView(latlng, 9);
                // destroy時にmapを破棄するよう設定
                this.own(this.map);

                this._subscribe();

                // 生成したmapのlayerControlを画面にセットする。
                this._layerControl = this.map.layerControl;
            },

            /**
             * マップをリフレッシュ
             */
            refreshMap: function (data) {
                // mapに計画情報を反映
                topic.publish(
                    'app/rescue/RescuePage::selectedFromGrid',
                    Util.historyListToHash(data));
            },

            /**
             * topicイベント登録
             */
            _subscribe: function () {
                // 計画一覧を地図に表示
                topic.subscribe(
                    module.id + '::setPlan',
                    lang.hitch(this, this._setPlan)
                );

                // チェックボックス>地図選択時の処理
                topic.subscribe(
                    module.id + '::selectedFromGrid',
                    lang.hitch(this, this._setSituations)
                );
            },

            /**
             * 計画を地図に表示
             * @param {*} payload
             */
            _setPlan: function (payload) {
                var matchedLayer;
                this.featureLayer.eachLayer(function (layer) {
                    if (payload.researchAreaCd === layer.feature.properties.DISTRICT_CD) {
                        matchedLayer = layer;
                        lang.mixin(layer.feature.properties, payload);
                    }
                });
                this.featureLayer.resetStyle(matchedLayer);
                this.map.panInsideBounds(matchedLayer.getBounds());
            },

            /**
             * 避難状況一覧状況を取得する。
             * @function module:app/view/page/EvacOrderDistrictPage~EvacOrderDistrictPage#~startup
             */
            onSubmit: function () {
                try {
                    if (this.form.validate()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGridQuery(this.form.get('value'));
                        console.debug(this.form.get('value'));
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
             * グリッドを初期化する
             */
            initGrid: function () {
                // 検索結果が保存されている場合は、検索結果をFormにセットする。
                this.applyFilterData();

                // 詳細ボタンをクリックした際のイベントをセットする
                this.grid.on('detailButtonClick', lang.hitch(this, function (evt) {
                    this.onDetailButtonClick(evt.item);
                }));

                // グリッドを更新する。
                this.updateGridQuery(this.form.get('value'));
            },

            showFileUploadDialog: function () {
                this.fileUploadDialog.show();
            },

            /**
             * グリッドの検索条件を指定された値で更新する。
             * @param {Object} value name属性と値のマッピング
             */
            updateGridQuery: function (value) {
                // 入力値を元にグリッド用フィルターを作成
                var filter = new this.store.Filter();

                // 災害IDはコンストラクタで存在チェックをしているため常にfilterにセット
                filter = filter.eq('disasterId', this._disasterId);

                if (value.district) {
                    filter = filter.eq('districtCd', value.district);
                }
                if (value.evacOrderTimestampFrom) {
                    filter = filter.eq('evacOrderTimestampFrom', value.evacOrderTimestampFrom.getTime());
                }
                if (value.evacOrderTimestampTo) {
                    filter = filter.eq('evacOrderTimestampTo', value.evacOrderTimestampTo.getTime());
                }
                if (value.evacOrderType) {
                    filter = filter.eq('evacOrderType', value.evacOrderType);
                }
                if (value.issueReasonType) {
                    filter = filter.eq('issueReasonType', value.issueReasonType);
                }

                var flg = value.correctCancelFlg;
                if (flg && flg.length === 0) {
                    filter = filter.eq('correctCancelFlg', false);
                } else {
                    filter = filter.eq('correctCancelFlg', true);
                }

                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);

                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.grid.set('collection', collection);
            },

            /**
             * 検索条件を保管する。
             */
            saveFilterData: function () {
                _filterData = this.form.get('value') || {};
            },

            /**
             * 保管されている検索条件をフォームにセットする。
             */
            applyFilterData: function () {
                // URL上の市町村コードが異なる場合は復元しない
                var currentMunicCd = Locator.getQuery().municipalityCd;
                if (!currentMunicCd || currentMunicCd === _filterData.municipalityCd) {
                    this.form.set('value', _filterData);
                }
            },

            /**
             * 避難状況詳細画面に遷移
             */
            onDetailButtonClick: function (object) {
                // 検索条件を保管する。
                this.saveFilterData();

                // 避難状況詳細画面へ遷移
                Router.moveTo('evacorder/detail', {
                    evacOrderId: object.evacOrderId,
                    // 訂正フラグ・取消しフラグのいずれかがtrueであれば、被訂正・取消し情報として、詳細画面で編集させない。
                    correctCancelFlg: (object.correctFlg || object.cancelFlg) ? 1 : 0
                });
            },

            /**
             * 概況画面に遷移
             * パンくずリストを押下した際の挙動
             */
            onAdminLinkClick: function (evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                // 概況画面に遷移
                Router.moveTo('evacorder/admin');
            },

            /**
             * 避難情報一覧画面に遷移
             * パンくずリストを押下した際の挙動
             */
            onEvacOrderLinkClick: function (evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                // 概況画面に遷移
                Router.moveTo('evacorder');
            },

            /**
             * 避難状況登録画面に遷移
             */
            onRegisterButtonClick: function () {
                // 検索条件を保管する。
                this.saveFilterData();

                // 避難状況登録登録画面へ遷移
                Router.moveTo('evacorder/register');
            },

            /**
             * 一覧情報をエクセル形式で出力します。
             */
            onOutputButtonClick: function () {
                this.formDialog.show();
            }
        });
});
