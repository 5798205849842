/**
 * 避難状況 ファイル読み込みダイアログ用モジュール。
 * @module app/evacorder/EvacOrderFileUploadDialog
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/EvacOrderFileUploadDialog.html',
    'dojo/cookie',
    'dojo/request/iframe',
    'idis/control/Router',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'app/model/DisasterInfo',
    'idis/store/IdisRest',
    'dojo/json',
    'idis/view/dialog/DialogChain',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'dstore/RequestMemory',
    // 以下、変数で受けないモジュール
    'dojox/form/Uploader',
    'dijit/form/Form',
    'dijit/form/Select',
    'app/evacorder/EvacOrderFileUploadConfirmDialog',
    'idis/view/form/Button',
    'app/view/page/ErrorMessageDialogForXoblos'
], function(module, array, declare, lang, template, cookie, iframe, Router, InfoDialog, _PageBase,
            Loader, DisasterInfo, IdisRest, json, DialogChain, UserInfo, Requester, RequestMemory) {
    /**
     * 避難状況ファイルアップロードダイアログ
     * @class EvacOrderFileUploadDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
                   /** @lends module:app/view/page/EvacOrderPage~EvacOrderPage# */ {
        // テンプレート文字列
        templateString: template,

        baseClass: 'idis-Page--safety-dialog',

        /**
        * データ格納用オブジェクト
        * @type {module:dstore/Store}
        */
        store: null,

        storepath: null,

        _fileId: null,

        infoDialog : null,

        _isValid : false,

        _errorObj : null,

        reqid : 'EVAC_ISSU_HIROSIMA_IN',

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.fileUploadConfirmDialog);
            this.own(this.errorMessageDialogForXoblos);
            this.initConfirmDialog();
            this.storepath.fetch().then(lang.hitch(this, function(data) {
                // storepathからreqidに応じたパラメータを取得する。
                data.some(function(data) {
                    if (data.reqid === this.reqid) {
                        this.reqParams = data;
                    }
                }, this);
            }));
        },

        /**
         * 災害ID
         */
        _disasterId: null,
        constructor: function(){
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'evacOrderId',
                target: '/api/evacorders/bulk'
            });
            // 入力帳票の各種パスを取得する。
            this.storepath = new RequestMemory({
                idProperty: 'reqid',
                target: '/data/report/xoblos-config.json'
            });

            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
            // 災害IDを取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定させていません。');
            }
            this._orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
            UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
            UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');
        },

        /**
         * 登録実行
         */
        onSubmit: function() {
            if (this.validate()) {
                console.log(this.attachFile._files);
                //ファイルが空の場合は処理を中断
                if(this.attachFile._files.length === 0) {
                    return;
                }

                // ファイルをサーバーにアップロードするAPIのURL
                var url = '/api/evacorders/uploadReport' +
                      '?reqid=' + this.reqid +
                      '&disid=' + this._disasterId +
                      '&path=' + this.reqParams.pathweb;

                // ファイルをサーバーにアップロードする。
                iframe.post(url, {
                    form: this.form.id,
                    handleAs: 'json'
                }).then(lang.hitch(this, function(ret) {
                    // アップロードしたファイルパスとエラーメッセージjson、
                    // エラー箇所を強調表示したファイルの出力先を指定してxoBlosをコールする。
                    var promise = Requester.post('/bousai/Upload/Upload',{
                       data: {
                           reqid: this.reqid,
                           disid: this._disasterId,
                           path: this.reqParams.path + this._disasterId + '\\' + ret.fileName,
                           errpath: this.reqParams.errpath + this._disasterId + '\\'+ ret.fileName,
                           errmsgpath: this.reqParams.errmsgpath + this._disasterId + '\\'+ ret.fileName + '.json'
                       },
                       headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                       handleAs: 'json'
                   }).then(lang.hitch(this, function(data) {

                        if (data.ResultCode !== 0){
                            // エラーダイアログに結果コードを渡す
                            var errPage = this.errorMessageDialogForXoblos.getChildren()[0];
                            errPage.initErrorMessageDialogForXoblos(data.ResultCode,
                                                                    this.reqParams.errpathweb +
                                                                    this._disasterId +
                                                                    '/' +
                                                                    ret.fileName);
                            this.errorMessageDialogForXoblos.show();
                            this.attachFile.reset();
                        } else {
                            // データの整合性チェックを行う
                            this._errorObj = null;
                            var validatedData = this.validateInputData(data.data);

                            if(this._isValid){ //データに不正がなかった場合
                                // 確認ダイアログにjsonデータを渡す
                                var page = this.fileUploadConfirmDialog.getChildren()[0];
                                page.initFileUploadConfirmDialog(validatedData);
                                this.fileUploadConfirmDialog.show();

                                // 災害IDと組織コードをjsonへセット
                                var i;
                                for(i = 0; i < validatedData.length; i++) {
                                    validatedData[i].disasterId=this._disasterId;
                                    validatedData[i].organization=this._orgCd;
                                }
                                this.data = validatedData;
                            } else { //データに不正があった場合
                                // this._errorObjには、この時点で不正があった地区・避難理由が入っている。
                                var reasonStr = null;
                                switch (this._errorObj.evacOrderReason){
                                    case '01':
                                        reasonStr = '土砂';
                                        break;
                                    case '02':
                                        reasonStr = '洪水';
                                        break;
                                    case '03':
                                        reasonStr = '地震';
                                        break;
                                    case '04':
                                        reasonStr = '津波';
                                        break;
                                    case '05':
                                        reasonStr = '高潮';
                                        break;
                                    case '00':
                                        reasonStr = 'その他';
                                        break;
                                }
                                var errorSentence = 'データに不正があります。<br>'+
                                          '解除日時が設定されている地区に、避難情報が存在しないか、<br>' +
                                          '発令する前に解除されています。<br><br>' +
                                          '対象地区：' + this._errorObj.districtName + '<br>' +
                                          '発令理由：' + reasonStr;
                                this.infoDialog = new InfoDialog({
                                    title : '入力エラー',
                                    content : errorSentence
                                });
                                this.infoDialog.show();
                                // this.attachFile.reset();
                            }
                        }

                    }), lang.hitch(this, function(error) {
                        console.log(error);
                        // 失敗した場合、エラーダイアログを表示する。
                        this.infoDialog = new InfoDialog({
                            title : 'システムエラー',
                            content : 'エラーが発生しました。システム管理者にお問い合わせください。'
                        });
                        this.infoDialog.show();
                        this.attachFile.reset();
                    }));
                    // ローダーの表示
                    Loader.wait(promise);
                }));
            }
        },

        /*
         * xoblosから返ってきたデータの不正チェックを行い、「解除」のデータのevacOrderTypeを変更する。
         */
        validateInputData: function(data){
            this._isValid = true;
            var self = this;
            var updatedData = [];
            array.forEach(data, function(obj){
                if(obj.evacOrderType === '00' && self._isValid){
                    // 避難区分が「解除」だった場合
                    self._isValid = false;
                    var districtCd = obj.districtCd;
                    var evacOrderReason = obj.evacOrderReason;
                    var evacOrderTimestamp = obj.evacOrderTimestamp;
                    var forReleaseList = [];
                    var _self = self;
                    array.forEach(data, function(comp){
                        //もしも、地区・発令理由が同じ、かつ避難区分が「解除」でないデータがあり。
                        // そのデータが「解除」よりも先に発令されていれば、不正ではない
                        if(districtCd === comp.districtCd &&
                            evacOrderReason === comp.evacOrderReason &&
                            comp.evacOrderType !== '00' &&
                            evacOrderTimestamp > comp.evacOrderTimestamp){
                                // データの不正フラグを更新する
                                _self._isValid = true;
                                // 解除対象となる避難情報の候補をリストアップする
                                forReleaseList.push(comp);
                        }
                    });
                    // １つの「解除」データについて検証し終えた時点で_isValidフラグが偽だった場合、処理を進めずに返す。
                    if(!self._isValid){
                        self._errorObj = obj;
                        return;
                    }
                    // 解除対象が見つかった場合、「解除」データの解除対象(地区・発令理由が同じで「解除」ではないデータのうち最新のもの)を探す
                    var latestOrderType = self.pickLatestOrderType(forReleaseList);
                    switch(latestOrderType){
                        case '01' :
                            obj.evacOrderType = '05';
                            break;
                        case '02' :
                            obj.evacOrderType = '06';
                            break;
                        case '03' :
                            obj.evacOrderType = '07';
                            break;
                    }
                }
                if(obj.isNewReport === '1'){
                    // もしもデータがDBへの入力対象だった場合、リストに追加
                    delete obj.isNewReport;
                    updatedData.push(obj);
                }
            });
            return updatedData;
        },

        initConfirmDialog: function() {
            var page = this.fileUploadConfirmDialog.getChildren()[0];
            // 確認ダイアログのregisterイベントを受け取る
            page.on('send', lang.hitch(this, function() {
                console.debug('registerイベント');
                console.debug(this.data);
                // // FIXME 避難地区の自由入力項目にそれぞれ避難地区名をセットする。
                var reqData = [];
                this.data.forEach(lang.hitch(this, function(data) {
                    var _data = data;
                    // 自由入力地区情報を追加する。
                    _data.districtFreeName = data.districtName;
                    _data.districtFreeNameKana = '';
                    reqData.push(_data);
                }));

                // 登録が押された場合
                // 追加処理と共にローディング表示
                var promise = this.store.add(reqData).then(lang.hitch(this, function() {
                    this._buttonFlg = false;
                    this.fileUploadConfirmDialog.hide();
                    // 確認ダイアログを閉じる
                    this.chain.hide();
                    this.emit('upload');
                }), lang.hitch(this, function(err) {
                    if(err.response.data && typeof(err.response.data) === 'string'){
                        err.response.data = json.parse(err.response.data);
                    }
                    this.chain.infoError(err);
                }));
                this.attachFile.reset();
                Loader.wait(promise);
            }));
        },

        /**
         * 入力チェック
         */
        validate: function() {
          //ファイルが空の場合は処理を中断
          if(this.attachFile.getFileList().length === 0) {
            InfoDialog.show('入力チェック', 'ファイルが選択されていません');
            return false;
          }
          return true;
        },

        /**
         *報告書読み込みで呼ばれる
         **/
        loadFile: function() {
             //ファイルが空の場合は処理を中断
             if(this.attachFile._files.length === 0) {
                 return;
             }

             console.debug('report change');

             // エクセルファイル以外の場合はエラー
             var file = this.attachFile._files[0];
             if(file.name.indexOf('.xls') === -1 && file.name.indexOf('.xlsx') === -1 &&
             file.name.indexOf('.xlsm') === -1) {
                 if (!this.infoDialog) {
                     this.infoDialog = new InfoDialog({
                         title : 'エラー',
                         content : 'エクセルファイルを選択してください。'
                     });
                 }
                 this.infoDialog.show();
                 this.infoDialog=null;
                 this.attachFile.reset();
                 return;
             }

             var form = new FormData();
             form.append('report', this.attachFile._files[0]);

            // ファイル名をyyyymmddhhssに設定。このファイル名はimport用APIをコールするときにも使う。
            var now = new Date();
            this._fileId = now.getFullYear() + this.padZero(now.getMonth() + 1) +
                this.padZero(now.getDate()) + this.padZero(now.getHours()) +
                this.padZero(now.getMinutes()) + this.padZero(now.getSeconds());

            // ファイルがonloadされたときにサーバーに一時保存する
            var promise = iframe.post('/api/upload/uploadFile?fileId=' +
                this._fileId , {
                    form: this.form.id,
                    handleAs: 'json'
             });
             //ローダーの表示
             Loader.wait(promise);
         },

         /*
          * リストにある避難情報オブジェクトのうち、最新のオブジェクトの避難区分コードを返す
          */
         pickLatestOrderType: function(list) {
             var latestOrderTime = null;
             var latestOrderType = null;

             array.forEach(list, function(each){
                 if(!latestOrderTime){
                     // データが初出の場合
                     latestOrderTime = each.evacOrderTimestamp;
                     latestOrderType = each.evacOrderType;
                 } else if (latestOrderTime < each.evacOrderTimestamp){
                     latestOrderTime = each.evacOrderTimestamp;
                     latestOrderType = each.evacOrderType;
                 }
             });
             return latestOrderType;
         },

        // 日付をゼロパディング
        padZero : function(num){
            var result;
            if (num < 10) {
                result = '0' + num;
            } else {
                result = '' + num;
            }
            return result;
        }
    });
});
