/**
 * 避難状況詳細画面用モジュール。
 * @module app/evacorder/EvacOrderDetailPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/dom-style',
    'dojo/promise/all',
    'dojo/text!./templates/EvacOrderDetailPage.html',
    'dojo/topic',
    'dojo/json',
    'idis/view/Loader',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/view/dialog/InfoDialog',
    'idis/model/UserInfo',
    'idis/consts/ACL',
    './_EvacOrderPageBase',
    './EvacOrderDetailStoreModel',
    './Reason',
    'app/config',
    './EvacOrderConfig',
    'app/provide/consts/DeliveredStatus',
    'idis/consts/USER_TYPE',
    // 'app/evacorder/DistributionType',
    // 以下、変数で受けないモジュール
    'idis/view/form/DateTimeInput',
    'app/view/form/AllFromCityMunicipalitySelector',
    'dijit/layout/TabContainer',
    'app/view/form/MunicipalitySelector',
    'app/evacorder/EvacOrderCancelDialog',
    'app/provide/ProvideEvacOrderRegisterDialog',
    './EvacOrderPopulationGrid'
], function (module, declare, lang, array, domStyle, all, template, topic,
    json, Loader, Locator, Router, Requester, IdisRest, InfoDialog,
    UserInfo, ACL, _EvacOrderPageBase, EvacOrderDetailStoreModel, Reason, config, EvacOrderConfig,
    DeliveredStatus, USER_TYPE) {
    /**
     * 避難状況詳細画面
     * @class EvacOrderDetailPage
     * @extends module:app/evacorder/_EvacOrderPageBase~_EvacOrderPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _EvacOrderPageBase,
    /** @lends module:app/evacorder/EvacOrderDetailPage~EvacOrderDetailPage# */ {

            // テンプレート文字列
            templateString: template,

            // 避難情報ID
            _evacOrderId: null,

            // 登録時に選択されていた避難情報発令地区
            _selectedDitstirctCds: null,

            // 修正用のページかどうか
            isFixPage: true,

            // 初期表示時の避難情報発令理由(取消時のLアラート連携用)
            _initialIssueReasonType: null,

            // 地域情報を固定するフラグ（キャッシュJSON使用）
            _useCacheJson: false,

            _cancelIssueReasonType: null,

            // 最新の避難情報IDのリスト
            _latestEvacOrderIds: [],

            // 災害シミュレーション用
            _simulateFlg: false,
            _eventId: null,
            _scenarioId: null,

            OLD_EVAC_TYPE_CD_LIST: ['01', '02', '03', '04', '90', '91', '92', '93', '94'],

            constructor: function () {
                // ベースクラスのコンストラクタを呼ぶ
                this.inherited(arguments);
                // form用のストアを生成する。
                this.formStore = new IdisRest({
                    idProperty: 'evacOrderId',
                    target: '/api/evacorders'
                });

                // URLから災害シミュレーション用パラメータを取得
                this._simulateFlg = Locator.getQuery().simulateFlg === 'true';
                this._eventId = Locator.getQuery().eventId;
                this._scenarioId = Locator.getQuery().scenarioId;
                // 一覧画面から渡された避難情報IDを取得する。
                this._evacOrderId = Locator.getQuery().evacOrderId;
            },

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
                // 自分が削除された時は関連ダイアログも削除する
                this.own(this.cancelDialog);
            },

            // HTML上にウィジェットが設置されてから呼ばれる
            startup: function () {
                this.inherited(arguments);

                // 避難情報詳細ページを初期化する。
                this.initDetailPage();

                // 詳細画面で利用するTipsをセットする
                this.setTipsPopups();
            },

            /**
             * TipsPopupをセットする。
             */
            setTipsPopups: function () {
                // 避難理由
                this.setIssueReasonTypeTips();
                // 発令理由詳細
                this.setIssueReasonTips();
                // 補足情報
                this.setNoteTips();
                // 地区情報
                this.setDistrictTips();
            },

            setButtonStatus: function () {
                // 災害シミュレーションの場合、訂正ボタンを表示する
                if (this._simulateFlg) {
                    domStyle.set(this.registerButtonArea, 'display', 'none');
                    domStyle.set(this.correctButtonArea, 'display', '');
                    domStyle.set(this.cancelButtonArea, 'display', 'none');
                    domStyle.set(this.saveButtonArea, 'display', 'none');
                } else if (this._useCacheJson) {
                    // 地域情報を固定している場合、全てのボタンを表示しない
                    domStyle.set(this.registerButtonArea, 'display', 'none');
                    domStyle.set(this.correctButtonArea, 'display', 'none');
                    domStyle.set(this.cancelButtonArea, 'display', 'none');
                    domStyle.set(this.saveButtonArea, 'display', 'none');
                } else {
                    // Queryから受け取った訂正・取消フラグがtrueであれば、ボタンは表示しない。
                    var flg = Locator.getQuery().correctCancelFlg;
                    if (flg === this.CORRECT_CANCEL_FLG.TRUE) {
                        // チェックでreturnされなかった場合、訂正取消しフラグを表示する。
                        domStyle.set(this.correctButtonArea, 'display', 'none');
                        domStyle.set(this.cancelButtonArea, 'display', 'none');
                        return;
                    }
                    // 最新の避難情報IDのリストの中に避難情報IDがなければ、取消しボタンは表示しない。
                    var isLatest = false;
                    this._latestEvacOrderIds.some(function (latestId) {
                        if (latestId === Number(this._evacOrderId)) {
                            isLatest = true;
                            return true;
                        }
                    }, this);
                    if (!isLatest) {
                        domStyle.set(this.cancelButtonArea, 'display', 'none');
                    }

                    // 管理対象市町でなかった場合
                    if (UserInfo.getMunicipalityCds().indexOf(this._municipalityCd) === -1) {
                        domStyle.set(this.registerButtonArea, 'display', 'none');
                        domStyle.set(this.correctButtonArea, 'display', 'none');
                        domStyle.set(this.cancelButtonArea, 'display', 'none');
                        domStyle.set(this.deleteButtonArea, 'display', 'none');
                    }

                    // 発令内容が旧コードの場合
                    if (this.OLD_EVAC_TYPE_CD_LIST.indexOf(this._evacOrderType) !== -1) {
                        domStyle.set(this.registerButtonArea, 'display', 'none');
                        domStyle.set(this.correctButtonArea, 'display', 'none');
                        domStyle.set(this.cancelButtonArea, 'display', 'none');
                    }

                }
                // 未配信の場合、取消ボタンは表示せず、削除ボタンを表示する
                if (this._deliveredStatus===DeliveredStatus.UN_DELIVERED) {
                    domStyle.set(this.cancelButtonArea, 'display', 'none');
                    // 管理対象市町の場合のみ削除ボタンを表示
                    if (UserInfo.getMunicipalityCds().indexOf(this._municipalityCd) !== -1) {
                        domStyle.set(this.deleteButtonArea, 'display', '');
                    }
                }
            },

            /**
             * 避難情報詳細ページを初期表示する。
             * 処理に依存関係があるものをこの中で呼ぶ。
             */
            initDetailPage: function () {
                // 市町村ユーザーの場合(政令指定都市を除く)概況リンクを不可視
                if (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
                    UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd) {
                    domStyle.set(this.overviewLabel, 'display', 'none');
                }
                // evacOrderIdは一覧画面からの画面遷移時に渡されてコンストラクタでモジュールにセットされる。
                this.getEvacOrderDetailInfo(this._evacOrderId)
                    // 市町村コードがモジュールにセットされてから処理される。
                    .then(lang.hitch(this, function () {

                        // 地図の初期位置を設定する。
                        this.initLatlng();

                        // 初期表示時の避難情報発令理由を取得する。
                        this._initialIssueReasonType = this.issueReasonType.get('value');
                        this._initialEvacOrderType = this._evacOrderType;

                        if (this.OLD_EVAC_TYPE_CD_LIST.indexOf(this._initialEvacOrderType) !== -1){
                            // 災害対策基本法改正前のコード場合
                            this.setOldEvacOrderTypeSelector(this._initialIssueReasonType, this._evacOrderType);
                            this.evacOrderType.set('disabled', 'true');
                        } else {
                            this.setEvacOrderTypeSelector(this._initialIssueReasonType, this._evacOrderType);
                        }

                        // 訂正・取消ボタンの活性/非活性を初期化する。
                        this.setButtonStatus();

                        // 避難情報ツリーを初期表示した後にツリーのストア情報に基づいて地区レイヤーを表示する。
                        this.initTree().then(lang.hitch(this, 'initDistrictLayer'))
                            .then(lang.hitch(this, function () {
                                // 準備が整ったら選択中の各地区をチェック状態にする
                                this._selectDistricts(this._selectedDitstirctCds);
                            }));
                    }));
            },

            /**
             * 地図上の地区選択レイヤーを初期化する。
             * @returns {Promise} 完了後に解決するPromise
             */
            // 詳細ページでは現況レイヤーを表示しない。
            initDistrictLayer: function () {
                return all({
                    select: this.showSelectLayer()
                });
            },

            /**
             * ツリーを初期化する。
             * TODO ツリー初期化後にexpandAll()メソッドを呼ぶ。
             */
            initTree: function () {
                // ツリーを生成する。
                if (!this.tree) {
                    this.tree = new this.treeClass({
                        model: new EvacOrderDetailStoreModel({
                            disasterId: this._disasterId,
                            municipalityCd: this._municipalityCd
                        })
                    }, this.treeNode);
                    this.tree.startup();
                }
                this.own(this.tree);
                this.tree.onLoadDeferred.then(lang.hitch(this, '_updatePopulationStore'));
                // ツリーの作成が終了したら、地図のfilter用に地区ツリーのキャッシュを取得しておく
                this.tree.onLoadDeferred.then(lang.hitch(this, function () {
                    this.tree.model.store._getCache().then(lang.hitch(this, function (items) {
                        this._districtTreeCache = items.data;
                    }));
                }));
                return this.tree.onLoadDeferred;
            },

            /**
             * レイヤーIDから避難情報を取得する
             */
            getEvacOrderDetailInfo: function (evacOrderId) {
                // 災害シミュレーションからの遷移の場合、避難情報jsonを取得する
                if (this._simulateFlg) {
                    return Requester.get('/data/simulation/' + this._eventId + '.json')
                        .then(lang.hitch(this, function (item) {
                            var data = item.evacOrderPostForm;
                            // 取得した避難情報をformにセットする。
                            this.form.set('value', data);
                            // 組織を表示
                            // TODO: シミュレーションJSONに組織名（organizationName）を出力して、そちらを表示
                            domStyle.set(this.organization.domNode, 'display', '');
                            // 避難情報で選択されていた地区コードのリストをモジュールで保持する。
                            var distCdList = [];
                            data.districtList.forEach(function(item){
                                distCdList.push(item.districtCd);
                            });
                            this._initialDistrictCds = distCdList;
                            // 市町村コードを画面に保持しておく。(避難情報登録画面で同様の持ち方をしているので実装を合わせる)
                            this._municipalityCd = data.municipalityCd;
                            // 最新の避難情報IDのリストを画面に保持しておく。
                            this._latestEvacOrderIds = data.latestEvacOrderIds;
                            this._evacOrderType = data.evacOrderType;
                        }));
                } else {
                    // キャッシュJSONがあればそちらを参照する
                    return Requester.get('/data/evacorder/cache/detail_' + evacOrderId + '.json')
                    .then(lang.hitch(this, function(data) {
                        this.initEvacOrderDetailInfo(data);
                        // 地域情報を固定するフラグ
                        this._useCacheJson = true;
                    })).otherwise(lang.hitch(Requester, 'get', '/api/evacorders/detail/' + evacOrderId))
                        .then(lang.hitch(this, function(data) {
                            if(!this._useCacheJson){
                                this.initEvacOrderDetailInfo(data);
                            }
                    }));
                }
            },

            /**
             * レイヤーIDから避難情報を取得する
             */
            initEvacOrderDetailInfo: function (data) {
                // 取得した避難情報をformにセットする。
                this.form.set('value', data);
                // 組織名セット
                this.organizationName.innerHTML = data.organizationName;
                domStyle.set(this.organizationName, 'display', '');
                // 避難情報で選択されていた市町村コードのリストをモジュールで保持する。
                this._selectedDitstirctCds = data.evacOrderDistrictCds.split(',');
                // 市町村コードを画面に保持しておく。(避難情報登録画面で同様の持ち方をしているので実装を合わせる)
                this._municipalityCd = data.municipalityCd;
                // 最新の避難情報IDのリストを画面に保持しておく。
                this._latestEvacOrderIds = data.latestEvacOrderIds;
                this._evacOrderType = data.evacOrderType;
                //代理登録を設定
                this.representedFlg.set('checked', data.representedFlg);

                this._deliveredStatus = data.deliveredStatus;
                this._version = data.version;
                // 配信状況マークを表示
                switch (this._deliveredStatus) {
                    case DeliveredStatus.UN_DELIVERED:
                        domStyle.set(this.undeliveredMark, 'display', '');
                        break;
                    case DeliveredStatus.DELIVERED:
                        domStyle.set(this.deliveredMark, 'display', '');
                        break;
                }
            },

            // 発令ボタンを押した時の挙動
            onRegisterButtonClick: function () {
                this.isFixPage = false;
                // フォームのバリデーションを行う（共通部品）
                if (!this.form.validate()) { return false; }
                // フォームを取得
                this._evacOrderForm = this.generateForm(true);
                // 入力チェック
                if (!this.validateEvacOrderInfo(true)) { return; }
                // 緊急速報メールの文面作成対応
                delete this._evacOrderForm.correctCancelReason;
                delete this._evacOrderForm.evacOrderId; //idを削除することで、最終的にAPIを呼び出すときにPOSTとなる
                this.showEvacOrderDialog4Register(this._evacOrderForm);
            },

            // 一時保存ボタンを押した時の挙動
            onSaveButtonClick: function () {
                this.isFixPage = false;
                // フォームのバリデーションを行う（共通部品）
                if (!this.form.validate()) { return false; }
                // フォームを取得
                this._evacOrderForm = this.generateForm(true);
                // 入力チェック
                if (!this.validateEvacOrderInfo(true)) { return; }
                // 配信なしで登録処理
                this.register();
            },

            /**
             * 訂正ボタンが押下されてFormのサブミットイベントが発生した際に呼ばれる
             */
            onCorrectButtonClick: function () {
                this.isFixPage = true;
                // フォームのバリデーションを行う（共通部品）
                if (!this.form.validate()) { return false; }
                // フォームを取得
                this._evacOrderForm = this.generateForm(false);
                // 入力チェック
                if (!this.validateEvacOrderInfo(false)) { return; }

                // 災害シミュレーション
                if (this._simulateFlg) {
                    return this.collect4Simulation();
                }

                // 未配信情報の訂正の場合、配信はせず、DB登録内容を訂正する。配信ステータスは「未配信」のまま
                if (this._deliveredStatus===DeliveredStatus.UN_DELIVERED) {
                    return this.registerCorrect();
                }

                // 訂正確認画面ダイアログを表示する。
                var message = '避難情報を訂正します。<br><br>' +
                    '誤って登録した情報を訂正する場合は、<br>OKボタンをクリックして訂正を完了してください。<br><br>' +
                    '<font color="#dc143c">※続報情報を登録する場合には、<br>' +
                    '画面右下の「発令」ボタンから情報を登録してください。</font>';
                this.chain.confirm(message, lang.hitch(this, function (chain) {
                    chain.hide();
                    // 訂正・取消理由が入力されているか確認する。
                    if (!this._evacOrderForm.correctCancelReason.trim()) {
                        return this.showErrDialog('訂正・取消理由が入力されていません。');
                    }
                    this.showEvacOrderDialog4CorrectCancel(this._evacOrderForm, false);
                }));
            },

            /**
             * 災害シミュレーション用 イベントの訂正を実行する
             * @returns 
             */
            collect4Simulation: function() {
                // 確認ダイアログ内で設定した情報を、避難情報登録フォームに反映させる
                // this._evacOrderForm.twitterFlg = false;
                this._evacOrderForm.prefMailFlg = false;
                delete this._evacOrderForm.correctCancelReason;

                delete this._evacOrderForm.evacOrderLalertAreaMap;
                delete this._evacOrderForm.sendPostForms;
                var subSendData = {};
                subSendData.municipalityName = this.municipalityCd.domNode.innerText;
                subSendData.evacOrderTypeName = this.evacOrderType.domNode.innerText;
                subSendData.issueReasonTypeName = this.issueReasonType.domNode.innerText;

                var jsonStr = json.stringify({
                    evacOrderPostForm: this._evacOrderForm,
                    evacOrderConversionForm: subSendData,
                    scenarioId: this._scenarioId,
                    eventId: this._eventId
                });

                this.chain.confirm('イベントを訂正します。<br>よろしいですか。', function (chain) {
                    var promise = Requester.put('/api/simulations/event/' + this._eventId, {
                        data: jsonStr,
                        headers: { 'Content-Type': 'application/json; charset=utf-8' },
                        handleAs: 'json',
                        preventCache: true
                    }).then(function () {
                        console.debug('success update Chronology event');
                        chain.infoComplete(function () {
                            Router.moveTo('simulation/event', { scenarioId: this._scenarioId });
                        });
                    }, function (error) {
                        console.log(error);
                        if (error.response.data && typeof (error.response.data) === 'string') {
                            error.response.data = json.parse(error.response.data);
                        }
                        chain.infoError(error);
                    });
                    //ローダーの表示
                    Loader.wait(promise);
                });
            },

            /**
             * 送信用フォームの作成
             * @param {boolean} isRegist true: 発令、false: 訂正
             * @returns form
             */
            generateForm: function(isRegist) {
                // formデータを取得
                var form = this.form.get('value');
                // 避難情報IDをセットする。
                if (!isRegist) {
                    form.evacOrderId = this._evacOrderId;
                }
                // 市町村をセットする。
                form.municipalityCd = this._municipalityCd;
                // 災害IDをセットする。
                form.disasterId = this._disasterId;
                form.evacOrderType = this._evacOrderType;
                // 避難対象人数、避難対象世帯数をセットする。
                form.evaqueeNum = this.evaqueeNumNode.innerHTML;
                form.evacHouseholdNum = this.evacHouseholdNumNode.innerHTML;
                // 発令理由をセットする（disabledにしている項目はform.get('value')で対象外になるため
                form.issueReasonType = this.issueReasonType.get('value');
                // 代理登録の設定
                form.representedFlg = UserInfo.getMunicipalityCd() !== this._municipalityCd;
                this.representedFlg.set('checked', form.representedFlg);

                // 地区リストをセットする。
                var districtArray = this.tree.getCheckedLeafs();

                // 地区を変換する
                if (this._isEvacOrderTypeChanged() || !isRegist) {
                    // 発令内容が変わった又は訂正の場合、チェックされている地区をそのまま連携すればいい。
                    form.districtList = this.convertDistrictArray(districtArray, form.evacOrderType, form.issueReasonType, isRegist);
                } else {
                    // しかし、新規発令で、かつ発令内容が同じ場合、かつ地区が「増えている」場合、「増えた地区」だけをDB登録したい。
                    // なお、地区が「減っている」ケースは、上の処理でエラーダイアログを出しているのでそもそも発生し得ない。
                    var wholeDistrictCd = this.convertDistrictArray(districtArray, form.evacOrderType,  form.issueReasonType, true);
                    form.districtList = [];
                    array.forEach(wholeDistrictCd, function (dist) {
                        if (this.generateDistrictGap().addedDistCd.indexOf(dist.districtCd) !== -1) {
                            form.districtList.push(dist);
                        }
                    }, this);
                }

                // Lアラート連携用の地区リストを作成する。
                if (isRegist) {
                    var districtArray4Regist = [];
                    array.forEach(districtArray, function (dist) {
                        var isDistIncluded = false;
                        array.forEach(form.districtList, function (checkedDist) {
                            if (checkedDist.districtCd === dist.districtCd) {
                                isDistIncluded = true;
                            }
                        });
                        if (isDistIncluded) {
                            districtArray4Regist.push(dist);
                        }
                    }, this);
                    this._districtArray = districtArray4Regist;
                    form.evacOrderLalertAreaMap =
                        this.createLalertAreaList(this._districtTreeCache, districtArray4Regist,
                            null, form, false, false, null);
                } else {
                    // チェックを外された地区の一覧を作る
                    var deselectedList = this.createUnselectedList(districtArray);
                    // var evacOrderId = parseInt(this._evacOrderId, 10); //文字列型で入っているので数字に変える
                    form.evacOrderLalertAreaMap =
                        this.createLalertAreaList(this._districtTreeCache, districtArray,
                            deselectedList, form, false, true, this._initialEvacOrderType);
                }
                return form;
            },

            /**
             * 入力内容のバリデーションチェック
             * @param {boolean} isRegist true: 発令、false: 訂正
             * @returns チェック結果（true: OK, false: NG）
             */
            validateEvacOrderInfo: function (isRegist) {

                // 地区のバリデーションを行う
                if (!this.validateDistrictInfo(isRegist)) { return false; }

                // 訂正又は発令内容変更ありの場合は、バリデーションチェック終了
                if (!isRegist || this._isEvacOrderTypeChanged()) { return true; }

                // 新規発令の時は、追加で地区のエラーチェックを行う。
                var districtGap = this.generateDistrictGap();
                // 発令内容が変わらず、元々発令されていた地区が欠けていたら、
                // 「訂正」を選ぶ旨をリマインドする
                if (districtGap.removedDistName.length > 0) {
                    return this.showErrDialog('以下の地区が欠けています。<br><br>' +
                        '地区：' + districtGap.removedDistName.join('、') + '<br><br>' +
                        '発令した地区に誤りがあり、一部の地区の発令状況のみ取消したい場合は、<br>' +
                        '正しい地区のみにチェックを入れて、「訂正・取消理由」を入力の上、「訂正」ボタンを押してください。<br><br>' +
                        '一部地区のみ避難情報を解除する場合は、解除対象地区のみを選び、<br>' +
                        '発令内容を「解除」に変更した上で、「発令」ボタンを押してください。');
                }

                // 新規発令の時は、発令内容が変わらず、地区に変化がない場合は警告を出す。
                // 時刻を変える場合は「訂正」を選ぶ旨をリマインドする。
                if (districtGap.removedDistCd.length === 0 && districtGap.addedDistCd.length === 0) {
                    // 「未配信」情報の場合は、配信方法を伝える
                    if (this._deliveredStatus === DeliveredStatus.UN_DELIVERED) {
                        return this.showErrDialog('この画面からは配信できません。一覧画面からLアラート配信を実行してください');
                    }
                    return this.showErrDialog('発令内容・発令地区に変化がないので、続報として登録できません。<br><br>' +
                    '発令日時、発令理由詳細、行動指針、補足情報などを変更する場合は、「訂正」としての連携が必要です。<br>' +
                    '「訂正・取消理由」を入力の上、「訂正」ボタンを押してください。<br><br>');
                }
                return true;
            },

            /**
             * 前回発令との地区の差分を返す
             * （発令内容が変わらない場合は、地区の差分を取る必要がある。）
             * @returns {{
             *      removedDistCd: string     // 外した地区コード
             *      removedDistName: string   // 外した地区名
             *      addedDistCd: string       // 追加した地区コード
             *      addedDistName: string     // 追加した地区名
             * }}
             */
            generateDistrictGap: function() {
                var districtGap = {
                    removedDistCd: [],
                    removedDistName: [],
                    addedDistCd: [],
                    addedDistName: []
                };
                var checkedDistrictCdList = [];
                array.forEach(this.tree.getCheckedLeafs(), function (checkedDist) {
                    // 後続処理のため、地区コードのみを取り出して配列化しておく
                    checkedDistrictCdList.push(checkedDist.districtCd);

                    // 初期表示時の地区配列に、現在選択中の地区が含まれなかったら
                    if (this._selectedDitstirctCds.indexOf(checkedDist.districtCd) === -1) {
                        districtGap.addedDistCd.push(checkedDist.districtCd);
                        districtGap.addedDistName.push(checkedDist.districtName);
                    }
                }, this);

                array.forEach(this._selectedDitstirctCds, function (distCd) {
                    // 現在選択中の地区配列に、初期表示時に選択していた地区が含まれなかったら
                    if (checkedDistrictCdList.indexOf(distCd) === -1) {
                        districtGap.removedDistCd.push(distCd);
                        districtGap.removedDistName.push(this.getDistrictInfo(distCd).districtName);
                    }
                }, this);

                return districtGap;
            },

            createUnselectedList: function (selectedDistObjList) {
                var list = [];
                array.forEach(this._selectedDitstirctCds, function (distCd) {
                    var isSelected = false;
                    array.forEach(selectedDistObjList, function (distObj) {
                        if (distObj.districtCd === distCd) {
                            isSelected = true;
                        }
                    }, this);

                    if (!isSelected) {
                        list.push(distCd);
                    }
                }, this);
                return list;
            },

            register: function(){

                delete this._evacOrderForm.evacOrderLalertAreaMap;
                delete this._evacOrderForm.correctCancelReason;
                delete this._evacOrderForm.evacOrderId;

                var message = '避難情報を続報として登録します。<br>' +
                        '最新の世帯数・人口となっているか、また発令日時が正しいか、ご確認ください。<br>' +
                        'よろしければ、OKボタンを押してください。';

                this.chain.confirm(message, lang.hitch(this, function(chain){
                    // 最初に、DBに避難情報・避難所情報を登録する。Lアラート・緊急速報メール以外の外部配信もここで行われる。
                    var jsonStr = json.stringify(this._evacOrderForm);
                    var promise = Requester.post('/api/evacorders', {
                        data: jsonStr,
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(lang.hitch(this, function(){

                        chain.info('登録が完了しました。', '完了', lang.hitch(this, function(){
                            // ヘッダーに反映
                            topic.publish(module.id + '::updated');
                            this.evacOrderRegisterDialog.getChildren()[0].closeDialog();
                            Router.moveTo('evacorder', {
                                municipalityCd: this._municipalityCd
                            });
                        }));
                    }), lang.hitch(this, function(error){
                        console.error(error);
                        if (error.response.data && typeof (error.response.data) === 'string') {
                            error.response.data = json.parse(error.response.data);
                        }
                        chain.infoError(error);
                    }));
                    Loader.wait(promise);
                }));
            },

            /**
             * 配信せずに訂正する
             */
            registerCorrect: function(){
                // 避難所開設情報が設定されているか確認する。
                // 設定されていない場合はダイアログを警告メッセージに変更する。
                var message = '避難情報を訂正します。<br>よろしいですか。';
                var promise = null;

                delete this._evacOrderForm.evacOrderLalertAreaMap;
                this.chain.confirm(message, lang.hitch(this, function(chain){
                    // 最初に、DBに避難情報・避難所情報を登録する。Lアラート・緊急速報メール以外の外部配信もここで行われる。
                    var jsonStr = json.stringify(this._evacOrderForm);
                    promise = Requester.put('/api/evacorders/' + this._evacOrderForm.evacOrderId , {
                        data: jsonStr,
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(lang.hitch(this, function(){

                        chain.info('訂正が完了しました。', '完了', lang.hitch(this, function(){
                            // ヘッダーに反映
                            topic.publish(module.id + '::updated');
                            this.evacOrderRegisterDialog.getChildren()[0].closeDialog();
                            Router.moveTo('evacorder', {
                                municipalityCd: this._municipalityCd
                            });
                        }));

                    }), lang.hitch(this, function(error){
                        console.error(error);
                        if (error.response.data && typeof (error.response.data) === 'string') {
                            error.response.data = json.parse(error.response.data);
                        }
                        chain.infoError(error);
                    }));
                    Loader.wait(promise);
                }));
            },

            // 避難所情報を初期表示する。
            initShelterGrid: function () {
                this.updateGridQuery();
            },

            // 避難所グリッドを更新する
            updateGridQuery: function () {
                // 検索条件を格納するGrid用フィルターを作成
                var filter = new this.shelterStore.Filter();

                // 災害IDをセットする。
                filter = filter.eq('disasterId', this._disasterId);

                // 地区をセットする。避難地区はカンマ区切りに変換してから
                var districtArray = this.tree.getCheckedLeafs();
                var districtCds = this.parseArray2String(districtArray);
                filter = filter.eq('districtCds', districtCds);
                // 「発令理由」に一致する「対象災害」を持つ避難所を検索
                // 「その他」が選択されている場合は全避難所を表示
                // 選択された開設理由からフラグをセット
                var shelterType = this.issueReasonType.getValue();
                if (shelterType && shelterType !== '00') {
                    filter = filter.eq('shelterType', shelterType);
                }
                // filterに対応するcollectionを取得
                var collection = this.shelterStore.filter(filter);


                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.shelterGrid.set('collection', collection);

            },

            /**
             * 取消ボタンが押下された際に呼ばれる
             */
            onCancelButtonClick: function () {
                this.isFixPage = true;
                // 訂正確認画面ダイアログを表示する。
                var message = '避難情報を取消します。<br><br>' +
                    Reason.get(this._initialIssueReasonType).name + 'として発令された' +
                    '一連の避難情報をすべて取り消す場合は、<br>OKボタンをクリックして取消しを完了してください。<br><br>' +
                    '<font color="#dc143c">※一部の避難情報を訂正する場合には、<br>' +
                    'キャンセルボタンをクリックして、<br>訂正を行ってください。</font>';

                this.chain.confirm(message, lang.hitch(this, function (chain) {
                    this._cancelIssueReasonType = this.issueReasonType.get('value');
                    chain.hide();
                    // 訂正・取消理由が入力されているか確認する。
                    if (!this.correctCancelReason.get('value').trim()) {
                        return this.showErrDialog('訂正・取消理由が入力されていません。');
                    }
                    var form = this.form.get('value');

                    // 取消したあと、同じ市町村内に、別の避難理由で発令中の避難情報が残っているか否かで挙動が変わる。
                    var issuesList = this.getIssuesList();
                    if (issuesList.length === 1 && issuesList[0] === this._initialIssueReasonType) {

                        // 別の避難理由で発令中の避難情報が残っていない場合、Lアラートには取消報で連携する
                        // formデータに災害IDと地区リストをセットする。
                        // 市町区をセットする。
                        this._evacOrderForm = form;
                        this.showEvacOrderCancelDialog();
                    } else {
                        // 別の避難理由で発令中の避難情報が残っていれば、Lアラートには訂正報で連携する
                        form.municipalityCd = this._municipalityCd;

                        // 災害IDをセットする。
                        form.disasterId = this._disasterId;
                        form.evacOrderType = this._evacOrderType;

                        // 避難対象人数、避難対象世帯数をセットする。
                        form.evaqueeNum = this.evaqueeNumNode.innerHTML;
                        form.evacHouseholdNum = this.evacHouseholdNumNode.innerHTML;

                        // 発令理由をセットする（disabledにしている項目はform.get('value')で対象外になるため
                        form.issueReasonType = this.issueReasonType.get('value');

                        form.evacOrderLalertAreaMap =
                            this.createLalertAreaList(this._districtTreeCache, [], null, form, true, false);

                        this._evacOrderForm = form;
                        this.showEvacOrderDialog4CorrectCancel(form, true);
                    }

                }));
            },

            /**
             * 削除ボタンが押下された際に呼ばれる
             */
            onDeleteButtonClick: function() {
                this.isFixPage = true;
                // 訂正確認画面ダイアログを表示する。
                var message = '避難情報を削除します。<br><br>' +
                        '未配信のままこの避難情報を削除する場合は、<br>OKボタンをクリックして削除を完了してください。<br><br>' +
                        '<font color="#dc143c">※一部の避難情報を訂正する場合には、<br>' +
                        'キャンセルボタンをクリックして、<br>訂正を行ってください。</font>';

                this.chain.confirm(message, lang.hitch(this,function(chain) {
                    this._cancelIssueReasonType = this.issueReasonType.get('value');
                    chain.hide();
                    var promise = Requester.del('/api/evacorders/' + this._evacOrderId, {
                        query: {
                            disasterId: this._disasterId,
                            version: this._version
                        }
                    }).then(lang.hitch(this, function(){
                        chain.info('避難情報の削除が完了しました。', '完了', lang.hitch(this, function(){
                            // ヘッダーに反映
                            topic.publish(module.id + '::updated');
                            this.evacOrderRegisterDialog.getChildren()[0].closeDialog();
                            Router.moveTo('evacorder', {
                                municipalityCd: this._municipalityCd
                            });
                        }));
                    }), lang.hitch(this, function(error){
                        if (error.response.data && typeof (error.response.data) === 'string') {
                            error.response.data = json.parse(error.response.data);
                        }
                        chain.infoError(error);
                    }));
                    Loader.wait(promise);
                }));
            },

            // 発令内容の変更があったかどうか
            _isEvacOrderTypeChanged: function() {
                return this._initialEvacOrderType !== this._evacOrderType;
            },

            /**
             * 添付ファイル選択時に呼ばれる。
             */
            loadAttachFile: function () {
                //TODO 避難情報を参考に実装する
                console.debug('EvacOrderRegisterPage#loadAttachFile()');
            }
        });
});
