/**
 * 表示情報選択ダイアログ用モジュール。
 * @module app/beginner/BeginnerLoginDialog
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/on',
    'dojo/dom',
    'dojo/dom-style',
    'dojo/text!./templates/BeginnerLoginDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase' ,// 共通基底クラス
    'dijit/TooltipDialog',
    'dijit/popup',
    'idis/model/UserInfo',
    // 以下、変数で受けないモジュール
    'dijit/layout/ContentPane',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/RadioButton',
    // 'dijit/form/Select',
    'idis/view/form/Button',
    'idis/view/form/DateInput',
    'app/beginner/DisasterChanger',
    'app/beginner/DisasterChangerDialog'
], function(module, declare, on, dom, domStyle, template,
    _PageBase, TooltipDialog, popup, UserInfo) {

    /**
     * 統計情報のダウンロード可能開始年月日
     * @private
     */
    // TODO 正確な可能開始日をセット
    var _START_DATE = '2012/04/01';

    /**
     * <クラスの説明>
     * @class BeginnerLoginDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/beginner/BeginnerLoginDialog~BeginnerLoginDialog# */
        {
        // テンプレート文字列
        templateString: template,

        // ルート要素のCSSクラス
        baseClass: 'idis-Page idis-Page--beginner/beginnerlogindialog',

        isBeginner: false,

        constructor: function() {
            // 現在日時
            this.currentDate = new Date();
            // ダウンロード可能開始日
            this.startDate = new Date(_START_DATE);
        },

        startup: function() {

            this.inherited(arguments);
            if(this.isBeginner){
                domStyle.set(this.form.domNode, 'display', '');
                domStyle.set(this.startButton.domNode, 'display', '');
                domStyle.set(this.errorMessageArea, 'display', 'none');
                this.setDisasterNameChangeTips();
            } else {
                domStyle.set(this.form.domNode, 'display', 'none');
                domStyle.set(this.startButton.domNode, 'display', 'none');
                domStyle.set(this.errorMessageArea, 'display', ''); 
            }

            if (UserInfo.getLoginMode() === '訓練') {
                // 訓練
                domStyle.set(this.labelForProd, 'display', 'none');
                domStyle.set(this.labelForTraining, 'display', '');
                domStyle.set(this.messageForProd, 'display', 'none');
                domStyle.set(this.messageForTraining, 'display', '');
            } else {
                // 実災害
                domStyle.set(this.labelForProd, 'display', '');
                domStyle.set(this.labelForTraining, 'display', 'none');
                domStyle.set(this.messageForProd, 'display', '');
                domStyle.set(this.messageForTraining, 'display', 'none');
            }
        },

        initDialog: function(args){
            this.form.reset();
            this.isBeginner = args.isBeginner;
        },

        onLogout: function(){
            if (document.logout) {
                // ログアウトフォームをサブミット
                document.logout.submit();
            }
        },

        onStart: function(){
            // ページのリロード
            // window.location.reload();
             // ダイアログを閉じる
            this.leave();
        },

        setDisasterNameChangeTips: function(){
            var disasterNameChangeTips = new TooltipDialog({
                id: 'disasterNameChangeTips',
                style: 'width: 300px; height:100px',
                content: '<p>ここに災害名変更の説明が出ます</p>'
            });
            this.own(disasterNameChangeTips);
            on(dom.byId('dialogTipsLabel'), 'mouseover', function () {
                popup.open({
                    popup: disasterNameChangeTips,
                    around: dom.byId('dialogTipsLabel')
                });
            });
            on(dom.byId('dialogTipsLabel'), 'mouseleave', function () {
                popup.close(disasterNameChangeTips);
            });
        }

    });
});
