/**
 * 避難発令テンプレート登録画面用モジュール。
 * @module app/evacorder/EvacOrderTemplateRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/text!./templates/EvacOrderTemplateRegisterDialog.html',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    './_EvacOrderPageBase',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    '../config',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'idis/view/form/Button',
    'app/view/form/CustomizableMunicipalitySelector',
    'dijit/form/Select',
    'dijit/form/ValidationTextBox',
    'idis/view/form/WordCountTextarea'
], function(module, declare, lang, json, template, Requester, DialogChain, InfoDialog, _PageBase, Loader,
    _EvacOrderPageBase, UserInfo , USER_TYPE, config) {
    /**
     * 避難発令テンプレート登録画面用モジュール。
     * @class EvacOrderTemplateRegisterDialog
     * @extends module:app/evacorder/_EvacOrderPageBase~_EvacOrderPageBase
     */

    return declare(module.id.replace(/\//g, '.'), _EvacOrderPageBase,
                   /** @lends module:app/view/page/EvacOrderTemplateRegisterDialog~EvacOrderTemplateRegisterDialog# */ {
        // テンプレート文字列
        templateString: template,

        baseClass: 'idis-Page idis-Page--evacorder',

        _fileId: null,

        infoDialog : null,

        municipalityCd : null,

        constructor: function() {
            // ベースクラスのコンストラクタを呼ぶ
            this.inherited(arguments);
            this.chain = DialogChain.get(this);
        },

        buildRendering: function() {
            this.inherited(arguments);

            //ダイアログ内の市町セレクターをセットを行う
            //初期値
            this.municipalitySelectorNode.set('value', this.municipalityCd);
            //市町ユーザーの場合(政令指定都市を除く)編集不可
            if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY){
                if(UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
                    this.municipalitySelectorNode.set('noAllButton', true);
                    this.municipalitySelectorNode.set('value', UserInfo.getMunicipalityCd());
                } else {
                     //政令指定都市ユーザーの場合市内を選択
                    this.municipalitySelectorNode.set('prefFlg', '0');
                    this.municipalitySelectorNode.set('value', UserInfo.getMunicipalityCd());
                }
        }
        },

        resize: function(changeSize, resultSize) {
            // ウィジェット内最上位要素のresizeを呼ぶことで、ダイアログ内のサイズ調整を行う。
            // このウィジェットはダイアログ内最上位のウィジェットであるため、
            // ダイアログを開く際にresizeメソッドが無いとサイズ調整が行われない
            this.form.resize(changeSize, resultSize);
        },

        /**
         * ダイアログ内容を初期化する。
         */
        // initContent: function(item) {
        //     this.inherited(arguments);
        //     this.form.reset();
        //     this.form.set('value', item);
        // },

        onSubmit: function(){
            var data = this.form.get('value');
            if (!data.municipalityCd) {
                InfoDialog.show('入力エラー', '市町を入力してください。');
                return false;
            }
            // フォームのバリデーションを実施
            if (!this.form.validate()) {
                return false;
            }
            this.chain.confirmAdd(lang.hitch(this, function(chain) {

                var jsonStr = data;
                var promise = Requester.post('/api/evacorders/template', {
                    data: jsonStr,
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : false
                }).then(lang.hitch(this, function() {
                    chain.infoComplete(function() {
                        this.chain.hide();
                        this.getParent().hide();
                        this.emit('update', {
                            value: this.form.get('value')
                        });
                    });
                }), lang.hitch(this, function(error) {
                    if (error.response.data && typeof (error.response.data) === 'string') {
                        error.response.data = json.parse(error.response.data);
                    }
                    chain.infoError(error);
                }));
                //ローダーの表示
                Loader.wait(promise);
            }));
        }
    });
});
