define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/topic',
    'idis/model/UserInfo',
    'leaflet',
    '@mapbox/leaflet-pip',
    'app/damage/integrate/IntegrationDialog',
    'app/model/DisasterInfo'
], function (module, declare, lang, array, topic, UserInfo, leaflet, pip,
    IntegrationDialog, DisasterInfo) {
    return declare(module.id.replace(/\//g, '.'), null, {
        // 受け止めるべきsubscriber
        DISABLE_DAMAGE_INTEGRATE: '/app/damage/integrate/DamageReportIntegrator::disable',

        dialog: null,
        canDraw: false,

        constructor: function (map) {
            // 呼び出し元ページからmapオブジェクトをもらう
            this.map = map;

            if (!this.map) {
                console.error('描画用のmapが空');
                return;
            }

            // 描画機能
            this.selector = new leaflet.Draw.FreeHandPolygon(this.map, {
                showMeasurements: true,
                shapeOptions: {
                    color: '#FF0000',
                    opacity: 1,
                    fill: true
                }
            });

            // MainMapで他のDialogが開かれたらSubでそれを感知して自分自身を閉じる
            topic.subscribe(this.DISABLE_DAMAGE_INTEGRATE, lang.hitch(this, function () {
                this.deactivate();
            }));
        },

        activate: function () {
            console.log('module activated');
            this.selector.enable();

            if (!this.canDraw) {
                // 範囲選択された後のaction
                this.map.on('draw:created', lang.hitch(this, function (event) {
                    this.onSelected(event);
                }));

                this.canDraw = true;
            }

            if (this.dialog && this.dialog.hasData) {
                this.dialog.show();
            }
        },

        deactivate: function () {
            console.log('module deactivated');

            // 地図画面の「被害報告統合」ボタンの色を変更する
            topic.publish(module.id + '::deactivated');

            this.selector.disable();

            if (this.canDraw) {
                this.map.off('draw:created');
                this.canDraw = false;
            }

            if (this.dialog) {
                this.dialog.hide();
            }
        },

        destroy: function () {
            this.dialog.destroy();
        },

        _isDamageReport: function (layer) {
            // 判断基準はこれだけでいいのかな。。
            return (layer.feature &&
                layer.feature.properties &&
                layer.feature.properties.disasterId &&
                layer.feature.properties.disasterId.toString() === DisasterInfo.getDisasterId() &&
                layer.feature.properties.damageReportId);
        },

        onSelected: function (event) {
            // 地図画面の「被害報告統合」ボタンの色を変更する
            topic.publish(module.id + '::deactivated');
            // pointInLayerを使うためにはgeojsonレイヤーが必要となるので
            // 事前に作る
            var geojson = leaflet.geoJson();
            geojson.addData(event.layer.toGeoJSON());

            var candidates = [];
            var damageIds = [];
            if (this.map.integrationlayers) {
                Object.keys(this.map.integrationlayers).forEach(lang.hitch(this, function (id) {
                    this.map.integrationlayers[id].eachLayer(lang.hitch(this, function (integrationlayer) {
                        var layer = integrationlayer.getLayers()[0];
                        if (this._isDamageReport(layer)) {
                            // 被害情報に複数の被害種別が設定されている場合への対応
                            // 被害情報が複数のレイヤーに紐づくことにより同じ被害が複数回処理されてしまうことを回避するため、
                            // すでに処理した被害情報IDであれば候補に含まれないようにする
                            if (damageIds.indexOf(layer.feature.properties.damageReportId) !== -1) {
                                return false;
                            }
                            damageIds.push(layer.feature.properties.damageReportId);

                            var result = pip.pointInLayer(layer.feature.geometry.coordinates, geojson);

                            // 結果があったら統合候補(candidates)に積む
                            if (result && result[0]) {
                                candidates.push(layer.feature.properties);
                            }
                        }
                    }));
                }));
            }


            // 候補一覧でダイアログを作る
            // 0件/1件しかなかったら親子関係が作れないので何もしない
            if (!candidates || candidates.length <= 1) {
                console.error('統合対象が2件未満なので統合できない');
                return;
            }
            // 異なる市町の被害を含む場合は失敗とする
            var municipalityCd = '';
            var multiMunicsFlg = false;
            array.forEach(candidates, function (candidate) {
                if (municipalityCd && municipalityCd !== candidate.municipalityCd) {
                    multiMunicsFlg = true;
                    return;
                }
                municipalityCd = candidate.municipalityCd;
            });
            if (multiMunicsFlg) {
                console.error('異なる市町村の被害を含むので統合できない');
                return;
            }
            // ユーザの管理対象市町でない場合は失敗とする
            var userMunicipalityCds = UserInfo.getMunicipalityCds();
            var userMunicFlg = false;
            array.forEach(userMunicipalityCds, function (userMunicipalityCd) {
                if (userMunicipalityCd === municipalityCd) {
                    userMunicFlg = true;
                    return;
                }
            });
            if (!userMunicFlg) {
                console.error('ユーザの管理対象市町村でないので統合できない');
                return;
            }

            // 初回呼び出し時にインスタンス生成
            if (!this.dialog) {
                this.dialog = new IntegrationDialog();
            }

            this.dialog.show();
            this.dialog.setData(candidates);
        }
    });
});
