/**
 * 職員招集管理者更新用ダイアログモジュール。
 * @module app/convocation/view/user/UserDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/UserDetailDialog.html',
    './_UserDialogBase',
    'idis/model/UserInfo',
    'idis/view/Loader',
    'idis/service/Requester',
    // 以下、変数で受けないモジュール
    'app/view/form/EmployeeDivisionSelector',
    'idis/view/form/WordCountTextarea'
], function(module, declare, lang, domStyle, template, _UserDialogBase,
    UserInfo, Loader, Requester) {
    return declare(module.id.replace(/\//g, '.'), _UserDialogBase,
    {
        // テンプレート文字列
        templateString: template,

        /**
         * constructor
         */
        constructor: function() {
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * ロールコード
         */
        _roleCd: null,

        /**
         * ロール変更可否フラグ
         */
        _isRoleCdFixed: null,

        // バージョン
        _version: null,

        // 初期表示フラグ
        _initFlg: false,

        /**
         * フォームを初期化する。
         */
        initForm: function (object) {
            this._userId = object.userId;
            var self = this;
            var page = this.form;
            this.initSelectBox().then(lang.hitch(this, function () {
                return Requester.get('/api/convoMails/users/' + this._userId);
            })).then(function (data) {
                var item = data;
                delete item.userPassword;
                page.set('value', item);
                self.roleCdSelect.set('value', item.roleCd);
                self.organizationSelector.set('value', item.organization);
                self._roleCd = item.roleCd;
                self._version = item.version;
            });
            this._initFlg = true;
        },

        /**
         * 職員招集管理者を更新する。
         */
        onSubmit: function() {
            try {
                console.debug('[更新]ボタンがクリックされました。');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if (!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');
                value.userId = this._userId;
                if (this._isRoleCdFixed) {
                    value.roleCd = this._roleCd;
                }

                value.version = this._version;

                delete value.passwordConfirm;

                this.emit('update', { value: value });
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        onDelete:function(){
            console.debug('[削除]ボタンがクリックされました。');
            var value = this.form.get('value');
            value.userId = this._userId;
            value.version = this._version;
            this.emit('remove', {value: value});
        },

        /**
         * ダイアログを初期化する。（更新時）
         */
        initDialog: function(object) {
            this.reset();

            this.initForm(object);
            this.userId.innerHTML = this._userId;
            // 職員招集管理者が変更可能な場合はSelect要素、不可な場合は固定文字列を表示
            this._isRoleCdFixed = UserInfo.getRoleCd() !== 'R01001';
            this.roleCdSelect.set('disabled', this._isRoleCdFixed);
        },

        // 役割セレクトボックスの初期化を行う
        initSelectBox: function () {
            var promise = Requester.get('/api/roles/convocation');
            return Loader.wait(promise).then(lang.hitch(this, function (data) {
                // セレクトボックスに設定するデータの配列を作成
                var newOptions = [];
                // サーバーレスポンスの各要素をselectへ追加
                data.items.forEach(function (object) {
                    newOptions.push({
                        label: object.roleName,
                        value: object.roleCd
                    });
                });
                this.roleCdSelect.set('options', newOptions);
            }));
        },

        // 役割のセレクトボックスの値が変更された場合の処理
        onRoleCdSelectChanged: function() {
            if (!this._initFlg) {
                this.organizationSelector.set('value', '');
            }
        }
    });
});
