/**
 * ユーザーに紐付く市町選択用入力パーツ避難情報画面では変更後にtopicを発行する。
 * @module app/evacorder/EvacOrderMunicipalitySelector
 */
define([
    'module',
    'dojo/topic',
    'dojo/_base/declare',
    'idis/model/UserInfo',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'app/view/form/MunicipalityListSelector'
], function(module, topic, declare, UserInfo, CacheStoreModel, CacheTreeRest, MunicipalityListSelector) {
    /**
     * 市町選択用パーツ（避難情報）
     * @class FacilityTypeSelector
     * @extends module:idis/view/tree/ShelterFacilitySelector~ShelterFacilitySelector
     */
    return declare(module.id.replace(/\//g, '.'), MunicipalityListSelector,
        /** @lends module:app/evacorder/EvacOrderMunicipalitySelector~EvacOrderMunicipalitySelector*/ {

        // 選択ダイアログのタイトル
        title: '市町',

        // ツリー・モデル
        model: null,

        constructor : function() {

            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/municipalities/damageMunicipality4Tree'
                })
            });
        }

    });
});
