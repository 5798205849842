/**
 * 職員招集管理者登録用ダイアログモジュール。
 * @module app/convocation/view/user/UserRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/UserRegisterDialog.html',
    'idis/view/Loader',
    'idis/service/Requester',
    './_UserDialogBase',
    // 以下、変数で受けないモジュール
    'app/view/form/EmployeeDivisionSelector',
    'idis/view/form/WordCountTextarea'
], function(module, declare, lang, domStyle, template, Loader, Requester, _UserDialogBase) {
    return declare(module.id.replace(/\//g, '.'), _UserDialogBase,
    {
        // テンプレート文字列
        templateString: template,

        /**
         * constructor
         */
        constructor: function() {
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * 職員招集管理者を新規登録する。
         */
        onSubmit: function() {
            try {
                console.debug('[登録]ボタンがクリックされました。');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');
                value.newUserId = value.userId;
                delete value.userId;
                delete value.passwordConfirm;

                this.emit('register', {value: value});
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * ダイアログを初期化する。（新規登録時）
         */
        initDialog: function() {
            this.reset();

            this.initSelectBox();
            this.organizationSelector.set('value', '');
        },

        // 役割セレクトボックスの初期化を行う
        initSelectBox: function () {
            var promise = Requester.get('/api/roles/convocation');
            Loader.wait(promise).then(lang.hitch(this, function (data) {
                // セレクトボックスに設定するデータの配列を作成
                var newOptions = [{
                    label: '--選択してください--',
                    value: ''
                }];
                // サーバーレスポンスの各要素をselectへ追加
                data.items.forEach(function (object) {
                    newOptions.push({
                        label: object.roleName,
                        value: object.roleCd
                    });
                });
                this.roleCdSelect.set('options', newOptions);
            }));
        },

        // 役割のセレクトボックスの値が変更された場合の処理
        onRoleCdSelectChanged: function() {
            this.organizationSelector.set('value', '');
        }
    });
});
