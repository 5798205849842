/**
 * 詳細ダイアログ用の基底モジュール。
 * @module app/formDisplay/_formDisplayDialogBase
 */
define([
    'module',
    'leaflet',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'idis/view/form/AclButton',
    'idis/view/form/Button'
], function(module, leaflet, declare, lang, _PageBase, InfoDialog) {

    return declare(module.id.replace(/\//g, '.'), _PageBase,
    {
        /**
         * 画面ID
         */
         screenId: null,

        /**
         * ディスプレイ種別
         */
        _displayTypeName: null,

        /**
         * 備考
         */
        _comment: null,

        DAMAGE_GRID_TYPE_SELECTED: 'app/formDisplay/damagetype/DamageGridTypeSelector::selected',
        DAMAGE_GRID_TYPE_RELEASED: 'app/formDisplay/damagetype/DamageGridTypeSelector::released',

        /**
         * 災害発生日時
         */
         _disasterStartTimestamp: null,
         _disasterEndTimestamp: null,

        /**
         * 被害種別 リスト
         * @private
         */
        _damageType: null,
        _damageTypeList: null,

        /**
         * constructor
         */
        constructor: function() {
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * フォームをリセットする。
         */
        reset: function() {
            this.form.reset();
        },

        /**
         * 入力値の妥当性を検証する。
         */
        validate: function() {
            var formData = this.form.get('value');
            // 所轄組織
            if (formData.displayType !== 1 && formData.displayType !== 12 && formData.disasterId === '') {
                InfoDialog.show('入力チェック', '災害名が選択されていません');
                return false;
            }
            if (formData.startTimestamp !== null && formData.endTimestamp !== null) {
                if (formData.startTimestamp >= formData.endTimestamp) {
                    InfoDialog.show('入力チェック', '開始と終了を指定する場合、終了日時は開始日時以降を入力してください');
                    return false;
                }
            }
            if (formData.displayType === 12) {
                if (formData.dateSelect !== '' || formData.timeSelect !== '') {
                    if (formData.dateSelect && !formData.timeSelect){
                        InfoDialog.show('入力チェック', '時刻を選択する場合、日付と時刻を両方入力してください。');
                        return false;
                    } else if (!formData.dateSelect && formData.timeSelect) {
                        InfoDialog.show('入力チェック', '時刻を選択する場合、日付と時刻を両方入力してください。');
                        return false;
                    }
                }
                if (!formData.riverLevel) {
                    InfoDialog.show('入力チェック', '種別が「河川水位」の場合、水位を選択してください。');
                    return false;
                }
            }
            // ここでチェックする必要がある項目がないためtrueを返す
            return true;
        },

        setDamageType: function (data) {
            console.log('set Damage Type');
            this._damageType = data.mainTypeIds[0];
            this._damageTypeList = data.mainTypeIds.join(',');
        },

        releaseDamageType: function () {
            this._damageType = null;
            this._damageTypeList = null;
        }
    });
});
