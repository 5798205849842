/**
 * 送信メールテンプレートグリッド
 * @module app/convocation/view/mail/ConvoMailTempGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'dojo/_base/lang',
    'idis/control/Router',
    'idis/consts/ACL', 
    'idis/view/form/AclButton',
    'idis/view/grid/helper'
], function(module, declare, IdisGrid, lang, Router, ACL, AclButton, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        /**
         * 各列の定義
         * @type {Object[]}
         */
        collection: this.store,
        columns: [
            helper.buttonColumn('detail', '詳細'),
            helper.column('distributionTitle', '件名', {sortable: false}),
            helper.column('distributionBody', '本文', {sortable: false})
        ]
    });
});
