/**
 * 消防庁被害報告詳細ダイアログ用モジュール
 * @module app/view/page/FDMAReportDetailDialog
 */
 define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/json',
    'dojo/text!./templates/FDMAReportDetailDialog.html', // テンプレート文字列
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase', // 共通基底クラス
    'app/model/DisasterInfo',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/view/Loader',
], function(module, declare, json, template, DialogChain, _PageBase, DisasterInfo, Router, Requester, Loader) {
    /**
     * 消防庁被害報告詳細ダイアログ
     * @class FDMAReportDetailDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/damage/DamageReportPage~DamageReportPage# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * 災害ID
         */
        _disasterId: null,

        constructor: function() {
            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        cancelFDMAreport: function() {
            var sendData = this.form.get('value');
            // フォームのバリデーションを行う（共通部品）
            if(!this.form.validate()) {
                return false;
            }

            if (!sendData.correctCancelReason || sendData.correctCancelReason === '') {
                this.chain.info('訂正・取消理由を入力してください。', '入力エラー');
                return false;
            }

            sendData.disasterId = this._disasterId;
            var jsonStr = json.stringify(sendData);
            this.chain.confirmPut(function(chain) {
                var promise = Requester.put('/api/fdmaReports/delete', {
                    data: jsonStr
                }).then(function() {
                    console.debug('success update FDMAReport');
                    chain.infoComplete(function(){
                        Router.moveTo('fdmaReport');
                    });
                }, function(error) {
                    console.log(error);
                    chain.infoError(error);
                });
                //ローダーの表示
                Loader.wait(promise);
            });
        }
    });
});