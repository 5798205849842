/**
 * 施設種別登録ダイアログ用モジュール。
 * @module app/facility/_FacilityTypeBase
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/on',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/consts/ACL',
    'idis/view/form/AclButton',
    'dijit/layout/BorderContainer',
    'app/sample/view/SampleGrid',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/form/Form',
    'idis/view/form/DateTimeInput',
    'dijit/layout/AccordionContainer',
    'dijit/TitlePane',
    'dijit/form/Textarea',
    'app/view/form/ShelterSelector',
    'dijit/TitlePane',
    '../draw/DrawSelectIconDialog'
], function(module, declare, lang, domStyle, on, _PageBase) {

    /**
     * 施設種別詳細ダイアログ
     * @class FacilityTypeDetailDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
                   /** @lends module:app/facility/_FacilityTypeBase~_FacilityTypeBase# */ {

        confirmDialog: null,

        // icon用imageまでの相対パス
        _defaultIconPath: '/images/draw/icon/',

        _iconUrl: null,

        iconSelect: function(){
            this.iconSelectDialog.show();
        },

        onChangeIcon: function(){
            on(this.iconSelectDialog.inner.iconSelectDone, 'click',
                lang.hitch(this, function(){
                    // 初回だけデータがUndefinedになる対策
                    if (this.iconSelectDialog.selectedIcon) {
                        this.selectedIcon.src = this._defaultIconPath + this.iconSelectDialog.selectedIcon.name;
                        this._iconUrl = this.iconSelectDialog.selectedIcon.name;
                    }
            }));
        },

        reset: function() {
            this.form.reset();
        },

        validate: function() {
            return true;
        }
    });
});
